import React, { Component } from "react";

import { getLpSectionInformation } from "../../../actions/index";
import { getInfoUhoo } from "../../../actions/dataUhoo.actions";
import SocialNetworksLayoutIndex from "./layoutStyles/SocialNetworksLayoutIndex.jsx";

class SocialNetworksSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      SocialNetworksTitle: null,
      SocialNetworksTwitter: null,
      SocialNetworksFacebookUrl: null,
      SocialNetworksInstagram: null,
      SocialNetworksAurapp: null,
      urlCut: "",
    };
  }

  componentDidMount() {
    this.getSocialNetworksInformation();
  }

  async getSocialNetworksInformation() {
    let response = await getLpSectionInformation(
      this.props.lpId,
      this.props.lpSectionId,
      "SOCIALNETWORKS"
    );
    let sectionInfo = response.data.data;

    let SocialNetworksComponent = sectionInfo.components.find(
      (comp) => comp.name === "SocialNetworksContainer"
    );
    let SocialNetworksId = sectionInfo.id;
    let SocialNetworksTitle = SocialNetworksComponent.nodes.find(
      (node) => node.nodeType === "SocialNetworksContainerTitle"
    ).value;

    let SocialNetworksTwitter = SocialNetworksComponent.nodes.find(
      (node) => node.nodeType === "SocialNetworksTwitterUsername"
    );
    SocialNetworksTwitter = SocialNetworksTwitter
      ? SocialNetworksTwitter.value
      : "";

    let SocialNetworksFacebookUrl = SocialNetworksComponent.nodes.find(
      (node) => node.nodeType === "SocialNetworksFacebookUrl"
    );
    SocialNetworksFacebookUrl = SocialNetworksFacebookUrl
      ? SocialNetworksFacebookUrl.value
      : "";

    let SocialNetworksInstagram = SocialNetworksComponent.nodes.find(
      (node) => node.nodeType === "SocialNetworksInstagramPost"
    );
    SocialNetworksInstagram = SocialNetworksInstagram
      ? SocialNetworksInstagram.value
      : "";

    let SocialNetworksAurapp = SocialNetworksComponent.nodes.find(
      (node) => node.nodeType === "SocialNetworksUhooToken"
    );
    SocialNetworksAurapp = SocialNetworksAurapp
      ? SocialNetworksAurapp.value
      : "";

    SocialNetworksAurapp = "";
    if (SocialNetworksAurapp != "") {
      const dataAurApp = await getInfoUhoo(SocialNetworksAurapp);
      this.setState({
        SocialNetworksAurapp: dataAurApp,
      });
    } else {
      this.setState({
        SocialNetworksAurapp: "",
      });
    }
    let background = "";
    if (this.props.background === true) {
      background = "#ffff";
    }
    if (this.props.background === false) {
      background = "#eff5f5";
    }

    let layout = sectionInfo.lpSectionLayoutStyleId;
    if (layout === 14) { //Standar layout social networks
      layout = 0;
    }
    this.setState({
      SocialNetworksId,
      SocialNetworksTitle,
      SocialNetworksTwitter,
      SocialNetworksInstagram,
      SocialNetworksFacebookUrl,
      layout,
      background,
    });
    this.organizationInformation();
  }

  organizationInformation() {
    let SocialNetworksFacebookUrl = this.state.SocialNetworksFacebookUrl;
    let SocialNetworksInstagram = this.state.SocialNetworksInstagram;
    var regex1 = /www.instagram.com/;
    var result = regex1.test(SocialNetworksInstagram);
    if (result === false) {
      this.setState({
        SocialNetworksInstagram: "",
      });
    }

    var regex2 = /www.facebook.com/;
    var result = regex2.test(SocialNetworksFacebookUrl);
    if (result === false) {
      this.setState({
        SocialNetworksFacebookUrl: "",
      });
    } else {
    
      this.setState({
        SocialNetworksFacebookUrl
      });
    }
  }

  renderPostComponents(posts) {
    let postsComponents = [];
    if (posts) {
      posts.data.data.forEach((element, index) => {
        postsComponents.push(
          <Post
            key={Math.random() * 1000}
            title={element.title}
            description={element.content}
            date={element.created_at}
            image={element.media}
            name={element.user.name}
            lastName={element.user.lastname}
            approved={element.approved}
            active={element.active}
            banned={element.banned}
          />
        );
      });
    }
    return postsComponents;
  }

  renderComponentSocialNetworks() {
    let renderedComponent = [];
    const { theme } = this.props;
    let SocialNetworksTitle = this.state.SocialNetworksTitle;
    let SocialNetworksFacebookUrl = this.state.SocialNetworksFacebookUrl;
    let SocialNetworksInstagramPost = this.state.SocialNetworksInstagram;
    let SocialNetworksAurapp = this.state.SocialNetworksAurapp;
    let SocialNetworksTwitterUsername = this.state.SocialNetworksTwitter;
    let urlCut = this.state.urlCut;
    
    renderedComponent.push(
      <SocialNetworksLayoutIndex
        // key={this.state.SocialNetworksId}
        key={Math.random() * 1000}
        theme={theme}
        SocialNetworksTitle={SocialNetworksTitle}
        SocialNetworksFacebookUrl={SocialNetworksFacebookUrl}
        SocialNetworksInstagramPost={SocialNetworksInstagramPost}
        SocialNetworksAurapp={SocialNetworksAurapp}
        SocialNetworksTwitterUsername={SocialNetworksTwitterUsername}
        urlCut={urlCut}
        layout={this.state.layout}
        renderPostComponents={this.renderPostComponents}
      />
    );
    return renderedComponent;
  }

  render() {
    const { background } = this.state;

    return (
      <div
      //  style={{ background: background }}
       >
        <section id={"socialnetworks" + this.state.SocialNetworksId}>
          <div className="containerAux">
            {this.renderComponentSocialNetworks()}
          </div>
        </section>
      </div>
    );
  }
}

export default SocialNetworksSection;
