import React, { useEffect, useState } from "react";
import { get as _get } from "lodash";
import "../index.scss";

import { Drawer as DrawerBase, Box, IconButton, Toolbar, Typography, Divider, styled } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    // padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
    "& .MuiPaper-root": {
        
    }
}));

const Drawer = styled(DrawerBase)((props) => {
    let background = "lightblue";
    if(props.baseTheme && props.baseTheme.color) {
        background = props.baseTheme.color
    }
    return {
        "& .MuiPaper-root": {
            background: "white",
            margin: "0px"
        }
    }
});
  

export function HeaderLogoRightLayout(props) {
    let { buttonHeader, theme } = props;
    let headerFixedStyle = "linear-gradient(45deg, rgb(23,162,184), rgb(23,162,184))";
    let headerHomeStyle = "linear-gradient(45deg, rgba(23,162,184, 0.5), rgba(23,162,184, 0.5))";
    if (theme && theme.headerBackground) {
    if (theme.headerBackground.fixed) {
        headerFixedStyle = theme.headerBackground.fixed;
    }
    if (theme.headerBackground.home) {
        headerHomeStyle = theme.headerBackground.home;
    }
    }
    let fontFamilyColor;
    let fontFamily;
    if (theme && theme.headerTitles) {
      fontFamilyColor = theme.headerTitles.color;
    }
    if (theme && theme.headerTitles) {
      fontFamily = theme.headerTitles.fontFamily;
    }

    // responsive
    const [ mobileView, setMobileView ] = useState(false);
    const [ drawerOpen, setDrawerOpen ] = useState(false);

    useEffect(() => {
      const setResponsiveness = () => {
        if(window.innerWidth < 900) {
            setMobileView(true)
        } else {
            setMobileView(false);
        }
      };
      setResponsiveness();
      window.addEventListener("resize", () => setResponsiveness());
      return () => {
        window.removeEventListener("resize", () => setResponsiveness());
      }
    }, []);
  

    const handleDrawerOpen = () => setDrawerOpen(true)
    const handleDrawerClose = () => setDrawerOpen(false);
    
    return (
        // <div className="headerLogoRightContainer" style = {{width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
            <div className="headerLogoRightContainer" style={{width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center"}} >
                {/* <Container> */}
                    <>
                        {
                            mobileView ? (
                                <>
                                    <IconButton
                                        {...{
                                            edge: "start",
                                            color: "inherit",
                                            "aria-label": "menu",
                                            "aria-haspopup": "true",
                                            onClick: handleDrawerOpen,
                                        }}
                                    >
                                        <MenuIcon />
                                    </IconButton>
                                    <Drawer
                                        baseTheme={theme && theme.sectionTitle ? theme.sectionTitle : null}
                                        {...{
                                            variant: "persistent",
                                            anchor: "left",
                                            open: drawerOpen,
                                            onClose: handleDrawerClose,
                                        }}
                                        style={{ paddingRight: "20px" }}
                                    >
                                        <DrawerHeader>
                                            <IconButton onClick={handleDrawerClose}>
                                                <ChevronLeftIcon />
                                            </IconButton>
                                        </DrawerHeader>
                                        <Divider />
                                        { props.headerComponentInfo }
                                        {
                                            buttonHeader ? 
                                                <Box sx={{ display: "flex", alignItems: "center", paddingLeft: "16px", paddingRight: "16px" }}>
                                                    <Typography>
                                                        <a target="_blank" href={_get(buttonHeader, "externalLink", "")}>
                                                            <button
                                                            className='buttonHeader'
                                                            style={{
                                                                "--borderColorButton": 'white',
                                                                background: headerHomeStyle,
                                                                "--colorText": 'white',
                                                                fontFamily: fontFamily,
                                                                fontSize: "1.3rem"
                                                            }}
                                                            >
                                                            { _get(buttonHeader, "name", "") }
                                                            </button>
                                                        </a>
                                                    </Typography>
                                                </Box>
                                            : null
                                        }
                                    </Drawer>
                                </>

                            ) : 
                            <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
                                {props.headerComponentInfo}
                                {
                                    buttonHeader ? 
                                        <Box sx={{ display: "flex", alignItems: "center", paddingLeft: "16px", paddingRight: "16px" }}>
                                            <Typography>
                                                <a target="_blank" href={_get(buttonHeader, "externalLink", "")}>
                                                    <button
                                                    className='buttonHeader'
                                                    style={{
                                                        "--borderColorButton": 'white',
                                                        background: headerHomeStyle,
                                                        "--colorText": 'white',
                                                        fontFamily: fontFamily,
                                                        fontSize: "1.3rem"
                                                    }}
                                                    >
                                                    { _get(buttonHeader, "name", "") }
                                                    </button>
                                                </a>
                                            </Typography>
                                        </Box>
                                    : null
                                }
                            </Box>
                        }
                    </>
                   { props.icon ? 
                    <div id="logo2">
                        <div className="col-auto">
                            <a href="/#home"  className="scrollto" >
                                <img className="imgLogo" src={props.icon} alt="headerLogo" />
                            </a>
                        </div>
                    </div>
                    : null}
                {/* </Container> */}
            </div>

        // </div>
    );
}
