import ReactGA from "react-ga4";

let analitic = {
  initialized: false,
  initialize: function(traking) {
    ReactGA.initialize(traking);
    ReactGA.send("pageview")
    this.initialized = true;
  },
  setPage: function(location) {
    if(this.initialized) {
      // ReactGA.send({ page: location })
      ReactGA.send("pageview")
    }
  },
  setEvent: function(event) {
    if(this.initialized) {
      ReactGA.event(event)
    }
  }
}

export default analitic;