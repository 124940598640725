import React from "react";
import { Link } from "react-router-dom";
import imageNull from "../../../../../images/noImage.jpg";
import moment from "moment";

//Localization
import Strings from "../../../../../reactUtils/systemVariables/languageLocalization/LocalizedStrings.jsx";
import ButtonsContainer from "../../../general/ButtonsContainer.jsx";
import ButtonsSocialMedia from "../../../general/ButtonsSocialMedia.jsx";
import "./index.scss";
var dateOnlyFormat = "MMMM DD, YYYY";
var dateStartTime = "MMMM DD, YYYY";

export function StandarLayout(props) {
  const id = props.id;
  const theme = props.theme;
  let display = "flex";
  let dataFixedValue = "";
  if (props.fixedValue !== "-1") {
    dataFixedValue =  new Intl.NumberFormat('es-CO', { style: 'currency', currency: 'COP' }).format(props.fixedValue);
    dataFixedValue = dataFixedValue.replace(",00", "").toLocaleString("es");
  } else {
    dataFixedValue = Strings.donations.voluntary;
  }

  let startDate;

  if (props.news) {
    startDate = moment(props.date).format(dateOnlyFormat);
  } else if (props.events) {
    startDate = moment(props.date).format(dateStartTime);
  } else if (props.courses) {
    startDate = props.date ? moment(props.date).format(dateStartTime) : null;
  }

  if (props.individualStyle) {
    display = "block";
  }
  var event = {};
  if (props.events) {
    event = {
      title: props.title,
      description: props.text,
      startTime: moment(props.date).format(dateStartTime),
      endTime: moment(props.date).add(1, "hours").format(dateStartTime),
      location: props.place,
    };
  }

  let marginAdjusted = "";
  if (screen.width < 769) {
    marginAdjusted = "0px";
  } else {
    marginAdjusted = "50px";
  }
  let multimedia = null;

  let idVideo = null;
  if (props.multimedia && props.multimedia[0] == "isUploadedImage") {
    multimedia = props.multimedia[3]; //large Size
    if(window.innerWidth < 900) { // mobile 
      multimedia = props.multimedia[2]; //medium Size
    }
  } else if (props.multimedia && props.multimedia[0] == "isVideo") {
    idVideo = props.multimedia[1];
  } else if (props.multimedia && props.multimedia[0] == "isLinkedImage") {
    multimedia = props.multimedia[1].value;
  }

  let buttonLinkPath = "/component/"
  if (props.donations) {
    buttonLinkPath = "/payment/"
  }

  let colorBackground = theme && theme.headerBackground && theme.headerBackground.home 
  ? theme.headerBackground.home : 'linear-gradient(45deg, #6c757d, #6c757d);'
  let colorText = theme && theme.headerTitles && theme.headerTitles.color 
  ? theme.headerTitles.color : 'black;'
  let fontFamilyText = theme && theme.headerTitles && theme.headerTitles.fontFamily 
  ? theme.headerTitles.fontFamily : 'Verdana, sans-serif;'


  // check image container
  let existText = !!props.events || (props.id && props.buttons.length > 0) || !!(props.fixedValue && props.fixedValue.length) || !(props.text && props.text.props && props.text.props.children ? props.text.props.children : props.text)
  
  return (
    <div className="containerAux">
      <div className="row">
        <div className="containerAux">
          <div className="row">
            <div className="col-xs-12 col-md-12 spaceLayout1 wow fadeIn" data-wow-duration="1s">
              <Link to={{ pathname: buttonLinkPath + id }}>
                <h3 style={theme && theme.sectionSubtitle ? theme.sectionSubtitle : null + props.individualStyle && props.individualStyle.title
                  ? props.individualStyle.title : null}>
                  {props.title}
                </h3>
              </Link>
            </div>
            {/* <div className="row alignItems" style={{ 'align-items': 'flex-start' }}> */}
            <div className="row alignItems">
              {
                !existText ?
                // {/* text */}
                <div className={`col-xs-12 col-md-6 containerGeneralText`}>
                  <div className="containerBody">
                    
                  {/* {props.author !== "" && props.author !== " " && props.author !== null? (
                      <div className="col-xs-12 col-md-12">
                        <p style={theme && theme.sectionText1 ? theme.sectionText1 : null + theme && theme.sectionText2 ? theme.sectionText2 : null} className="body compact">
                          <strong> {Strings.sections.author}{" : "} </strong> <span style={{ paddingLeft: '5px' }}>{props.author}</span>
                        </p>
                      </div>
                    ) : null}
                    {props.responsible != "" && props.responsible != " " && props.responsible !== null ? (
                      <div className="col-xs-12 col-md-12">
                        <p style={theme && theme.sectionText1 ? theme.sectionText1 : null + theme && theme.sectionText2 ? theme.sectionText2 : null} className="body compact">
                          <strong>{Strings.basicWords.responsible} {" : "}  </strong><span style={{ paddingLeft: '5px' }}>{props.responsible}</span>
                        </p>
                      </div>
                    ) : null}
                    {props.place != "" && props.place != " " && props.place != null ? (
                      <div className="col-xs-12 col-md-12">
                        <p style={theme && theme.sectionText1 ? theme.sectionText1 : null + theme && theme.sectionText2 ? theme.sectionText2 : null} className="body compact last">
                          <strong> {Strings.basicWords.place} {" : "} </strong><span style={{ paddingLeft: '5px' }}>{props.place}</span>
                        </p>
                      </div>
                    ) : null} */}

                    {props.fixedValue ? (
                      <div className="col-xs-12 col-md-12">
                  <div className="col-xs-12 col-md-12 text-justify">
                    <p style={theme && theme.sectionText1 ? theme.sectionText1 : null + props.individualStyle && props.individualStyle.text ? props.individualStyle.text : null} id="textDescription"   >
                      {props.text && props.text.props && props.text.props.children ? props.text.props.children : props.text}
                    </p>
                  </div>
                  <br/>
                  {startDate != null ? (
                      <div className="col-xs-12 col-md-12">
                        <p style={  theme && theme.sectionText1 ? theme.sectionText1 : null + theme && theme.sectionText2  ? theme.sectionText2 : null} className="body compact">
                          <span>{startDate}</span>
                        </p>
                      </div>
                    ) : null}
                              <p style={theme && theme.sectionText1 ? theme.sectionText1 : null + props.individualStyle && props.individualStyle.text ? props.individualStyle.text : null} id="textDescription"   >
                          <strong> {Strings.basicWords.value}{": "}{" "}</strong> <span style={{ paddingLeft: '5px' }}>{dataFixedValue}</span>
                        </p>
                      </div>
                    ) : 
                    <div className="col-xs-12 col-md-12 text-justify">
                    <p style={theme && theme.sectionText1 ? theme.sectionText1 : null + props.individualStyle && props.individualStyle.text ? props.individualStyle.text : null} id="textDescription"   >
                      {props.text && props.text.props && props.text.props.children ? props.text.props.children : props.text}
                    </p>
                  </div>
                    }
                  </div>
                  {props.events ? (
                    <div className="col-xs-12 col-md-12 text-justify">
                      <div>
                        <ButtonsContainer showBorder={false} buttons={props.buttons} event={event} theme={theme} internalLpSectionComponentId={id} readMore={true} style='containerLinearButtons' />
                      </div>
                    </div>
                  ) : (
                    <div>             
                      {props.id ? (
                          <div className="text-justify" style={{ paddingLeft: '8px' }}> <ButtonsContainer showBorder={false} buttons={props.buttons} theme={theme} internalLpSectionComponentId={id} readMore={true} style='containerLinearButtons'/></div>
                      ) : (
                        null
                      )}
                    </div>
                  )}
                </div>
              : null
              }
              {/* image */}
              <div className={`${!existText ? "col-xs-12 col-md-12" : "col-xs-12 col-md-6"} centerImage fadeInRight`}>
                <Link to={{ pathname: buttonLinkPath + id }}>
                  {multimedia === null && idVideo == null ? (
                    <div className="box" style={{  "--widthHeigth": '350px', }} >
                      <img style={ theme && theme.imagesShape ? theme.imagesShape : null}
                        width={props.individualStyle &&props.individualStyle.imageWidth? props.individualStyle.imageWidth: null }
                        src={imageNull}
                        alt="image"
                      />
                    </div>
                  ) : null}

                  {idVideo != null ? (
                    <iframe
                      className="videoNewsStandar"
                      src={`https://www.youtube.com/embed/${idVideo}?version=3&loop=1&autoplay=0&controls=1&;rel=0&playlist=${idVideo}`}
                      frameBorder="0"
                      allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    ></iframe>
                  ) : null}

                  {idVideo == null && multimedia ? (
                    <div className="col-xs-12 col-md-12">
                      <div className="centerImage">
                        <div className="box" style={{ "--widthHeigth": !existText ? "90vw" : "490px" }}>
                          <img
                            style={theme && theme.imagesShape  ? theme.imagesShape : null }
                            width={ props.individualStyle && props.individualStyle.imageWidth ? props.individualStyle.imageWidth : null }
                            src={multimedia}
                            alt="image"
                          />
                        </div>
                      </div>
                    </div>
                  ) : null}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
