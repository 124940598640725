import axios from 'axios';

import { host, port, route, datasource } from '../reactUtils/systemVariables/lpserverInformation';

const resource = "/lpsection";

export async function getLpIdByUrl(lpUrl) {
    try {
        let response = await axios.get(
            host + port + route + datasource + resource, {
            params: {
                lp_url: lpUrl
            },
            headers: {
                'Content-Type': 'application/json'
            }
        })
        return response;
    } catch (error) {
        return error;
    }
}

export async function getLpSectionsOrder(lpId) {
    try {
        let response = await axios.get(
            host + port + route + datasource + resource, {
            params: {
                lp_id: lpId
            },
            headers: {
                'Content-Type': 'application/json'
            }
        })
        return response;
    } catch (error) {
        return error;
    }
}

export async function getLpSectionInformation(lpId, lpSectionId, sectionName) {
    try {
        let response = await axios.get(
            host + port + route + datasource + resource, {
            params: {
                lp_id: lpId,
                lpsection_id: lpSectionId,
                section_name: sectionName
            },
            headers: {
                'Content-Type': 'application/json'
            }
        })
        return response;
    } catch (error) {
        return(error)
    }
}

export async function getSectionInformationBetweenDates(lpId, lpSectionId, sectionName, startDate, endDate) {
    try {
        let response = await axios.get(
            host + port + route + datasource + resource, {
            params: {
                lp_id: lpId,
                lpsection_id: lpSectionId,
                section_name: sectionName,
                start_date: startDate,
                end_date: endDate
            },
            headers: {
                'Content-Type': 'application/json'
            }
        })
        return response;
    } catch (error) {
        return(error)
    }
}

export async function getNewsSectionInformationByTopLimit(lpId, lpSectionId, topLimit) {
    try {
        let response = await axios.get(
            host + port + route + datasource + resource + "/news", {
            params: {
                lp_id: lpId,
                lpsection_id: lpSectionId,
                top_limit: topLimit
            },
            headers: {
                'Content-Type': 'application/json'
            }
        })
        return response;
    } catch (error) {
        return(error)
    }
}

export async function getNewsSectionByLpIdPagedAndOrdered(lpId, lpSectionId, pageIdx, pageSize, attribute, order) {
    try {
        let response = await axios.get(
            host + port + route + datasource + resource + "/news", {
            params: {
                lp_id: lpId,
                lpsection_id: lpSectionId,
                page_idx: pageIdx,
                page_size: pageSize,
                attribute: attribute,
                order: order
            },
            headers: {
                'Content-Type': 'application/json'
            }
        })
        return response;
    } catch (error) {
        return(error)
    }
}

export async function getLpSectionComponentParentInformation(lpId, lpSectionId, sectionName) {
    try {
        let response = await axios.get(
            host + port + route + datasource + resource + "/parent", {
            params: {
                lp_id: lpId,
                lpsection_id: lpSectionId,
                section_name: sectionName
            },
            headers: {
                'Content-Type': 'application/json'
            }
        })
        return response;
    } catch (error) {
    }
}

export async function getDonationSectionByActiveState(lpId, lpSectionId, activeState) {
    try {
        let response = await axios.get(
            host + port + route + datasource + resource + "/donations", {
            params: {
                lp_id: lpId,
                lpsection_id: lpSectionId,
                active_state: activeState,
            },
            headers: {
                'Content-Type': 'application/json'
            }
        })
        return response;
    } catch (error) {
        return(error)
    }
 }