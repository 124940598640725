import React from "react";
import { Link } from "react-router-dom";
import imageNull from "../../../../../images/noImage.jpg";
import ButtonsContainer from "../../../general/ButtonsContainer.jsx";
import Grid from '@material-ui/core/Grid'
import "./index.scss";

export function Layout6(props) {
  let { id, theme, title, text, buttons, multimedia } = props;
  let buttonLinkPath = "/component/"
  if (props.donations) {
    buttonLinkPath = "/payment/"
  }

  let image = imageNull;
  if (multimedia && multimedia[1]) {
    image = multimedia[1].value ? multimedia[1].value : multimedia[1]
  }
  if (multimedia && multimedia[3]) {
    image = multimedia[3].value ? multimedia[3].value : multimedia[3];
  }
  
  let  headerHomeStyle = theme && theme.headerBackground && theme.headerBackground.home ? theme.headerBackground.home : 'linear-gradient(45deg, #7a7a7a, #0000007a);';

  // let principalColor =  theme &&  theme.headerTitles &&theme.headerTitles.color ?  theme.headerTitles.color : 'white';
  // let secondaryColor = theme &&  theme.sectionTitle &&theme.sectionTitle.color ?  theme.sectionTitle.color : 'black';
  // console.log("principalColor", principalColor);
  // console.log("secondaryColor", secondaryColor);
  // let  headerHomeStyle = `linear-gradient(45deg, ${principalColor}, ${secondaryColor})`;
  // console.log("theme", theme);

  return (
    <div className='col-xs-12 col-sm-6 col-md-4 col-lg-3 content wow fadeInRight animated .align-items-center' style={{'margin': '10px 0px'}}>
      <Link to={{ pathname: buttonLinkPath + id }}>
        <div className="cardL6">
          <img src={image} alt="image" />
          <h3 style={ theme && theme.sectionSubtitle ? theme.sectionSubtitle : null }>{title}</h3>
          {/* <span className="section-divider"></span> */}
          {/* <p style={ theme && theme.sectionText1 ? theme.sectionText1 : null + props.individualStyle && props.individualStyle.text ? props.individualStyle.text : null }
            className="textDescription"> {text}
          </p> */}
          <ButtonsContainer layout6={true} buttons={buttons} theme={theme} internalLpSectionComponentId={id} readMore={true} showBorder={false} typeLink={false} componentId={id} style='containerLinearButtons' />
          {/* <div className="linearGradient" style={{"--backgroundColor": headerHomeStyle}}/> */}
        </div>
      </Link>
    </div>
  );
}