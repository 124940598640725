import axios from "axios";

import {
  host,
  port,
  route,
  datasource,
} from "../reactUtils/systemVariables/lpserverInformation";

const resource = "/paymentplatform";

export async function getPaymentPlatformParameters(lpId) {
  try {
    let response = await axios.get(
      host + port + route + datasource + resource,
      {
        params: {
          lp_id: lpId,
        },
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
}

export async function postPayment(lpId, paymentDto) {
  try {
    const data = JSON.stringify(paymentDto);
    let response = await axios({
      method: "POST",
      url: host + port + route + datasource + resource + "/" + lpId,
      data,
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (error) {
    return error;
  }
}

export async function getPseBanks() {
  try {
    let response = await axios({
      method: "POST",
      url: "https://pay-api.refacil.co/payment/features",
      data: { id: 133 },
    });
    return response;
  } catch (error) {
    return error;
  }
}

export async function daviplataConfirm(confirmDto) {
  try {
    const data = JSON.stringify(confirmDto);
    let response = await axios({
      method: "POST",
      url: host + port + route + datasource + resource + "/refacilConfirm",
      data,
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (error) {
    return error;
  }
}