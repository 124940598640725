import React from "react";
import { useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
// import CloseIcon from '@mui/icons-material/Close';
// import "@fullcalendar/core/main.css";
// import "@fullcalendar/daygrid/main.css";
import parse from "html-react-parser";

import "./index.scss";

export function CalendarLayout(props) {

  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const [image, setImage] = useState("");
  let vertical = 'top';
  let horizontal = 'right';

  let events = [];

  props.components.forEach((element) => {
    let title = element.nodes.find((node) => node.nodeType == "EventTitle" );
    title = title ? title.value : "";
    let date = element.nodes.find((node) => node.nodeType == "EventDate");
    date = date ? date.value : "";
    let text = element.nodes.find((node) => node.nodeType == "EventText" );
    text = text ? text.value : "";
    let author = element.nodes.find( (node) => node.nodeType == "EventAuthor" );
    author = author ? author.value : "";
    let place = element.nodes.find( (node) => node.nodeType == "EventPlace" );
    place = place ? place.value : "";
    let responsible = element.nodes.find( (node) => node.nodeType == "EventResponsible" );
    responsible = responsible ? responsible.value : "";
    let multimedia = element.multimedia
    let eventToPush = {title, start: date, end: date, text, author, place, responsible, multimedia };
    events.push(eventToPush);
  });

  let calendarComponentRef = React.createRef();

  const setEvent = (event) => {
    let _title = event.event.title;
    setTitle(_title)
    let _text = event.event._def.extendedProps.text;
    setText(_text);
    let _image = event.event._def.extendedProps.multimedia;
    _image = _image && _image[3] ? _image [3] : _image && _image[2] ? _image[2] : _image && _image[0] ? _image[0] : "";
    setImage(_image);
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  let fontFamily = props.theme && props.theme.headerName.fontFamily ? props.theme.headerName.fontFamily : "Montserrat";
  let colorTitle = props.theme && props.theme.sectionText1.color ? props.theme.sectionTitle.color : "Black";
  let colorText = props.theme && props.theme.sectionText1.color ? props.theme.sectionText1.color : "Gray";

  const action = (
    <React.Fragment>
      <Button style={{ '--fontFamily': fontFamily, '--colorTitle': colorTitle }} color="secondary" size="small" onClick={handleClose} className="buttonSnackbar">
        Cerrar
      </Button>
    </React.Fragment>
  );
  

  return (
    <div>
      <FullCalendar
        ref={calendarComponentRef}
        schedulerLicenseKey="GPL-My-Project-Is-Open-Source"
        defaultView="dayGridMonth"
        headerToolbar={{ left: "prev,next today", center: "title",right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek" }}
        plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin,  resourceTimeGridPlugin ]}
        events={events}
        eventLimit={2}
        displayEventTime={false}
        selectable={true}
        editable={true}
        eventClick={(event) => setEvent(event)}
      />

      <Snackbar
        anchorOrigin={{ vertical, horizontal }}  
        open={open}
        // autoHideDuration={6000}
        onClose={handleClose}
        message={
          <div className="containerSnackbar">
            <p className="titleSnackbar" style={{ '--fontFamily': fontFamily, '--colorTitle': colorTitle }}>{title}</p>
            <p className="textSnackbar" style={{ '--fontFamily': fontFamily, '--colorText': colorText }}>{parse(text)}</p>
            {image != "" ? <img  className="imageSnackbar" src ={image}  alt="Calendar" /> : null}
          </div>}
        action={action}
      />
    </div>
  );
}