import React, { Component } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import {
  getNewsSectionInformationByTopLimit,
  getNewsSectionByLpIdPagedAndOrdered,
  getLpSectionComponentParentInformation,
  getFilePath,
} from "../../../actions/index";
import getVideoId from "get-video-id";

import LayoutIndex from "../genericLayout/newsEventsCourses/layoutStyles/LayoutIndex.jsx";
//Localized
import Strings from "../../../reactUtils/systemVariables/languageLocalization/LocalizedStrings.jsx";
import "./index.css";
import { Pagination } from "@mui/material";

class NewsSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lpId: this.props.lpId,
      news: [],
      //pagination
      page: 1,
      totalElements: 0,
      totalPages: null,
      newsToShow: [],
      pageSize: 10,
      attribute: "creationDate",
      order: "DESC",
      isLoading: false,
    };
  }

  componentDidMount() {
    const { lpId, page, pageSize, attribute, order, layout } = this.state;
    if (layout === 2) {
      // Get all news
      this.getNewsPagination(lpId, page, pageSize, attribute, order);
      this.setState({
        loadingInfo: true,
      });
    } else {
      this.getNewsInformation();
      this.setState({
        loadingInfo: true,
      });
    }
  }

  async getNewsInformation() {
    let response = await getNewsSectionInformationByTopLimit(
      this.props.lpId,
      this.props.lpSectionId,
      0
    );
    let sectionInfo = response.data.data;
    this.setState({
      limit: sectionInfo.components.length,
    });
    let newsContainerComponent = sectionInfo.components.find(
      (comp) => comp.name === "NewsContainerComponent"
    );
    let newsId = sectionInfo.id;
    let newsContainerTitle = newsContainerComponent.nodes.find(
      (node) => node.nodeType === "NewsContainerTitle"
    ).value;
    let newsQuanty = newsContainerComponent.nodes.find(
      (node) => node.nodeType === "NewsQuantity"
    ).value;

    let totalElements = newsQuanty;
    this.setState({ pageSize: totalElements });
    let news = sectionInfo.components.filter(
      (comp) => comp.name === "NewsComponent"
    );
    this.setChildComponentsInfoPaged(
      news,
      this.state.page,
      this.state.pageSize
    );
    let layout = sectionInfo.lpSectionLayoutStyleId;
    if (layout === 5) {
      layout = 0; //Standar Layout news
    }
    if (layout === 19) {
      //News image rigth layout
      layout = 1;
    }
    if (layout === 31) {
      //News grid layout
      layout = 2;
    }
    if (layout === 32) {
      //News cards layout
      layout = 3;
    }
    if (layout === 33) {
      //News carousel layout
      layout = 4;
    }
    if (layout === 84) {
      //News carousel layout
      layout = 6;
    }
    if (process.env.REACT_APP_DEFAULT_LAYOUT_NEWS) {
      layout = parseInt(process.env.REACT_APP_DEFAULT_LAYOUT_NEWS);
    }
    let background = "";
    // let backgroundImage = "";
    if (this.props.background === true) {
      background = "#eff5f5";
      // backgroundImage =
      //   ' url("https://jointabella.com/wp-content/uploads/2020/06/forchurches-section2.svg")';
    }
    if (this.props.background === false) {
      background = "#ffff";
      // backgroundImage =
      //   ' url("https://jointabella.com/wp-content/uploads/2020/10/BG-Section2-1.svg")';
    }
    this.setState({
      newsId,
      newsContainerTitle,
      news,
      totalElements,
      isLoading: false,
      layout,
      background,
      // backgroundImage,
    });
  }

  async setChildComponentsInfoPaged(news, page, pageSize) {
    let newsToShow = [];

    for (let i = 0; i < news.length; i++) {
      if (!news[i].img) {
        let imgNode = news[i].nodes.find(
          (node) => node.nodeType == "NewsImage"
        );
        if (imgNode && imgNode.value != null && imgNode.value != "") {
          //Get image or video
          let multimedia = [];

          if (!imgNode.value.includes("https://")) {
            // checks if it's an uploaded image
            let imgName = imgNode.value
              .replace(".jpeg", "")
              .replace(".jpg", "")
              .replace(".png", "");
            let format = imgNode.value.match(/\.([^.]*)$/)[1];
            let imgS = await getFilePath(imgName, "smallSize", format);
            let imgM = await getFilePath(imgName, "mediumSize", format);
            let imgL = await getFilePath(imgName, "largeSize", format);
            multimedia.push("isUploadedImage")
            multimedia.push(imgS);
            multimedia.push(imgM);
            multimedia.push(imgL);
          } else if (imgNode.value.includes("https://www.youtube.com/watch?")) {
            // checks if it's video or image link
            let idVideo = getVideoId(imgNode.value);
            multimedia.push("isVideo")
            multimedia.push(idVideo.id);
          } else {
            multimedia.push("isLinkedImage")
            multimedia.push(imgNode);
          }
          news[i].multimedia = multimedia;
        }
      }
      newsToShow.push(news[i]);
    }
    this.setState({ newsToShow });
  }

  async getNewsPagination(lpId, page, pageSize, attribute, order) {
    let totalElements = 0;
    let news = [];
    let responseParent = await getLpSectionComponentParentInformation(
      lpId,
      this.props.lpSectionId,
      "NEWS"
    );
    let newsContainerComponent = responseParent.data.data.components.find(
      (comp) => comp.name === "NewsContainerComponent"
    );
    let newsContainerTitle = newsContainerComponent.nodes.find(
      (node) => node.nodeType === "NewsContainerTitle"
    ).value;

    let response = await getNewsSectionByLpIdPagedAndOrdered(
      lpId,
      this.props.lpSectionId,
      page,
      pageSize,
      attribute,
      order
    );
    if (String(response).includes("Error:")) {
    } else {
      news = response.data.data.content;
      totalElements = response.data.data.totalElements;
    }
    this.setState({
      page,
      pageSize,
      attribute,
      order,
      totalElements,
      news: news,
      newsContainerTitle,
      isLoading: false,
    });
    this.setChildComponentsInfoPaged(news, page, pageSize);
  }

  onPaginationChange = (page, pageSize) => {
    const { lpId, attribute, order } = this.state;
    this.setState({
      page: page,
      pageSize: pageSize,
      newsToShow: [],
      isLoading: true,
    });
    this.getNewsPagination(lpId, page, pageSize, attribute, order);
  };

  render() {
    const antIcon = <LoadingOutlined style={{ fontSize: 150 }} spin />;
    const { theme } = this.props;
    const {
      background,
      layout,
      lpId,
      // backgroundImage
    } = this.state;
    let colorSpin = "#008cba";
    if (theme != null) {
      colorSpin = theme.sectionTitle.color;
    }
    return (
      <div
      // className="section-bg"
      // style={{
      //   background: background,
      // }}
      >
        <section id={"news" + this.state.newsId}>
          <div className="containerAux">
            <div className="row">
              <div className="col-xs-12 col-md-12">
                <div
                  className="section-header wow fadeIn"
                  data-wow-duration="1s"
                >
                  <h1 style={ theme && theme.sectionTitle ? theme.sectionTitle : null } >
                    {this.state.newsContainerTitle}
                  </h1>
                  <span className="section-divider"></span>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-md-12 text-center">
              {this.state.newsToShow.length === 0 ? (
                <p
                  style={
                    theme && theme.sectionDescription
                      ? theme.sectionDescription
                      : null
                  }
                  className="description"
                >
                  "Actualmente no se tienen noticias publicadas."
                </p>
              ) : (
                <div className="row">
                  <LayoutIndex
                    components={this.state.newsToShow}
                    layout={layout}
                    theme={theme ? theme : null}
                    containerTitle={this.state.newsContainerTitle}
                    news={true}
                    lpId={lpId}
                  />
                </div>
              )}
              <br />
              <div className="row text-center">
                <div className="col-xs-12 col-md-12 text-center d-flex justify-content-center">
                  {layout === "NEWS LAYOUT 1" && Math.ceil(this.state.totalElements / this.state.pageSize) > 1 ? (
                    <Pagination

                      size="small"
                      onChange={(event, value) => {                        
                        this.onPaginationChange(value, this.state.pageSize)
                      }}
                      count={ Math.ceil(this.state.totalElements / this.state.pageSize) }
                      page={this.state.page}

                      // ant desing
                      // current={this.state.page}
                      // disabled={this.state.totalElements === 0}
                      // hideOnSinglePage={false}
                      // pageSizeOptions={["5", "10", "25", "50"]}
                      // onChange={this.onPaginationChange}
                      // onShowSizeChange={this.onPaginationChange}
                      // loading={this.state.isLoading}
                      // showQuickJumper
                      // showSizeChanger
                      // showTotal={(total, range) =>
                      //   `${range[0]}-${range[1]} of ${total} items`
                      // }
                      // size="small"
                      // total={this.state.totalElements}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
export default NewsSection;
