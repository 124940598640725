import axios from 'axios';

import { host, port, route, datasource } from '../reactUtils/systemVariables/lpserverInformation'; 

const resource = "/payerdocumenttype";

export async function getPayerDocumentTypes() {
    try {
        // let response = await axios.get(
        //     host + port + route + datasource + resource, {
        //     params: {},
        //     headers: {
        //         'Content-Type': 'application/json'
        //     }
        // })
              let response = {
                "data": [
                    {
                        "id": 1,
                        "description": "Cédula de ciudadanía",
                        "code": "CC"
                    },
                    {
                        "id": 2,
                        "description": "Cédula de extranjería",
                        "code": "CE"
                    },
                    {
                        "id": 3,
                        "description": "Número de identificación personal",
                        "code": "NIP"
                    },
                    {
                        "id": 4,
                        "description": "Número de identificación tributaria",
                        "code": "NIT"
                    },
                    {
                        "id": 5,
                        "description": "Tarjeta de identidad",
                        "code": "TI"
                    },
                    {
                        "id": 6,
                        "description": "Pasaporte",
                        "code": "PAP"
                    }
                ],
                "status": "success",
                "code": 0,
                "httpErrorCode": 200,
                "description": "(list of 6PayerDocumentTypes). Record successfully retrieved."
            }
        return response;
    } catch (error) {
        return error;
    }
}
