import React from "react";
import "../index.css"
import imgNull from "../../../../images/noImage.jpg"
import ButtonsContainer from "../../general/ButtonsContainer.jsx";

export function HomeImageAndText(props) {
  const { theme, title, description, img1, img2, img3, imgBack, buttons } = props;
  return (
    <div>
      <section id="home" style={{ padding: "95px 0px" }}>
        <img src={img1 ? img1 : imgNull} className="imageBgLayout1" />
      </section>
      <div className="textHome">
        <h1 style={theme && theme.homeTitle ? theme.homeTitle : null}>
          {title}
        </h1>
        <br />
        <p style={theme && theme.homeDescription ? theme.homeDescription : null} >
          {description}
        </p>
        {buttons ?
        <div className="homeButtonsComponent"  style={{ marginTop: "10%" }}>
          <ButtonsContainer showBorder={true} buttons={buttons} theme={theme} isHome={true}/>
        </div>
        : ""}
      </div>
    </div>
  );
}
