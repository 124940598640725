import React from "react";
import "../index.css";
import imgNull from "../../../../images/noImage.jpg";

export function HomeScrollImageLayout(props) {
  const { theme, title, description, img1, img2, img3, imgBack } = props;
  
  return (
    <div>
      <section id="home" style={{ padding: "0px" }}>
        <div>
          {imgBack.includes("noImage") ? 
            <img src={img1 ? img1 : img2 ? img2 : img3 ? img3 : imgBack} className="slide1" alt="slide1" />
          : ( <img src={imgBack} className="slide1" alt="slide1" /> )}
          {img1.includes("noImage") ? 
            <img src={imgBack ? imgBack : img2 ? img2 : img3 ? img3 : img1} className="slide1" alt="slide1" />
          : (  <img src={img1} className="slide2" alt="slide2" /> )}
          {img2.includes("noImage") ? 
            <img src={imgBack ? imgBack : img1 ? img1 : img3 ? img3 : img2} className="slide1" alt="slide1" />
          : ( <img src={img2} className="slide3" alt="slide3" /> )}
          {img3.includes("noImage") ? 
            <img src={imgBack ? imgBack : img1 ? img1 : img2 ? img2 : img3} className="slide4" alt="slide4" />
          : ( <img src={img3} className="slide4" alt="slide4" /> )}
        </div>
        <div className="descriptionText">
          <h1 style={theme && theme.homeTitle ? theme.homeTitle : null}> {title} </h1>
          <br />
          <br />
          <p style={ theme && theme.homeDescription ? theme.homeDescription : null  } >  {description} </p>
        </div>
      </section>
    </div>
  );
}
