import React, { Component } from "react";

import { getLpSectionInformation, getFilePath } from "../../../actions/index";

import HomeLayoutIndex from "./layoutStyles/HomeLayoutIndex.jsx";
import imgNull from "../../../images/noImage.jpg";

class HomeSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: null,
      description: null,
      img1: [],
      img2: null,
      img3: null,
    };
  }

  componentDidMount() {
    this.getHomeInformation();
  }

  async getHomeInformation() {
    let response = await getLpSectionInformation(
      this.props.lpId,
      this.props.lpSectionId,
      "HOME"
    );
    let sectionInfo = response.data.data;
    let homeComponent = sectionInfo.components.find(
      (comp) => comp.name == "HomeComponent"
    ); //Llamando al componente

    let title = homeComponent.nodes.find(
      (node) => node.nodeType == "HomeTitle"
    ); //Obteniendo los nodos del  componente
    title = title ? title.value : "";
    let description = homeComponent.nodes.find(
      (node) => node.nodeType == "HomeDescription"
    );
    description = description ? description.value : "";
    let img1Path = homeComponent.nodes.find(
      (node) => node.nodeType == "HomeImg1"
    );
    img1Path = img1Path ? img1Path.value : "";
    let img2Path = homeComponent.nodes.find(
      (node) => node.nodeType == "HomeImg2"
    );
    img2Path = img2Path ? img2Path.value : "";
    let img3Path = homeComponent.nodes.find(
      (node) => node.nodeType == "HomeImg3"
    );
    img3Path = img3Path ? img3Path.value : "";
    let imgBackPath = homeComponent.nodes.find(
      (node) => node.nodeType == "HomeImgBackground"
    );
    let homePathVideo = homeComponent.nodes.find(
      (node) => node.nodeType == "HomeVideo"
    ).value;
    imgBackPath = imgBackPath ? imgBackPath.value : "";
    //Get images
    let imgName1;
    let format1;
    let img1S = imgNull;
    let img1M = imgNull;
    let img1L = imgNull;
    if (img1Path != " " && img1Path.length > 0) {
      imgName1 = img1Path
        .replace(".jpeg", "")
        .replace(".jpg", "")
        .replace(".png", "");
      format1 = img1Path.match(/\.([^.]*)$/)[1];
      img1S = await getFilePath(imgName1, "smallSize", format1);
      img1M = await getFilePath(imgName1, "mediumSize", format1);
      img1L = await getFilePath(imgName1, "largeSize", format1);
    }

    let imgName2;
    let format2;
    let img2S = imgNull;
    let img2M = imgNull;
    let img2L = imgNull;
    if (img2Path != " " && img2Path.length > 0) {
      imgName2 = img2Path
        .replace(".jpeg", "")
        .replace(".jpg", "")
        .replace(".png", "");
      format2 = img2Path.match(/\.([^.]*)$/)[1];
      img2S = await getFilePath(imgName2, "smallSize", format2);
      img2M = await getFilePath(imgName2, "mediumSize", format2);
      img2L = await getFilePath(imgName2, "largeSize", format2);
    }

    let imgName3;
    let format3;
    let img3S = imgNull;
    let img3M = imgNull;
    let img3L = imgNull;
    if (img3Path != " " && img3Path.length > 0) {
      imgName3 = img3Path
        .replace(".jpeg", "")
        .replace(".jpg", "")
        .replace(".png", "");
      format3 = img3Path.match(/\.([^.]*)$/)[1];
      img3S = await getFilePath(imgName3, "smallSize", format3);
      img3M = await getFilePath(imgName3, "mediumSize", format3);
      img3L = await getFilePath(imgName3, "largeSize", format3);
    }

    let imgNameBack;
    let formatBack;
    let imgBack = imgNull;
    if (imgBackPath != " ") {
      imgNameBack = imgBackPath
        .replace(".jpeg", "")
        .replace(".jpg", "")
        .replace(".png", "");
      formatBack = imgBackPath.match(/\.([^.]*)$/);
      formatBack = formatBack && formatBack[1] ? formatBack[1] : '.jpg';
      imgBack = await getFilePath(imgNameBack, "largeSize", formatBack);
    }

    // let videoHome = await getFilePath(homePathVideo);

    // let video = await getFilePath(video);
    // video = "data:" + video.fileType + ";mp4," + video.data;
    let layout = sectionInfo.lpSectionLayoutStyleId;
    if(process.env.REACT_APP_DEFAULT_LAYOUT_HOME){
      layout = parseInt(process.env.REACT_APP_DEFAULT_LAYOUT_HOME);
    }
    // layout = 2;
    const buttons = homeComponent.buttons;

    this.setState({
      title,
      description,
      img1S,
      img1M,
      img1L,
      img2S,
      img2M,
      img2L,
      img3S,
      img3M,
      img3L,
      imgBack,
      videoHome: homePathVideo,
      layout,
      buttons,
    });
  }

  renderComponentHome() {
    let renderedComponent = [];
    const { theme } = this.props;
    const {
      title,
      description,
      img1S,
      img1M,
      img1L,
      img2S,
      img2M,
      img2L,
      img3S,
      img3M,
      img3L,
      imgBack,
      videoHome,
      layout,
      buttons,
    } = this.state;
    renderedComponent.push(
      <HomeLayoutIndex
        id={Math.random() * 1000}
        key={Math.random() * 1000}
        theme={theme}
        title={title}
        description={description}
        img1S={img1S}
        img1M={img1M}
        img1L={img1L}
        img2S={img2S}
        img2M={img2M}
        img2L={img2L}
        img3S={img3S}
        img3M={img3M}
        img3L={img3L}
        imgBack={imgBack}
        video={videoHome}
        layout={layout}
        buttons={buttons}
      />
    );
    return renderedComponent;
  }

  render() {
    return <div>{this.renderComponentHome()}</div>;
  }
}

export default HomeSection;
