import React from "react";
import ShowMoreText from "react-show-more-text";
import {
  VerticalAlignTopOutlined,
  StepForwardOutlined,
} from "@ant-design/icons";
import "../index.css";

export function TeamMoveDownLayout(props) {
  const { id, theme, name, image, text, position } = props;
  let widthHeigth = "250px";
  return (
    <div className="wow fadeInRight" key={id}>
      <div className="containerTeam">
        <div className="col-xs-12 col-md-12 text-center">
          <div className="centerImage">
            <div className="card" style={{"--widthHeigth": widthHeigth }} >
              <div className="imgBx">
                <img src={image} alt="member team" />
              </div>
              <div className="content">
                <h4 style={ theme && theme.sectionSubtitle ? theme.sectionSubtitle  : null }  >
                  {name}
                </h4>
                <span style={ theme && theme.sectionText1 ? theme.sectionText1 : null } className="description" >
                  {position}
                </span>
                <p style={ theme && theme.sectionText1 ? theme.sectionText1 : null } >
                  <ShowMoreText
                    lines={6}
                    more={
                      <span id="moretext">
                        <StepForwardOutlined />
                      </span>
                    }
                    less={
                      <span id="moretext">
                        <VerticalAlignTopOutlined />
                      </span>
                    }
                    expanded={false}
                  >
                    <p id="textDescription">{text}</p>
                  </ShowMoreText>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
