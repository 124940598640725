import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import parse from "html-react-parser";
import getVideoId from "get-video-id";

import "./stylesLP.css";

import LayoutIndex from "./genericLayout/newsEventsCourses/layoutStyles/LayoutIndex.jsx";

import { getComponentByLpIdAndId, getFilePath } from "../../actions/index";

class ComponentPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      componentInfo: null,
      redirectHome: false,
    };
  }

  componentDidMount() {
    this.getComponentInfo(this.props.lpId, this.props.componentId);
  }

  async getComponentInfo(lpId, componentId) {
    this.setState({ redirectHome: false });

    let response = await getComponentByLpIdAndId(lpId, componentId);
    if (response.data.data) {
      let componentInfo = response.data.data[0];
      if (componentInfo) {
        let multimediaNode = componentInfo.nodes.find((node) =>
          node.nodeType.includes("Image")
        );

        //Get image or video

        if (multimediaNode) {
          let multimedia = [];

          if (!multimediaNode.value.includes("https://")) {
            // checks if it's an uploaded image
            let imgName = multimediaNode.value
              .replace(".jpeg", "")
              .replace(".jpg", "")
              .replace(".png", "");
            let format = multimediaNode.value.match(/\.([^.]*)$/);
            format = format!= null && format.length ? format[1] : 'jpg';
            let imgS = await getFilePath(imgName, "smallSize", format);
            let imgM = await getFilePath(imgName, "mediumSize", format);
            let imgL = await getFilePath(imgName, "largeSize", format);
            multimedia.push("isUploadedImage")
            multimedia.push(imgS);
            multimedia.push(imgM);
            multimedia.push(imgL);
          } else if (
            multimediaNode.value.includes("https://www.youtube.com/watch?")
          ) {
            // checks if it's video or image link
            let idVideo = getVideoId(multimediaNode.value);
            multimedia.push("isVideo")
            multimedia.push(idVideo.id);
          } else {
            multimedia.push("isLinkedImage")
            multimedia.push(multimediaNode);
          }
          componentInfo.multimedia = multimedia;
        }
      }

      this.setState({ componentInfo });
    } else {
      this.setState({ redirectHome: true });
    }
  }

  renderComponentPage = () => {
    const { theme, lpId } = this.props;
    const { componentInfo } = this.state;
    let individualComponent = [];
    if (componentInfo != null) {
      let title = null;
      let text = null;
      let date = null;
      let place = null;
      let author = null;
      let responsible = null;
      let multimedia = componentInfo.multimedia ? componentInfo.multimedia : null ;
      let individualStyle = null;
      let endDate = null;
      let fixedValue = null;
      let layout = "";
      switch (componentInfo.sectionType) {
        case "EVENTS":
          title = componentInfo.nodes.find(
            (node) => node.nodeType == "EventTitle"
          );
          title = title ? title.value : "";
          text = componentInfo.nodes.find(
            (node) => node.nodeType == "EventText"
          );
          text = text ? text.value : "";
          date = componentInfo.nodes.find(
            (node) => node.nodeType == "EventDate"
          );
          date = date ? date.value : "";
          place = componentInfo.nodes.find(
            (node) => node.nodeType == "EventPlace"
          );
          place = place ? place.value : "";
          responsible = componentInfo.nodes.find(
            (node) => node.nodeType == "EventResponsible"
          );
          responsible = responsible ? responsible.value : "";
          individualStyle = {
            divClass: "col-md-12 col-xs-12 wow fadeInRight event",
            title: {
              fontFamily: "Georgia, Times, 'Times New Roman'",
              fontSize: "52px",
            },
            text: {
              fontFamily: "Georgia, Times, 'Times New Roman'",
              fontSize: "20px",
            },
            imageWidth: "90%", // antes estaba 150%
          };
          layout = -1;
          individualComponent.push(
            <div className="singleRender">
              <div className="containerAux">
                <LayoutIndex
                  title={title}
                  text={text}
                  date={date}
                  place={place}
                  responsible={responsible}
                  author={author}
                  multimedia={multimedia}
                  theme={theme ? theme : null}
                  individualStyle={individualStyle ? individualStyle : null}
                  buttons={componentInfo.buttons}
                  id={componentInfo.id}
                  key={componentInfo.id}
                  layout={layout}
                  lpId={lpId}
                />
              </div>
            </div>
          );
          break;
        case "NEWS":
          title = componentInfo.nodes.find(
            (node) => node.nodeType == "NewsTitle"
          );
          title = title ? title.value : "";
          text = componentInfo.nodes.find(
            (node) => node.nodeType == "NewsText"
          );
          text = text ? text.value : "";
          date = componentInfo.nodes.find(
            (node) => node.nodeType == "NewsDate"
          );
          date = date ? date.value : "";
          author = componentInfo.nodes.find(
            (node) => node.nodeType == "NewsAuthor"
          );
          author = author ? author.value : "";
          individualStyle = {
            title: {
              fontFamily: "Georgia, Times, 'Times New Roman'",
              fontSize: "35px",
            },
            text: {
              fontFamily: "Georgia, Times, 'Times New Roman'",
              fontSize: "20px",
              textalign: "justify",
            },
            imageWidth: "90%",
          };
          layout = -1;
          individualComponent.push(
            <div className="singleRender">
              <div className="containerAux">
                <LayoutIndex
                  title={title}
                  text={text}
                  date={date}
                  place={place}
                  responsible={responsible}
                  author={author}
                  multimedia={multimedia}
                  theme={theme ? theme : null}
                  buttons={componentInfo.buttons}
                  individualStyle={individualStyle ? individualStyle : null}
                  id={componentInfo.id}
                  key={componentInfo.id}
                  layout={layout}
                  lpId={lpId}
                />
              </div>
            </div>
          );
          break;
        case "COURSES":
          title = componentInfo.nodes.find(
            (node) => node.nodeType === "CourseTitle"
          );
          title = title ? title.value : "";
          text = componentInfo.nodes.find(
            (node) => node.nodeType === "CourseText"
          );
          text = text ? text.value : "";
          date = componentInfo.nodes.find(
            (node) => node.nodeType === "CourseDate"
          );
          date = date ? date.value : "";
          place = componentInfo.nodes.find(
            (node) => node.nodeType === "CoursePlace"
          );
          place = place ? place.value : "";
          responsible = componentInfo.nodes.find(
            (node) => node.nodeType === "CourseResponsible"
          );
          individualStyle = {
            title: {
              fontFamily: "Georgia, Times, 'Times New Roman'",
              fontSize: "35px",
            },
            text: {
              fontFamily: "Georgia, Times, 'Times New Roman'",
              fontSize: "20px",
              textalign: "justify",
            },
            imageWidth: "90%",
          };
          responsible = responsible ? responsible.value : "";
          layout = -1;
          individualComponent.push(
            <div className="singleRender">
              <div className="containerAux">
                <LayoutIndex
                  title={title}
                  text={parse(text)}
                  date={date}
                  place={place}
                  responsible={responsible}
                  author={author}
                  multimedia={multimedia}
                  theme={theme ? theme : null}
                  buttons={componentInfo.buttons}
                  individualStyle={individualStyle ? individualStyle : null}
                  id={componentInfo.id}
                  key={componentInfo.id}
                  layout={layout}
                  lpId={lpId}
                />
              </div>
            </div>
          );
          break;
        case "DONATIONS":
          title = componentInfo.nodes.find(
            (node) => node.nodeType == "DonationComponentTitle"
          );
          title = title ? title.value : "";
          text = componentInfo.nodes.find(
            (node) => node.nodeType == "DonationComponentText"
          );
          text = text ? text.value : "";
          endDate = componentInfo.nodes.find(
            (node) => node.nodeType == "DonationComponentEndDate"
          );
          endDate = endDate ? endDate.value : "";
          fixedValue = componentInfo.nodes.find(
            (node) => node.nodeType == "DonationComponentFixedValue"
          );
          fixedValue = fixedValue ? fixedValue.value : "";
          individualStyle = {
            divClass: "col-md-12 col-xs-12 box wow fadeInRight event",
            title: {
              fontFamily: "Georgia, Times, 'Times New Roman'",
              fontSize: "35px",
            },
            text: {
              fontFamily: "Georgia, Times, 'Times New Roman'",
              fontSize: "20px",
            },
            imageWidth: "90%",
          };
          layout = -1;
          individualComponent.push(
            <div className="singleRender">
              <div className="containerAux">
                <LayoutIndex
                  title={title}
                  text={parse(text)}
                  endDate={endDate}
                  fixedValue={fixedValue}
                  place={place}
                  responsible={responsible}
                  author={author}
                  multimedia={multimedia}
                  theme={theme ? theme : null}
                  individualStyle={individualStyle ? individualStyle : null}
                  buttons={componentInfo.buttons}
                  id={componentInfo.id}
                  key={componentInfo.id}
                  layout={layout}
                  lpId={lpId}
                />
              </div>
            </div>
          );
          break;
      }
    }

    if (componentInfo && individualComponent.length > 0) {
      return individualComponent;
    }
  };

  render() {
    const { redirectHome } = this.state;

    if (redirectHome == true) {
      // Redirects to home if component doesnt exist
      return <Redirect to="/" />;
    }

    return <div> {this.renderComponentPage()}</div>;
  }
}
export default ComponentPage;
