import React, { Component } from "react";
import "../../stylesLP.css";
import StandarAboutLayout from "./StandarAboutLayout.jsx";
import CardsAboutLayout from "./CardsAboutLayout.jsx";
import CardsImageAboutLayout from "./CardsImageAboutLayout.jsx";

class AboutLayoutIndex extends Component {

  renderAboutComponent() {
    let renderedComponent = [];
    switch (this.props.layout) {
      case 0:
        renderedComponent.push(
          <StandarAboutLayout
            aboutId={this.props.aboutId}
            aboutTitle={this.props.aboutTitle}
            aboutText={this.props.aboutText}
            missionTitle={this.props.missionTitle}
            missionText={this.props.missionText}
            visionTitle={this.props.visionTitle}
            visionText={this.props.visionText}
            multimedia={this.props.multimedia}
            layout={this.props.layout}
            theme={this.props.theme}
            key={this.props.aboutId}
            
          />
        );
        break;
      case 1:
        renderedComponent.push(
          <CardsAboutLayout
            aboutId={this.props.aboutId}
            aboutTitle={this.props.aboutTitle}
            aboutText={this.props.aboutText}
            missionTitle={this.props.missionTitle}
            missionText={this.props.missionText}
            visionTitle={this.props.visionTitle}
            visionText={this.props.visionText}
            layout={this.props.layout}
            theme={this.props.theme}
            key={this.props.aboutId}
            
          />
        );
        break;
      case 2:
        renderedComponent.push(
          <CardsImageAboutLayout
            aboutId={this.props.aboutId}
            aboutTitle={this.props.aboutTitle}
            aboutText={this.props.aboutText}
            missionTitle={this.props.missionTitle}
            missionText={this.props.missionText}
            visionTitle={this.props.visionTitle}
            visionText={this.props.visionText}
            multimedia={this.props.multimedia}
            layout={this.props.layout}
            theme={this.props.theme}
            key={this.props.aboutId}
            
          />
        );
        break;
    }
    return renderedComponent;
  }

  render() {
    return <div>{this.renderAboutComponent()}</div>;
  }
}
export default AboutLayoutIndex;
