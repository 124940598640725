import React from "react";
import { Link } from "react-router-dom";
import imageNull from "../../../../../images/noImage.jpg";
import moment from "moment";
import parse from "html-react-parser";

//Localization
import Strings from "../../../../../reactUtils/systemVariables/languageLocalization/LocalizedStrings.jsx";
import ButtonsContainer from "../../../general/ButtonsContainer.jsx";
import ButtonsSocialMedia from "../../../general/ButtonsSocialMedia.jsx";
import "./index.scss";

var dateOnlyFormat = "MMMM DD, YYYY";
var dateStartTime = "MMMM DD, YYYY";

export function IndividualLayout(props) {
  const id = props.id;
  const theme = props.theme;
  let display = "flex";
  if (props.individualStyle) {
    display = "block";
  }
  var event = {};
  if (props.events) {
    event = {
      title: props.title,
      description: parse(props.text),
      startTime: moment(props.date).format(dateStartTime),
      endTime: moment(props.date).add(1, "hours").format(dateStartTime),
      location: props.place,
    };
  }
  let regex = new RegExp("</");
  let mustBeParse = regex.test(props.text);
  let text;
  if (mustBeParse) {
    text = parse(props.text);
  } else {
    text = props.text;
  }

  let startDate;
  let startDateLabel = Strings.basicWords.date;
  if (props.date !== "") {
    startDate = moment(props.date).format(dateStartTime);
  } else {
    startDate = null
  } 

  let multimedia = null;
  let idVideo = null;
  if (props.multimedia && props.multimedia[0] == "isUploadedImage") {
    multimedia = props.multimedia[3]; //large Size
    if(window.innerWidth < 900) { // mobile 
      multimedia = props.multimedia[2]; //medium Size
    }
  } else if (props.multimedia && props.multimedia[0] == "isVideo") {
    idVideo = props.multimedia[1];
  } else if (props.multimedia && props.multimedia[0] == "isLinkedImage") {
    multimedia = props.multimedia[1].value;
  }
  let widthHeigth = "300px";

  let buttonLinkPath = "/component/"
  if(props.donations){
    buttonLinkPath = "/payment/"
  }

  return (
    <div className="containerAux">
      <div className="row">
        <div
          className="subpage-title col-xs-12 col-md-12 spaceLayout1 wow fadeIn"
          data-wow-duration="1s"
        >
          <Link to={{ pathname: buttonLinkPath + id }}>
            <h1
              style={
                theme && theme.sectionSubtitle
                  ? theme.sectionSubtitle
                  : null + props.individualStyle && props.individualStyle.title
                  ? props.individualStyle.title
                  : null
              }
            >
              {props.title}
            </h1>
          </Link>
        </div>

        <div className="col-xs-12 col-md-7" style={{ paddingRight: '0px', height: '100%', paddingTop: '10px' }}>
          {/* <div className="containerBody">

            {props.author && props.author !== "" && props.author !== " " && props.author !== null? (
              <div className="col-xs-12 col-md-12">
                <p
                  style={
                    theme && theme.sectionText1
                      ? theme.sectionText1
                      : null + theme && theme.sectionText2
                      ? theme.sectionText2
                      : null
                  }
                  className="body compact"
                >
                  <strong>
                    {Strings.sections.author}
                    {": "}
                  </strong>
                  {props.author}
                </p>
              </div>
            ) : null}
            {props.responsible !== "" && props.responsible !== " " && props.responsible !== null? (
              <div className="col-xs-12 col-md-12">
                <p
                  style={
                    theme && theme.sectionText2
                      ? theme.sectionText2
                      : null + theme && theme.sectionText2
                      ? theme.sectionText2
                      : null
                  }
                  className="body compact"
                >
                  <strong>
                    {Strings.basicWords.responsible}
                    {": "}
                  </strong>
                  {props.responsible}
                </p>
              </div>
            ) : null}
            {props.place !== "" && props.place !== " " && props.place !== null? (
              <div className="col-xs-12 col-md-12">
                <p
                  style={
                    theme && theme.sectionText2
                      ? theme.sectionText2
                      : null + theme && theme.sectionText2
                      ? theme.sectionText2
                      : null
                  }
                  className="body compact last"
                >
                  <strong>
                    {Strings.basicWords.place}
                    {": "}
                  </strong>
                  {props.place}
                </p>
              </div>
            ) : null}
          </div> */}

          <div className="col-xs-12 col-md-12 text-justify">
            <p
              style={
                theme && theme.sectionText1
                  ? theme.sectionText1
                  : null + props.individualStyle && props.individualStyle.text
                  ? props.individualStyle.text
                  : null
              }
            >
              {/* <ShowMoreText
                lines={15}
                more={
                  <span id="moretext">
                    <StepForwardOutlined />
                  </span>
                }
                less={
                  <span id="moretext">
                    <VerticalAlignTopOutlined />
                  </span>
                }
                expanded={false}
              > */}
              <p id="textDescription">{text}</p>
              {/* </ShowMoreText> */}
            </p>
          </div>
          <br/>
          {startDate != null ? (
            <div className="col-xs-12 col-md-12">
              <p style={  theme && theme.sectionText1 ? theme.sectionText1 : null + theme && theme.sectionText2  ? theme.sectionText2 : null} className="body compact">
                <span>{startDate}</span>
              </p>
            </div>
            ) : null}
          {/* {props.endDate != "" && props.endDate !== "-1" ? (
            <div className="row">
              <div className="col-xs-12 col-md-12">
                <p style={theme && theme.sectionText1 ? theme.sectionText1 : null + theme && theme.sectionText2 ? theme.sectionText2 : null} className="bodyDate">
                  {(<strong>{Strings.basicWords.closeDate} {" : "}{" "}</strong>)} {moment(props.endDate).format(dateStartTime)}
                </p>
              </div>
            </div>
          ) : (
            null
          )} */}
          {props.events ? (
            <div className="col-xs-12 col-md-12 text-justify">
              {/* <div>
                <ButtonsSocialMedia
                  title={props.title}
                  text={props.text}
                  id={props.id}
                  size={20}
                />
              </div> */}
              <div>
                <ButtonsContainer
                  buttons={props.buttons}
                  event={event}
                  id={props.id}
                  theme={theme}
                  style='containerLinearButtons'
                  showBorder={true}
                />
              </div>
            </div>
          ) : (
            <div>
              {props.id ? (
                <div className="col-xs-12 col-md-12 text-justify">
                  {/* <div>
                    <ButtonsSocialMedia
                      title={props.title}
                      text={props.text}
                      id={props.id}
                      size={20}
                    />
                  </div> */}
                  <div>
                    <ButtonsContainer
                      buttons={props.buttons}
                      id={props.id}
                      theme={theme}
                      style='containerLinearButtons'
                      showBorder={false}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          )}
        </div>

        <div
          className="col-xs-12 col-md-5"
          style={{ display: display, alignItems: "left" }}
        >
          <div
            className={multimedia ? "col-xs-12 col-md-12 wow fadeInRight" : ""}
          >
            <Link to={{ pathname: buttonLinkPath + id }}>
              {multimedia === null && idVideo == null ? (
                <div
                  className="frameImgStandarLayout"
                  style={{
                    "--widthHeigth": widthHeigth,
                  }}
                >
                  <img
                    style={ theme && theme.imagesShape ? theme.imagesShape : null }
                    width={
                      props.individualStyle && props.individualStyle.imageWidth
                        ? props.individualStyle.imageWidth
                        : null
                    }
                    src={imageNull}
                    alt="image"
                  />
                </div>
              ) : null}

              {idVideo != null ? (
                <iframe
                  className="video"
                  style={{ height: "300px" }}
                  src={`https://www.youtube.com/embed/${idVideo}?version=3&loop=1&autoplay=0&controls=1&;rel=0&playlist=${idVideo}`}
                  frameBorder="0"
                  allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                ></iframe>
              ) : null}

              {idVideo == null && multimedia ? (
                <div className="col-xs-12 col-md-12">
                  <div className="centerImage">
                    <div className="box">
                      <img
                        style={  theme && theme.imagesShape ? theme.imagesShape : null  }
                        width={
                          props.individualStyle &&
                          props.individualStyle.imageWidth
                            ? props.individualStyle.imageWidth
                            : null
                        }
                        src={multimedia}
                        alt="image"
                      />
                    </div>
                  </div>
                </div>
              ) : null}
            </Link>
          </div>
        </div>
      </div>
      <br />
      <br />
    </div>
  );
}
