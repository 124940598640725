import React, { Component } from "react";
import { Box, CircularProgress, Typography } from "@material-ui/core";
import { getLpSectionInformation, getFilePath } from "../../../actions/index";
import { LoadingOutlined } from "@ant-design/icons";
import "./index.css";
import TeamLayoutIndex from "./layoutStyles/TeamLayoutIndex.jsx";
import { TeamLayout6 } from "./layoutStyles/TeamLayout6.jsx";
import { TeamLayout7 } from "./layoutStyles/TeamLayout7.jsx";

class TeamSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      limit: 4,
      team: [],
    };
  }

  componentDidMount() {
    this.getTeamInformation();
  }

  async getTeamInformation() {
    let response = await getLpSectionInformation(
      this.props.lpId,
      this.props.lpSectionId,
      "TEAM"
    );
    let sectionInfo = response.data.data;
    let teamContainerComponent = sectionInfo.components.find(
      (comp) => comp.name == "TeamContainerComponent"
    );
    let teamId = sectionInfo.id;
    let teamContainerTitle = teamContainerComponent.nodes.find(
      (node) => node.nodeType == "TeamContainerTitle"
    );
    teamContainerTitle = teamContainerTitle ? teamContainerTitle.value : "";
    let teamContainerText = teamContainerComponent.nodes.find(
      (node) => node.nodeType == "TeamContainerText"
    );
    teamContainerText = teamContainerText ? teamContainerText.value : "";
    let teamMembers = sectionInfo.components.filter(
      (comp) => comp.name == "TeamMemberComponent"
    );
    for (var i = 0; i < teamMembers.length; i++) {
      if (!teamMembers[i].img) {
        let imgNode = teamMembers[i].nodes.find(
          (node) => node.nodeType == "TeamMemberImage"
        );
        if (imgNode.value != null && imgNode.value != "") {
          //Get image
          let imgName = imgNode.value
            .replace(".jpeg", "")
            .replace(".jpg", "")
            .replace(".png", "");
          let format = imgNode.value.match(/\.([^.]*)$/)[1];

          let imgS = await getFilePath(imgName, "smallSize", format);
          let imgM = await getFilePath(imgName, "mediumSize", format);
          let imgL = await getFilePath(imgName, "largeSize", format);

          teamMembers[i].imgS = imgS;
          teamMembers[i].imgM = imgM;
          teamMembers[i].imgL = imgL;
        }
      }
    }
    let background = "";
    // let backgroundImage = "";
    if (this.props.background === true) {
      background = "#eff5f5";
      // backgroundImage =
      //   ' url("https://jointabella.com/wp-content/uploads/2020/06/forchurches-section2.svg")';
    }
    if (this.props.background === false) {
      background = "#ffff";
      // backgroundImage =
      //   ' url("https://jointabella.com/wp-content/uploads/2020/10/BG-Section2-1.svg")';
    }
    let layout = sectionInfo.lpSectionLayoutStyleId;
    if (layout === 6) {
      //Standar team layout
      layout = 0;
    }
    if (layout === 34) {
      //Team scrooll down layout
      layout = 1;
    }
    if (layout === 35) {
      //Team scrooll up layout
      layout = 2;
    }
    if (layout === 36) {
      //Team diagonally layout
      layout = 3;
    }
    if (layout === 37) {
      //Team flip card layout
      layout = 4;
    }
    if (layout === 83) {
      //Team carousel layout
      layout = 6;
    }
    if (layout === 88) {
      //Team carousel 2 - circular image layout
      layout = 7;
    }
    if(process.env.REACT_APP_DEFAULT_LAYOUT_TEAM){
      layout = parseInt(process.env.REACT_APP_DEFAULT_LAYOUT_TEAM);
    }
    this.setState({
      teamId,
      teamContainerTitle,
      teamContainerText,
      teamMembers,
      layout,
      background,
    });
  }

  renderTeamMembersComponents(teamMembers) {
    const { theme } = this.props;
    let renderedComponents = [];
    if (teamMembers) {
      teamMembers.forEach((element) => {
        let name = element.nodes.find(
          (node) => node.nodeType === "TeamMemberName"
        );
        name = name ? name.value : "";
        let position = element.nodes.find(
          (node) => node.nodeType === "TeamMemberPosition"
        );
        position = position ? position.value : "";
        let text = element.nodes.find(
          (node) => node.nodeType === "TeamMemberText"
        );
        text = text ? text.value : "";
        let image = element.imgL;
        renderedComponents.push(
          <TeamLayoutIndex
            id={element.id}
            key={element.id}
            name={name}
            position={position}
            text={text}
            image={image}
            theme={theme}
            layout={this.state.layout}
            members={teamMembers}
          />
        );
      });
    }
    return renderedComponents;
  }

  render() {
    const antIcon = <LoadingOutlined style={{ fontSize: 150 }} spin />;
    const { theme } = this.props;
    let { layout } = this.state;
    let colorSpin = "#008cba";
    if (theme != null) {
      colorSpin = theme.sectionTitle.color;
    }
    let membersCarrousel = this.state.teamMembers ? this.state.teamMembers : [];
    // membersCarrousel = membersCarrousel.concat(membersCarrousel);

    return (
      <section
        id={"team" + this.state.teamId}
        // style={{
        //   background: background,
        // }}
      >
        <div className="containerAux">
          <div className="row">
            <div className="col-xs-12 col-md-12">
              <div className="wow fadeIn" data-wow-duration="1s">
                <h1 style={ theme && theme.sectionTitle ? theme.sectionTitle : null  }  >
                  {this.state.teamContainerTitle}
                </h1>
                <span className="section-divider"></span>
              </div>
            </div>
            {this.state.teamContainerText ? (
              <div className="col-12 wow fadeInLeft">
                <p
                  style={
                    theme && theme.sectionDescription
                      ? theme.sectionDescription
                      : null
                  }
                  className="description"
                >
                  {this.state.teamContainerText}
                </p>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className={layout == 6 || layout == 7 ?"containerAux" :  "centerItems" }>
            {this.state.teamMembers === 0 ? (
              <div className="centerImage">
                <Box>
                  <CircularProgress color={colorSpin} />
                  <Typography>{Strings.generalMessages.loading}</Typography>
                </Box>
              </div>
            ) : (
              layout == 6 ? 
                <TeamLayout6 
                  theme={this.props.theme}
                  members={membersCarrousel}
                /> 
                :  
                layout == 7 ? 
                <TeamLayout7 
                  theme={this.props.theme}
                  members={membersCarrousel}
                /> 
                :  
                this.renderTeamMembersComponents(this.state.teamMembers)
            )}
          </div>
        </div>
      </section>
    );
  }
}
export default TeamSection;
