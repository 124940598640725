import React, { Component, Fragment } from "react";
import "../index.scss";
import { PlusCircleOutlined } from "@ant-design/icons";
import ModalInfoImageGallery from "../ModalInfoImageGallery.jsx";

export class GalleryOpenImageLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openModalImage: false,
    };
  }
  openModalImage = () => {
    this.setState({ openModalImage: true });
  };
  closeModaleImage = () => {
    this.setState({ openModalImage: false });
  };

  render() {
    const { id, theme, note, image, text, date } = this.props;
    let backgroundColor = "#008cba";
    if (theme) {
      backgroundColor = theme.headerBackground.fixed;
      backgroundColor = backgroundColor.substring(23, backgroundColor.length);
      let last = backgroundColor.substring(backgroundColor.length - 2);
      if (last === "))") {
        backgroundColor = backgroundColor.substring(
          0,
          backgroundColor.length - 1
        );
      }
    }
    let widthHeigth = "250px";
    return (
      <div>
        <ModalInfoImageGallery
          note={note}
          text={text}
          image={image}
          date={date}
          theme={theme}
          visible={this.state.openModalImage}
          handleClose={this.closeModaleImage}
        />
        <div className="centerImage">
          <div className="openImages">
            <div
              className="imageGallery-pic"
              style={{
                "--backgroundColor": backgroundColor,
                "--widthHeigth": widthHeigth,
              }}
            >
              <img src={image} alt="imageGallery" />
              <div className="detailImage">
                <p>
                  <PlusCircleOutlined
                    onClick={() => this.openModalImage()}
                    className="iconPLusCircle"
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
