import React from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { CloseOutlined, LogoutOutlined } from "@ant-design/icons";
import "../../genericComponents/css/Button.css";
import { WarningOutlined } from "@ant-design/icons";

//Localization
import Strings from "../../../reactUtils/systemVariables/languageLocalization/LocalizedStrings.jsx";

import "./index.css";

const EventLiveModal = (props) => {
  const { visible, nowStreaming, isSubpage, sectionStreamingId, theme } = props;

  var bodyModal = [];
  var titleModal = "";
  // if (nowStreaming.length === 1) {
  //   bodyModal = `${Strings.streaming.weHaveStreaming}: `;
  //   bodyModal = `${bodyModal} ${nowStreaming[0].title}`;
  // } else {
  //   bodyModal = `${Strings.streaming.weHaveStreamings}: `;
  //   nowStreaming.forEach((element) => {
  //     bodyModal = `${bodyModal} ${element.title},`;
  //   });
  //   bodyModal = bodyModal.substring(0, bodyModal.length - 1);
  // }
  if (nowStreaming.length === 1) {
    titleModal = Strings.streaming.weHaveEventLive;
  } else {
    titleModal = Strings.streaming.weHaveEventLives;
  }
  nowStreaming.forEach((element) => {
    bodyModal.push(
      <div className="text-center">
        <h5 className="h5">{element.title}</h5>
        <br />
      </div>
    );
  });
  let backgroundButton = "transparent";
  let headerFixedStyle = "transparent";
  let headerHomeStyle = "transparent";
  let borderColorButton = "#008cba";
  let colorText = "black";
  if (theme != null) {
    backgroundButton = theme.headerTitles.color;
    headerFixedStyle = theme.headerTitles.fixed;
    headerHomeStyle = theme.headerBackground.fixed;
    borderColorButton = theme.sectionText1.color;
    colorText = "white";
  }
  let fontFamily = "Montserrat";

  return (
    <Modal open={visible} onClose={() => props.handleClose()} aria-labelledby="child-modal-title" aria-describedby="child-modal-description" >
      <Box className="modal-container3">
        <div className="containerIconLive">
          <WarningOutlined className="iconLiveWarning" />
        </div>
        <div className="containerModalLive">
          <h6 id="child-modal-title" style={{'--fontFamily': fontFamily}}>{titleModal}</h6>
          <p id="child-modal-description" style={{'--fontFamily': fontFamily}}> {bodyModal} </p>
          <div className="row" key={1}>
          {isSubpage === false ? (
            <div className="col-xs-12 col-xl-12 text-center">
              <Button
                icon={<LogoutOutlined />}
                className="buttonOk"
                style={{"--backgroundButton": headerHomeStyle, "--borderColorButton": borderColorButton, "--colorText": colorText, }}
                onClick={(e) => props.handleGoToStreaming(e)}
                href={"/#streaming" + sectionStreamingId}
              >
                {Strings.basicWords.goTo}
              </Button>
              <Button
                icon={<CloseOutlined />}
                className="buttonClose"
                style={{ "--backgroundButton": headerHomeStyle, "--borderColorButton": borderColorButton, "--colorText": colorText, }}
                onClick={(e) => props.handleClose(e)}
              >
                {Strings.basicWords.close}
              </Button>
            </div>
            ) : (
              <div className="col-xs-12 col-xl-12 text-center">
                <Button
                icon={<CloseOutlined />}
                className="buttonClose"
                style={{ "--backgroundButton": headerHomeStyle, "--borderColorButton": borderColorButton, "--colorText": colorText, }}
                onClick={(e) => props.handleClose(e)}
                >
                  {Strings.basicWords.close}
                </Button>
              </div>
            )}
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default EventLiveModal;
