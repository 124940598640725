import React from "react";
import "../index.css";
import SimpleImageSlider from "react-simple-image-slider";

export function StandarHomeLayout(props) {
  let { img1, img2, img3, imgBack } = props;
  let images = [
    { url: imgBack && !imgBack.includes("undefined") && !imgBack.includes("noImage") ? imgBack : null },
    { url: img1 && !img1.includes("undefined") && !img1.includes("noImage") ? img1 : null },
    { url: img2 && !img2.includes("undefined") && !img2.includes("noImage") ? img2 : null },
    { url: img3 && !img3.includes("undefined") && !img3.includes("noImage") ? img3 : null },
  ];

  let finalImages = [];

  images.forEach(img => {
    if (img.url != null) finalImages.push(img);
  });

  return (
    // <section id="home">
    <SimpleImageSlider
      width={'100vw'}
      height={screen.width < 769 ? "40vh" :
        "70vh"}
      images={finalImages}
      showBullets={true}
      showNavs={true}
    />
    // </section>
  );
}
