import React, { Component } from "react";
import "../index.css";
import noVideo from "../../../../images/noVideo.png";
import getVideoId from "get-video-id";

// export function HomeVideoLayout(props) {
export class HomeVideoLayout extends Component {
  render() {
    const { theme, title, description, imgBack, linkYoutube } = this.props;
    let lenWindow = window.screen.height;
    if (lenWindow > 1000) {
      lenWindow = window.screen.height - 143;
    }
    let idVideo = getVideoId(linkYoutube);
    idVideo = idVideo.id;
    return (
      <div>
        {idVideo ? (
          <section id="home">
            <iframe className="video"
              src={`https://www.youtube.com/embed/${idVideo}?version=3&loop=1&autoplay=1&mute=1&controls=0&;rel=0&playlist=${idVideo}`}
              frameBorder="0"
              allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            ></iframe>
          </section>
        ) : (
          <section
          id="home"
            style={ theme && theme.homeBackground
                ? {   backgroundImage:  "linear-gradient(" + theme.homeBackground + '), url("' + noVideo + '")',  }
                : {
                  backgroundImage: 'linear-gradient(45deg, rgba(63,132,170, 0.8), rgba(63,132,170, 0.8)), url("' + noVideo + '")',   }   }
          >
            <div className="descriptionText">
              <h2 style={theme && theme.homeTitle ? theme.homeTitle : null}>    {title}  </h2>
              <br />
              <br />
              <p style={ theme && theme.homeDescription  ? theme.homeDescription  : null  } > {description}  </p>
            </div>
          </section>
        )}
      </div>
    );
  }
}
