import axios from 'axios';

const queryUrl = 'https://publication.evangelizo.ws/SP/days/'

export async function getGospelInfo(date) {
    try {
        let response = await axios.get(
            queryUrl + date, {
            params: {
                from: 'gospelComponent',
            },
        })
        return response;
    } catch (error) {
        return error;
    }

 }