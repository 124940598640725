import React from "react";
import "../index.css";
import imgNull from "../../../../images/noImage.jpg";
import ButtonsContainer from "../../general/ButtonsContainer.jsx";

export function HomeOneImageLayout(props) {
  const { key, theme, buttons, title, description, img1, img2, img3, imgBack } =
    props;
  console.log(buttons);
  return (
    <div>
      <section
        id="home"
        // style={{backgroundImage: img1}}
      >
        <img src={img1} className="imageBgLayout1" alt="image background" />
          {buttons ? (
            <div className="homeButtonsComponent" >
              <ButtonsContainer
                showBorder={true}
                buttons={buttons}
                theme={theme}
                isHome={true}
              />
            </div>
          ) : null}
          
      </section>
    </div>
  );
}
