import { combineReducers } from 'redux';

import {lpTheme} from './theme.reducer'


const rootReducer = combineReducers({

   lpTheme

});

export default rootReducer;
