import React, { Component } from "react";
import { CloseCircleOutlined, HomeOutlined } from "@ant-design/icons";

import Button from "@mui/material/Button";

//Localized
import Strings from "../../../reactUtils/systemVariables/languageLocalization/LocalizedStrings.jsx";

// Styles
import "./paymentMethods/payments.css";
import "./index.css";

class DonationCancel extends Component {
  constructor(props) {
    super(props);
    this.state = { };
  }
  componentDidMount() {
    const { lpId, theme } = this.props;
    let headerHomeStyle = theme ? theme.headerBackground.fixed : null;
    let borderColorButton = theme ? theme.sectionTitle.color : null;
    let borderColorButtonHover = theme ? theme.sectionSubtitle.color : null;
    let fontFamily = theme ? theme.headerTitles.fontFamily : null;
    let colorText = "white";

    this.setState({
      lpId,
      headerHomeStyle,
      borderColorButton,
      borderColorButtonHover,
      fontFamily,
      colorText,
    });
  }

  goToHome = () => {
    window.location.replace(window.location.origin);
  }

  render() {
    // const { } = this.state;
    const theme = this.props.theme;

    return (
      <div className="containerPaymentsSuccess">
        <div className="paymentSuccess wow fadeIn" data-wow-duration="1s">
          <div className="payment-success">
            <div className="payment-title">
              <CloseCircleOutlined style={{ fontSize: 50, color: 'red', margin: '10px' }}  />
              <h6 style={theme && theme.sectionSubtitle ? theme.sectionSubtitle : null + this.props && this.props.individualStyle &&
                this.props.individualStyle.title ? this.props.individualStyle.title : null} className="titleDonationCancel">
                ¡Transacción cancelada!
              </h6>
            </div>
            <div className="form-container">
                <div className="row button-center marginTop zIndexOne">
                  <Button
                    className="btnPayment"
                    style={{
                      "--backgroundButton": this.state.headerHomeStyle,
                      "--borderColorButton": this.state.borderColorButton,
                      "--borderColorButtonHover":
                        this.state.borderColorButtonHover,
                      "--colorText": this.state.colorText,
                    }}
                    onClick={() => this.goToHome()}
                  >
                    {Strings.sections.home} <HomeOutlined color="white" />
                  </Button>
                </div>
              </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DonationCancel;
