import React, { Component } from "react";

import { getLpSectionInformation } from "../../../actions/index";

import {
  HomeOutlined,
  MailOutlined,
  PhoneOutlined,
  FacebookOutlined,
  TwitterOutlined,
  InstagramOutlined,
  YoutubeOutlined,
  WhatsAppOutlined,
} from "@ant-design/icons";

import "./index.css";
import strings from "../../../reactUtils/systemVariables/languageLocalization/LocalizedStrings";

class ContactSection extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.getContactInformation();
  }

  async getContactInformation() {
    let response = await getLpSectionInformation(
      this.props.lpId,
      this.props.lpSectionId,
      "CONTACT"
    );
    let sectionInfo = response.data.data;
    if (sectionInfo != null) {
      let contactComponent = sectionInfo.components.find(
        (comp) => comp.name === "ContactContainerComponent"
      );
      let contactUsTitle = contactComponent.nodes.find(
        (node) => node.nodeType === "ContactUsTitle"
      ).value;
      let contactUsText = contactComponent.nodes.find(
        (node) => node.nodeType === "ContactUsText"
      );
      contactUsText = contactUsText ? contactUsText.value : "";
      let contactUsAddress = contactComponent.nodes.find(
        (node) => node.nodeType === "ContactUsAddress"
      );
      contactUsAddress = contactUsAddress ? contactUsAddress.value : "";
      let contactUsEmail = contactComponent.nodes.find(
        (node) => node.nodeType === "ContactUsEmail"
      );
      contactUsEmail = contactUsEmail ? contactUsEmail.value : "";
      let contactUsPhone = contactComponent.nodes.find(
        (node) => node.nodeType === "ContactUsPhone"
      );
      contactUsPhone = contactUsPhone ? contactUsPhone.value : "";
      let contactUsTwitter = contactComponent.nodes.find(
        (node) => node.nodeType === "ContactUsTwitter"
      );
      contactUsTwitter = contactUsTwitter ? contactUsTwitter.value : "";
      let contactUsFacebook = contactComponent.nodes.find(
        (node) => node.nodeType === "ContactUsFacebook"
      );
      contactUsFacebook = contactUsFacebook ? contactUsFacebook.value : "";
      let contactUsInstagram = contactComponent.nodes.find(
        (node) => node.nodeType === "ContactUsInstagram"
      );
      contactUsInstagram = contactUsInstagram ? contactUsInstagram.value : "";
      let contactUsYoutube = contactComponent.nodes.find(
        (node) => node.nodeType === "ContactUsYoutube"
      );
      contactUsYoutube = contactUsYoutube ? contactUsYoutube.value : "";
      let contactUsWapp = contactComponent.nodes.find(
        (node) => node.nodeType === "ContactUsWhastapp"
      );
      contactUsWapp = contactUsWapp ? contactUsWapp.value : "";

      var regex = /https:/;
      var validateTwitter =
        contactUsTwitter != "" ? regex.test(contactUsTwitter) : "";
      var validateAddress =
        contactUsAddress != "" ? regex.test(contactUsAddress) : "";
      var validateFacebook =
        contactUsFacebook != "" ? regex.test(contactUsFacebook) : "";
      var validateInstagram =
        contactUsInstagram != "" ? regex.test(contactUsInstagram) : "";
      var validateYoutube =
        contactUsYoutube != "" ? regex.test(contactUsYoutube) : "";
      if (validateTwitter === false) {
        contactUsTwitter = `https://${contactUsTwitter}`;
      }
      if (validateFacebook === false) {
        contactUsFacebook = `https://${contactUsFacebook}`;
      }
      if (validateInstagram === false) {
        contactUsInstagram = `https://${contactUsInstagram}`;
      }
      if (validateYoutube === false) {
        contactUsYoutube = `https://${contactUsYoutube}`;
      }

      this.setState({
        contactUsTitle,
        contactUsText,
        contactUsAddress,
        contactUsEmail,
        contactUsPhone,
        contactUsTwitter,
        contactUsFacebook,
        contactUsInstagram,
        contactUsYoutube,
        contactUsWapp,
      });
    } else {
      this.setState({ flagEmpty: true });
    }
  }

  render() {
    const { theme } = this.props;
    let headerFixedStyle =
      "linear-gradient(45deg, rgb(23,162,184), rgb(23,162,184))";
    let headerHomeStyle =
      "linear-gradient(45deg, rgba(23,162,184, 0.5), rgba(23,162,184, 0.5))";
    if (theme && theme.headerBackground) {
      if (theme.headerBackground.fixed) {
        headerFixedStyle = theme.headerBackground.fixed;
      }
      if (theme.headerBackground.home) {
        headerHomeStyle = theme.headerBackground.home;
      }
    }
    if (this.props.isSubpageFlag == true) {
      headerHomeStyle = headerFixedStyle;
    } else {
      headerHomeStyle = "background: background: transparent !important";
    }

    {
      /* <div className="contact">
      <h3>{this.state.contactUsTitle}</h3>
      <p id="contactText">{this.state.contactUsText}</p>
      <div className="social-links">
        <div className="text-center">
          {this.state.contactUsInstagram != "" ? (
            <a
              href={this.state.contactUsInstagram}
              className="instagram"
              target="_blank"
              rel="noopener noreferrer"
            >
              <InstagramOutlined />
            </a>
          ) : (
            ""
          )}
          {this.state.contactUsFacebook != "" ? (
            <a
              href={this.state.contactUsFacebook}
              className="facebook"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FacebookOutlined />
            </a>
          ) : (
            ""
          )}
          {this.state.contactUsTwitter != "" ? (
            <a
              href={this.state.contactUsTwitter}
              className="twitter"
              target="_blank"
              rel="noopener noreferrer"
            >
              <TwitterOutlined />
            </a>
          ) : (
            ""
          )}
          {this.state.contactUsYoutube != "" ? (
            <a
              href={this.state.contactUsYoutube}
              className="youtube"
              target="_blank"
              rel="noopener noreferrer"
            >
              <YoutubeOutlined />
            </a>
          ) : (
            ""
          )}
        </div>
      </div>
    </div> */
    }

    return (
      <section
        id="contact"
        style={{
          "--background-col-scroll": headerFixedStyle,
          background: headerHomeStyle,
        }}
      >
        <div className="containerContact wow fadeInUp">
          <div className="info">
            {this.state.contactUsWapp != "" &&
            this.state.contactUsWapp != "https://" ? (
              <>
                <div className="rowContainerContactSection">
                  <div className="containerContactSection">
                    {this.state.contactUsWapp != "" &&
                    this.state.contactUsWapp != "https://" ? (
                      <a
                        href={
                          "https://api.whatsapp.com/send?phone=" +
                          this.state.contactUsWapp
                        }
                        className="youtube"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={ theme && theme.headerTitles ? {...theme.headerTitles} : {color: "white"} }
                      >
                        <WhatsAppOutlined />
                      </a>
                    ) : null}
                  </div>
                  <p style={ theme && theme.headerTitles ? {...theme.headerTitles} : {color: "white"} }>{this.state.contactUsWapp}</p>
                  {/* <p>WhatsApp</p> */}
                </div>
                <hr className="separatorContact" style={ theme && theme.headerTitles ? {...theme.headerTitles} : {color: "white"} }/>

              </>
            ) : null}
            {this.state.contactUsAddress != "" &&
            this.state.contactUsAddress != "https://" ? (
              <>
                <div className="rowContainerContactSection">
                  <div className="containerContactSection">
                    {this.state.contactUsAddress != "" &&
                    this.state.contactUsAddress != "https://" ? (
                      <a
                        href={
                          "https://www.google.com/maps/dir/" +
                          this.state.contactUsAddress
                        }
                        className="address"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={ theme && theme.headerTitles ? {...theme.headerTitles} : {color: "white"} }
                      >
                        <HomeOutlined />
                      </a>
                    ) : null}
                    <p style={ theme && theme.headerTitles ? {...theme.headerTitles} : {color: "white"} }>{this.state.contactUsAddress}</p>
                  </div>
                  {/* <p>Dirección</p> */}
                </div>
                <hr className="separatorContact" style={ theme && theme.headerTitles ? {...theme.headerTitles} : {color: "white"} }/>

              </>
            ) : null}
            {this.state.contactUsPhone != "" &&
            this.state.contactUsPhone != "https://" ? (
              <>
                <div className="rowContainerContactSection">
                  <div className="containerContactSection">
                    {this.state.contactUsPhone != "" &&
                    this.state.contactUsPhone != "https://" ? (
                      <a
                        href={"tel:" + this.state.contactUsPhone}
                        className="phone"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={ theme && theme.headerTitles ? {...theme.headerTitles} : {color: "white"} }
                      >
                        <PhoneOutlined />
                      </a>
                    ) : null}
                  </div>
                  <p style={ theme && theme.headerTitles ? {...theme.headerTitles} : {color: "white"} }>{this.state.contactUsPhone}</p>
                  {/* <p>WhatsApp</p> */}
                </div>
                <hr className="separatorContact" style={ theme && theme.headerTitles ? {...theme.headerTitles} : {color: "white"} }/>
              </>
            ) : null}
            {this.state.contactUsEmail != "" &&
            this.state.contactUsEmail != "https://" ? (
              <>
                <div className="rowContainerContactSection">
                  <div className="containerContactSection">
                    {this.state.contactUsEmail != "" &&
                    this.state.contactUsEmail != "https://" ? (
                      <a
                        href={"mailto:" + this.state.contactUsEmail}
                        className="email"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={ theme && theme.headerTitles ? {...theme.headerTitles} : {color: "white"} }
                      >
                        <MailOutlined />
                      </a>
                    ) : null}
                  </div>
                  <p style={ theme && theme.headerTitles ? {...theme.headerTitles} : {color: "white"} }>{this.state.contactUsEmail}</p>
                  {/* <p>Email</p> */}
                </div>
                <hr className="separatorContact" style={ theme && theme.headerTitles ? {...theme.headerTitles} : {color: "white"} }/>

              </>
            ) : null}
            {(this.state.contactUsInstagram != "" &&
              this.state.contactUsInstagram !=
                "https://instagram/miparroquia") ||
            (this.state.contactUsFacebook != "" &&
              this.state.contactUsFacebook != "https://facebook/miparroquia") ||
            (this.state.contactUsTwitter != "" &&
              this.state.contactUsTwitter != "https://twitter/miparroquia") ||
            (this.state.contactUsYoutube != "" &&
              this.state.contactUsYoutube != "https:// ") ? (
              <>
                <div className="rowContainerContactSectionSocialMedia">
                  <div className="containerSocialMedia">
                    {this.state.contactUsInstagram != "" &&
                    this.state.contactUsInstagram !=
                      "https://instagram/miparroquia" ? (
                      <a
                        href={this.state.contactUsInstagram}
                        className="instagram"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={ theme && theme.headerTitles ? {...theme.headerTitles} : {color: "white"} }
                      >
                        <InstagramOutlined />
                      </a>
                    ) : null}
                    {this.state.contactUsFacebook != "" &&
                    this.state.contactUsFacebook !=
                      "https://facebook/miparroquia" ? (
                      <a
                        href={this.state.contactUsFacebook}
                        className="facebook"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={ theme && theme.headerTitles ? {...theme.headerTitles} : {color: "white"} }
                      >
                        <FacebookOutlined />
                      </a>
                    ) : null}
                    {this.state.contactUsTwitter != "" &&
                    this.state.contactUsTwitter !=
                      "https://twitter/miparroquia" ? (
                      <a
                        href={this.state.contactUsTwitter}
                        className="twitter"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={ theme && theme.headerTitles ? {...theme.headerTitles} : {color: "white"} }
                      >
                        <TwitterOutlined />
                      </a>
                    ) : null}
                    {this.state.contactUsYoutube != "" &&
                    this.state.contactUsYoutube != "https:// " ? (
                      <a
                        href={this.state.contactUsYoutube}
                        className="youtube"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={ theme && theme.headerTitles ? {...theme.headerTitles} : {color: "white"} }
                      >
                        <YoutubeOutlined />
                      </a>
                    ) : null}
                  </div>
                  <p style={ theme && theme.headerTitles ? {...theme.headerTitles} : {color: "white"} }>{strings.generalMessages.followUs}</p>
                </div>
              </>
            ) : null}
          </div>
        </div>
      </section>
    );
  }
}
export default ContactSection;
