import React from "react";
import { Link } from "react-router-dom";
import imageNull from "../../../../../images/noImage.jpg";
import moment from "moment";
import "./index.scss";
import ButtonsContainer from "../../../general/ButtonsContainer.jsx";

var dateStartTime = "DD/MM/YYYY hh:mm";
export function CardLayout(props) {
  let { id, theme, title, text, multimedia} = props;

  if (props.individualStyle) {
    display = "block";
  }
  var event = {};
  if (props.events) {
    event = {
      title: props.title,
      description: props.text,
      startTime: moment(props.date).format(dateStartTime),
      endTime: moment(props.date).add(1, "hours").format(dateStartTime),
      location: props.place,
    };
  }

  let firstButton = [];
  // if (props.buttons.length > 0) {
  // let firstElement = props.buttons.shift();
  //   firstButton.push(firstElement);
  // }

  let buttonLinkPath = "/component/"
  if(props.donations){
    buttonLinkPath = "/payment/"
  }

  let image  =imageNull;
  if(multimedia && multimedia[1]){
    image = multimedia[1].value ? multimedia[1].value : multimedia[1]
  }
  if(multimedia && multimedia[3]){
    image = multimedia[3].value ? multimedia[3].value : multimedia[3];
  }
  let buttons = props.buttons;

  return (
      <div className="col-xs-12 col-md-4 col-lg-3 cardNews" >
        <Link to={{ pathname: buttonLinkPath + id }}>
          <img className="cardImgNews" src={image} alt="Card" />
        </Link>
        <div className="contentCardNews">
          <Link to={{ pathname: buttonLinkPath + id }}>
            <h3 style={theme && theme.sectionSubtitle ? theme.sectionSubtitle : null}>{title} </h3>
          </Link>
          <p style={theme && theme.sectionText1 ? theme.sectionText1 : null} >
            <Link to={{ pathname: buttonLinkPath + id }}>{text}</Link>
          </p>
          <div className="col-xs-12 col-md-12 text-center align-self-end">
            {props.events ? (
              <ButtonsContainer 
                showBorder={true} 
                buttons={props.buttons} 
                event={event} 
                theme={theme}  
                internalLpSectionComponentId={id} 
                style='containerLinearButtons'
                size={12} 
                margin={5} 
                componentId={id} 
              />
            ) : (
              <div>
                <ButtonsContainer 
                  showBorder={true} 
                  buttons={props.buttons} 
                  theme={theme} 
                  internalLpSectionComponentId={id} 
                  style='containerLinearButtons'
                  readMore={false} 
                  componentId={id} 
                />
              </div>
            )}
          </div>
        </div>
      </div>
  );
}
