import React from "react";
import moment from "moment";
import "./index.scss";
import { Modal, Button } from "@mui/material";

//Localization
import Strings from "../../../reactUtils/systemVariables/languageLocalization/LocalizedStrings.jsx";

const ModalInfoImageGallery = (props) => {
  const { visible, note, text, image, date, theme } = props;
  const formarImage = "DD/MM/YYYY";
  let width = "400px";
  let heigth = "400px";
  let backgroundButton = "transparent";
  let headerFixedStyle = "transparent";
  let headerHomeStyle = "transparent";
  let borderColorButton = "#008cba";
  let colorText = "black";
  if (theme != null) {
    backgroundButton = theme.headerTitles.color;
    headerFixedStyle = theme.headerTitles.fixed;
    headerHomeStyle = theme.headerBackground.fixed;
    borderColorButton = theme.sectionText1.color;
    colorText = "white";
  }
  return (
    <Modal
      width={800}
      open={visible}
      onClose={(e) => props.handleClose(e)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      key={1}
    >
      <>
        <div className="col-xs-12 col-md-12" style={{ margin: "5px" }}>
          <div className="centerImage">
            <div className="row">
              <br />
              <div className="col-xs-12 col-md-12 componentSpace">
                <div className="col-xs-12 col-md-12 componentSpace">
                  <div
                    className="boxModalLayout"
                    style={{
                      "--width": width,
                      "--heigth": heigth,
                    }}
                  >
                    <div className="centerImage">
                      <img src={image} alt="gallery" />
                    </div>
                  </div>
                </div>
                {text ? (
                  <div className="col-xs-12 col-md-12 componentSpace">
                    <h3
                      style={
                        theme && theme.sectionSubtitle
                          ? theme.sectionSubtitle
                          : null + props.individualStyle &&
                            props.individualStyle.title
                          ? props.individualStyle.title
                          : null
                      }
                    >
                      {note}
                    </h3>
                  </div>
                ) : (
                  ""
                )}
                {note ? (
                  <div className="col-xs-12 col-md-12 componentSpace centerImage">
                    <p
                      style={
                        theme && theme.sectionText1
                          ? theme.sectionText1
                          : null + props.individualStyle &&
                            props.individualStyle.text
                          ? props.individualStyle.text
                          : null
                      }
                      className="body text"
                      id="text"
                    >
                      {text}
                    </p>
                  </div>
                ) : (
                  ""
                )}
                {date ? (
                  <div className="col-xs-12 col-md-12 componentSpace centerImage">
                    <p
                      style={
                        theme && theme.sectionText1
                          ? theme.sectionText1
                          : null + props.individualStyle &&
                            props.individualStyle.text
                          ? props.individualStyle.text
                          : null
                      }
                      className="body text"
                      id="text"
                    >
                      {moment(date).format(formarImage)}
                    </p>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="centerImage">
            <div className="col-xs-12 col-md-12">
              <Button
                className="buttonModal"
                style={{
                  "--backgroundButton": headerHomeStyle,
                  "--borderColorButton": borderColorButton,
                  "--colorText": colorText,
                }}
                onClick={(e) => props.handleClose(e)}
                size="large"
                type="primary"
                shape="round"
              >
                {Strings.basicWords.back}
              </Button>
            </div>
          </div>,
      </>
    </Modal>
  );
};

export default ModalInfoImageGallery;
