import React, { Component } from "react";
import {
  getLpSectionInformation,
  getFilePath,
} from "../../../actions/index";
import GalleryLayoutIndex from "./layoutStyles/GalleryLayoutIndex.jsx";
import { Box, CircularProgress, Typography } from "@material-ui/core";

import { LoadingOutlined } from "@ant-design/icons";
import { GalleryCarouselLayout } from "./layoutStyles/GalleryCarouselLayout.jsx";

class GallerySection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      limit: 6,
      photos: [],
    };
  }

  componentDidMount() {
    this.getGalleryInformation();
  }

  async getGalleryInformation() {
    let response = await getLpSectionInformation(
      this.props.lpId,
      this.props.lpSectionId,
      "GALLERY"
    );
    let sectionInfo = response.data.data;
    let galleryComponent = sectionInfo.components.find(
      (comp) => comp.name === "GalleryComponent"
    );
    let galleryId = sectionInfo.id;
    let galleryTitle = galleryComponent.nodes.find(
      (node) => node.nodeType === "GalleryTitle"
    ).value;
    let galleryText = galleryComponent.nodes.find(
      (node) => node.nodeType === "GalleryText"
    );
    galleryText = galleryText ? galleryText.value : "";
    let photos = sectionInfo.components.filter(
      (comp) => comp.name === "PhotoComponent"
    );

    for (var i = 0; i < photos.length; i++) {
      if (!photos[i].img) {
        let imgNode = photos[i].nodes.find(
          (node) => node.nodeType === "PhotoImage"
        );
        if (imgNode.value != null && imgNode.value !== "") {
          //Get image
          let imgName = imgNode.value.replace(".jpeg", "").replace(".jpg", "").replace(".png","")
          let format = imgNode.value.match(/\.([^.]*)$/)[1]

           let imgS = await getFilePath(imgName, "smallSize", format);
           let imgM = await getFilePath(imgName, "mediumSize", format);
           let imgL = await getFilePath(imgName, "largeSize", format);

           photos[i].imgS = imgS;
           photos[i].imgM = imgM;
           photos[i].imgL = imgL;
        }
      }
    }
    let background = "";
    // let backgroundImage = "";
    if (this.props.background === true) {
      background = "#eff5f5";
      // backgroundImage =
      //   ' url("https://jointabella.com/wp-content/uploads/2020/06/forchurches-section2.svg")';
    }
    if (this.props.background === false) {
      background = "#ffff";
      // backgroundImage =
      //   ' url("https://jointabella.com/wp-content/uploads/2020/10/BG-Section2-1.svg")';
    }
    let layout = sectionInfo.lpSectionLayoutStyleId;
    if (layout === 7) {
      //standar gallery layout
      layout = 0;
    }
    if (layout === 38) {
      //gallery move iamge layout
      layout = 1;
    }
    if (layout === 39) {
      //gallery black and white image layout
      layout = 2;
    }
    if (layout === 82) {
      //gallery carousel layout
      layout = 3;
    }
    if(process.env.REACT_APP_DEFAULT_LAYOUT_GALLERY){
      layout = parseInt(process.env.REACT_APP_DEFAULT_LAYOUT_GALLERY);
    }
    this.setState({
      galleryId,
      galleryTitle,
      galleryText,
      photos,
      layout,
      background,
      // backgroundImage,
    });
  }

  renderPhotoComponents(photos) {
    const { theme } = this.props;
    let renderedComponents = [];
    if (photos) {
      photos.forEach((element) => {
        let note = element.nodes.find((node) => node.nodeType === "PhotoNote");
        note = note ? note.value : "";
        let date = element.nodes.find((node) => node.nodeType === "PhotoDate");
        date = date ? date.value : "";
        let text = element.nodes.find(
          (node) => node.nodeType === "PhotoAuthor"
        );
        text = text ? text.value : "";
        let image = element.imgL;
        renderedComponents.push(
          <GalleryLayoutIndex
            note={note}
            date={date}
            theme={theme}
            text={text}
            image={image}
            layout={this.state.layout}
            id={element.id}
            key={element.id}
            photos={photos}
          />
        );
      });
    }
    return renderedComponents;
  }

  render() {
    const antIcon = <LoadingOutlined style={{ fontSize: 150 }} spin />;
    const { theme } = this.props;
    const {
      background,
      // backgroundImage
    } = this.state;
    let colorSpin = "#008cba";
    if (theme != null) {
      colorSpin = theme.sectionTitle.color;
    }

    return (
      <div >
      {/* <div style={{ background: background, }} > */}
        <section id={"gallery" + this.state.galleryId}>
          <div className="containerAux">
            <div className="row">
              <div className="col-12">
                <div className="wow fadeIn" data-wow-duration="1s">
                  <h1 style={ theme && theme.sectionTitle ? theme.sectionTitle : null } >
                    {this.state.galleryTitle}
                  </h1>
                  <span className="section-divider"></span>
                </div>
              </div>
              {this.state.galleryText ? (
                <div className="col-12 wow fadeInLeft">
                  <p style={  theme && theme.sectionDescription ? theme.sectionDescription : null } className="description"  >
                    {this.state.galleryText}
                  </p>
                </div>
              ) : (
                ""
              )}
            </div>
            <div>
              {this.state.photos === 0 ? (
                <Box>
                  <CircularProgress color={colorSpin} />
                  <Typography>{Strings.generalMessages.loading}</Typography>
                </Box>
              ) : (
                this.state.layout == 3 ? 
                <GalleryCarouselLayout theme={this.props.theme}  photos={this.state.photos} /> 
                :  
                <div className="centerItems">
                  {this.renderPhotoComponents(this.state.photos)}
                </div>
              )}
            </div>
          </div>
        </section>
      </div>
    );
  }
}
export default GallerySection;
