import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { CookiesProvider } from "react-cookie";
import { hydrate, render } from "react-dom";
import { AppRoutes } from "./reactUtils/routes/routes.js";
import { store } from "./reactUtils/helpers/store";
import * as serviceWorker from "./serviceWorker";

import "./components/css/util.css";
import "./components/css/main.css";

// Components
import LandingPage from "./components/landingPage";

// Verify if the address typed is the CMS url or an organization url
// And redirect to the corresponding web page
let code = window.location.hostname;
code = code.replace("www.", "").replace("https://", "");

if (code.includes("tabella")) {
  code = window.location.href;
  if (code.includes("/payment/")) {
    code = code.slice(0, -13);
  }
  code = code.replace("www.", "").replace("https://", "");
}

let page = process.env.REACT_APP_LP_URL;

// If you desired to load a specific url you should change the enviroments variables else let it null
if (process.env.REACT_APP_LP_URL === "null") {
  page = code;
}

// setTimeout(()=>document.write(JSON.parse(root)), 3000 )

const renderPage = (
  <Provider store={store}>
    <CookiesProvider>
      <Router>
        <LandingPage lpUrl={code} />
      </Router>
    </CookiesProvider>
  </Provider>
);

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(renderPage, rootElement);
} else {
  render(renderPage, rootElement);
}

// render(
//   document.getElementById("root")
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
