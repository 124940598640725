import { LP_THEME } from "../../actions/lpStyleAttribute.actions";

// const initialState = {
//   lpTheme: []
// }

export function lpTheme(state = {}, action) {
  switch (action.type) {
    case "LP_THEMES":
      var lpTheme = action.payload;
      return lpTheme;
    //return Object.assign({}, state, { theme: action.payload });
    default:
      return state;
  }
}
