import React, { Component } from "react";
import "../../stylesLP.css";
import "../index.scss";
import imageNull from "../../../../images/noImage.jpg";
import parse from "html-react-parser";
import ShowMoreText from "react-show-more-text";
import moment from "moment";

class CardsAboutLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      colorSpin: "#008cba",
      limitLine: 3,
    };
  }

  render() {
    const { theme, aboutTitle, aboutText, missionTitle, missionText, visionTitle, visionText, multimedia } = this.props;

    let missionContent = missionTitle.length < 0 && missionText.length < 0;
    let vissionContent = visionTitle.length < 0 && visionText.length < 0; 
    let vissionTextValue = parse(visionText);
    let missionTextValue = parse(missionText);
    let idVideo = null;
    if (!multimedia.includes("https://")) {
      idVideo = multimedia;
    }

    return (
      <div>
        <div className="section-header wow fadeInRight">
          <h1 style={theme && theme.sectionTitle ? theme.sectionTitle : null}> {aboutTitle} </h1>
          <span className="section-divider"></span>
          <p style={  theme && theme.sectionDescription ? theme.sectionDescription : null} className="description" > {aboutText} </p>
        </div>
        <div className="row" style={{ justifyContent: 'center', alignItems: 'flex-start' }}>
          <div style={{ margin: '5px' }} className={vissionContent ? 'col-xs-12 col-md-12 content wow fadeInRight animated text-center' :'col-xs-12 col-md-5 content wow fadeInRight animated text-center' }>
            <div className="cardAbout">
              <h4 style={theme && theme.sectionSubtitle ? theme.sectionSubtitle : null}> {missionTitle}</h4>
              {idVideo != null ? (
                <iframe
                  className="videoAbout"
                  src={`https://www.youtube.com/embed/${idVideo}?version=3&loop=1&autoplay=0&controls=1&;rel=0&playlist=${idVideo}`}
                  frameBorder="0"
                  allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                ></iframe>
              ) : null}
              {idVideo == null && multimedia ? (
                <div className="col-xs-12 col-md-12">
                  <div className="centerImage">
                    <div className="box">
                      <img style={this.props.theme && theme.imagesShape ? theme.imagesShape : null }  src={multimedia}  alt={aboutTitle} />
                    </div>
                  </div>
                </div>
              ) : null}
              {
                missionTextValue.props && missionTextValue.props.children && missionTextValue.props.children.length > 0 ?
                <p style={theme && theme.sectionText1 ? theme.sectionText1 : null}>{missionTextValue}</p>
                : null
              }
            </div>
          </div>
          
          <div style={{ margin: '5px' }} className={missionContent ? 'col-xs-12 col-md-12 content wow fadeInRight animated text-center' :'col-xs-12 col-md-5 content wow fadeInRight animated text-center' }>
            <div className="cardAbout">
              <h4 style={theme && theme.sectionSubtitle ? theme.sectionSubtitle : null}> {visionTitle}</h4>
              {idVideo != null ? (
                <iframe
                  className="videoAbout"
                  src={`https://www.youtube.com/embed/${idVideo}?version=3&loop=1&autoplay=0&controls=1&;rel=0&playlist=${idVideo}`}
                  frameBorder="0"
                  allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                ></iframe>
              ) : null}
              {idVideo == null && multimedia ? (
                <div className="col-xs-12 col-md-12">
                  <div className="centerImage">
                    <div className="box">
                      <img style={this.props.theme && theme.imagesShape ? theme.imagesShape : null }  src={multimedia}  alt={aboutTitle} />
                    </div>
                  </div>
                </div>
              ) : null}
              {
                vissionTextValue.props && vissionTextValue.props.children && vissionTextValue.props.children.length > 0 ?
                <p style={theme && theme.sectionText1 ? theme.sectionText1 : null}>{vissionTextValue.props.children}</p>
                : null
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CardsAboutLayout;
