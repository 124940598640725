import axios from 'axios';

import { host, port, route, datasource } from '../reactUtils/systemVariables/lpserverInformation';

const resource = "/scheduleeventinfo";

export async function getScheduleEventInfoByLpId(lpSectionId) {
    try {
        let response = await axios.get(
            host + port + route + datasource + resource, {
            params: {
                lpsection_id: lpSectionId,
            },
            headers: {
                'Content-Type': 'application/json'
            }
        })
        return response;
    } catch (error) {
        return error;
    }
}