import React from "react";
import ShowMoreText from "react-show-more-text";
import {
  VerticalAlignTopOutlined,
  StepForwardOutlined,
} from "@ant-design/icons";

export function TeamMoveUpLayout(props) {
  const { id, theme, name, image, text, position } = props;
  let widthHeigth = "300px";
  let heightContent = `${name.length*1.65}px`;

  return (
    <div className="wow fadeInRight" key={id}>
      <div className="containerTeam">
        <div className="centerImage">
          <div className="card2"  style={{ "--widthHeigth": widthHeigth, '--heightContent': heightContent }} >
            <div className="imgBx2">
              <img src={image} alt="member team" />
            </div>
            <div className="content2">
              <h4 className="h4" style={theme && theme.sectionSubtitle ? theme.sectionSubtitle : null} > {name} </h4>
              <span style={theme && theme.sectionText1 ? theme.sectionText1 : null} className="description" >
                {position}
              </span>
              <p style={theme && theme.sectionText1 ? theme.sectionText1 : null} >
                <ShowMoreText
                  lines={6}
                  more={
                    <span id="moretext">
                      <StepForwardOutlined />
                    </span>
                  }
                  less={
                    <span id="moretext">
                      <VerticalAlignTopOutlined />
                    </span>
                  }
                  expanded={false}
                >
                  <p id="textDescription">{text}</p>
                </ShowMoreText>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
