import React from "react";
export function HeaderLogoLayout(props) {
  let marginTop = "4px";
  let marginBottom = "4px";
  if (props && props.icon) {
    return (
      <div className="col-12">
        <a href="/#home" className="scrollto">
          <img className="imgLogo" src={props.icon} alt="headerLogo" />
        </a>
      </div>
    );
  } else {
    return null;
  }
}
