import React from "react";

export function TeamTurnCardLayout(props) {
  const { id, theme, name, image, text, position } = props;
  let widthHeigth = "250px";
  return (
    <div className="wow fadeInRight" key={id}>
      <div className="containerTeam">
        <div className="card4"  style={{ "--widthHeigth": widthHeigth, }} >
          <div className="front">
            <img className="img4" src={image} alt="member team" />
          </div>
          <div className="back">
            <div className="content4">
              <h4 style={  theme && theme.sectionSubtitle ? theme.sectionSubtitle : null   }  > {name} <br /> </h4>
              <span style={theme && theme.sectionText1 ? theme.sectionText1 : null} className="description"> {position} </span>
              <p className="textDescriptionWithLimit"  style={theme && theme.sectionText1 ? theme.sectionText1 : null}> {text} </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
