import React, { Component } from "react";
import "../../stylesLP.css";
import { StandarStreamingLayout } from "./StandarStreamingLayout.jsx";

class StreamingLayoutIndex extends Component {
  renderAboutComponent(streamings) {
    const { theme } = this.props;
    let renderedComponents = [];
    let nowStreaming = streamings.nowStreaming;
    let futureStreaming = streamings.futureStreaming;
    let pastStreaming = streamings.pastStreaming;
    if (this.props.sectionStreamingPage != undefined) {
      switch (this.props.layout) {
        case 0:
          renderedComponents.push(
            <StandarStreamingLayout
              sectionStreamingId={this.props.sectionStreamingId}
              futureStreaming={futureStreaming}
              pastStreamings={pastStreaming}
              nowStreaming={nowStreaming}
              channel={this.props.sectionStreamingPage}
              theme={theme ? theme : null}
              isSubpageFlag={this.props.isSubpageFlag}
              key={this.props.id}
              id={this.props.id}
            />
          );
          break;
      }
      return renderedComponents;
    }
  }

  render() {
    return <div>{this.renderAboutComponent(this.props.streamings)}</div>;
  }
}
export default StreamingLayoutIndex;
