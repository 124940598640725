import React, { Component } from "react";
import ButtonsContainer from "../../general/ButtonsContainer.jsx";
import "../index.css";
import { Divider, Typography } from "@material-ui/core";
//import Carousel from "react-material-ui-carousel";
import Carousel from "react-elastic-carousel";
import { getDataYoutube } from "../../../../actions/dataYoutubeVideo.actions";
import getVideoId from "get-video-id";
import moment from "moment";
import imgOffline from "../../../../images/offline.png";
import EventLiveModal from "../EventLiveModal.jsx";

//Localization
import Strings from "../../../../reactUtils/systemVariables/languageLocalization/LocalizedStrings.jsx";

const dateOnlyFormat = "DD/MM/YYYY/HH/mm";

export class StandarStreamingLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: props.title,
      description: props.description,
      futureStreaming: props.futureStreaming,
      pastStreamings: props.pastStreamings,
      nowStreaming: props.nowStreaming,
      verifyFB: /www.facebook.com/,
      flagFB: 0,
      verifyYT: /www.youtube.com/,
      flagYT: 0,
      id: props.id,
      isLive: false,
      renderLiveStreaming: [],
      renderFutureStreaming: [],
      renderPastStreaming: [],
      channel: props.channel,
      isModalVisible: false,
      showFutureStreamings: true,
      isSubpageFlag: props.isSubpageFlag,
      theme: props.theme ? props.theme : null,
      sectionStreamingId: props.sectionStreamingId,
    };
  }

  componentDidMount() {
    if (this.state.channel === "") {
      this.setState({ channel: "https://www.youtube.com/" });
    }
    if (this.state.nowStreaming.length === 0) {
      var renderFutureStreaming = this.state.futureStreaming;
      this.setState({ renderFutureStreaming });
      var renderPastStreaming = this.state.pastStreamings;
      this.setState({ renderPastStreaming });
      let showFutureStreamings = true;
      renderFutureStreaming.forEach(element => {
        if(element.url === null || element.url === ''){
          showFutureStreamings = false;
        }
      });
      this.setState({ showFutureStreamings });
    }

    this.state.nowStreaming.forEach((obj) => {
      var DateInitNowStreamings = obj.initialDate;
      var dateInitNowStreaming = DateInitNowStreamings.split("-");
      dateInitNowStreaming = dateInitNowStreaming[2];
      dateInitNowStreaming = dateInitNowStreaming.split("T");
      dateInitNowStreaming = dateInitNowStreaming[1];
      dateInitNowStreaming = dateInitNowStreaming.split(":");
      var hourInitDateNowStreaming = dateInitNowStreaming[0];
      var minutesInitDateNowStreaming = dateInitNowStreaming[1];

      let DateFinalNowStreamings = obj.finalDate; /// POSIBLE BUG
      var dateFinalNowStreaming = DateFinalNowStreamings.split("-");
      dateFinalNowStreaming = dateFinalNowStreaming[2];
      dateFinalNowStreaming = dateFinalNowStreaming.split("T");
      dateFinalNowStreaming = dateFinalNowStreaming[1];
      dateFinalNowStreaming = dateFinalNowStreaming.split(":");
      var hourFinalDateNowStreaming = dateFinalNowStreaming[0];
      var minutesFinalDateNowStreaming = dateFinalNowStreaming[1];
      var dateNow = moment().format(dateOnlyFormat);
      var minutesDateNow = dateNow.split("/");
      var hourDateNow = minutesDateNow[3];
      minutesDateNow = minutesDateNow[4];
      var diffInittMinutes =
        hourInitDateNowStreaming * 60 +
        minutesInitDateNowStreaming * 1 -
        (hourDateNow * 60 + minutesDateNow * 1);
      var diffFinaltMinutes =
        hourFinalDateNowStreaming * 60 +
        minutesFinalDateNowStreaming * 1 -
        (hourDateNow * 60 + minutesDateNow * 1);
      var urlShowStreamings = obj.url;
      if (urlShowStreamings != "") {
        this.dataYT(
          urlShowStreamings,
          obj,
          diffInittMinutes,
          diffFinaltMinutes
        );
      } else {
        this.dataYT(
          urlShowStreamings,
          obj,
          diffInittMinutes,
          diffFinaltMinutes
        );
      }
    });
  }

  renderStreamings(streamings, word) {
    const { theme } = this.props;
    var renderedComponents = [];
    var tamVideo = screen.width - 100;
    if (streamings) {
      streamings.forEach((obj) => {
        var urlShowStreamings = obj.url;
        if (urlShowStreamings != null) {
          var titleShowStreamings = obj.title;
          var descriptionShowStreamings = obj.description;
          var event = {
            title: obj.title,
            description: obj.description,
            startTime: moment.utc(obj.initialDate).add(5, "hours"),
            endTime: moment.utc(obj.finalDate).add(5, "hours"),
            location: obj.url,
          };
          if (urlShowStreamings != "" && urlShowStreamings != null) {
            var resultFB = this.state.verifyFB.test(urlShowStreamings);
            var resultYT = this.state.verifyYT.test(urlShowStreamings);
            if (resultYT === true) {
              var idYT = getVideoId(urlShowStreamings);
            }
            if (resultFB === true) {
              const separatedUrlVideo = urlShowStreamings.split("/");
              var nameFB = separatedUrlVideo[3];
              var idFB = separatedUrlVideo[5];
            }
            <div>
              {resultYT === true
                ? renderedComponents.push(
                    <div className="col-12">
                      <div className="section-header wow fadeInRight">
                        <h2 style={theme && theme.sectionSubtitle ? theme.sectionSubtitle: null}>
                          <strong>{titleShowStreamings}</strong>
                        </h2>
                      </div>
                      <br/>
                      <div>
                        <iframe
                          // className="responsiveIframe"
                          width="100%"
                          height={this.getContentHeightNonLive()}
                          src={`https://www.youtube.com/embed/${idYT.id}`}
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        ></iframe>
                      </div>
                      <div>
                        {word === "FUTURE" ? (
                          <ButtonsContainer
                            buttons={obj.buttons}
                            isStreamings={true}
                            event={event}
                            showBorder={true}
                          />
                        ) : (
                          <ButtonsContainer
                            buttons={obj.buttons}
                            isStreamings={true}
                            showBorder={true}
                          />
                        )}
                      </div>
                      <br />
                      <div>
                        <p className="description">
                          {descriptionShowStreamings}
                        </p>
                      </div>
                    </div>
                  )
                : null}
              {resultFB === true
                ? renderedComponents.push(
                    <div className="col-12">
                      <div className="section-header wow fadeInRight">
                        <h2
                          style={
                            theme && theme.sectionSubtitle
                              ? theme.sectionSubtitle
                              : null
                          }
                        >
                          <strong>{titleShowStreamings}</strong>
                        </h2>
                      </div>
                      <div>
                        {this.state.flagFB === 1 ? (
                          <iframe
                            src={`https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2F${nameFB}%2Fvideos%2F${idFB}&appId=1027397741079968`}
                            width={widthVideo}
                            height={heigthVideo}
                            frameborder="0"
                            allowfullscreen="true"
                            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                          ></iframe>
                        ) : null}
                      </div>
                      <div>
                        {word === "FUTURE" ? (
                          <ButtonsContainer
                            buttons={obj.buttons}
                            isStreamings={true}
                            event={event}
                            showBorder={true}
                          />
                        ) : (
                          <ButtonsContainer
                            buttons={obj.buttons}
                            isStreamings={true}
                            showBorder={true}
                          />
                        )}
                      </div>
                      <br />
                      <div>
                        <p className="description">
                          {descriptionShowStreamings}
                        </p>
                      </div>
                    </div>
                  )
                : null}
            </div>;
          } else {
            urlShowStreamings = this.state.channel;
            renderedComponents.push(
              <div className="row">
                <div className="col-xl-12 col-xl-12">
                  <a
                    href={urlShowStreamings}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={imgOffline} width={"100%"} height={"100%"} alt="streaming"  />
                  </a>
                </div>
              </div>
            );
          }
        }
      });
    }
    return renderedComponents;
  }

  renderStreamingsLive(streamings) {
    const { theme } = this.props;
    var renderLiveStreaming = [];
    var widthVideo = screen.width - 500;
    var heigthVideo = screen.height - 100;
    if (streamings) {
      streamings.forEach((obj) => {
        var titleShowStreamings = obj.title;
        var descriptionShowStreamings = obj.description;
        var urlShowStreamings = obj.url;
        if (urlShowStreamings != "" && urlShowStreamings != null) {
          var resultFB = this.state.verifyFB.test(urlShowStreamings);
          var resultYT = this.state.verifyYT.test(urlShowStreamings);
          if (resultYT === true) {
            var idYT = getVideoId(urlShowStreamings);
          }
          if (resultFB === true) {
            const separatedUrlVideo = urlShowStreamings.split("/");
            var nameFB = separatedUrlVideo[3];
            var idFB = separatedUrlVideo[5];
          }
          <div>
            {resultYT === true
              ? renderLiveStreaming.push(
                  <div className="col-12">
                    <strong>{titleShowStreamings}</strong>
                    <div>
                      <iframe
                        width={widthVideo}
                        height={heigthVideo}
                        
                        src={`https://www.youtube.com/embed/${idYT.id}`}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                    </div>
                    <div>
                      <ButtonsContainer
                        buttons={obj.buttons}
                        isStreamings={true}
                        showBorder={true}
                      />
                    </div>
                    <br />
                    <div>
                      <p className="description">{descriptionShowStreamings}</p>
                    </div>
                  </div>
                )
              : null}
            {resultFB === true
              ? renderLiveStreaming.push(
                  <div className="col-xs-12 col-md-12">
                    <div className="section-header wow fadeInRight">
                      <h2
                        style={
                          theme && theme.sectionSubtitle
                            ? theme.sectionSubtitle
                            : null
                        }
                      >
                        <strong>{titleShowStreamings}</strong>
                      </h2>
                    </div>
                    <iframe
                      src={`https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2F${nameFB}%2Fvideos%2F${idFB}&appId=1027397741079968`}
                      width={widthVideo}
                      height={heigthVideo}
                      frameborder="0"
                      allowfullscreen="true"
                      allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                    ></iframe>
                    <div>
                      <ButtonsContainer
                        buttons={obj.buttons}
                        isStreamings={true}
                        showBorder={true}
                      />
                    </div>
                    <br />
                    <div>
                      <p className="description">{descriptionShowStreamings}</p>
                    </div>
                  </div>
                )
              : null}
          </div>;
        } else {
          renderLiveStreaming.push(
            <div className="row">
              <div className="col-xl-12 col-xl-12" href={this.state.channel}>
                <a
                  href={this.state.channel}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={imgOffline} width={"100%"} height={"100%"} alt="streaming" />
                </a>
              </div>
            </div>
          );
        }
      });
    }
    return renderLiveStreaming;
  }

  //Functions
  onCloseModal = () => {
    this.setState({
      isModalVisible: false,
    });
  };

  goToStreaming = () => {
    this.setState({
      isModalVisible: false,
    });
  };

  dataYT = async (
    urlShowStreamings,
    obj,
    diffInittMinutes,
    diffFinaltMinutes
  ) => {
    var renderLiveStreaming = this.state.renderLiveStreaming;
    var renderFutureStreaming = [];
    var renderPastStreaming = [];
    this.state.futureStreaming.forEach((element) => {
      if (element.url != null && element.url != "") {
        renderFutureStreaming.push(element);
      }
    });
    this.state.pastStreamings.forEach((element) => {
      if (element.url != null && element.url != "") {
        renderPastStreaming.push(element);
      }
    });

    if (urlShowStreamings != "" && urlShowStreamings != null) {
      var resultYT = this.state.verifyYT.test(urlShowStreamings);
      if (resultYT === true) {
        this.setState({ flagYT: 1 });
      } else {
        this.setState({ flagYT: 0 });
      }
      var resultFB = this.state.verifyFB.test(urlShowStreamings);
      if (resultFB === true) {
        this.setState({ flagFB: 1 });
      } else {
        this.setState({ flagFB: 0 });
      }
      if (resultYT === true) {
        const idVideo = getVideoId(urlShowStreamings);
        const dataYoutube = await getDataYoutube(idVideo.id);
        if (
          dataYoutube.data.items[0].snippet.liveBroadcastContent &&
          this.state.nowStreaming.length
        ) {
          var live = dataYoutube.data.items[0].snippet.liveBroadcastContent;
          if (
            (diffFinaltMinutes >= 0 && diffInittMinutes < 30) ||
            live === "live"
          ) {
            this.setState({ isLive: true, isModalVisible: true });
            renderLiveStreaming.push(obj);
          } else if (diffInittMinutes > 31) {
            renderFutureStreaming.push(obj);
          } else if (diffFinaltMinutes < 0) {
            renderPastStreaming.push(obj);
          }
        }
      }
      if (resultFB === true) {
        if (this.state.nowStreaming.length) {
          if (diffFinaltMinutes >= 0 && diffInittMinutes < 30) {
            this.setState({ isLive: true, isModalVisible: true });
            renderLiveStreaming.push(obj);
          } else if (diffInittMinutes > 31) {
            renderFutureStreaming.push(obj);
          } else if (diffFinaltMinutes < 0) {
            renderPastStreaming.push(obj);
          }
        }
      }

      this.setState({
        renderLiveStreaming,
        renderFutureStreaming,
        renderPastStreaming,
      });
    } else {
      urlShowStreamings = this.state.channel;
      if (diffFinaltMinutes >= 0 && diffInittMinutes < 11) {
        this.setState({ isLive: true });
        renderLiveStreaming.push(obj);
        this.setState({ renderLiveStreaming });
      } else if (diffInittMinutes > 12) {
        renderFutureStreaming.push(obj);
        this.setState({ renderFutureStreaming });
      } else if (diffFinaltMinutes < 0) {
        renderPastStreaming.push(obj);
        this.setState({ renderPastStreaming });
      }
    }
  };

  getContentHeightLive = () => {
    let headerHeight = 100;
    let footerHeight = 100;
    return window.innerHeight - 2 * footerHeight - 1.2 * headerHeight;
  };

  getContentHeightNonLive = () => {
    let headerHeight = 200;
    let footerHeight = 106;
    return window.innerHeight - 2 * footerHeight - 1.2 * headerHeight;
  };

  render() {

    var isOnlyOneVideoLive = false;
    if (this.state.renderLiveStreaming.length === 1) {
      var urlShowStreamings = this.state.renderLiveStreaming[0].url;
      if (urlShowStreamings != "" && urlShowStreamings != null) {
        var titleNowStreamings = this.state.renderLiveStreaming[0].title;
        var descriptionNowStreamings =
          this.state.renderLiveStreaming[0].description;
        if (this.state.flagYT === 1) {
          var idYT = getVideoId(urlShowStreamings);
        }
        if (this.state.flagFB === 1) {
          const separatedUrlVideo = urlShowStreamings.split("/");
          var nameFB = separatedUrlVideo[3];
          var idFB = separatedUrlVideo[5];
        }

        isOnlyOneVideoLive = true;
      }
    }
    var isSubpage = this.state.isSubpageFlag ? this.state.isSubpageFlag : false;
    const { theme } = this.props;
    var widthVideo = screen.width - 500;
    var heigthVideo = screen.height - 100;

    return (
      <div className="container wow fadeInRight">
        <EventLiveModal
          sectionStreamingId={this.state.sectionStreamingId}
          visible={this.state.isModalVisible}
          handleClose={this.onCloseModal}
          handleGoToStreaming={this.goToStreaming}
          nowStreaming={this.state.renderLiveStreaming}
          isSubpage={isSubpage}
          theme={this.state.theme}
        />

        {isSubpage ? (
          <div>
            {this.state.isLive ? (
              <div>
                {isOnlyOneVideoLive === true ? (
                  <div>
                    <div className="section-header wow fadeInRight">
                      <h2 style={ theme && theme.sectionSubtitle ? theme.sectionSubtitle : null }  >
                        <strong>{titleNowStreamings}</strong>
                      </h2>
                    </div>
                    <br />
                    {this.state.flagYT === 1 ? (
                      <iframe
                        width={widthVideo}
                        height={heigthVideo}
                        src={`https://www.youtube.com/embed/${idYT.id}?autoplay=1`}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                    ) : null}
                    {this.state.flagFB === 1 ? (
                      <iframe
                        src={`https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2F${nameFB}%2Fvideos%2F${idFB}&appId=1027397741079968`}
                        width={widthVideo}
                        height={heigthVideo}
                        frameborder="0"
                        allowfullscreen="true"
                        allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                      ></iframe>
                    ) : null}
                    <div className="section-header wow fadeInRight">
                      <p style={ theme && theme.sectionDescription ? theme.sectionDescription  : null } className="description" >
                        {descriptionNowStreamings}
                      </p>
                    </div>
                    <div className="col-xl-12 col-xl-12">
                      <ButtonsContainer showBorder={true} buttons={this.state.renderLiveStreaming[0].buttons} isStreamings={true} />
                    </div>
                    {this.state.renderFutureStreaming &&
                    this.state.renderFutureStreaming.length > 0 && this.state.showFutureStreamings ?  (
                      <div>
                        <Typography>{Strings.streaming.futureStreamings}</Typography>
                        <Divider />
                        <Carousel itemsToShow={1}>
                          {this.renderStreamings( this.state.renderFutureStreaming,"FUTURE" )}
                        </Carousel>
                      </div>
                    ) : null}
                  </div>
                ) : (
                  <div>
                    <div>
                      <Carousel itemsToShow={1}>
                        {this.renderStreamingsLive( this.state.renderLiveStreaming )}
                      </Carousel>
                    </div>
                    {this.state.renderFutureStreaming &&
                    this.state.renderFutureStreaming.length > 0 && this.state.showFutureStreamings? (
                      <div>
                        <Typography>{Strings.streaming.futureStreamings}</Typography>
                        <Divider />
                        <Carousel itemsToShow={1}>
                          {this.renderStreamings( this.state.renderFutureStreaming,"FUTURE")}
                        </Carousel>
                      </div>
                    ) : null}
                  </div>
                )}
              </div>
            ) : (
              <div>
                {this.state.renderFutureStreaming &&
                this.state.renderFutureStreaming.length > 0 && this.state.showFutureStreamings ? (
                  <div className="row">
                    <Typography>{Strings.streaming.futureStreamings}</Typography>
                    <Divider />
                    <Carousel itemsToShow={1}>
                      {this.renderStreamings(
                        this.state.renderFutureStreaming,
                        "FUTURE"
                      )}
                    </Carousel>
                  </div>
                ) : null}
                {this.state.renderPastStreaming &&
                this.state.renderPastStreaming.length > 0 ? (
                  <div className="row">
                    <Typography>{Strings.streaming.pastStreamings}</Typography>
                    <Divider />
                    <Carousel itemsToShow={1}>
                      {this.renderStreamings(this.state.renderPastStreaming)}
                    </Carousel>
                  </div>
                ) : null}
                {this.state.renderPastStreaming &&
                this.state.renderPastStreaming.length === 0 &&
                this.state.renderFutureStreaming &&
                this.state.renderFutureStreaming.length === 0 ? (
                  <div className="row">
                    <div className="col-xl-12 col-xl-12">
                      <a
                        href={urlShowStreamings}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={imgOffline} width={"100%"} height={"100%"} alt="streaming" />
                      </a>
                    </div>
                  </div>
                ) : null}
              </div>
            )}
          </div>
        ) : (
          <div>
            {/* LandingPage */}
            {this.state.isLive ? ( //Streaming live
              <div>
                {isOnlyOneVideoLive === true ? (
                  <div>
                    <div className="section-header wow fadeInRight">
                      <h2
                        style={
                          theme && theme.sectionSubtitle
                            ? theme.sectionSubtitle
                            : null
                        }
                      >
                        <strong>{titleNowStreamings}</strong>
                      </h2>
                    </div>
                    <br />
                    {this.state.flagYT === 1 ? (
                      <iframe
                      width={widthVideo}
                      height={heigthVideo}
                        src={`https://www.youtube.com/embed/${idYT.id}?autoplay=1`}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                    ) : null}
                    {this.state.flagFB === 1 ? (
                      <iframe
                        src={`https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2F${nameFB}%2Fvideos%2F${idFB}&appId=1027397741079968`}
                        width={widthVideo}
                        height={heigthVideo}
                        frameborder="0"
                        allowfullscreen="true"
                        allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                      ></iframe>
                    ) : null}
                    <div className="section-header wow fadeInRight">
                      <p
                        style={
                          theme && theme.sectionDescription
                            ? theme.sectionDescription
                            : null
                        }
                        className="description"
                      >
                        {descriptionNowStreamings}
                      </p>
                    </div>
                    <div className="col-xl-12 col-xl-12">
                      <ButtonsContainer
                      showBorder={true}
                        buttons={this.state.renderLiveStreaming[0].buttons}
                        isStreamings={true}
                      />
                    </div>
                  </div>
                ) : (
                  <div>
                    <Carousel itemsToShow={1}>
                      {this.renderStreamingsLive(
                        this.state.renderLiveStreaming
                      )}
                    </Carousel>
                  </div>
                )}
              </div>
            ) : (
              <div>
                {this.state.renderLiveStreaming.length === 0 ? (
                  <div className="row">
                    <div className="col-xs-12 col-xl-12">
                      <a
                        href={this.state.channel}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={imgOffline} width={"100%"} height={"100%"} alt="streaming" />
                      </a>
                    </div>
                  </div>
                ) : null}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}
