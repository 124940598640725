import React, { Component, Fragment } from "react";
import "./index.scss";
import {
  getLpSectionInformation,
  getFilePath,
} from "../../../actions/index";
import AboutLayoutIndex from "./layoutStyles/AboutLayoutIndex.jsx";
import getVideoId from "get-video-id";

class AboutSection extends Component {
  constructor(props) {
    super(props);
    this.state = { response: [], visibleModal: true };
  }

  componentDidMount() {
    this.getHomeInformation();
    //this.onSearch();
  }

  async getHomeInformation() {
    let response = await getLpSectionInformation(
      this.props.lpId,
      this.props.lpSectionId,
      "ABOUT"
    );
    let sectionInfo = response.data.data;
    let aboutComponent = sectionInfo.components.find(
      (comp) => comp.name === "AboutComponent"
    );
    let aboutId = sectionInfo.id;
    let aboutTitle = aboutComponent.nodes.find(
      (node) => node.nodeType === "AboutUsTitle"
    );
    aboutTitle = aboutTitle ? aboutTitle.value : "";
    let aboutText = aboutComponent.nodes.find(
      (node) => node.nodeType === "AboutUsText"
    );
    aboutText = aboutText ? aboutText.value : "";
    let missionTitle = aboutComponent.nodes.find(
      (node) => node.nodeType === "MissionTitle"
    );
    missionTitle = missionTitle ? missionTitle.value : "";
    let missionText = aboutComponent.nodes.find(
      (node) => node.nodeType === "MissionText"
    );
    missionText = missionText ? missionText.value : "";
    let visionTitle = aboutComponent.nodes.find(
      (node) => node.nodeType === "VisionTitle"
    );
    visionTitle = visionTitle ? visionTitle.value : "";
    let visionText = aboutComponent.nodes.find(
      (node) => node.nodeType === "VisionText"
    );
    visionText = visionText ? visionText.value : "";
    let aboutImgPath = aboutComponent.nodes.find(
      (node) => node.nodeType === "AboutUsImage"
    );
    aboutImgPath = aboutImgPath ? aboutImgPath.value : "";

    //Get image or video
    let multimedia = aboutImgPath;
    if (!aboutImgPath.includes("https://")) {
      // checks if it's an uploaded image
      let imgName = aboutImgPath.replace(".jpeg", "").replace(".jpg", "").replace(".png","")
      let format = aboutImgPath.match(/\.([^.]*)$/);
      format = format && format[1] ? format[1] : '.jpg';

      let sizeSelect = "largeSize"
      if(window.innerWidth < 900) { // mobile 
        sizeSelect = "largeSize"; //medium Size
      }
      multimedia = await getFilePath(imgName, sizeSelect, format);

    } else if (aboutImgPath.includes("https://www.youtube.com/watch?")){
      // checks if it's video or image link
        let idVideo = getVideoId(aboutImgPath);
        multimedia = idVideo.id;
    }

    let layout = sectionInfo.lpSectionLayoutStyleId;
    if (layout === 3) {
      //standar layout about us
      layout = 0;
    }
    if (layout === 87) {
      //by side layout about us
      layout = 1;
    }
    if (layout === 89) {
      //by side with image layout about us
      layout = 2;
    }
    if(process.env.REACT_APP_DEFAULT_LAYOUT_ABOUT){
      layout = parseInt(process.env.REACT_APP_DEFAULT_LAYOUT_ABOUT);
    }
    let background = "";
    // let backgroundImage = "";
    if (this.props.background === true) {
      background = "#eff5f5";
      // backgroundImage =
      //   ' url("https://jointabella.com/wp-content/uploads/2020/06/forchurches-section2.svg")';
    }
    if (this.props.background === false) {
      background = "#ffff";
      // backgroundImage =
      //   ' url("https://jointabella.com/wp-content/uploads/2020/10/BG-Section2-1.svg")';
    }
    this.setState({
      aboutId,
      aboutTitle,
      aboutText,
      missionTitle,
      missionText,
      visionTitle,
      visionText,
      multimedia,
      layout,
      background: this.props.background,
      // backgroundImage,
    });
  }

  renderinfoVideosComponents(infoVideos) {
    let infoVideosComponents = [];
    if (infoVideos) {
      infoVideos.forEach((element, key) => {
        infoVideosComponents.push(
          <videoCarousel
            title={element.snippet.title}
            description={element.snippet.description}
            date={element.snippet.publishTime}
            key={key}
            videoId={element.id.videoId}
          />
        );
      });
    }
    return infoVideosComponents;
  }

  render() {
    const { theme } = this.props;
    const {
      aboutId,
      aboutTitle,
      aboutText,
      missionTitle,
      missionText,
      visionTitle,
      visionText,
      multimedia,
      layout,
      background,
      // backgroundImage,
    } = this.state;
    return (
      <div 
      // style={{ background: background,
      //  backgroundImage: backgroundImage 
      //  }}
       >
        <section id={"about" + this.state.aboutId}>
          <div className="containerAux">
            {aboutId != undefined ? (
              <AboutLayoutIndex
                aboutId={aboutId}
                aboutTitle={aboutTitle}
                aboutText={aboutText}
                missionTitle={missionTitle}
                missionText={missionText}
                visionTitle={visionTitle}
                visionText={visionText}
                multimedia={multimedia}
                layout={layout}
                theme={theme}
              />
            ) : null}
          </div>
        </section>
      </div>
    );
  }
}

export default AboutSection;
