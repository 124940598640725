import React, { Component } from "react";
import "../../stylesLP.css";
import "../index.scss";
import { Box, CircularProgress, Typography } from "@material-ui/core";
import parse from "html-react-parser";

class StandarAboutLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      colorSpin: "#008cba",
      limitLine: 3,
    };
  }

  // showMoreOrLessText = (e, data) => {
  //   if (data === "more") {
  //     this.setState({
  //       limitLine: 5000,
  //     });
  //   }
  //   if (data === "more") {
  //     this.setState({
  //       limitLine: 3,
  //     });
  //   }
  // };

  render() {
    let {
      theme,
      multimedia,
      aboutTitle,
      aboutText,
      missionTitle,
      missionText,
      visionTitle,
      visionText,
    } = this.props;
    var { colorSpin, limitLine } = this.state;
    let idVideo = null;
    if (!multimedia.includes("https://")) {
      idVideo = multimedia;
    }
    let missionTextValue = parse(missionText);
    let visionTextValue = parse(visionText);
    console.log(
      missionTitle)
      console.log(
        missionText,
        )
        console.log(
          visionTitle,
          )
          console.log(
            visionText)
console.log( missionTitle.length > 0 )
    return (
      <div>
        <div className="section-header wow fadeInRight">
          {aboutTitle !== " " || aboutTitle !== "" ? (
            <h1 style={theme && theme.sectionTitle ? theme.sectionTitle : null}>
              {aboutTitle}
            </h1>
          ) : null}
          {aboutText !== " " || aboutText !== "" ? (
            <p
              style={
                theme && theme.sectionDescription
                  ? theme.sectionDescription
                  : null
              }
              className="description"
            >
              {aboutText}
            </p>
          ) : null}
        </div>

        <div
          className="row"
          style={{ "align-items": "flex-start", height: "100%" }}
        >
          {missionTitle.length > 0 || missionText.length > 0 ||
          (missionTextValue.props &&
            missionTextValue.props.children &&
            missionTextValue.props.children.length > 0) ||
          visionTitle.length > 0 || visionText.length > 0 ||
          (visionTextValue.props &&
            visionTextValue.props.children &&
            visionTextValue.props.children.length > 0) ? (
            <>
              {
                idVideo != null || this.props.multimedia === null ? 
                <div
                  className={
                    idVideo != null
                      ? "col-xs-12 col-md-7 wow fadeInLeft animated positionImage align-self-center"
                      : "col-xs-12 col-md-5 wow fadeInLeft animated positionImage align-self-center"
                  }
                >
                  <div>
                    {this.props.multimedia === null ? (
                      <div className="col-xs-12 col-md-12">
                        <div className="centerImage">
                          <Box>
                            <CircularProgress color={colorSpin} />
                            <Typography>
                              {Strings.generalMessages.loading}
                            </Typography>
                          </Box>
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div style={{ width: "auto", "min-width": "600px;" }}>
                    {idVideo != null ? (
                      <iframe
                        className="videoAbout"
                        src={`https://www.youtube.com/embed/${idVideo}?version=3&loop=1&autoplay=0&controls=1&;rel=0&playlist=${idVideo}`}
                        frameBorder="0"
                        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      ></iframe>
                    ) : null}

                    {idVideo == null && multimedia ? (
                      <div className="col-xs-12 col-md-12">
                        <div className="centerImage">
                          <div className="box">
                            <img
                              style={
                                this.props.theme && theme.imagesShape
                                  ? theme.imagesShape
                                  : null
                              }
                              src={multimedia}
                              alt={aboutTitle}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
                : null
              }


              <div
                className={
                  idVideo != null
                    ? "col-xs-12 col-md-5 content wow fadeInRight animated text-center align-self-center"
                    : "col-xs-12 col-md-7 content wow fadeInRight animated text-center align-self-center"
                }
              >
                {missionTitle.length ? (
                  <h3
                    style={
                      theme && theme.sectionSubtitle
                        ? theme.sectionSubtitle
                        : null
                    }
                    className="titleVision"
                  >
                    {" "}
                    {missionTitle}
                  </h3>
                ) : null}
                {missionText.length ? (
                  <p
                    id="textDescription"
                    style={
                      theme && theme.sectionText1 ? theme.sectionText1 : null
                    }
                  >
                    <p id="textDescription">{parse(missionText)}</p>
                  </p>
                ) : null}
                <br />
                {visionTitle.length ? (
                  <h3
                    style={
                      theme && theme.sectionSubtitle
                        ? theme.sectionSubtitle
                        : null
                    }
                    className="titleVision"
                  >
                    {visionTitle}{" "}
                  </h3>
                ) : null}
                {visionText.length ? (
                  <p
                    id="textDescription"
                    style={
                      theme && theme.sectionText1 ? theme.sectionText1 : null
                    }
                  >
                    <p id="textDescription">{parse(visionText)}</p>
                  </p>
                ) : null}
              </div>
            </>
          ) : (
            <div className="col-xs-12 col-md-12 wow fadeInLeft animated positionImage align-self-center">
              <div>
                {this.props.multimedia === null ? (
                  <div className="col-xs-12 col-md-12">
                    <div className="centerImage">
                      <Box>
                        <CircularProgress color={colorSpin} />
                        <Typography>
                          {Strings.generalMessages.loading}
                        </Typography>
                      </Box>
                    </div>
                  </div>
                ) : null}
              </div>
              <div style={{ width: "auto", "min-width": "600px;" }}>
                {idVideo != null ? (
                  <iframe
                    className="videoAbout"
                    src={`https://www.youtube.com/embed/${idVideo}?version=3&loop=1&autoplay=0&controls=1&;rel=0&playlist=${idVideo}`}
                    frameBorder="0"
                    allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  ></iframe>
                ) : null}

                {idVideo == null && multimedia ? (
                  <div className="col-xs-12 col-md-12">
                    <div className="centerImage">
                      <img
                        className="imageAbout"
                        style={
                          this.props.theme && theme.imagesShape
                            ? theme.imagesShape
                            : null
                        }
                        src={multimedia}
                        alt={aboutTitle}
                      />
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default StandarAboutLayout;
