import React, { Component } from "react";
import { Pagination } from "@mui/material";
import moment from "moment";
import getVideoId from "get-video-id";

import { getSectionInformationBetweenDates, getFilePath } from "../../../actions/index";

import { backendDateFormat } from "../../../reactUtils/systemVariables/generalVariables";
import "./index.css";
import "../stylesLP.css";
import LayoutIndex from "../genericLayout/newsEventsCourses/layoutStyles/LayoutIndex.jsx";
import { CalendarLayout } from "../genericLayout/newsEventsCourses/layoutStyles/CalendarLayout.jsx";

class EventsSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: moment().subtract(2, "hours"),
      endDate: moment().add(2, "months"),
      pageIdx: 1,
      pageSize: 6,
      totalElements: 0,
      events: [],
      eventsToShow: [],
    };
  }

  componentDidMount() {
    this.getEventsInformation();
  }

  async getEventsInformation() {
    let response = await getSectionInformationBetweenDates(
      this.props.lpId,
      this.props.lpSectionId,
      "EVENTS",
      this.state.startDate.format(),
      this.state.endDate.format()
    );
    let sectionInfo = response.data.data;
    let eventContainerComponent = sectionInfo.components.find(
      (comp) => comp.name == "EventContainerComponent"
    );
    let eventsId = sectionInfo.id;
    let eventsContainerTitle = eventContainerComponent.nodes.find(
      (node) => node.nodeType == "EventContainerTitle"
    ).value;
    let events = sectionInfo.components.filter(
      (comp) => comp.name == "EventComponent"
    );
    events = events.sort(this.compareEventDates);
    let totalElements = events.length;

    this.setChildComponentsInfoPaged(
      events,
      this.state.pageIdx,
      this.state.pageSize
    );
    let layout = sectionInfo.lpSectionLayoutStyleId;
    if(layout === 4){ //standar events layout
      layout = 2;
    }
    if(layout === 18){ //events image left layout
      layout = 1;
    }
    if(layout === 28){//events image rigth layout
      layout = 0;
    }
    if(layout === 29){//events cards layout
      layout = 3;
    }
    if(layout === 30){//events carousel layout
      layout = 4;
    }
    if (layout === 85) {
      //Events carousel layout
      layout = 6;
    }
    if (layout === 89) {
      //Events calendar layout
      layout = 7;
    }
    if(process.env.REACT_APP_DEFAULT_LAYOUT_EVENTS){
      layout = parseInt(process.env.REACT_APP_DEFAULT_LAYOUT_EVENTS);
    }
    // layout = 5;
    let background = "";
    // let backgroundImage = "";
    if (this.props.background === true) {
      background = "#eff5f5";
      // backgroundImage =
      //   ' url("https://jointabella.com/wp-content/uploads/2020/06/forchurches-section2.svg")';
    }
    if (this.props.background === false) {
      background = "#ffff";
      // backgroundImage =
      //   ' url("https://jointabella.com/wp-content/uploads/2020/10/BG-Section2-1.svg")';
    }
    this.setState({
      eventsId,
      eventsContainerTitle,
      events,
      totalElements,
      layout,
      background,
      // backgroundImage,
    });
  }

  async setChildComponentsInfoPaged(events, pageIdx, pageSize) {
    let eventsToShow = [];
    for (
      var i = (pageIdx - 1) * pageSize;
      i < pageIdx * pageSize && i < events.length;
      i++
    ) {
      if (!events[i].img) {
        let imgNode = events[i].nodes.find(
          (node) => node.nodeType == "EventImage"
        );
        if (imgNode && imgNode.value != null && imgNode.value != "") {

          //Get image or video
          let multimedia = [];

          if (!imgNode.value.includes("https://")) {
            // checks if it's an uploaded image
            let imgName = imgNode.value.replace(".jpeg", "").replace(".jpg", "").replace(".png","")
            let format = imgNode.value.match(/\.([^.]*)$/)[1]
            let imgS = await getFilePath(imgName, "smallSize", format);
            let imgM = await getFilePath(imgName, "mediumSize", format);
            let imgL = await getFilePath(imgName, "largeSize", format);
            multimedia.push("isUploadedImage")
            multimedia.push(imgS)
            multimedia.push(imgM)
            multimedia.push(imgL)

          } else if (imgNode.value.includes("https://www.youtube.com/watch?")){
            // checks if it's video or image link
              let idVideo = getVideoId(imgNode.value);
            multimedia.push("isVideo")
              multimedia.push(idVideo.id);
          } else {
            multimedia.push("isLinkedImage")
            multimedia.push(imgNode)
          }
          events[i].multimedia = multimedia
        }
      }
      eventsToShow.push(events[i]);
    }
    this.setState({ eventsToShow });
  }

  onPaginationChange = (pageIdx, pageSize) => {
    this.setState({ pageIdx, pageSize, eventsToShow: [] });
    this.setChildComponentsInfoPaged(this.state.events, pageIdx, pageSize);
  };

  compareEventDates(a, b) {
    let aDateStr = a.nodes.find((node) => node.nodeType === "EventDate").value;
    let bDateStr = b.nodes.find((node) => node.nodeType === "EventDate").value;
    let aDate = moment(aDateStr, backendDateFormat);
    let bDate = moment(bDateStr, backendDateFormat);
    if (bDate.isAfter(aDate)) {
      return -1;
    }
    if (aDate.isAfter(bDate)) {
      return 1;
    }
    return 0;
  }

  render() {
    const { theme } = this.props;
    const { background, layout, lpId
      // backgroundImage 
    } = this.state;
    return (
      <div>
      {/* <div className="section-bg" style={{ background: background,
      //  backgroundImage: backgroundImage
        }}> */}
        <section id={"events" + this.state.eventsId}>
          <div className="containerAux">
            <div className="row">
              <div className="col-xs-12 col-md-12">
                <div
                  className="section-header wow fadeIn"
                  data-wow-duration="2s"
                >
                  <h1 style={  theme && theme.sectionTitle ? theme.sectionTitle : null  } >
                    {this.state.eventsContainerTitle}
                  </h1>
                  <span className="section-divider"></span>
                </div>
              </div>
            </div> 
            {this.state.eventsToShow.length === 0 ? (
              <p style={  theme && theme.sectionDescription ? theme.sectionDescription : null } className="description" >
                "Actualmente no se tienen eventos programados."
              </p>
            ) : (
              <div className="row">
                <div className="col-xs-12 col-md-12">
                  {
                    layout == 7 ? 
                    <div>
                      <CalendarLayout 
                        components={this.state.eventsToShow}
                        layout={layout}
                        theme={theme ? theme : null}
                        containerTitle={this.state.eventsContainerTitle}
                        events={true}
                        lpId={lpId}
                      />
                    </div>
                    :
                    <div>
                      <LayoutIndex
                        components={this.state.eventsToShow}
                        layout={layout}
                        theme={theme ? theme : null}
                        containerTitle={this.state.eventsContainerTitle}
                        events={true}
                        lpId={lpId}

                      />
                      <br />
                      <div className="row text-center">
                        <div className="col-xs-12 col-md-12 text-center d-flex justify-content-center">
                          {
                            Math.ceil(this.state.totalElements / this.state.pageSize) > 1 ?
                            <Pagination
                              size="small"
                              onChange={(event, value) => {
                                this.onPaginationChange(value, this.state.pageSize)
                              }}
                              count={ Math.ceil(this.state.totalElements / this.state.pageSize) }
                              page={this.state.pageIdx}
                            />
                            : null
                          }
                        </div>
                      </div>
                    </div>
                  }
                  
                </div>
              </div>
            )}
          </div>
        </section>
      </div>
    );
  }
}

export default EventsSection;
