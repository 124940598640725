import React from "react";

export function StandarHeaderLayoutStyle(props) {
  return (
    <div id="logo">
      <h1 style={props.theme && props.theme.headerName ? props.theme.headerName : null}>
        <a
          href="/#home"
          className="scrollto"
          style={{
            fontSize: props.headerFontSize, // + theme && theme.headerName ? theme.headerName : null
          }}
        >
          {props.headerTitle}
        </a>
      </h1>
    </div>
  );
}
