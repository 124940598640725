import axios from "axios";
import { store } from "../reactUtils/helpers";

import {
  host,
  port,
  route,
  datasource,
} from "../reactUtils/systemVariables/lpserverInformation";

const resource = "/lpstyleattribute";

export const LP_THEME = "LP_THEME";

export async function getLPStyleAttributesByLpId(lpId) {
    try {
        let response = await axios.get(
            host + port + route + datasource + resource, {
            params: {
                lp_id: lpId
            },
            headers: {
                'Content-Type': 'application/json'
            }
        })
        return response;
    } catch (error) {
        return error;
    }
}

// export function getLPStyleAttributesByLpId(lpId) {  
//   console.log('1')
//   return async dispatch => {
//     try {
//       const success = await axios.get(host + port + route + datasource + resource, {
//         params: {
//           lp_id: lpId,
//         },
//         headers: {
//           "Content-Type": "application/json",
//         },
//       });
//       console.log(success)
//       return (success);
//     } catch (error) {
//       return (error);
//     }
//   }
// }

// export function getLPStyleAttributesByLpId(lpId) { ///////////// closer to work
//   const request = axios.get(host + port + route + datasource + resource, {
//     params: {
//       lp_id: lpId,
//     },
//     headers: {
//       "Content-Type": "application/json",
//     },
//   });
//   store.dispatch(console.log(request))
// }

// export async function getLPStyleAttributesByLpId(lpId) {
//   console.log("action");
//   return (dispatch) => {
//     axios
//       .get(host + port + route + datasource + resource, {
//         params: {
//           lp_id: lpId,
//         },
//         headers: {
//           "Content-Type": "application/json",
//         },
//       })
//       .then((response) => {
//         console.log(response);
//         dispatch({
//           type: LP_THEME,
//           payload: response.data,
//         });
//       })

//       .catch(function (error) {
//         return error;
//       });
//   };

//   // return (dispatch) => {
//   //   let response = axios
//   //     .get(host + port + route + datasource + resource, {
//   //       params: {
//   //         lp_id: lpId,
//   //       },
//   //       headers: {
//   //         "Content-Type": "application/json",
//   //       },
//   //     })
//   //     .then((response) => {
//   //       console.log(response);
//   //     //  return response;
//   //       dispatch({ type: LP_THEME, payload: response.data });
//   //     })
//   //     .catch(function (error) {
//   //       return error;
//   //     });
//   //   console.log(response);
//   // };
// }

// export function getLPStyleAttributesByLpId(lpId, callback) {
//   return async (dispatch) => {
//     axios
//       .get(host + port + route + datasource + resource, {
//         params: {
//           lp_id: lpId,
//         },
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: "Bearer " + sessionStorage.getItem("access_token"),
//         },
//       })
//       .then((response) => {
//         dispatch({ type: LP_THEME, payload: response.data });
//         callback(response);
//         console.log(response)
//       })
//       .catch(function (error) {
//           console.log(error)
//           callback(error)
//         return error;
//       });
//   };
// }
