import React, { Component } from "react";

import { getLpSectionInformation } from "../../../actions/index";

import "./index.css";

//Localization
import Strings from "../../../reactUtils/systemVariables/languageLocalization/LocalizedStrings.jsx";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.getFooterInformation();
  }

  async getFooterInformation() {
    let response = await getLpSectionInformation(
      this.props.lpId,
      this.props.lpSectionId,
      "FOOTER"
    );
    let sectionInfo = response.data.data;
    let footerComponent = sectionInfo.components.find(
      (comp) => comp.name === "FooterComponent"
    );
    let footerPrayer = footerComponent.nodes.find(
      (node) => node.nodeType === "FooterPrayer"
    );
    footerPrayer = footerPrayer ? footerPrayer.value : "";

    this.setState({ footerPrayer });
  }

  render() {
    const { theme } = this.props;

    let headerFixedStyle =
      "linear-gradient(45deg, rgb(23,162,184), rgb(23,162,184))";
    let headerHomeStyle =
      "linear-gradient(45deg, rgba(23,162,184, 0.5), rgba(23,162,184, 0.5))";
    if (theme && theme.headerBackground) {
      if (theme.headerBackground.fixed) {
        headerFixedStyle = theme.headerBackground.fixed;
      }
      if (theme.headerBackground.home) {
        headerHomeStyle = theme.headerBackground.home;
      }
    }
    if (this.props.isSubpageFlag == true) {
      headerHomeStyle = headerFixedStyle;
    } else {
      headerHomeStyle = "background: background: transparent !important";
    }

    return (
        <footer
          id="footer"
          style={{
            "--background-col-scroll": headerFixedStyle,
            background: headerHomeStyle,
          }}
        >
          <div className="containerAux">
            <div className="row wow fadeInUp">
            <div className="col-xs-12 col-md-6">
                <p
                  className="footer text-center"
                >
                        
                  <i style={ theme && theme.headerTitles ? {...theme.headerTitles} : {color: "white"} }
                  >{this.state.footerPrayer}</i>
                </p>
              </div>
              <div className="col-xs-12 col-md-6">
                
                  <p className="footer text-center" 
                  style={ theme && theme.headerTitles ? {...theme.headerTitles} : {color: "white"} }>
                    {" "}
                    &copy; Copyright{" "}
                    <strong>
                      <a style={ theme && theme.headerTitles ? {...theme.headerTitles} : {color: "white"} } href="https://somosaura.org/">Aura Software</a>
                    </strong>
                    . {Strings.footer.rigthsReserved}{" "}
                  </p>
              </div>

            </div>
          </div>
        </footer>
    );
  }
}
export default Footer;
