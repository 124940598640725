import React from "react";
import "../index.scss";
import { Tooltip } from "@material-ui/core";

export function GalleryImageMoveLayout(props) {
  const { id, note, image, text } = props;
  return (
    <div className="centerImage">
      <div
        className="boxLayout1" >
        <div className="hover-point"></div>
        <div className="hover-point"></div>
        <div className="hover-point"></div>
        <div className="hover-point"></div>
        <div className="hover-point"></div>
        <div className="hover-point"></div>
        <div className="hover-point"></div>
        <div className="hover-point"></div>
        <div className="box-contents" >
          <Tooltip title={note + ".\n Autor: " + text} arrow>
            <a href={image} className="gallery-popup">
              <img src={image} key={id} alt="gallery" />
            </a>
          </Tooltip>
        </div>
      </div>
    </div>
  );
}