import { Link } from "react-router-dom";
import ICalendarLink from "react-icalendar-link";
import "../linktree/index.css";
import "../streaming/index.css";
import "../genericLayout/newsEventsCourses/layoutStyles/index.scss";
//Localized
import Strings from "../../../reactUtils/systemVariables/languageLocalization/LocalizedStrings.jsx";
import Analitic from "../../../reactUtils/helpers/analitics";

const ButtonsContainer = (props) => {
  const {
    buttons,
    componentId,
    theme,
    buttonsColor,
    target,
    isLinkTree,
    buttonModal,
    readMore,
    typeLink,
    onlyreadMore,
    event,
    name,
    internalLpSectionComponentId,
    isHome,
    layoutLinktree,
    style,
    showBorder,
    layout6
  } = props;

  const key = Math.random() * 100000;
  let buttonsRender = [];
  let className = "btn1";
  if (isLinkTree) {
    className = "ltbtn1";
  }
  if (buttonModal) {
    className = "buttonModal";
  }
  if (readMore) {
    className = "btnReadMore";
  }
  if (typeLink) {
    className = "typeLink";
  }
  if (isHome) {
    className = "homeButtons";
  }

  let backgroundButton = "transparent";
  let headerFixedStyle = "transparent";
  let headerHomeStyle = "gray";
  let borderColorButton = "#008cba";
  let borderColorButtonHover = "#008cba";
  let fontFamily;
  let colorText = "black";

  if (theme != null) {
    backgroundButton = theme.headerTitles.color;
    headerFixedStyle = theme.headerTitles.fixed;
    headerHomeStyle = theme.headerBackground.fixed;
    borderColorButton = theme.sectionTitle.color;
    borderColorButtonHover = theme.sectionSubtitle.color;
    fontFamily = theme.sectionText1.fontFamily;
    colorText = "white";
    // colorText = headerHomeStyle;

  }
  // if(!showBorder){
  //   fontFamily = theme != null ? theme.headerName.fontFamily : 'Monserrat';
  //   if(layout6) colorText = "white";
  // }

  const eventsAnalitic = (category, action, label, value) => {
    Analitic.setEvent({
      category: category,
      action: action,
      label: label,
      value: value,
      nonInteraction: true,
    })
  }

  if (buttonModal) {
    buttonsRender.push(
      <button
        className={className}
        style={{
          "--backgroundButton": headerHomeStyle,
          // "--borderColorButton": showBorder? '' : borderColorButton,
          // "--borderColorButtonHover": showBorder? '' : borderColorButtonHover,
          "--borderColorButton": borderColorButton,
          "--borderColorButtonHover": borderColorButtonHover,
          "--colorText": colorText,
          'z-index': '99',
          fontFamily: fontFamily
        }}
        key={key}
        onClick={() => eventsAnalitic("modals", name, "click in button of modal", 1)}
        // onClick={(e) => props.handleClose(e)}
      >
        {name}
      </button>
    );
  }

  let buttonLinkPath = "/component/"
  if(props.donations){
    buttonLinkPath = "/payment/"
  }

  if (readMore) {
    buttonsRender.push(
      <Link
        to={{ pathname: buttonLinkPath + internalLpSectionComponentId }}
        target="_blank"
        rel="noopener noreferrer"
        className="linkReffer"
        style={{'z-index': '99'}}
      >
        {/* {typeLink ? (
          <button
            className={className}
            style={{
              "--backgroundButton": headerHomeStyle,
              "--borderColorButton": borderColorButton,
              "--borderColorButtonHover": borderColorButtonHover,
              "--colorText": colorText,
              margin: "10px",
              'z-index': '99',
              fontFamily: fontFamily
            }}
            key={key}
          >
            {" "}
            {Strings.basicWords.readMore}
            <i className="fa fa-arrow-right" aria-hidden="true"></i>
          </button>
        ) : (
          <button
            className={className}
            style={{
              "--backgroundButton": headerHomeStyle,
              "--borderColorButton": borderColorButton,
              "--borderColorButtonHover": borderColorButtonHover,
              "--colorText": colorText,
              fontSize: props.size,
              margin: props.margin,
              fontFamily: fontFamily
            }}
            key={key}
          >
            {" "}
            {Strings.basicWords.readMore}{" "}
          </button>
        )} */}
      </Link>
    );
  }
  if (!onlyreadMore && typeLink) {
    buttons.forEach((b) => {
      buttonsRender.push(
        <Link
          to={{ pathname: buttonLinkPath + internalLpSectionComponentId }}
          target="_blank"
          rel="noopener noreferrer"
          style={{'z-index': '99'}}
        >
          <button
            className={className}
            style={{
              "--backgroundButton": headerHomeStyle,
              "--borderColorButton": borderColorButton,
              "--borderColorButtonHover": borderColorButtonHover,
              "--colorText": colorText,
              'z-index': '99',
              fontFamily: fontFamily
            }}
            onClick={() => eventsAnalitic("read more not only and typeLink", `${buttonLinkPath + internalLpSectionComponentId}`, `Read more button`, 1)}
            key={key}
          >
            {b.name} <i className="fa fa-arrow-right" aria-hidden="true"></i>
          </button>

          <br />
        </Link>
      );
    });
  }
  if (!onlyreadMore && !typeLink) {
    buttons.forEach((b) => {
      let link = b.externalLink;
      let regexHTTP = /http/;
      let resultHTTP = regexHTTP.test(link);
      let regexMailto = /mailto:/;
      let resultMailto = regexMailto.test(link);
      if (
        resultHTTP === false &&
        link !== "PAYMENT_PLATFORM" &&
        resultMailto === false
      ) {
        link = `https://${link}`;
      }
      if (b.internalLpSectionComponentId) {
        if (!isLinkTree) {
          buttonsRender.push(
            <Link
              to={{ pathname: buttonLinkPath + b.internalLpSectionComponentId }}
              target={target}
            >
              <button
                //  id={className}
                className={className}
                style={{
                  "--backgroundButton": headerHomeStyle,
                  "--borderColorButton": borderColorButton,
                  "--borderColorButtonHover": borderColorButtonHover,
                  "--colorText": colorText,
                  'z-index': '99',
                  fontFamily: fontFamily
                }}
                onClick={() => eventsAnalitic("button internal section", `${buttonLinkPath + b.internalLpSectionComponentId}`, `button internal section not link tree`, 1)}
                key={key}
              >
                {" "}
                {b.name}{" "}
              </button>
            </Link>
          );
        } else {
          buttonsRender.push(
            // <div className="row">
            <Link
              to={{ pathname: buttonLinkPath + b.internalLpSectionComponentId }}
              target={target}
              style={{'display': 'flex'}}
            >
              <button
                // id={className}
                className={className}
                style={{ background: buttonsColor }}
                key={key}
                onClick={() => eventsAnalitic("button internal section", `${buttonLinkPath + b.internalLpSectionComponentId}`, `button internal section link tree`, 2)}
              >
                {" "}
                {b.name}{" "}
              </button>
            </Link>
            // </div>
          );
        }
      } else if (!isLinkTree) {
        if (link !== "PAYMENT_PLATFORM") {
          buttonsRender.push(
            <a href={link} target="_blank" rel="noopener noreferrer">
              <button
                className={className}
                style={{
                  "--backgroundButton": headerHomeStyle,
                  "--borderColorButton": borderColorButton,
                  "--borderColorButtonHover": borderColorButtonHover,
                  "--colorText": colorText,
                  'z-index': '99',
                  fontFamily: fontFamily
                }}
                onClick={() => eventsAnalitic("button not linktree", `${link}`, `not is LinkTree and not PAYMENT_PLATFORM`, 1)}
                key={key}
              >
                {b.name}
              </button>
            </a>
          );
        } else {
          buttonsRender.push(
            <Link
              to={{ pathname: "/payment/" + b.lpSectionComponentId }}
              target="_blank"
              rel="noopener noreferrer"
            >
              <button
                className={className}
                style={{
                  "--backgroundButton": headerHomeStyle,
                  "--borderColorButton": borderColorButton,
                  "--borderColorButtonHover": borderColorButtonHover,
                  "--colorText": colorText,
                  'z-index': '99',
                  fontFamily: fontFamily

                }}
                onClick={() => eventsAnalitic("button not linktree", `${ "/payment/" + b.lpSectionComponentId }`, `button for when it is a payment method and not a link tree`, 2)}
                key={key}
              >
                {b.name}
              </button>
            </Link>
          );
        }
      } else {
        // WHEN IS LINKTREE
        buttonsRender.push(
          // <div className="row">
          <a href={link} target={target} style={{'margin': '0px 10px'}}>
            <button
              className={className}
              style={{
                "--backgroundButton": headerHomeStyle,
                "--borderColorButton": borderColorButton,
                "--borderColorButtonHover": borderColorButtonHover,
                "--colorText": colorText,
                'z-index': '99',
                fontFamily: fontFamily
              }}
              // style={{ border: `2px solid ${buttonsColor}`, color: buttonsColor, 'z-index': '99', fontFamily: fontFamily }}
              onClick={() => eventsAnalitic("button default", `${ link }`, `default render button in not only reader and not type link`, 1)}
              key={key}
            >
              {b.name}
            </button>
          </a>
          // </div>
        );
      }
    });
  }

  // if (event) {
  //   if (!isLinkTree) {
  //     if (typeLink) {
  //       buttonsRender.push(
  //         <ICalendarLink event={event}>
  //           <button
  //             className={className}
  //             style={{
  //               "--backgroundButton": headerHomeStyle,
  //               "--borderColorButton": borderColorButton,
  //               "--borderColorButtonHover": borderColorButtonHover,
  //               "--colorText": colorText,
  //               fontSize: props.size,
  //               margin: props.margin,
  //               'z-index': '99',
  //               fontFamily: fontFamily
  //             }}
  //             onClick={() => eventsAnalitic("button events", `${ event.title }`, `button of type event is not linktree and of type link`, 1)}
  //             key={key}
  //           >
  //             {Strings.calendarComponents.addCalendar}{" "}
  //             <i className="fa fa-arrow-right" aria-hidden="true"></i>
  //           </button>
  //         </ICalendarLink>
  //       );
  //     } else {
  //       buttonsRender.push(
  //         <ICalendarLink event={event}>
  //           <button
  //             className={className}
  //             style={{
  //               "--backgroundButton": headerHomeStyle,
  //               "--borderColorButton": borderColorButton,
  //               "--borderColorButtonHover": borderColorButtonHover,
  //               "--colorText": colorText,
  //               fontSize: props.size,
  //               margin: props.margin,
  //               'z-index': '99',
  //               fontFamily: fontFamily
  //             }}
  //             onClick={() => eventsAnalitic("button events", `${ event.title }`, `button of type event is not linktree and not is type link`, 2)}
  //             key={key}
  //           >
  //             {Strings.calendarComponents.addCalendar}
  //           </button>
  //         </ICalendarLink>
  //       );
  //     }
  //   } else {
  //     if (typeLink) {
  //       buttonsRender.push(
  //         <ICalendarLink event={event}>
  //           <button
  //             className={className}
  //             style={{
  //               "--backgroundButton": headerHomeStyle,
  //               "--borderColorButton": borderColorButton,
  //               "--borderColorButtonHover": borderColorButtonHover,
  //               "--colorText": colorText,
  //               fontSize: props.size,
  //               margin: props.margin,
  //               'z-index': '99',
  //               fontFamily: fontFamily
  //             }}
  //             onClick={() => eventsAnalitic("button events", `${ event.title }`, `button of type event is linktree and of type link`, 3)}
  //             key={key}
  //           >
  //             {Strings.calendarComponents.addCalendar}{" "}
  //             <i className="fa fa-arrow-right" aria-hidden="true"></i>
  //           </button>
  //         </ICalendarLink>
  //       );
  //     } else {
  //       buttonsRender.push(
  //         <ICalendarLink event={event}>
  //           <button
  //             className={className}
  //             style={{
  //               "--backgroundButton": headerHomeStyle,
  //               "--borderColorButton": borderColorButton,
  //               "--borderColorButtonHover": borderColorButtonHover,
  //               "--colorText": colorText,
  //               fontSize: props.size,
  //               margin: props.margin,
  //               'z-index': '99',
  //               fontFamily: fontFamily
  //             }}
  //             onClick={() => eventsAnalitic("button events", `${ event.title }`, `button of type event is linktree and not is type link`, 4)}
  //             key={key}
  //           >
  //             {Strings.calendarComponents.addCalendar}
  //           </button>
  //         </ICalendarLink>
  //       );
  //     }
  //   }
  // }
  return (
    (layoutLinktree == 1 && isLinkTree) ?
      <div className="containerLinearButtons"> {buttonsRender} </div>
    :
      <div className={style ? style : "standarContainerButtons"}> {buttonsRender} </div>
  );
};

export default ButtonsContainer;
