import React from "react";
import "../index.css";
import imgNull from "../../../../images/noImage.jpg";
import ButtonsContainer from "../../general/ButtonsContainer.jsx";

export function HomebuttonsLayout(props) {
  const { theme, title, description, img1, img2, img3, imgBack } = props;
  let {buttons} = props;

  return (
    <div>
      <section
        id="home"
        style={
          theme && theme.homeBackground
            ? {
                backgroundImage:
                  "linear-gradient(" +
                  theme.homeBackground +
                  '), url("' +
                  imgBack +
                  '")',
              }
            : {
                backgroundImage:
                  'linear-gradient(45deg, rgba(63,132,170, 0.8), rgba(63,132,170, 0.8)), url("' +
                  imgBack +
                  '")',
              }
        }
      >
        <div className="left-intro-text">
          <h1 style={theme && theme.homeTitle ? theme.homeTitle : null}>
            {title}
          </h1>
          <br />
          <p
            style={
              theme && theme.homeDescription ? theme.homeDescription : null
            }
          >
            {description}
          </p>
          {buttons ?
          <div className="homeButtonsComponent"  style={{ marginTop: "10%" }}>
            <ButtonsContainer showBorder={true} buttons={buttons} theme={theme} isHome={true}/>
          </div>
          : ""}
        </div>

      </section>
    </div>
  );
}
