import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  postPayment,
  getPayerDocumentTypes,
  // getPseBanks,
} from "../../../actions/index.js";
// import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { WarningOutlined, InfoCircleFilled } from "@ant-design/icons";
import "./index.css";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Select from "react-select";
import Button from "@mui/material/Button";
import banks from "./paymentMethods/pseBanks.json";
// import { Country, State, City } from "country-state-city";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import strings from "../../../reactUtils/systemVariables/languageLocalization/LocalizedStrings.jsx";

const VALIDATE_NAME = true;
const VALIDATE_EMAIL = true;
const VALIDATE_PHONE = true;

class DonationForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      text: "",
      buttonName: "",
      endDate: null,
      fixedValue: null,
      payment: {
        paymentPlatformId: null,
        lpComponentId: null,
        referenceName: "",
        name: "",
        email: "",
        phone: "",
        value: "",
        taxValue: "",
        payerDocumentType: "CC", //CC by default
        payerDocumentNumber: "",
        externalReference: "",
        bankId: null,
        buyerAddress: "",
        userUuid: "",
      },
      paymentPlatformSelected: null,
      modalMsg: props.modalMsg,
      errorLink: "",
      openModal: false,
      openErrorModal: false,
      errorMsg: "",
      payParameters: null,
      platformName: null,

      // Validations
      nameFlag: false,
      emailFlag: false,
      paymentPendingFlag: false,

      disablePayButton: false,

      //country
      // countries: [],
      // states: [],
      // cities: [],
      statesBase: [],
      // country: "",
      // region: "",
      // city: "",
      //questions
      questions: [],
    };
  }
  componentDidMount() {
    const { lpId, PAYMENT_PLATFORM_ID, paymentValue, platformName } =
      this.props;
    let { payment } = { ...this.props };

    (payment = {
      form: payment.form,
      paymentPlatformId: payment.paymentPlatformId,
      lpComponentId: payment.lpComponentId,
      referenceName: payment.referenceName,
      name: payment.name,
      email: payment.email,
      phone: payment.phone,
      value: payment.value,
      taxValue: payment.taxValue,
      payerDocumentType: "CC",
      payerDocumentNumber: "",
      externalReference: "",
      bankId: null,
      buyerAddress: "",
      userUuid: "",
    }),
      this.getPayerDocumentTypes();
    // this.getCountries();
    // this.getBanks();

    this.setState({
      lpId,
      payment,
      PAYMENT_PLATFORM_ID,
      paymentValue,
      questions: payment && payment.form ? payment.form : [],
      platformName,
    });
  }

  async getPayerDocumentTypes() {
    let response = await getPayerDocumentTypes();
    if (String(response).includes("Error:") || response.data.length == 0) {
      //TODO error page
    } else {
      let payerDocumentTypes = response.data;
      payerDocumentTypes = payerDocumentTypes.map(function (row) {
        return { value: row.id, label: row.description, code: row.code };
      });
      this.setState({ payerDocumentTypes });
    }
  }
  // getCountries() {
  //   let countries = Country.getAllCountries().map((country) => {
  //     return {
  //       isoCode: country.isoCode,
  //       label: country.name,
  //       countryCode: country.countryCode,
  //     };
  //   });
  //   let states = State.getAllStates().map((state) => {
  //     return { isoCode: state.isoCode, label: state.name };
  //   });
  //   let cities = City.getAllCities().map((city) => {
  //     return { isoCode: city.isoCode, city: city.name };
  //   });

  //   this.setState({ countries, states, cities });
  // }

  async postPaymentFunction(lpId, paymentDto, firstTry) {
    let openErrorModal = false;
    let disablePayButton = true;
    let errorMsg = "";

    this.setState({
      disablePayButton: true,
    });

    let response = await postPayment(lpId, paymentDto);

    if (
      String(response).includes("Error") ||
      String(response.data.status).includes("failure") ||
      String(response.data.description).includes("unavailable")
    ) {
      if (firstTry == true) {
        await this.postPaymentFunction(lpId, paymentDto, false);
      } else {
        errorMsg =
          "¡Lo sentimos! En este momento el método de pago se encuentra fuera de servicio. Por favor cambie el método o inténtelo nuevamente más tarde.";
        openErrorModal = true;
      }
    } else if (String(response.data.status).includes("success")) {
      if (
        //Nequi,
        paymentDto.paymentPlatformId == 2
      ) {
        this.props.setChange(paymentDto);
      } else if (paymentDto.paymentPlatformId == 5) {
        //Daviplata
        if (
          response.data.data == null &&
          String(response.data.description) != null
        ) {
          if (firstTry == true) {
            await this.postPaymentFunction(lpId, paymentDto, false);
          } else {
            errorMsg = response.data.description
              .replace("Record successfully inserted.", "")
              .replace("(Error: ", "")
              .replace(")", "");
            openErrorModal = true;
          }
        } else {
          this.props.setChange(paymentDto, response.data.data.reference);
        }
      } else if (
        //PSE, bancolombia, creditcard
        paymentDto.paymentPlatformId == 3 ||
        paymentDto.paymentPlatformId == 4 ||
        paymentDto.paymentPlatformId == 6
      ) {
        if (response.data.data.url == null) {
          if (firstTry == true) {
            await this.postPaymentFunction(lpId, paymentDto, false);
          } else {
            errorMsg = response.data.data.detail
              ? response.data.data.detail
              : "";
            openErrorModal = true;
            disablePayButton = false;
          }
        } else {
          window.location.replace(response.data.data.url);
        }
      } else {
        if (firstTry == true) {
          await this.postPaymentFunction(lpId, paymentDto, false);
        } else {
          errorMsg =
            "¡Lo sentimos! El método de pago seleccionado está reportando intermitencia en su funcionamiento. Por favor cambie el método o inténtelo nuevamente más tarde.";
          openErrorModal = true;
        }
      }
    } else {
      if (firstTry == true) {
        await this.postPaymentFunction(lpId, paymentDto, false);
      } else {
        errorMsg =
          "¡Error de conexión! Por favor inténtelo nuevamente más tarde.";
        openErrorModal = true;
      }
    }

    if (firstTry == false) {
      this.setState({
        openErrorModal,
        errorMsg,
        disablePayButton,
      });
      //}
      this.forceUpdate();
    }
  }

  async getBanks() {
    let banks = await getPseBanks();
    this.setState({ banks });
  }

  onChange = (e, id, platformId) => {
    const { value } = e.target !== undefined ? e.target : e;
    const { payment } = { ...this.state };
    var { paymentPlatformSelected } =
      platformId == 3 ? e.label + " (PSE)" : { ...this.state };

    // Verify if the payment platform id has not changed
    if (payment.paymentPlatformId !== platformId) {
      payment["paymentPlatformId"] = platformId;
    }

    // Validate and save the name
    if (id === "name") {
      const validate = /[^A-Za-zÀ-ÿ\s]+/.test(value);
      if (validate === true) {
        this.setState({
          nameFlag: id === "name" ? true : false,
        });
      } else {
        payment[id] = value;
      }
      // Validate and save the phone number
    } else if (id === "phone" || id === "payerDocumentNumber") {
      payment[id] = value;
      // Validate and save the email
    } else if (id == "email") {
      const validate = /[\])}>[<{(]/.test(value);
      if (validate === true) {
        this.setState({
          emailFlag: id === "email" ? true : false,
        });
      } else {
        payment[id] = value;
      }
      // Validate and save the bank id
    } else if (id === "bankId") {
      payment[id] = value;
    } else if (id === "buyerAddress") {
      payment[id] = value;
      this.setState({ paymentPlatformSelected });
    }
    this.setState({ payment });
  };

  selectCountry(val) {
    let states = State.getStatesOfCountry(val.isoCode);
    let newStates = states.map((state) => {
      return { isoCode: state.isoCode, label: state.name };
    });
    this.setState({ statesBase: states, states: newStates, country: val });
  }

  selectRegion(val) {
    const { country, statesBase } = { ...this.state };
    let regionSelected = statesBase.filter((state) => state.name == val.label);
    let cities = City.getCitiesOfState(
      regionSelected[0] ? regionSelected[0].countryCode : "",
      regionSelected[0] ? regionSelected[0].isoCode : ""
    );
    let newCities = cities.map((city) => {
      return { isoCode: city.isoCode, label: city.name };
    });
    this.setState({ cities: newCities, region: val });
  }

  selectCity(val) {
    this.setState({ city: val });
  }

  selectDocumentType = (e) => {
    const { payment } = { ...this.state };
    payment["payerDocumentType"] = e.code;
    this.setState({ payment });
  };

  onPayButton = async () => {
    const { lpId, PAYMENT_PLATFORM_ID } = this.props;
    const { payment, country, region, city, questions } = {
      ...this.state,
    };

    //Get client's IP address
    // fetch('https://api.ipify.org?format=json')
    // .then(response => response.json())
    // .then(data => console.log(data.ip));

    //Validate fields
    let validName = VALIDATE_NAME
      ? payment.name && payment.name.length > 2
      : true;

    let validEmail = VALIDATE_EMAIL
      ? payment.email &&
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          payment.email
        )
      : true;
    let validPhone = VALIDATE_PHONE
      ? payment.phone && /^[0-9]{6,12}$/.test(payment.phone)
      : true;
    let validDocumentType = payment.payerDocumentType != "" ? true : false;
    let validDocumentNumber = payment.payerDocumentNumber != "" ? true : false;
    let validBank = true;
    if (PAYMENT_PLATFORM_ID == 3) {
      validBank = payment.bankId != 0 ? true : false; //only pse
    }
    let openModal = false;
    let modalMsg = "Verifique el parámetro: ";
    // let validCountry = country != "" ? true : false;
    // let validRegion = region != "" ? true : false;
    // let validCity = city != "" ? true : false;
    // let validAddress = payment.buyerAddress != "" ? true : false;
    let validQuestions = true;
    if (questions && questions.length > 1) {
      questions.forEach((qst) => {
        if (
          qst.obligatory == true &&
          (qst.response == "" || qst.response.length == 0)
        ) {
          validQuestions = false;
        }
      });
    } else {
      validQuestions = true;
    }

    // if (validName && validEmail && validPhone && validDocumentType && validDocumentNumber && validBank) {
    if (
      validName &&
      validEmail &&
      validPhone &&
      validDocumentType &&
      validDocumentNumber &&
      // validCountry &&
      // validRegion &&
      // validCity &&
      // validAddress &&
      validBank &&
      validQuestions
    ) {
      modalMsg = "";

      let paymentCopy = { ...this.state.payment };

      paymentCopy.paymentPlatformId = PAYMENT_PLATFORM_ID;

      if (PAYMENT_PLATFORM_ID !== 3) {
        paymentCopy["bankId"] = null;
      }

      // Remove unnecessary characters and convert the values to Numbers
      //Get tax value
      // paymentCopy["taxValue"] = Number(
      //   paymentCopy["taxValue"].replace(/(,\w).*/, "")
      // );

      if (paymentCopy["bankId"] != null) {
        paymentCopy["bankId"] = Number(paymentCopy["bankId"]);
        if (typeof paymentCopy["value"] === "string") {
          paymentCopy["value"] = Number(
            paymentCopy["value"]
              .replace(/(,\w).*/, "")
              .replace("$", "")
              .replace(".", "")
          );
        }
      }
      //  paymentCopy["buyerAddress"] = `${payment.buyerAddress}, ${country.label}, ${region.label}, ${city.label}`;
      paymentCopy["buyerAddress"] = "No address";
      let formAns = [];
      if (paymentCopy.form.length > 0) {
        paymentCopy.form.forEach((fr) => {
          let newData = {
            uuid: "",
            response: "",
          };
          newData.uuid = fr.uuid;
          newData.response = fr.response;
          formAns.push(newData);
        });
      }
      paymentCopy.formAns = JSON.stringify(formAns);
      paymentCopy["taxValue"] = 0.0;
      paymentCopy["fee"] = 0.0;
      paymentCopy["returnUrl"] = window.location.origin;

      delete paymentCopy.form;
      //Request backend for payment
      await this.postPaymentFunction(lpId, paymentCopy, false);
    } else if (!validName) {
      modalMsg += "Nombre";
      openModal = true;
    } else if (!validEmail) {
      modalMsg += "Email";
      openModal = true;
    } else if (!validPhone) {
      modalMsg += "Celular";
      openModal = true;
    } else if (!validDocumentType) {
      modalMsg += "Tipo de documento";
      openModal = true;
    } else if (!validDocumentNumber) {
      modalMsg += "Número de documento";
      openModal = true;
    } else if (!validBank) {
      modalMsg += "Banco";
      openModal = true;
      // } else if (!validCountry) {
      //   modalMsg += "País";
      //   openModal = true;
      // } else if (!validRegion) {
      //   modalMsg += "Departamento / Estado";
      //   openModal = true;
      // } else if (!validCity) {
      //   modalMsg += "Ciudad";
      //   openModal = true;
      // } else if (!validAddress) {
      //   modalMsg += "Dirección";
      //   openModal = true;
    } else if (!validQuestions) {
      questions.forEach((qst) => {
        if (
          qst.obligatory == true &&
          (qst.response == "" || qst.response.length == 0)
        ) {
          modalMsg += qst.question;
        }
      });
      openModal = true;
    }

    this.setState({
      modalMsg,
      paymentPendingFlag: true,
      openModal,
      disablePayButton: true,
    });
  };

  handleClose = () => {
    this.setState({
      openModal: false,
      openErrorModal: false,
      disablePayButton: false,
    });
  };

  contactSupport = (url) => {
    window.open(url, "_blank");
  };

  onChangeResponse = (e, index) => {
    const { questions } = { ...this.state };
    questions[index].response = e.target.value;
    this.setState({ questions });
  };

  onCheckMultiple = (e, index) => {
    const { questions } = { ...this.state };
    questions[index].response = e.target.value;
    this.setState({ questions });
  };

  onCheckSelect = (e, index) => {
    let { questions } = { ...this.state };
    let responseSelected = e.target.value;
    let add = true;

    if (!questions[index].response.length) {
      questions[index].response = [];
    }
    questions[index].response.forEach((rsp, indexRsp) => {
      if (rsp == responseSelected) {
        add = false;
        questions[index].response.splice(indexRsp, 1);
      }
    });
    if (add) {
      questions[index].response.push(responseSelected);
    }
    this.setState({ questions });
  };

  renderQuestion = () => {
    const { questions } = { ...this.state };
    return questions.map((qst, index) => {
      return (
        <div className="containerQuestion">
          <p className="textUpInput">
            {qst.question} {qst.obligatory ? "*" : ""}
          </p>
          {qst.type == "text" ? (
            <div style={{ margin: "10px 0px", width: "95%" }}>
              <input
                type="text"
                id="name"
                size="40"
                value={qst.response}
                className="inputText"
                onChange={(e) => this.onChangeResponse(e, index)}
              ></input>
            </div>
          ) : null}
          {qst.type == "multiple" ? (
            <div className="containerOptions">
              {qst.options.map((option) => {
                return (
                  <div>
                    <input
                      type="radio"
                      id={option}
                      name={option}
                      value={option}
                      checked={option == qst.response}
                      onChange={(e) => this.onCheckMultiple(e, index)}
                    />
                    <label for={option} className="labelRadio">
                      {option}
                    </label>
                  </div>
                );
              })}
            </div>
          ) : null}
          {qst.type == "select" ? (
            <div className="containerOptions">
              {qst.options.map((option) => {
                return (
                  <div>
                    <input
                      type="checkbox"
                      id={option}
                      name={option}
                      value={option}
                      checked={qst.response.includes(option)}
                      onChange={(e) => this.onCheckSelect(e, index)}
                    />
                    <label for={option} className="labelRadio">
                      {option}
                    </label>
                  </div>
                );
              })}
            </div>
          ) : null}
        </div>
      );
    });
  };

  render() {
    const {
      payment,
      modalMsg,
      errorLink,
      payParameters,
      payerDocumentTypes,
      openModal,
      // countries,
      // states,
      // cities,
      disablePayButton,
      openErrorModal,
      errorMsg,
      // banks,
      questions,
    } = this.state;

    const { PAYMENT_PLATFORM_ID } = this.props;

    const theme = this.props.theme;

    let allBanks = banks
      ? banks.data.features.map((bank) => {
          return { value: bank.id, label: bank.name };
        })
      : "";

    let currencyCode = payParameters
      ? payParameters.find((p) => p.name === "currencyCode")
      : "";
    currencyCode = currencyCode ? "(" + currencyCode.value + ")" : "";

    let backgroundButton = "transparent";
    let headerFixedStyle = "transparent";
    let headerHomeStyle = "gray";
    let borderColorButton = "#008cba";
    let borderColorButtonHover = "#008cba";
    let colorText = "black";
    let fontFamily = "Montserrat";
    if (theme != null) {
      backgroundButton = theme.headerTitles.color;
      headerFixedStyle = theme.headerTitles.fixed;
      headerHomeStyle = theme.headerBackground.fixed;
      borderColorButton = theme.sectionTitle.color;
      borderColorButtonHover = theme.sectionSubtitle.color;
      fontFamily = theme.headerTitles.fontFamily;
      colorText = "white";
    }

    const value =
      typeof payment.value !== "number"
        ? parseInt(payment.value.replace("$", "").replace(".", ""), 10)
        : payment.value;

    return (
      <div className="containerInputs">
        {/* Secure form */}
        {/* <div
          className="col-xs-12 col-lg-12"
          style={{
            display: "flex",
            flexWrap: "wrap",
            marginBottom: "5%",
            justifyContent: "center",
          }}
        ></div> */}

        <div className="col-xs-12 col-lg-12" style={{ display: "flex", flexWrap: "wrap" }}>
          <div className="col-xs-12 col-lg-12">
            <div style={{ "display": "flex", "flexDirection": "row", "width": "100%", "padding": "10px 0px 40px" }}>
              <InfoCircleFilled style={{ "fontSize": "24px", marginRight: "20px" }} />
              { PAYMENT_PLATFORM_ID === 2 ? <p>Recuerda que a veces la notificación no llega por lo cual te recomendamos ingresar a la APP de Nequi</p> : null }
              { PAYMENT_PLATFORM_ID === 3 ? <p>Los datos que se ingresen deben ser del pagador</p> : null }
            </div>
          </div>
        </div>

        <div
          className="col-xs-12 col-lg-12"
          style={{ display: "flex", flexWrap: "wrap" }}
        >
          <div className="col-xs-12 col-lg-6 containerInputText">
            <p className="textUpInput">Nombre completo</p>
            <input
              type="text"
              id="name"
              size="40"
              value={payment.name}
              className="inputText"
              onChange={(e) => this.onChange(e, "name", PAYMENT_PLATFORM_ID)}
            ></input>
          </div>
          <div className="col-xs-12 col-lg-6 containerInputText">
            <p className="textUpInput">Celular </p>
            <input
              type="number"
              id="phone"
              size="40"
              pattern="[0-9]+"
              className="inputText"
              onChange={(e) => this.onChange(e, "phone", PAYMENT_PLATFORM_ID)}
            ></input>
          </div>
        </div>
        <div
          className="col-xs-12 col-lg-12"
          style={{ display: "flex", flexWrap: "wrap" }}
        >
          <div className="col-xs-12 col-lg-6 containerInputText">
            <p className="textUpInput">Tipo de documento</p>
            <Select
              className="selectorInput zIndex99"
              name="payerDocumentType"
              autofocus
              options={payerDocumentTypes}
              placeholder="Seleccionar"
              onChange={(e) => this.selectDocumentType(e)}
              //   defaultValue = {
              //     payerDocumentTypes.filter(option =>
              //        option.id === 1)
              //  }
              // defaultValue={payerDocumentTypes.filter(({value}) => value === 1)}
              defaultValue={{ label: "Cédula de ciudadanía", value: 1 }}
            />
          </div>
          <div className="col-xs-12 col-lg-6 containerInputText">
            <p className="textUpInput">Número de documento</p>
            <input
              type="number"
              id="payerDocumentNumber"
              size="40"
              className="inputText"
              onChange={(e) =>
                this.onChange(e, "payerDocumentNumber", PAYMENT_PLATFORM_ID)
              }
            ></input>
          </div>
        </div>
        <div
          className="col-xs-12 col-lg-12"
          style={{ display: "flex", flexWrap: "wrap" }}
        >
          <div
            className={
              PAYMENT_PLATFORM_ID === 3
                ? "col-xs-12 col-lg-6 containerInputText"
                : "col-xs-12 col-lg-12 containerInputText"
            }
          >
            <p className="textUpInput">Email</p>
            <input
              type="email"
              id="email"
              size="40"
              value={payment.email}
              className="inputText"
              onChange={(e) => this.onChange(e, "email", PAYMENT_PLATFORM_ID)}
            ></input>
          </div>
          {PAYMENT_PLATFORM_ID === 3 ? ( //PSE ONLY
            <div className="col-xs-12 col-lg-6 containerInputText">
              <p className="textUpInput">Banco</p>
              <Select
                className="selectorInput zIndex95"
                autofocus
                options={allBanks}
                placeholder="Seleccionar"
                onChange={(e) =>
                  this.onChange(e, "bankId", PAYMENT_PLATFORM_ID)
                }
              />
            </div>
          ) : null}
        </div>
        {/* <div
          className="col-xs-12 col-lg-12"
          style={{ display: "flex", flexWrap: "wrap" }}
        >
          <div className="col-xs-12 col-lg-6 containerInputText">
            <p className="textUpInput">País</p>
            <Select
              className="selectorInput zIndex90"
              name="countries"
              autofocus
              options={countries}
              placeholder="Seleccionar"
              // defaultInputValue="Colombia"
              onChange={(val) => this.selectCountry(val)}
            />
          </div>
          <div className="col-xs-12 col-lg-6 containerInputText">
            <p className="textUpInput">Departamento / Estado</p>
            <Select
              className="selectorInput zIndex80"
              name="states"
              autofocus
              options={states}
              placeholder="Seleccionar"
              onChange={(val) => this.selectRegion(val)}
            />
          </div>
        </div>
        <div
          className="col-xs-12 col-lg-12"
          style={{ display: "flex", flexWrap: "wrap" }}
        >
          <div className="col-xs-12 col-lg-6 containerInputText">
            <p className="textUpInput">Ciudad</p>
            <Select
              className="selectorInput zIndex50"
              name="cities"
              autofocus
              options={cities}
              placeholder="Seleccionar"
              onChange={(val) => this.selectCity(val)}
            />
          </div>
          <div className="col-xs-12 col-lg-6 containerInputText">
            <p className="textUpInput">Dirección</p>
            <input
              type="text"
              id="buyerAddress"
              size="40"
              value={payment.buyerAddress}
              className="inputText"
              onChange={(e) =>
                this.onChange(e, "buyerAddress", PAYMENT_PLATFORM_ID)
              }
            ></input>
          </div> 
        </div> */}

        <div className="col-xs-12 col-lg-12 containerInputText">
          {questions && questions.length > 0 ? this.renderQuestion() : null}
        </div>

        <div className="containerButtonDonationForm zIndexOne">
          <div className="row button-center marginTop">
            <Button
              className="btnPayment"
              style={{
                "--backgroundButton": headerHomeStyle,
                "--borderColorButton": borderColorButton,
                "--borderColorButtonHover": borderColorButtonHover,
                "--colorText": colorText,
              }}
              onClick={() => this.onPayButton()}
              disabled={disablePayButton}
            >
              {disablePayButton ? (
                <CircularProgress color="inherit" />
              ) : (
                "Pagar $" + value.toLocaleString("es")
              )}
            </Button>
          </div>
        </div>

        {/* Paramether error */}
        <div>
          <div id="payuformcontainer"></div>
          <Modal
            // hideBackdrop
            open={openModal}
            onClose={() => this.handleClose()}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
          >
            <Box className="modal-container2">
              <div className="containerIcon">
                <WarningOutlined className="iconModalWarning" />
              </div>
              <div className="containerRigth">
                <h6
                  id="child-modal-title"
                  style={{ "--fontFamily": fontFamily }}
                >
                  {strings.basicWords.error}
                </h6>
                <p
                  id="child-modal-description"
                  style={{ "--fontFamily": fontFamily }}
                >
                  {" "}
                  {modalMsg}{" "}
                </p>
                {errorLink ? (
                  <Button onClick={() => this.contactSupport(errorLink)}>
                    Contactar a soporte
                  </Button>
                ) : (
                  ""
                )}
                <Button onClick={() => this.handleClose()}>Cerrar</Button>
              </div>
            </Box>
          </Modal>
        </div>

        {/* Payment method error */}
        <div>
          <div id="payuformcontainer"></div>
          <Modal
            // hideBackdrop
            open={openErrorModal}
            onClose={() => this.handleClose()}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
          >
            <Box className="modal-container2">
              <div className="containerIcon">
                <WarningOutlined className="iconModalWarning" />
              </div>
              <div className="containerRigth">
                <h6
                  id="child-modal-title"
                  style={{ "--fontFamily": fontFamily }}
                >
                  ¡Lo sentimos!
                </h6>
                <p
                  id="child-modal-description"
                  style={{ "--fontFamily": fontFamily }}
                >
                  {" "}
                  {errorMsg}{" "}
                </p>
                <Button onClick={() => this.handleClose()}>Cerrar</Button>
              </div>
            </Box>
          </Modal>
        </div>
      </div>
    );
  }
}

export default DonationForm;
