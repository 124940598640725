import React from "react";
import { Link } from "react-router-dom";
import imageNull from "../../../../../images/noImage.jpg";
import moment from "moment";

//Localization
import Strings from "../../../../../reactUtils/systemVariables/languageLocalization/LocalizedStrings.jsx";
import ButtonsContainer from "../../../general/ButtonsContainer.jsx";
import "./index.scss";
var dateOnlyFormat = "MMMM DD, YYYY";
var dateStartTime = "MMMM DD, YYYY : HH:mm";

export function GridLayout(props) {
  const id = props.id;
  const theme = props.theme;
  let multimedia = null;
  let idVideo = null;
  if (props.multimedia && props.multimedia[0] == "isUploadedImage") {
    multimedia = props.multimedia[3]; // large Size
    if(window.innerWidth < 900) { // mobile 
      multimedia = props.multimedia[2]; //medium Size
    }
  } else if (props.multimedia && props.multimedia[0] == "isVideo") {
    idVideo = props.multimedia[1];
  } else if (props.multimedia && props.multimedia[0] == "isLinkedImage") {
    multimedia = props.multimedia[1].value;
  }

  let className = "row";
  let classNameTop = "col-xs-12 col-md-12 wow fadeInRight";
  if(props.components.length > 1){
    classNameTop = "col-xs-12 col-md-6 wow fadeInRight";
  }
  let widthHeigth = "200px";
  let dataFixedValue = "";
  if (props.fixedValue !== "-1") {
    dataFixedValue =  new Intl.NumberFormat('es-CO', { style: 'currency', currency: 'COP' }).format(props.fixedValue);
    dataFixedValue = dataFixedValue.replace(",00", "").toLocaleString("es");
  } else {
    dataFixedValue = Strings.donations.voluntary;
  }

  let startDate;
  let startDateLabel = Strings.basicWords.date;

  if (props.news) {
    startDate = moment(props.date).format(dateOnlyFormat);
    startDateLabel = Strings.sections.publicationDate;
  } else if (props.events) {
    startDate = moment(props.date).format(dateStartTime);
  } else if (props.courses) {
    startDate = props.date ? moment(props.date).format(dateStartTime) : null;
  }

  var event = {};
  if (props.events) {
    event = {
      title: props.title,
      description: props.text,
      startTime: moment(props.date).format(dateStartTime),
      endTime: moment(props.date).add(1, "hours").format(dateStartTime),
      location: props.place,
    };
  }
  if (props.courses) {
    dateOnlyFormat = "MMMM DD, YYYY";
  }
  if (screen.width < 992) {
    classNameTop = "col-xs-12 col-md-12 wow fadeInRight spaceGridLayout";
    widthHeigth = "250px";
  }
  if (screen.width < 769) {
    className = "container";
  }
  let titleLen = props.text.length;

  let buttonLinkPath = "/component/"
  if (props.donations) {
    buttonLinkPath = "/payment/"
  }

  let colorBackground = theme && theme.headerBackground && theme.headerBackground.home 
  ? theme.headerBackground.home : 'linear-gradient(45deg, #6c757d, #6c757d);'
  let colorText = theme && theme.headerTitles && theme.headerTitles.color 
  ? theme.headerTitles.color : 'black;'
  let fontFamilyText = theme && theme.headerTitles && theme.headerTitles.fontFamily 
  ? theme.headerTitles.fontFamily : 'Verdana, sans-serif;'

  return (
    <div className={classNameTop} style={{marginBotton: '15px'}}>
      <div className="col-xs-12 col-md-12"
        style={{ minHeight: "80px", display: "flex", width: "auto", alignItems: "center", justifyContent: "center" }}>
        <Link to={{ pathname: buttonLinkPath + id }}>
          <h3 style={theme && theme.sectionSubtitle ? theme.sectionSubtitle : null + props.individualStyle && props.individualStyle.title
            ? props.individualStyle.title : null} className={"spaceH3GridLayout"}> {props.title}
          </h3>
        </Link>
      </div>
      <div className="row" style={{ 'align-items': 'flex-start'}}>
        {/* // image */}
        <div className="col-xs-12 col-md-12 col-lg-5">
          <div style={{ 'align-items': 'flex-start'}}>
          {/* <div> */}
            <Link to={{ pathname: buttonLinkPath + id }} className='centerJustify'>
              {multimedia === null && idVideo == null ? (
                <div className="frameImgGridLayout" style={{ "--widthHeigth": widthHeigth }}>
                  <img className="mx-auto d-block" style={theme && theme.imagesShape ? theme.imagesShape : null}
                    width={props.individualStyle && props.individualStyle.imageWidth ? props.individualStyle.imageWidth : null }
                    src={imageNull}
                    alt="grid"
                  />
                </div>
              ) : null}

              {idVideo != null ? (
                <iframe className="video" style={{ height: "300px" }}
                  src={`https://www.youtube.com/embed/${idVideo}?version=3&loop=1&autoplay=0&controls=1&;rel=0&playlist=${idVideo}`}
                  frameBorder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                ></iframe>
              ) : null}

              {idVideo == null && multimedia ? (
                <div className="frameImgGridLayout" style={{ "--widthHeigth": widthHeigth }}
                >
                  <img className="mx-auto d-block" style={theme && theme.imagesShape ? theme.imagesShape : null}
                    width={props.individualStyle && props.individualStyle.imageWidth ? props.individualStyle.imageWidth : null}
                    src={multimedia}
                    alt="image grid"
                  />
                </div>
              ) : null}
            </Link>
          </div>
        </div>
        {/* // info */}
        <div className="col-xs-12 col-md-12 col-lg-7" style={{ marginTop: window.innerWidth < 760 ? "10px" : "0px",  'align-items': 'flex-start' }}>

          <p style={theme && theme.sectionText1 ? theme.sectionText1 : null + props.individualStyle && props.individualStyle.text ? props.individualStyle.text : null} id="textDescription"   >
            {props.text}
          </p>
          <br/>
          {startDate != null ? (
            <p style={  theme && theme.sectionText1 ? theme.sectionText1 : null + theme && theme.sectionText2  ? theme.sectionText2 : null} className="body compact">
              <span>{startDate}</span>
            </p>
            ) : null}
                        {props.fixedValue ? (
          <p style={theme && theme.sectionText1 ? theme.sectionText1 : null + props.individualStyle && props.individualStyle.text ? props.individualStyle.text : null} id="textDescription"   >

                <strong> {Strings.basicWords.value}{": "}{" "}  </strong> <span style={{ paddingLeft: '5px' }}>{dataFixedValue}</span>
              </p>
            ) : null}

        </div>
      </div>
      {/* buttons */}
      <div className="row">
        <div className="col-xs-12 col-md-12 text-center">
          {props.events ? (
            <ButtonsContainer
              buttons={props.buttons}
              event={event}
              theme={theme}
              internalLpSectionComponentId={id}
              readMore={true}
              typeLink={false}
              componentId={id}
              style='containerLinearButtons'
              showBorder={true}
            />
          ) : (
            <div>
              <ButtonsContainer
                buttons={props.buttons}
                theme={theme}
                internalLpSectionComponentId={id}
                readMore={true}
                typeLink={false}
                componentId={id}
                style='containerLinearButtons'
                showBorder={true}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
