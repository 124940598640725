import React, { Component, useState } from "react";
import { get as _get } from "lodash";
import "./index.scss";
import { getLpSectionInformation, getFilePath } from "../../../actions/index";
//Localization
import Strings from "../../../reactUtils/systemVariables/languageLocalization/LocalizedStrings.jsx";
import { Helmet } from "react-helmet";
import { MenuOutlined, CaretDownOutlined } from "@ant-design/icons";
import Analitic from "./../../../reactUtils/helpers/analitics";
import HeaderLayoutIndex from "./layoutStyles/HeaderLayoutIndex.jsx";
import ButtonsContainer from "../general/ButtonsContainer.jsx";

import { Menu as BaseMenu, Drawer as DrawerBase, MenuItem, IconButton, Typography, Box, styled, Divider } from "@material-ui/core";

import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

const Menu = styled(BaseMenu)((props) => {
  let background = "lightblue";
  if(props.baseTheme && props.baseTheme.color) {
    background = props.baseTheme.color
  }
  return {
    "& .MuiPaper-root": {
      background: "white"
    }
  }
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  // padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
  "& .MuiPaper-root": {
      
  }
}));

const Drawer = styled(DrawerBase)((props) => {
  let background = "lightblue";
  // if(props.baseTheme && props.baseTheme.color) {
  //   background = props.baseTheme.color
  // }
  return {
    "& .MuiPaper-root": {
      background: "white",
      margin: "0px"
    }
  }
});

class Header extends Component {
  constructor(props) {
    super(props);

    let subpagesAndGroupsHeader = this.getSubpagesAndGroupsHeader(
      props.subpagesInfo,
      props.groupSubpagesInfo
    );

    let mainPageHeader = this.getMainPageSectionsHeader(
      props.sectionsInfo.lpsections
    );

    let headerComponentInfo = mainPageHeader.concat(subpagesAndGroupsHeader);

    this.state = {
      sectionsInfo: props.sectionsInfo,
      headerComponentInfo,
      headerTitle: "",
      headerFontSize: 34,
      visible: false,
      hideSingleArrow: true,
      iconImage: props.iconImage,
      buttonHeader: null,
      menuOpen: null,
      isPath: false,
      drawerOpen: false
    };

    this.forceRenderC = this.forceRenderC.bind(this);
  }

  forceRenderC = () => {
    let subpagesAndGroupsHeader = this.getSubpagesAndGroupsHeader(
      this.props.subpagesInfo,
      this.props.groupSubpagesInfo
    );
    let mainPageHeader = this.getMainPageSectionsHeader(
      this.props.sectionsInfo.lpsections
    );
    let headerComponentInfo = mainPageHeader.concat(subpagesAndGroupsHeader);
    this.setState({ headerComponentInfo }, () => {
      if(this.state.isPath && window.location.hash) {
        window.location.href = window.location.href
        this.setState({ isPath: false })
      }
    })
  }

  componentDidUpdate() {
    const favicon = document.getElementById("favicon"); //Change favicon in certain lp
    if (this.props.lpId === 15) {
      //effeta
      favicon.href =
        "https://somosaura-cms.s3.amazonaws.com/content/COLOMBIA/BOGOTA D.C./15/logoEffeta.png";
    }
    if (this.props.lpId === 46) {
      // vuela
      favicon.href =
        "https://somosaura-cms.s3.amazonaws.com/content/COLOMBIA/BOGOTA D.C./48/imgf1e86c0d-a221-40c5-a538-8432075c9339.png";
    }
    if (this.props.lpId === 75) {
      // P. Madre de la Eucaristía
      favicon.href =
        "https://somosaura-cms.s3.amazonaws.com/content/Logos/arqmanizales.png";
    }
    if (this.props.lpId === 81) {
      // Pan de vida
      favicon.href =
        "https://somosaura-cms.s3.amazonaws.com/content/Logos/logo_pandevida.png";
    }
    if (this.props.lpId === 99) {
      // Corporacion Segundo Piso
      favicon.href =
        "https://somosaura-cms.s3.amazonaws.com/content/Logos/logo_segundo_piso.png";
    }
    if (this.props.lpId === 17 || this.props.lpId === 18) {
      // RC
      favicon.href =
        "https://somosaura-cms.s3.amazonaws.com/content/Logos/logo_rc_white.png ";
    }
    
  }

  analiticEvent(category, action, label, value) {
    Analitic.setEvent({
      category: category,
      action: action,
      label: label,
      value: value,
      nonInteraction: true, // optional, true/false
    })
  }

  async getHeaderInformation() {
    const { headerFontSize } = this.state;

    let response = await getLpSectionInformation(
      this.props.lpId,
      this.props.lpSectionId,
      "HEADER"
    );
    let sectionInfo = response.data.data;
    let headerTitle = sectionInfo.components[0].nodes.filter(
      (node) => node.nodeType === "HeaderTitle"
    )[0].value;
    let headerPathIcon = sectionInfo.components[0].nodes.filter(
      (node) => node.nodeType === "HeaderLogo"
    )[0].value;
    //let headerFontSize = Math.round(headerTitle.length * -0.78 + 47.85);
    let layout = sectionInfo.lpSectionLayoutStyleId;
    if (layout === 1) {
      //Standar header layout
      layout = 0;
    }
    if (layout === 16) {
      // Header logo layout
      layout = 1;
    }
    if (layout === 80) {
      // Header logo layout
      layout = 2;
    }
    if (layout === 90) {
      // Header logo layout
      layout = 3;
    }
    // layout = 0;
    if(process.env.REACT_APP_DEFAULT_LAYOUT_HEADER){
      layout = parseInt(process.env.REACT_APP_DEFAULT_LAYOUT_HEADER);
    }
    let headerIcon;
    if (headerPathIcon !== "" && headerPathIcon !== " ") {
      let imgName = headerPathIcon
        .replace(".jpeg", "")
        .replace(".jpg", "")
        .replace(".png", "");
      let format = headerPathIcon.match(/\.([^.]*)$/)[1];
      headerIcon = await getFilePath(imgName, "smallSize", format);
    }


    let buttonHeader = _get(sectionInfo, "components[0].buttons[0]", null)

    this.setState({
      headerTitle,
      headerIcon,
      headerFontSize,
      layout,
      buttonHeader
    });
  }

  getMainPageSectionsHeader = (sectionsInfo) => {
    const { theme, isTabella } = this.props;
    let sections = Array(
      sectionsInfo.filter(
        (s) => s.subpages.length === 0 && s.mustBeSubpage !== 1
      ).length - 2
    );

    let mainPageMenu = [];
    if (sections.length > 6) {
      const handleClick = (event, menu) => {
        this.setState({
          menuOpen: menu,
        }, () => this.forceRenderC())
      };
      const handleClose = () => {
        this.setState({
          menuOpen: null,
        }, () => this.forceRenderC())
      };
      if (isTabella == false){
      mainPageMenu.push(
        <>
          <MenuItem
            id={"main-menu-button-container"}
            onClick={(event) => {
              handleClick(event, "main-event")
            }}
            aria-controls={_get(this.state, "menuOpen", null) == "main-event" ? 'basic-menu-main' : undefined}
            aria-haspopup="true"
            aria-expanded={_get(this.state, "menuOpen", null) == "main-event" ? 'true' : undefined}            
          >
            <a style={theme && theme.headerTitles ? (window.innerWidth < 900 ? Object.assign({ ...theme.headerTitles }, { color: "black", fontSize: "1.3rem" }) : Object.assign({ ...theme.headerTitles }, { fontSize: "1.3rem" })) : null}>
              {
                (window.location.host == "pandevidacer.org" || window.location.host == "www.pandevidacer.org") ?
                "Asociación Pan de Vida"
                : Strings.basicWords.mainpage
              }
            </a>
          </MenuItem>
          <Menu
            key={"groupSubpage"}
            className="dataMenuMainPage"
            id="basic-menu-main"
            anchorEl={() => {
              return document.getElementById(`main-menu-button-container`)
            }}
            open={_get(this.state, "menuOpen", null) == "main-event"}
            onClose={handleClose}
            icon={<CaretDownOutlined />}
            style={{ ...(theme && theme.headerTitles ? theme.headerTitles : null) }}
            baseTheme={theme && theme.sectionTitle ? theme.sectionTitle : null}
          >
            {this.getMainPageMenuItems(sections, sectionsInfo, true)}
          </Menu>
        </>
      );
          }
    } else {
      mainPageMenu = this.getMainPageMenuItems(sections, sectionsInfo);
    }
    return mainPageMenu;
  };

  getMainPageMenuItems = (sections, sectionsInfo, subMenu = false) => {
    const { theme } = this.props;
    for (var i = 0; i < sectionsInfo.length; i++) {
      if (
        sectionsInfo[i].subpages.length === 0 &&
        sectionsInfo[i].mustBeSubpage !== 1
      ) {
        switch (sectionsInfo[i].name) {
          case "HOME":
            sections[sectionsInfo[i].positionInMainPage - 1] = (
              <MenuItem
                key={sectionsInfo[i].id}
                onClick={() => {
                  this.setState({
                    menuOpen: null,
                    isPath: true
                  }, () => this.forceRenderC())
                  this.analiticEvent("MainHeader", "HOME", `click en home`, 1)
                }}
                style={{ zIndex: 999 }}
              >
                <a
                  id={sectionsInfo[i].id}
                  key={sectionsInfo[i].name.toLowerCase() + sectionsInfo[i].id}
                  // className="itemMenu"
                  href="/#home"
                  style={theme && theme.headerTitles ? (subMenu ? Object.assign({ ...theme.headerTitles }, { color: "black", fontSize: "1.3rem" }) : Object.assign({ ...theme.headerTitles }, { fontSize: "1.3rem" })) : null}
                >
                  {Strings.sections.home}
                </a>
              </MenuItem>
            );
            break;
          case "ABOUT":
            sections[sectionsInfo[i].positionInMainPage - 1] = (
              <MenuItem
                key={sectionsInfo[i].id}
                onClick={() => {
                  this.setState({
                    menuOpen: null,
                    isPath: true
                  }, () => this.forceRenderC())
                  this.analiticEvent("MainHeader", "ABOUT", `click en about`, 1)
                }}
                style={{ zIndex: 999 }}
                background={theme ? theme.headerBackground.fixed : 'transparent'}
              >
                <a
                  id={sectionsInfo[i].id}
                  key={sectionsInfo[i].name.toLowerCase() + sectionsInfo[i].id}
                  // className="itemMenu"
                  href={
                    "/#" + sectionsInfo[i].name.toLowerCase() + sectionsInfo[i].id
                  }
                  style={theme && theme.headerTitles ? (subMenu ? Object.assign({ ...theme.headerTitles }, { color: "black", fontSize: "1.3rem" }) : Object.assign({ ...theme.headerTitles }, { fontSize: "1.3rem" })) : null}
                >
                  {sectionsInfo[i].customName
                    ? sectionsInfo[i].customName
                    : Strings.sections.aboutUs}
                </a>
              </MenuItem>
            );
            break;
          case "EVENTS":
            sections[sectionsInfo[i].positionInMainPage - 1] = (
              <MenuItem
                key={sectionsInfo[i].id}
                onClick={() => {
                  this.setState({
                    menuOpen: null,
                    isPath: true
                  }, () => this.forceRenderC())
                  this.analiticEvent("MainHeader", "EVENTS", `click en events`, 1)
                }}
                style={{ zIndex: 999 }}
              >
                <a
                  id={sectionsInfo[i].id}
                  key={sectionsInfo[i].name.toLowerCase() + sectionsInfo[i].id}
                  // className="itemMenu"
                  href={
                    "/#" +
                    sectionsInfo[i].name.toLowerCase() +
                    sectionsInfo[i].id
                  }
                  style={theme && theme.headerTitles ? (subMenu ? Object.assign({ ...theme.headerTitles }, { color: "black", fontSize: "1.3rem" }) : Object.assign({ ...theme.headerTitles }, { fontSize: "1.3rem" })) : null}
                >
                  {sectionsInfo[i].customName
                    ? sectionsInfo[i].customName
                    : Strings.sections.events}
                </a>
              </MenuItem>
            );
            break;
          case "NEWS":
            sections[sectionsInfo[i].positionInMainPage - 1] = (
              <MenuItem
                key={sectionsInfo[i].id}
                onClick={() => {
                  this.setState({
                    menuOpen: null,
                    isPath: true
                  }, () => this.forceRenderC())
                  this.analiticEvent("MainHeader", "NEWS", `click en news`, 1)
                }}
                style={{ zIndex: 999 }}
              >
                <a
                  id={sectionsInfo[i].id}
                  key={sectionsInfo[i].name.toLowerCase() + sectionsInfo[i].id}
                  // className="itemMenu"
                  href={
                    "/#" +
                    sectionsInfo[i].name.toLowerCase() +
                    sectionsInfo[i].id
                  }
                  style={theme && theme.headerTitles ? (subMenu ? Object.assign({ ...theme.headerTitles }, { color: "black", fontSize: "1.3rem" }) : Object.assign({ ...theme.headerTitles }, { fontSize: "1.3rem" })) : null}
                >
                  {sectionsInfo[i].customName
                    ? sectionsInfo[i].customName
                    : Strings.sections.news}
                </a>
              </MenuItem>
            );
            break;
          case "TEAM":
            sections[sectionsInfo[i].positionInMainPage - 1] = (
              <MenuItem
                key={sectionsInfo[i].id}
                onClick={() => {
                  this.setState({
                    menuOpen: null,
                    isPath: true
                  }, () => this.forceRenderC())
                  this.analiticEvent("MainHeader", "TEAM", `click en team`, 1)
                }}
                style={{ zIndex: 999 }}
              >
                <a
                  id={sectionsInfo[i].id}
                  key={sectionsInfo[i].name.toLowerCase() + sectionsInfo[i].id}
                  // className="itemMenu"
                  href={
                    "/#" +
                    sectionsInfo[i].name.toLowerCase() +
                    sectionsInfo[i].id
                  }
                  style={theme && theme.headerTitles ? (subMenu ? Object.assign({ ...theme.headerTitles }, { color: "black", fontSize: "1.3rem" }) : Object.assign({ ...theme.headerTitles }, { fontSize: "1.3rem" })) : null}
                >
                  {sectionsInfo[i].customName
                    ? sectionsInfo[i].customName
                    : Strings.sections.team}
                </a>
              </MenuItem>
            );
            break;
          case "COURSES":
            sections[sectionsInfo[i].positionInMainPage - 1] = (
              <MenuItem
                key={sectionsInfo[i].id}
                onClick={() => {
                  this.setState({
                    menuOpen: null,
                    isPath: true
                  }, () => this.forceRenderC())
                  this.analiticEvent("MainHeader", "COURSES", `click en courses`, 1)
                }}
                style={{ zIndex: 999 }}
              >
                <a
                  id={sectionsInfo[i].id}
                  key={sectionsInfo[i].name.toLowerCase() + sectionsInfo[i].id}
                  // className="itemMenu"
                  href={
                    "/#" +
                    sectionsInfo[i].name.toLowerCase() +
                    sectionsInfo[i].id
                  }
                  style={theme && theme.headerTitles ? (subMenu ? Object.assign({ ...theme.headerTitles }, { color: "black", fontSize: "1.3rem" }) : Object.assign({ ...theme.headerTitles }, { fontSize: "1.3rem" })) : null}
                >
                  {sectionsInfo[i].customName
                    ? sectionsInfo[i].customName
                    : Strings.sections.courses}
                </a>
              </MenuItem>
            );
            break;
          case "GALLERY":
            sections[sectionsInfo[i].positionInMainPage - 1] = (
              <MenuItem
                key={sectionsInfo[i].id}
                onClick={() => {
                  this.setState({
                    menuOpen: null,
                    isPath: true
                  }, () => this.forceRenderC())
                  this.analiticEvent("MainHeader", "GALLERY", `click en gallery`, 1)
                }}
                style={{ zIndex: 999 }}
              >
                <a
                  id={sectionsInfo[i].id}
                  key={sectionsInfo[i].name.toLowerCase() + sectionsInfo[i].id}
                  // className="itemMenu"
                  href={
                    "/#" +
                    sectionsInfo[i].name.toLowerCase() +
                    sectionsInfo[i].id
                  }
                  style={theme && theme.headerTitles ? (subMenu ? Object.assign({ ...theme.headerTitles }, { color: "black", fontSize: "1.3rem" }) : Object.assign({ ...theme.headerTitles }, { fontSize: "1.3rem" })) : null}
                >
                  {sectionsInfo[i].customName
                    ? sectionsInfo[i].customName
                    : Strings.sections.gallery}
                </a>
              </MenuItem>
            );
            break;
          case "DONATIONS":
            sections[sectionsInfo[i].positionInMainPage - 1] = (
              <MenuItem
                key={sectionsInfo[i].id}
                onClick={() => {
                  this.setState({
                    menuOpen: null,
                    isPath: true
                  }, () => this.forceRenderC())
                  this.analiticEvent("MainHeader", "DONATIONS", `click en donations`, 1)
                }}
                style={{ zIndex: 999 }}
              >
                <a
                  id={sectionsInfo[i].id}
                  key={sectionsInfo[i].name.toLowerCase() + sectionsInfo[i].id}
                  // className="itemMenu"
                  href={
                    "/#" +
                    sectionsInfo[i].name.toLowerCase() +
                    sectionsInfo[i].id
                  }
                  style={theme && theme.headerTitles ? (subMenu ? Object.assign({ ...theme.headerTitles }, { color: "black", fontSize: "1.3rem" }) : Object.assign({ ...theme.headerTitles }, { fontSize: "1.3rem" })) : null}
                >
                  {sectionsInfo[i].customName
                    ? sectionsInfo[i].customName
                    : Strings.sections.donations}
                </a>
              </MenuItem>
            );
            break;
          case "CONTACT":
            sections[sectionsInfo[i].positionInMainPage - 1] = (
              <MenuItem
                key={sectionsInfo[i].id}
                onClick={() => {
                  this.setState({
                    menuOpen: null,
                    isPath: true
                  }, () => this.forceRenderC())
                  this.analiticEvent("MainHeader", "CONTACT", `click en contact`, 1)
                }}
                style={{ zIndex: 999 }}
              >
                <a
                  key={sectionsInfo[i].name.toLowerCase() + sectionsInfo[i].id}
                  // className="itemMenu"
                  href="/#contact"
                  style={theme && theme.headerTitles ? (subMenu ? Object.assign({ ...theme.headerTitles }, { color: "black", fontSize: "1.3rem" }) : Object.assign({ ...theme.headerTitles }, { fontSize: "1.3rem" })) : null}
                >
                  {sectionsInfo[i].customName
                    ? sectionsInfo[i].customName
                    : Strings.sections.contactUs}
                </a>
              </MenuItem>
            );
            break;
          case "SOCIALNETWORKS":
            sections[sectionsInfo[i].positionInMainPage - 1] = (
              <MenuItem
                key={sectionsInfo[i].id}
                onClick={() => {
                  this.setState({
                    menuOpen: null,
                    isPath: true
                  }, () => this.forceRenderC())
                  this.analiticEvent("MainHeader", "SOCIALNETWORKS", `click en social network`, 1)
                }}
                style={{ zIndex: 999 }}
              >
                <a
                  key={sectionsInfo[i].name.toLowerCase() + sectionsInfo[i].id}
                  // className="itemMenu"
                  href={
                    "/#" +
                    sectionsInfo[i].name.toLowerCase() +
                    sectionsInfo[i].id
                  }
                  style={theme && theme.headerTitles ? (subMenu ? Object.assign({ ...theme.headerTitles }, { color: "black", fontSize: "1.3rem" }) : Object.assign({ ...theme.headerTitles }, { fontSize: "1.3rem" })) : null}
                >
                  {sectionsInfo[i].customName
                    ? sectionsInfo[i].customName
                    : Strings.sections.socialnetworks}
                </a>
              </MenuItem>
            );
            break;
          case "STREAMING":
            sections[sectionsInfo[i].positionInMainPage - 1] = (
              <MenuItem
                key={sectionsInfo[i].id}
                onClick={() => {
                  this.setState({
                    menuOpen: null,
                    isPath: true
                  }, () => this.forceRenderC())
                  this.analiticEvent("MainHeader", "STREAMING", `click en streaming`, 1)
                }}
                style={{ zIndex: 999 }}
              >
                <a
                  key={sectionsInfo[i].name.toLowerCase() + sectionsInfo[i].id}
                  // className="itemMenu"
                  href={
                    "/#" +
                    sectionsInfo[i].name.toLowerCase() +
                    sectionsInfo[i].id
                  }
                  style={theme && theme.headerTitles ? (subMenu ? Object.assign({ ...theme.headerTitles }, { color: "black", fontSize: "1.3rem" }) : Object.assign({ ...theme.headerTitles }, { fontSize: "1.3rem" })) : null}
                >
                  {sectionsInfo[i].customName
                    ? sectionsInfo[i].customName
                    : Strings.sections.streamings}
                </a>
              </MenuItem>
            );
            break;
        }
      }
    }
    return sections;
  };

  getSubpagesAndGroupsHeader = (subpagesInfo, groupSubpagesInfo) => {
    const { theme } = this.props;

    let subpages = [];
    for (var i = 0; i < subpagesInfo.length; i++) {
      if (
        subpagesInfo[i].isEnabled == 1 &&
        subpagesInfo[i].positionInSubpageGroup == null
      ) {
        subpages.push(subpagesInfo[i]);
      }
    }

    let groupSubpages = [];
    for (var i = 0; i < groupSubpagesInfo.length; i++) {
      if (groupSubpagesInfo[i].isEnabled === 1) {
        groupSubpages.push(groupSubpagesInfo[i]);
      }
    }

    let concatInfo = subpages.concat(groupSubpages);
    let subpagesAndGroups = [...new Set(concatInfo)];

    function comparePositions(a, b) {
      if (a.positionInHeader > b.positionInHeader) {
        return 1;
      }
      if (b.positionInHeader > a.positionInHeader) {
        return -1;
      }
      return 0;
    }

    let headerInfo = subpagesAndGroups.sort(comparePositions);
    let header = [];

    for (var i = 0; i < headerInfo.length; i++) {
      switch (headerInfo[i].style) {
        case "subpage":
          header.push(
            <MenuItem
              style={{ margin: window.innerWidth < 900 ? "0px" : "0px 20px" }}
              key={"subpage-"+headerInfo[i].id}
              onClick={() => this.analiticEvent("subpage", `subpage ${decodeURIComponent(headerInfo[i].name) + "-" + headerInfo[i].id}`, `click en subpage ${decodeURIComponent(headerInfo[i].name) + "-" + headerInfo[i].id}`, 1)}
            >
              <a
                key={
                  decodeURIComponent(headerInfo[i].name) +
                  "-" +
                  headerInfo[i].id
                }
                style={theme && theme.headerTitles ? (window.innerWidth < 900 ? Object.assign({ ...theme.headerTitles }, { color: "black", fontSize: "1.3rem" }) : Object.assign({ ...theme.headerTitles }, { fontSize: "1.3rem" })) : null}
                href={"/" + decodeURIComponent(headerInfo[i].name)}
              >
                {decodeURIComponent(headerInfo[i].name)}
              </a>
            </MenuItem>
          );
          break;
        case "groupSubpage":
          let sectionsGroupSubpage = this.getSectionsGroupSubpage(
            headerInfo[i]
          );
          const handleClick = (event, menu) => {
            this.setState({
              menuOpen: menu,
            }, () => this.forceRenderC())
            event.stopPropagation();
          };
          const handleClose = () => {
            this.setState({
              menuOpen: null,
            }, () => this.forceRenderC())
          };
          let id = headerInfo[i].id;
          let name = headerInfo[i].name;
          header.push(
            <Box key={`subgroup-${id}`} style={{ position: "relative" }}>
              <MenuItem
                id={`subgroup-box-${id}`}
                className="dataMenuGroup"
                aria-controls={_get(this.state, "menuOpen", null) == `basic-menu-${id}` ? `basic-menu-${i}` : undefined}
                aria-expanded={_get(this.state, "menuOpen", null) == `basic-menu-${id}` ? 'true' : undefined}
                style={{ zIndex: "999" }}
                onClick={(event) => {
                  handleClick(event, `basic-menu-${id}`)
                  this.analiticEvent("groupSubpage", `subpage ${name}`, `click en groupSubpage ${name}`, 1)
                }}
                icon={<CaretDownOutlined />}
                // style={{ margin: "0px 20px" }}
              >
                <a style={theme && theme.headerTitles ? (window.innerWidth < 900 ? Object.assign({ ...theme.headerTitles }, { color: "black", fontSize: "1.3rem" }) : Object.assign({ ...theme.headerTitles }, { fontSize: "1.3rem" })) : null}>
                  {  decodeURIComponent(headerInfo[i].name) }
                </a>
              </MenuItem>
              
              <Menu
                id={`basic-menu-${id}`}
                anchorEl={() => {
                  return document.getElementById(`subgroup-box-${id}`)
                }}
                open={_get(this.state, "menuOpen", null) == `basic-menu-${id}`}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                  sx: { padding: "0px" }
                }}
                style={{ padding: "0px", margin: "0px" }}
                baseTheme={theme && theme.sectionTitle ? theme.sectionTitle : null}
              >
                {sectionsGroupSubpage}
              </Menu>
            </Box>
          );
          break;
      }
    }

    return header;
  };

  getSectionsGroupSubpage = (groupSubpage,) => {
    const { theme } = this.props;
    let SubpageInGroup = [];
    groupSubpage.sections.forEach((section) =>
      SubpageInGroup.push(
        <MenuItem
          onClick={() => this.analiticEvent("groupSubpage", `subpage ${section.name}`, `click en sections group subpage ${section.name}`, 1)}
        >
          <a
            key={section.name}
            href={"/" + section.name}
            style={
              theme && theme.headerTitles ? Object.assign({ ...theme.headerTitles }, { color: "black", fontSize: "1.3rem" }) : null
            }
          >
            {decodeURIComponent(section.name)}
          </a>
        </MenuItem>
      )
    );
    return SubpageInGroup;
  };

  componentDidMount() {
    this.getHeaderInformation();
  }

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  renderComponentHeader() {
    let renderedComponent = [];
    // let layout = "HEADER LAYOUT 1";
    const { theme } = this.props;
    const { headerTitle, headerFontSize, headerIcon, headerComponentInfo } = this.state;
    renderedComponent.push(
      <HeaderLayoutIndex
        id={Math.random() * 1000}
        key={Math.random() * 1000}
        theme={theme}
        headerTitle={headerTitle}
        headerFontSize={headerFontSize}
        icon={headerIcon}
        layout={this.state.layout}
        headerComponentInfo={headerComponentInfo}
        buttonHeader={this.state.buttonHeader}
      />
    );
    return renderedComponent;
  }

  render() {
    const { theme, isTabella } = this.props;
    const { headerTitle, headerFontSize, headerComponentInfo, buttonHeader } = this.state;
    let borderColorButton = "#008cba";
    let borderColorButtonHover = "#008cba";
    if (theme != null) {
      // borderColorButton = theme.sectionTitle.color;
      borderColorButton = 'white';
      borderColorButtonHover = 'white';
      // borderColorButtonHover = theme.sectionSubtitle.color;
    }

    let headerFixedStyle =
      "linear-gradient(45deg, rgb(23,162,184), rgb(23,162,184))";
    let headerHomeStyle =
      "linear-gradient(45deg, rgba(23,162,184, 0.5), rgba(23,162,184, 0.5))";
    let fontFamilyColor;
    let fontFamily;
    if (theme && theme.headerTitles) {
      fontFamilyColor = theme.headerTitles.color;
    }
    if (theme && theme.headerTitles) {
      fontFamily = theme.headerTitles.fontFamily;
    }
    if (theme && theme.headerBackground) {
      if (theme.headerBackground.fixed) {
        headerFixedStyle = theme.headerBackground.fixed;
      }
      if (theme.headerBackground.home) {
        headerHomeStyle = theme.headerBackground.home;
      }
    }
    if (this.props.isSubpageFlag == true) {
      headerHomeStyle = headerFixedStyle;
    } else {
      headerHomeStyle = "background: background: transparent !important";
    }
    document.title = headerTitle;
    // document.head.innerHTML += `
    // <meta name="description" content="Somos Aura - Conectamos la comunidad católica" />
    // <meta name="image" content="https://somosaura.org/wp-content/uploads/2020/07/Aura-Verical-1-295x300.png" />
    // <link rel="shortcut icon" href="https://www.google.com/search?q=regnum+christi&tbm=isch&ved=2ahUKEwjw4rf0uavxAhWQXzABHSS-AYgQ2-cCegQIABAA&oq=regnum+christi&gs_lcp=CgNpbWcQAzIECCMQJzICCAAyAggAMgQIABAeMgQIABAeMgQIABAeMgQIABAeMgQIABAeMgQIABAeMgYIABAFEB46BggAEAgQHlDpD1iIHWC4HmgAcAB4AYAB6wGIAZAOkgEGMC4xMy4xmAEAoAEBqgELZ3dzLXdpei1pbWfAAQE&sclient=img&ei=0PPRYPC6FZC_wbkPpPyGwAg&bih=641&biw=1336#imgrc=SCdTa2uyWSIo3M" />
    // `
    const handleDrawerClose = (event) => {
      
    };
    const handleDrawerOpen = () => {
      
    };

    return (
      <div>
        {/* <Helmet>
          <title> {headerTitle} </title>
          <meta
            name="description"
            content="Somos Aura - Conectamos la comunidad católica"
          />
          <meta
            name="image"
            content="https://somosaura.org/wp-content/uploads/2020/07/Aura-Verical-1-295x300.png"
          />
          <link
            rel="shortcut icon"
            href="https://www.google.com/search?q=regnum+christi&tbm=isch&ved=2ahUKEwjw4rf0uavxAhWQXzABHSS-AYgQ2-cCegQIABAA&oq=regnum+christi&gs_lcp=CgNpbWcQAzIECCMQJzICCAAyAggAMgQIABAeMgQIABAeMgQIABAeMgQIABAeMgQIABAeMgQIABAeMgYIABAFEB46BggAEAgQHlDpD1iIHWC4HmgAcAB4AYAB6wGIAZAOkgEGMC4xMy4xmAEAoAEBqgELZ3dzLXdpei1pbWfAAQE&sclient=img&ei=0PPRYPC6FZC_wbkPpPyGwAg&bih=641&biw=1336#imgrc=SCdTa2uyWSIo3M"
          />
        </Helmet> */}
        <header
          id="header"
          style={{"--background-col-scroll": headerFixedStyle, background: headerHomeStyle, 
                  "--fontFamilyColor": fontFamilyColor,  "--fontFamily": fontFamily,
          }}
        >
          <div
            id="container"
            className={"containerHeader d-flex justify-content-between align-items-center"} 
            style={{ background: headerHomeStyle, padding: "0px 5vw", width: "99vw", height: window.location.host == "parroquiasantaelenasv.com" ? "18vh" : "auto",
              "--fontFamilyColor": fontFamilyColor, "--fontFamily": fontFamily,
              "--background-col-scroll": headerFixedStyle,
            }}
          >
            {this.renderComponentHeader()}
            {
              this.state.layout === 2 ? "" :  
                (
                  (window.innerWidth < 900 || (window.location.host == "parroquiasantaelenasv.com" && window.innerWidth < 1600)) && isTabella == false ?
                    <Box>
                      <IconButton
                          {...{
                            edge: "start",
                            color: "inherit",
                            "aria-label": "menu",
                            "aria-haspopup": "true",
                            onClick: () => this.setState({ drawerOpen: true, }),
                          }}
                      >
                        <MenuIcon style={{color: fontFamilyColor}}/>
                      </IconButton>
                      <Drawer
                        baseTheme={theme && theme.sectionTitle ? theme.sectionTitle : null}
                        {...{
                          variant: "persistent",
                          anchor: "left",
                          open: this.state.drawerOpen,
                          onClose: () => this.setState({ drawerOpen: false, }),
                        }}
                        style={{ paddingRight: "20px", zIndex: 998 }}
                      >
                        <DrawerHeader>
                            <IconButton onClick={() => this.setState({ drawerOpen: false, })}>
                              <ChevronLeftIcon />
                            </IconButton>
                        </DrawerHeader>
                        <Divider />
                        {headerComponentInfo}
                      </Drawer>
                      {
                        buttonHeader ? 
                          <Typography>
                            <a target="_blank" href={_get(buttonHeader, "externalLink", "")}>
                              <button
                                className='buttonHeader'
                                style={{
                                  "--borderColorButton": 'white',
                                  background: headerHomeStyle,
                                  "--colorText": 'white',
                                  fontFamily: fontFamily,
                                  fontSize: "20px"
                                }}
                              >
                                { _get(buttonHeader, "name", "") }
                              </button>
                            </a>
                          </Typography>
                        : null
                      }
                  </Box>
                
                  :
                  <Box style={{ display: "flex", justifyContent: "center" }}>
                    {headerComponentInfo}
                    {
                      buttonHeader ? 
                        <Typography>
                          <a target="_blank" href={_get(buttonHeader, "externalLink", "")}>
                            <button
                              className='buttonHeader'
                              style={{
                                "--borderColorButton": 'white',
                                background: headerHomeStyle,
                                "--colorText": 'white',
                                fontFamily: fontFamily,
                                fontSize: "20px"
                              }}
                            >
                              { _get(buttonHeader, "name", "") }
                            </button>
                          </a>
                        </Typography>
                      : null
                    }
                  </Box>
                )
            }
          </div>
        </header>
      </div>
    );
  }
}
export default Header;
