import React, { Component } from "react";

import HomeSection from "./home/homeIndex";
import AboutSection from "./about/aboutIndex";
import EventsSection from "./events/eventsIndex";
import NewsSection from "./news/newsIndex";
import TeamSection from "./team/teamIndex";
import CoursesSection from "./courses/coursesIndex";
import GallerySection from "./gallery/galleryIndex";
import DonationSection from "./donations/donationsIndex.jsx";
import SocialNetworksSection from "./socialNetworks/socialNetworksIndex";
import StreamingSection from "./streaming/streamingsIndex.jsx";
import LinktreeSection from "./linktree/linkTreeIndex.jsx";


class MainPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderMainPageSections = () => {
    var background = false;
    let sectionsInfo = this.props.sectionsInfo.lpsections;
    //Sort and paint accoding query
    let sections = Array(
      sectionsInfo.filter(
        (s) => s.subpages.length === 0 && s.mustBeSubpage !== 1
      ).length - 2
    );
    for (var i = 0; i < sectionsInfo.length; i++) {
      if (
        sectionsInfo[i].subpages.length === 0 &&
        sectionsInfo[i].mustBeSubpage !== 1
      ) {
        switch (sectionsInfo[i].name) {
          case "HOME":
            // if (background === "#eff5f5") {
            //   background = "#ffff";
            // } else if (background === "#ffff") {
            //   background = "#eff5f5";
            // }
            background = !background;
            sections[sectionsInfo[i].positionInMainPage] = (
              <HomeSection
                lpId={this.props.lpId}
                lpSectionId={sectionsInfo[i].id}
                theme={this.props.theme ? this.props.theme : null}
                key={i}
                isSubpageFlag={false}
                background={background}
              />
            );
            break;
          case "ABOUT":
            background = !background;
            sections[sectionsInfo[i].positionInMainPage] = (
              <AboutSection
                lpId={this.props.lpId}
                lpSectionId={sectionsInfo[i].id}
                theme={this.props.theme ? this.props.theme : null}
                key={i}
                isSubpageFlag={false}
                background={background}
              />
            );
            break;
          case "EVENTS":
            background = !background;
            sections[sectionsInfo[i].positionInMainPage] = (
              <EventsSection
                lpId={this.props.lpId}
                lpSectionId={sectionsInfo[i].id}
                theme={this.props.theme ? this.props.theme : null}
                key={i}
                isSubpageFlag={false}
                background={background}
              />
            );
            break;
          case "NEWS":
            background = !background;
            sections[sectionsInfo[i].positionInMainPage] = (
              <NewsSection
                lpId={this.props.lpId}
                lpSectionId={sectionsInfo[i].id}
                theme={this.props.theme ? this.props.theme : null}
                key={i}
                isSubpageFlag={false}
                position={sectionsInfo[i].positionInMainPage}
                background={background}
              />
            );
            break;
          case "TEAM":
            background = !background;
            sections[sectionsInfo[i].positionInMainPage] = (
              <TeamSection
                lpId={this.props.lpId}
                lpSectionId={sectionsInfo[i].id}
                theme={this.props.theme ? this.props.theme : null}
                key={i}
                isSubpageFlag={false}
                background={background}
              />
            );
            break;
          case "COURSES":
            background = !background;
            sections[sectionsInfo[i].positionInMainPage] = (
              <CoursesSection
                lpId={this.props.lpId}
                lpSectionId={sectionsInfo[i].id}
                theme={this.props.theme ? this.props.theme : null}
                key={i}
                isSubpageFlag={false}
                background={background}
              />
            );
            break;
          case "GALLERY":
            background = !background;
            sections[sectionsInfo[i].positionInMainPage] = (
              <GallerySection
                lpId={this.props.lpId}
                lpSectionId={sectionsInfo[i].id}
                theme={this.props.theme ? this.props.theme : null}
                key={i}
                isSubpageFlag={false}
                background={background}
              />
            );
            break;
          case "DONATIONS":
            background = !background;
            sections[sectionsInfo[i].positionInMainPage] = (
              <DonationSection
                lpId={this.props.lpId}
                lpSectionId={sectionsInfo[i].id}
                theme={this.props.theme ? this.props.theme : null}
                key={i}
                isSubpageFlag={false}
                background={background}
              />
            );
            break;
          case "SOCIALNETWORKS":
            background = !background;
            sections[sectionsInfo[i].positionInMainPage] = (
              <SocialNetworksSection
                lpId={this.props.lpId}
                lpSectionId={sectionsInfo[i].id}
                theme={this.props.theme ? this.props.theme : null}
                key={i}
                isSubpageFlag={false}
                position={sectionsInfo[i].positionInMainPage}
                background={background}
              />
            );
            break;
          case "STREAMING":
            background = !background;
            sections[sectionsInfo[i].positionInMainPage] = (
              <StreamingSection
                lpId={this.props.lpId}
                lpSectionId={sectionsInfo[i].id}
                theme={this.props.theme ? this.props.theme : null}
                key={i}
                isSubpageFlag={false}
                position={sectionsInfo[i].positionInMainPage}
                background={background}
              />
            );
            break;
          case "LINKTREE":
            sections[sectionsInfo[i].positionInMainPage] = (
              <LinktreeSection
                lpId={this.props.lpId}
                lpSectionId={sectionsInfo[i].id}
                theme={this.props.theme ? this.props.theme : null}
                key={i}
                isSubpageFlag={false}
                position={sectionsInfo[i].positionInMainPage}
                background={background}
              />
            );
            break;
        }
      }
    }
    return sections;
  };

  render() {
    return <div>{this.renderMainPageSections()}</div>;
  }
}
export default MainPage;
