import React, { Component } from "react";
import "../../stylesLP.css";
import { StandarHomeLayout } from "./StandarHomeLayout.jsx";
import { HomeOneImageLayout } from "./HomeOneImageLayout.jsx";
import { HomeScrollImageLayout } from "./HomeScrollImageLayout.jsx";
import { HomeVideoLayout } from "./HomeVideoLayout.jsx";
import { HomebuttonsLayout } from "./HomeButtonsLayout.jsx";
import { HomeImageAndText } from "./HomeImageAndText.jsx";

class HomeLayoutIndex extends Component {
  renderGalleryComponent() {
    let renderedComponent = [];
    switch (this.props.layout) {
      case 2:
        renderedComponent.push(
          <StandarHomeLayout
            key={this.props.id}
            theme={this.props.theme}
            title={this.props.title}
            description={this.props.description}
            // img1={window.innerWidth < 900 && this.props.img1M ? this.props.img1M : this.props.img1L}
            // img2={window.innerWidth < 900 && this.props.img2M ? this.props.img2M : this.props.img2L}
            // img3={window.innerWidth < 900 && this.props.img3M ? this.props.img3M : this.props.img3L}
            img1={this.props.img1L}
            img2={this.props.img2L}
            img3={this.props.img3L}
            imgBack={this.props.imgBack}
          />
        );
        break;
      case 17:
        renderedComponent.push(
          <HomeOneImageLayout
            key={this.props.id}
            theme={this.props.theme}
            title={this.props.title}
            description={this.props.description}
            //   img1={window.innerWidth < 900 && this.props.img1M ? this.props.img1M : this.props.img1L}
            img1={this.props.img1L}
            imgBack={this.props.imgBack}
            buttons={this.props.buttons}
          />
        );
        break;
      case 20:
        renderedComponent.push(
          <HomeScrollImageLayout
            key={this.props.id}
            theme={this.props.theme}
            title={this.props.title}
            description={this.props.description}
            // img1={window.innerWidth < 900 && this.props.img1M ? this.props.img1M : this.props.img1L}
            // img2={window.innerWidth < 900 && this.props.img2M ? this.props.img2M : this.props.img2L}
            // img3={window.innerWidth < 900 && this.props.img3M ? this.props.img3M : this.props.img3L}
            img1={this.props.img1L}
            img2={this.props.img2L}
            img3={this.props.img3L}
            imgBack={this.props.imgBack}
          />
        );
        break;
      case 21:
        renderedComponent.push(
          <HomeVideoLayout
            key={this.props.id}
            theme={this.props.theme}
            title={this.props.title}
            imgBack={this.props.imgBack}
            description={this.props.description}
            linkYoutube={this.props.video}
          />
        );
        break;
      case 78:
        renderedComponent.push(
          <HomebuttonsLayout
            key={this.props.id}
            theme={this.props.theme}
            title={this.props.title}
            imgBack={this.props.imgBack}
            description={this.props.description}
            buttons={this.props.buttons}
          />
        );
        break;
      case 79:
        renderedComponent.push(
          <HomeImageAndText
            key={this.props.id}
            theme={this.props.theme}
            title={this.props.title}
            description={this.props.description}
            // img1={window.innerWidth < 900 && this.props.img1M ? this.props.img1M : this.props.img1L}
            // img2={window.innerWidth < 900 && this.props.img2M ? this.props.img2M : this.props.img2L}
            // img3={window.innerWidth < 900 && this.props.img3M ? this.props.img3M : this.props.img3L}
            img1={this.props.img1L}
            img2={this.props.img2L}
            img3={this.props.img3L}
            imgBack={this.props.imgBack}
            buttons={this.props.buttons}
          />
        );
        break;
    }
    return renderedComponent;
  }

  render() {
    return <div>{this.renderGalleryComponent()}</div>;
  }
}
export default HomeLayoutIndex;
