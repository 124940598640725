export function ThemeFunction(props) {
     let theme = null;
     let headerNameFontFamily = null;
     let headerTitlesColor = null;
     let headerTitlesFontFamily = null;
     let headerBackgroundFixed = null;
     let headerBackgroundHome = null;
     let homeBackground = null;
     let homeTitleColor = null;
     let homeTitleFontSize = null;
     let homeTitleFontFamily = null;
     let homeDescriptionColor = null;
     let homeDescriptionFontSize = null;
     let homeDescriptionFontFamily = null;
     let sectionTitleColor = null;
     let sectionTitleFontFamily = null;
     let sectionTitleFontSize = null;
     let sectionSubtitleColor = null;
     let sectionSubtitleFontFamily = null;
     let sectionSubtitleFontSize = null;
     let sectionSubtitleTextAlign = null;
     let sectionDescriptionColor = null;
     let sectionDescriptionFontFamily = null;
     let sectionDescriptionFontStyle = null;
     let sectionDescriptionTextAlign = null;
     let sectionDescriptionFontSize = null;
     let sectionText1Color = null;
     let sectionText1FontFamily = null;
     let sectionText1FontSize = null;
     let sectionText2Color = null;
     let sectionText2FontFamily = null;
     let sectionText2FontSize = null;
     let imagesShapeBorderRadius = null;

     props.forEach((obj) => {
       if (obj.name.includes("headerName.fontFamily")) {
         headerNameFontFamily = obj.value;
       }
       if (obj.name.includes("headerTitles.color")) {
         headerTitlesColor = obj.value;
       }
       if (obj.name.includes("headerTitles.fontFamily")) {
         headerTitlesFontFamily = obj.value;
       }
       if (obj.name.includes("headerBackground.fixed")) {
         headerBackgroundFixed = obj.value;
       }
       if (obj.name.includes("headerBackground.home")) {
         headerBackgroundHome = obj.value;
       }
       if (obj.name.includes("homeBackground")) {
         homeBackground = obj.value;
       }
       if (obj.name.includes("homeTitle.color")) {
         homeTitleColor = obj.value;
       }
       if (obj.name.includes("homeTitle.fontSize")) {
         homeTitleFontSize = obj.value;
       }
       if (obj.name.includes("homeTitle.fontFamily")) {
         homeTitleFontFamily = obj.value;
       }
       if (obj.name.includes("homeDescription.color")) {
         homeDescriptionColor = obj.value;
       }
       if (obj.name.includes("homeDescription.fontSize")) {
         homeDescriptionFontSize = obj.value;
       }
       if (obj.name.includes("homeDescription.fontFamily")) {
         homeDescriptionFontFamily = obj.value;
       }
       if (obj.name.includes("sectionTitle.color")) {
         sectionTitleColor = obj.value;
       }
       if (obj.name.includes("sectionTitle.fontFamily")) {
         sectionTitleFontFamily = obj.value;
       }
       if (obj.name.includes("sectionTitle.fontSize")) {
        sectionTitleFontSize = obj.value;
      }
       if (obj.name.includes("sectionSubtitle.color")) {
         sectionSubtitleColor = obj.value;
       }
       if (obj.name.includes("sectionSubtitle.fontFamily")) {
         sectionSubtitleFontFamily = obj.value;
       }
       if (obj.name.includes("sectionSubtitle.textAlign")) {
         sectionSubtitleTextAlign = obj.value;
       }
       if (obj.name.includes("sectionSubtitle.fontSize")) {
        sectionSubtitleFontSize = obj.value;
      }
       if (obj.name.includes("sectionDescription.color")) {
         sectionDescriptionColor = obj.value;
       }
       if (obj.name.includes("sectionDescription.fontFamily")) {
         sectionDescriptionFontFamily = obj.value;
       }
       if (obj.name.includes("sectionDescription.fontStyle")) {
         sectionDescriptionFontStyle = obj.value;
       }
       if (obj.name.includes("sectionDescription.textAlign")) {
         sectionDescriptionTextAlign = obj.value;
       }
       if (obj.name.includes("sectionDescription.fontSize")) {
        sectionDescriptionFontSize = obj.value;
      }
       if (obj.name.includes("sectionText1.color")) {
         sectionText1Color = obj.value;
       }
       if (obj.name.includes("sectionText1.fontFamily")) {
         sectionText1FontFamily = obj.value;
       }
       if (obj.name.includes("sectionText1.fontSize")) {
         sectionText1FontSize = obj.value;
       }
       if (obj.name.includes("sectionText2.color")) {
         sectionText2Color = obj.value;
       }
       if (obj.name.includes("sectionText2.fontFamily")) {
         sectionText2FontFamily = obj.value;
       }
       if (obj.name.includes("sectionText2.fontSize")) {
         sectionText2FontSize = obj.value;
       }
       if (obj.name.includes("imagesShape.borderRadius")) {
         imagesShapeBorderRadius = obj.value;
       }
     });

       theme = {
         headerName: {
           fontFamily: headerNameFontFamily,
         },
         headerTitles: {
           color: headerTitlesColor,
           fontFamily: headerTitlesFontFamily,
         },
         headerBackground:{
           home: headerBackgroundHome,
           fixed: headerBackgroundFixed,
         },
         homeBackground: homeBackground,
         homeTitle: {
           color: homeTitleColor,
           fontSize: homeTitleFontSize,
           fontFamily: homeTitleFontFamily,
         },
         homeDescription: {
           color: homeDescriptionColor,
           fontSize: homeDescriptionFontSize,
           fontFamily: homeDescriptionFontFamily,
         },
         sectionTitle: {
           color: sectionTitleColor,
           fontFamily: sectionTitleFontFamily,
           fontSize: sectionTitleFontSize
         },
         sectionSubtitle: {
           color: sectionSubtitleColor,
           fontFamily: sectionSubtitleFontFamily,
           textAlign: sectionSubtitleTextAlign,
           fontSize: sectionSubtitleFontSize
         },
         sectionDescription: {
           color: sectionDescriptionColor,
           fontFamily: sectionDescriptionFontFamily,
           fontStyle: sectionDescriptionFontStyle,
           textAlign: sectionDescriptionTextAlign,
           fontSize: sectionDescriptionFontSize
         },
         sectionText1: {
           color: sectionText1Color,
           fontFamily: sectionText1FontFamily,
           fontSize: sectionText1FontSize,
         },
         sectionText2: {
           color: sectionText2Color,
           fontFamily: sectionText2FontFamily,
           fontSize: sectionText2FontSize,
         },
         imagesShape: {
           borderRadius: imagesShapeBorderRadius,
         },
       }

    return theme;
  }