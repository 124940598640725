import React from "react";
import { Link } from "react-router-dom";
import imageNull from "../../../../../images/noImage.jpg";
import ButtonsContainer from "../../../general/ButtonsContainer.jsx";

import "./index.scss";

export function Layout7(props) {
  let { id, theme, title, text, buttons, multimedia } = props;
  let buttonLinkPath = "/component/"
  if(props.donations){
    buttonLinkPath = "/payment/"
  }

  let image  =imageNull;
  if(multimedia && multimedia[1]){
    image = multimedia[1].value ? multimedia[1].value : multimedia[1]
  }
  if(multimedia && multimedia[3]){
    image = multimedia[3].value ? multimedia[3].value : multimedia[3];
  }

  const arrayOfColorFunctions = [ "0", "1","2","3", "4", "5", "6", "7","8","9", "a", "b", "c", "d","e", "f"];
  let randomColorString = "#";

  const newColorFind = () => {
    for (let x = 0; x < 6; x++) {
      let index = Math.floor(Math.random() * 16);
      let value = arrayOfColorFunctions[index];
  
      randomColorString += value;
    }
    return randomColorString;
  };
  let randomColor = newColorFind();

  return (
    <div className='col-xs-12 col-sm-6 col-md-4 col-lg-4' style={{marginBottom: '20px', height: '370px', 'max-width': '450px'}}>
      <Link to={{ pathname: buttonLinkPath + id }}>
        <div className="cardL7" style={{'--randomColor': randomColor}}>
          <img src={image} alt="img news" />
          <h3 style={ theme && theme.sectionSubtitle ? theme.sectionSubtitle : null + props.individualStyle && props.individualStyle.title
          ? props.individualStyle.title: null}>{title}</h3>
          <p style={ theme && theme.sectionText1 ? theme.sectionText1 : null + props.individualStyle && props.individualStyle.text ? props.individualStyle.text : null }
          id="textDescription"> {text}
        </p>
          {/* <ButtonsContainer buttons={buttons} theme={theme} internalLpSectionComponentId={id} readMore={true}  typeLink={false} componentId={id} style='containerLinearButtons'/> */}
        </div>
        
      </Link>
    </div>
  );
}