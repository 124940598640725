import React from "react";

export function CircularImageTeamLayout(props) {
  const { id, theme, name, image, text, position, layout } = props;
  let borderColor = theme && theme.sectionText1.color ? theme.sectionText1.color : 'black';

  return (
    <div className="containerSevenLayout">
      <div className="containerImageSevenLayout" >
        <img src={image} alt="image team" style={{ "--borderColor": borderColor }} />
        <h2 style={theme && theme.sectionSubtitle ? theme.sectionSubtitle : null}>{name} </h2>
        <p style={theme && theme.sectionText1 ? theme.sectionText1 : null}>{text}</p>
        <div className="col-xs-12 col-md-4 col-lg-4">
          {this.renderGalleryComponent()}
        </div>
      </div>
    </div>
  );
}
