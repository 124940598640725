import React from "react";
import {
  HomeOutlined,
  MailOutlined,
  PhoneOutlined,
  FacebookOutlined,
  TwitterOutlined,
  InstagramOutlined,
} from "@ant-design/icons";

export function TeamLayout5(props) {
  const { id, theme, name, image, text, position } = props;
  let backgroundColor = "#008cba";
  if (theme) {
    backgroundColor = theme.headerBackground.fixed;
    backgroundColor = backgroundColor.substring(23, backgroundColor.length);
    let last = backgroundColor.substring(backgroundColor.length - 2);
    if (last === "))") {
      backgroundColor = backgroundColor.substring(
        0,
        backgroundColor.length - 1
      );
    }
  }
  let widthHeigth = "300px";
  return (
    <div className="wow fadeInRight" key={id}>
      <div className="centerImage">
        <div className="my-team">
          <div
            className="profile-pic"
            style={{
              "--backgroundColor": backgroundColor,
              "--widthHeigth": widthHeigth,
            }}
          >
            <img src={image} alt="member team" />
            <div className="detail-text">
              <h4>{name}</h4>
              <p>{position}</p>
              <p>
                <a href="#">
                  <InstagramOutlined className="socialmedia" />
                </a>
                <a href="#">
                  <TwitterOutlined className="socialmedia" />
                </a>
                <a href="#">
                  <InstagramOutlined className="socialmedia" />
                </a>
              </p>
            </div>
            <div className="col-xs-12 col-md-4 col-lg-4">
              {this.renderGalleryComponent()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
