import React, { Component } from "react";
import parse from "html-react-parser";
//Queries
import {
  getPaymentPlatformParameters,
  getComponentByLpIdAndId,
  getFilePath,
  daviplataConfirm,
} from "../../../../actions";

// Material
import { Button } from "@material-ui/core";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";

// Images
import mastercard from "../../../../images/PaymentsMethods/mastercard.png";
import AmericanExpress from "../../../../images/PaymentsMethods/AmericanExpress.png";
import Bancolombia from "../../../../images/PaymentsMethods/Bancolombia.png";
import Daviplata from "../../../../images/PaymentsMethods/Daviplata.png";
import Nequi from "../../../../images/PaymentsMethods/Nequi.png";
import PSE from "../../../../images/PaymentsMethods/PSE.png";
import Visa from "../../../../images/PaymentsMethods/Visa.png";
import candado from "../../../../images/PaymentsMethods/candado.png";
import Paypal from "../../../../images/PaymentsMethods/Paypal.png";

// Styles
import "./payments.css";
import "../index.css";

import Strings from "../../../../reactUtils/systemVariables/languageLocalization/LocalizedStrings.jsx";

// Components
import DonationForm from "../DonationForm";

class PaymentMethods extends Component {
  constructor(props) {
    super(props);
    this.changePaymentState = this.changePaymentState.bind(this);

    this.state = {
      title: "",
      text: "",
      buttonName: "",
      endDate: null,
      fixedValue: null,
      payment: {
        // paymentPlatformId: PAYMENT_PLATFORM_ID,
        lpComponentId: null,
        referenceName: "",
        name: "",
        email: "",
        phone: "",
        value: "",
        taxValue: "",
      },
      errorMsg: "",
      openModal: false,
      payParameters: null,

      openMethods: false,
      paymentPendingFlag: false,
      paymentInfo: {},
      daviplataPaymentPINform: false,
      payerDocumentNumber: null,
      daviplataPin: null,
    };
  }
  componentDidMount() {
    const { lpId, componentId } = this.props;
    this.getCompByLpIdAndId(lpId, componentId);
    this.getPaymentPlatformParameters(lpId);
    this.setState({ lpId });
  }

  async getCompByLpIdAndId(lpId, componentId) {
    let response = await getComponentByLpIdAndId(lpId, componentId);
    if (String(response).includes("Error:") || response.data.data.length == 0) {
      //TODO error page
    } else {
      let donationComponent = response.data.data[0];
      let title = donationComponent.nodes.find(
        (node) => node.nodeType == "DonationComponentTitle"
      );
      title = title ? title.value : "";
      let text = donationComponent.nodes.find(
        (node) => node.nodeType == "DonationComponentText"
      );

      let regex = new RegExp("</");
      let mustBeParse = regex.test(text.value);
      let parsedText = "";

      if (mustBeParse) {
        parsedText = parse(text.value);
      } else {
        parsedText = text.value;
      }

      let endDate = donationComponent.nodes.find(
        (node) => node.nodeType == "DonationComponentEndDate"
      );
      endDate = endDate ? endDate.value : "";

      let formatting_options = {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 3,
      };

      let form = donationComponent.nodes.find(
        (node) => node.nodeType == "DonationFormRule"
      );

      let fixedValue = donationComponent.nodes.find(
        (node) => node.nodeType == "DonationComponentFixedValue"
      );

      fixedValue = fixedValue ? parseFloat(fixedValue.value) : -1;

      // fixedValue = fixedValue != -1 ? fixedValue.toLocaleString('co-CO', { style: 'currency', currency: 'COP' }) : -1;

      let button = donationComponent.buttons.find(
        (but) => but.externalLink === "PAYMENT_PLATFORM"
      );
      let imgNode = donationComponent.nodes.find(
        (node) => node.nodeType === "DonationComponentImage"
      );
      let multimedia = [];
      if (imgNode && imgNode.value != null && imgNode.value !== "") {
        //Get image or video

        if (!imgNode.value.includes("https://")) {
          // checks if it's an uploaded image
          let imgName = imgNode.value
            .replace(".jpeg", "")
            .replace(".jpg", "")
            .replace(".png", "");
          let format = imgNode.value.match(/\.([^.]*)$/)[1];
          let imgS = await getFilePath(imgName, "smallSize", format);
          let imgM = await getFilePath(imgName, "mediumSize", format);
          let imgL = await getFilePath(imgName, "largeSize", format);
          multimedia.push("isUploadedImage");
          multimedia.push(imgS);
          multimedia.push(imgM);
          multimedia.push(imgL);
        } else if (imgNode.value.includes("https://www.youtube.com/watch?")) {
          // checks if it's video or image link
          let idVideo = getVideoId(imgNode.value);
          multimedia.push("isVideo");
          multimedia.push(idVideo.id);
        } else {
          multimedia.push("isLinkedImage");
          multimedia.push(imgNode);
        }
        multimedia = multimedia;
      }
      let buttonName = "Pagar";
      if (button) {
        buttonName = button.name;
      }
      form = form && form.value ? JSON.parse(form.value) : [];

      let paymentCopy = { ...this.state.payment };
      paymentCopy.value = fixedValue != -1 ? fixedValue : 0.0;
      paymentCopy.referenceName = title;
      paymentCopy.lpComponentId = componentId;
      paymentCopy.form = form;

      this.setState({
        title,
        text: parsedText,
        endDate,
        fixedValue,
        multimedia,
        buttonName,
        payment: paymentCopy,
      });
    }
  }

  async getPaymentPlatformParameters(lpId) {
    let response = await getPaymentPlatformParameters(lpId);
    if (String(response).includes("Error:") || response.data.length == 0) {
      //TODO error page
    } else {
      let paymentPlatformParameters = response.data;
      this.setState({ paymentPlatformParameters });
    }
  }

  async daviplataConfirm(confirmDto) {
    let response = await daviplataConfirm(confirmDto);
    let errorMsg = "";
    let openModal = false;

    if (
      String(response).includes("Error") ||
      String(response.data.status).includes("success")
    ) {
      window.location.replace(window.location.origin + "/payments/success");
    } else {
      errorMsg = response.data.data.message;
      openModal = true;
    }

    this.setState({
      openMethods: false,
      openForm: false,
      errorMsg,
      openModal,
    });
  }

  onChangeValue = (value) => {
    const { payment } = { ...this.state };
    payment["value"] = value;
    this.setState({ payment });
  };

  onChangePINValue = (value) => {
    var { daviplataPin } = { ...this.state };
    daviplataPin = value;
    this.setState({ daviplataPin });
  };

  onConfirmPINdaviplata = (e) => {
    const { lpId, paymentReference, daviplataPin } = { ...this.state };
    let confirmDto = {
      lpId: lpId,
      paymentPlatformId: 5,
      reference: paymentReference,
      otp: daviplataPin != null ? parseInt(daviplataPin) : 123,
    };
    this.daviplataConfirm(confirmDto);
  };

  onPayButton = () => {
    if (this.state.payment.value == 0) {
      this.setState({
        errorMsg: "Debes ingresar un valor",
        openModal: true,
      });
    } else {
      this.setState({ openMethods: true, errorMsg: "" });
    }
  };

  onClickMethod = (platformId, minimumValue, name, taxValue) => {
    const { payment } = this.state;
    const minimumPayment = Number(minimumValue.replace(/(,\w).*/, ""));

    if (Number(payment.value) < Number(minimumPayment)) {
      this.setState({
        openMethods: false,
        openForm: false,
        errorMsg: `Para ${name} el pago mínimo es ${minimumPayment.toLocaleString(
          "es"
        )} COP`,
        openModal: true,
      });
    } else {
      this.setState({
        payment: { ...payment, paymentPlatformId: platformId, taxValue },
        openForm: true,
      });
    }
  };

  getPaymentMethodInfo = (id) => {
    switch (id) {
      case 2: // Nequi
        return { img: Nequi, width: "88", name: "Nequi" };
      case 3: // PSE
        return { img: PSE, width: "77", name: "PSE" };
      case 4: // Bancolombia
        return { img: Bancolombia, width: "84", name: "Bancolombia" };
      // case 5: // DaviPlata
      //   return { img: Daviplata, width: "76", name: "DaviPlata" };
      case 6: // Tarjeta de crédito
        return { name: "Tarjeta de crédito" };
      case 7: //Paypal
        return { img: Paypal, width: "84", name: "Paypal" };

      default:
        break;
    }
  };

  getPaymentMethods = (paymentPlatformParameters) => {
    const { payment } = this.state;

    if (Array.isArray(paymentPlatformParameters)) {
      // Map the weird response
      let response = paymentPlatformParameters.map((paymentMethod) => {
        let newObject = {
          id: paymentMethod.id,
          name: paymentMethod.name,
        };
        paymentMethod.parametersList.map((parameter) => {
          newObject[parameter.name] = parameter.value;
        });
        return newObject;
      });

      // response = response.filter((e) => e.id !== 7); // PAYPAL NO

      // SUEÑOS DE LA CALLE ONLY
      // if (payment.lpComponentId == 4496) {
      //   let paypalLink = {
      //     id: 4496,
      //     link: "https://www.paypal.com/donate/?hosted_button_id=QCW6SUKP8GG26",
      //   };
      //   response.push(paypalLink);
      // }

      // if (payment.lpComponentId == 4496) {
      //   let paypalLink = {
      //     id: 7,
      //     link: "https://www.paypal.com/donate/?hosted_button_id=QCW6SUKP8GG26",
      //   };
      //   response.push(paypalLink);
      // }

      let renderedMethods = [];

      if (response) {
        response.forEach((element) => {
          let paymentData = this.getPaymentMethodInfo(element.id);
          if (paymentData) {
            let className =
              element.id === this.state.payment.paymentPlatformId
                ? "container-card-selected"
                : "container-card";

            // Methods to render
            renderedMethods.push(
              <div key={element.id}>
                <div className="marginTop center marginBottom">
                  <div
                    className={className}
                    onClick={() =>
                      this.onClickMethod(
                        element.id,
                        element.minimumPayment,
                        element.name,
                        element.taxValue
                      )
                    }
                  >
                    <div>
                      {element.id === 6 ? ( // Is a credit card?
                        <div className="first-card">
                          <div>
                            <div>
                              <img
                                src={mastercard}
                                width="19"
                                alt="mastercard"
                              />
                            </div>
                          </div>
                          <div>
                            <div>
                              <img src={Visa} width="27" alt="Visa" />
                            </div>
                          </div>
                          <div>
                            <div>
                              <img
                                src={AmericanExpress}
                                width="35"
                                alt="Visa"
                              />
                            </div>
                          </div>
                        </div>
                      ) : (
                        // Other payment methods

                        <div className="center-image">
                          <div>
                            <div>
                              <img
                                src={paymentData.img}
                                width={paymentData.width}
                                alt="paymentData"
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          }
          // } else if (element.id === 7) {
          //   let className =
          //     element.id === this.state.payment.paymentPlatformId
          //       ? "container-card-selected"
          //       : "container-card";
          //   renderedMethods.push(
          //     <div key={element.id}>
          //       <div className="marginTop center">
          //         <div
          //           className={className}
          //           onClick={() => window.location.replace(element.link)}
          //         >
          //           <div>
          //             <div className="center-image">
          //               <div>
          //                 <div>
          //                   <img
          //                     src={paymentData.img}
          //                     width={paymentData.width}
          //                     alt="paymentData"
          //                     // src={Paypal}
          //                     // width={84}
          //                   />
          //                 </div>
          //               </div>
          //             </div>
          //           </div>
          //         </div>
          //       </div>
          //     </div>
          //   );
          // }
        });
      }
      return renderedMethods;
    }
  };

  // Open pending payment info
  changePaymentState = (paymentDto, reference) => {
    let paymentPendingFlag = false;
    let daviplataPaymentPINform = false;
    let payerDocumentNumber = null;
    let paymentReference = null;

    if (paymentDto.paymentPlatformId == 5) {
      // Daviplata confirmation pin needed
      daviplataPaymentPINform = true;
      payerDocumentNumber = paymentDto.payerDocumentNumber;
      paymentReference = reference;
    } else {
      paymentPendingFlag = true;
    }

    this.setState({
      paymentPendingFlag,
      daviplataPaymentPINform,
      reference,
      openForm: false,
      paymentInfo: paymentDto,
      payerDocumentNumber,
      paymentReference,
    });
  };

  // This function returns the info for payment pending view
  renderPaymentPendingInfo = (theme) => {
    const { paymentInfo } = this.state;
    const platformName = this.getPaymentMethodInfo(
      paymentInfo.paymentPlatformId
    ).name;
    return (
      <form className="payment-info">
        <div>
          <label
            style={theme && theme.sectionText1 ? theme.sectionText1 : null}
            className="label"
            htmlFor="name"
          >
            Nombre Completo
          </label>
          <p
            style={theme && theme.sectionText1 ? theme.sectionText1 : null}
            className="value"
            id="name"
          >
            {paymentInfo.name}
          </p>

          <label
            style={theme && theme.sectionText1 ? theme.sectionText1 : null}
            className="label"
            htmlFor="phone"
          >
            Celular
          </label>
          <p
            style={theme && theme.sectionText1 ? theme.sectionText1 : null}
            className="value"
            id="phone"
          >
            {paymentInfo.phone}
          </p>

          <label
            style={theme && theme.sectionText1 ? theme.sectionText1 : null}
            className="label"
            htmlFor="idNumber"
          >
            Documento de Identidad
          </label>
          <p
            style={theme && theme.sectionText1 ? theme.sectionText1 : null}
            className="value"
            id="idNumber"
          >
            {paymentInfo.payerDocumentNumber}
          </p>

          <label
            style={theme && theme.sectionText1 ? theme.sectionText1 : null}
            className="label"
            htmlFor="paymentValue"
          >
            Valor de la transacción
          </label>
          <p
            style={theme && theme.sectionText1 ? theme.sectionText1 : null}
            className="value"
            id="paymentValue"
          >
            {paymentInfo.value.toLocaleString("es")}
          </p>

          <label
            style={theme && theme.sectionText1 ? theme.sectionText1 : null}
            className="label"
            htmlFor="platformName"
          >
            Plataforma de Pago
          </label>
          <p
            style={theme && theme.sectionText1 ? theme.sectionText1 : null}
            className="value"
            id="platformName"
          >
            {platformName}
          </p>

          {paymentInfo.paymentPlatformId === 3 ? (
            <div>
              <label
                style={theme && theme.sectionText1 ? theme.sectionText1 : null}
                className="label"
                htmlFor="email"
              >
                Email
              </label>
              <p
                style={theme && theme.sectionText1 ? theme.sectionText1 : null}
                className="value"
                id="email"
              >
                {paymentInfo.email}
              </p>
            </div>
          ) : (
            ""
          )}
        </div>
      </form>
    );
  };

  handleClose = () => {
    this.setState({ openModal: false });
  };

  render() {
    const {
      payment,
      payParameters,
      openMethods,
      fixedValue,
      openForm,
      lpId,
      multimedia,
      paymentPlatformParameters,
      paymentPendingFlag,
      daviplataPaymentPINform,
      errorMsg,
      openModal,
      payerDocumentNumber,
      daviplataPin,
    } = this.state;
    const theme = this.props.theme;

    let currencyCode = payParameters
      ? payParameters.find((p) => p.name === "currencyCode")
      : "";
    currencyCode = currencyCode ? "(" + currencyCode.value + ")" : "";

    let backgroundButton = "transparent";
    let headerFixedStyle = "transparent";
    let headerHomeStyle = "gray";
    let borderColorButton = "#008cba";
    let borderColorButtonHover = "#008cba";
    let colorText = "black";
    if (theme != null) {
      backgroundButton = theme.headerTitles.color;
      headerFixedStyle = theme.headerTitles.fixed;
      headerHomeStyle = theme.headerBackground.fixed;
      borderColorButton = theme.sectionTitle.color;
      borderColorButtonHover = theme.sectionSubtitle.color;
      colorText = "white";
    }

    let linkExpired = false;

    // Get the platform name
    let platformId = this.state.payment.paymentPlatformId;
    let platformName = "";
    if (platformId !== undefined) {
      platformName = this.getPaymentMethodInfo(platformId).name;
    }

    // Modal styles
    const style = {
      display: "grid",
      placeItems: "center",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "auto",
      bgcolor: "background.paper",
      border: "1px solid rgba(0,0,0)",
      boxShadow: 24,
      pt: 2,
      px: 4,
      pb: 3,
    };

    let paymentWithFormat =
      typeof payment.value === "string"
        ? payment.value.replace("$", "").replace(",00", "").toLocaleString("es")
        : payment.value.toLocaleString("es");

    return (
      <div
        className="container"
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: "8vh",
          marginBottom: "8vh",
        }}
      >
        <div className="center wow fadeIn" data-wow-duration="1s">
          <div className="col-xs-12 col-lg-4 containerPayments">
            <div className="col-xs-12wow fadeIn" data-wow-duration="1s">
              <h1
                style={
                  theme && theme.sectionSubtitle
                    ? theme.sectionSubtitle
                    : null + this.props &&
                      this.props.individualStyle &&
                      this.props.individualStyle.title
                    ? this.props.individualStyle.title
                    : null
                }
                className="titlePaymentMethod"
              >
                {this.state.title}
              </h1>
            </div>
            <div className="col-xs-12text-justify">
              <p
                style={
                  theme && theme.sectionText1
                    ? theme.sectionText1
                    : null + this.props &&
                      this.props.individualStyle &&
                      this.props.individualStyle.text
                    ? this.props.individualStyle.text
                    : null
                }
              >
                <p id="textDescription" style={{ textAlign: "center" }}>
                  {this.state.text}
                </p>
              </p>
            </div>
            <div
              className={multimedia ? "col-xs-12 wow fadeInRight " : ""}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div className="imagePaymentMethod">
                {multimedia != null ? (
                  <img src={multimedia[2]} alt="imagePaymentMethod" />
                ) : null}
              </div>
            </div>
            <div
              className={multimedia ? "col-xs-12 wow fadeInRight " : ""}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div>
                <p style={{ fontSize: "20px" }}>
                  <strong>
                    {payment.value === 0 ? "" : `$${paymentWithFormat}`}
                  </strong>
                </p>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-lg-7 containerPayments secondContainer">
            {/* When update message is needed */}
            {/* <div className="error-container">
              <span className="error-container-title">
                Actualización en proceso
              </span>
              <div>
                <span>
                  Estamos mejorando la experiencia de pagos. Por favor inténtalo
                  nuevamente más tarde.
                </span>
              </div>
            </div>  */}

            {linkExpired === false &&
            paymentPendingFlag === false &&
            daviplataPaymentPINform === false ? (
              <div>
                {fixedValue == -1 && openMethods === false ? (
                  <div>
                    {/* <div className="center-content">
                      <h1
                        className="titlePaymentMethod"
                        style={
                          theme && theme.sectionSubtitle
                            ? theme.sectionSubtitle
                            : null + this.props.individualStyle &&
                              this.props.individualStyle.title
                            ? this.props.individualStyle.title
                            : null && "margin-bottom: 2rem"
                        }
                      >
                        {" "}
                        {Strings.basicWords.enterAmount}:{" "}
                      </h1>
                    </div> */}
                    <div className="center-content marginTop">
                      <div className="marginTop">
                        <CurrencyTextField
                          className="CurrencyTextField"
                          label={Strings.basicWords.enterAmount}
                          variant="outlined"
                          value={payment.value}
                          currencySymbol="$"
                          onChange={(e, value) => this.onChangeValue(value)}
                          decimalPlaces={0}
                          decimalCharacter={"."}
                        />
                      </div>
                    </div>
                    <div className="containerButtonDonationForm zIndexOne">
                      <div className="row button-center marginTop">
                        <Button
                          className="btnPayment"
                          style={{
                            "--backgroundButton": headerHomeStyle,
                            "--borderColorButton": borderColorButton,
                            "--borderColorButtonHover": borderColorButtonHover,
                            "--colorText": colorText,
                          }}
                          onClick={() => this.onPayButton()}
                        >
                          {Strings.basicWords.continue}
                        </Button>
                      </div>
                    </div>

                    <Modal
                      hideBackdrop
                      open={openModal}
                      onClose={() => this.handleClose()}
                      aria-labelledby="child-modal-title"
                      aria-describedby="child-modal-description"
                    >
                      <Box className="modal-container">
                        <h6 id="child-modal-title">
                          {Strings.basicWords.error}
                        </h6>
                        <p id="child-modal-description"> {errorMsg} </p>
                        <Button
                          onClick={() => this.handleClose()}
                          className="buttonModal"
                        >
                          {Strings.basicWords.close}
                        </Button>
                      </Box>
                    </Modal>
                  </div>
                ) : (
                  <div>
                    <div></div>
                    <div className="containerButtons">
                      {/* <div
                        style={{
                          position: "absolute",
                          top: "0.5rem",
                          right: "0rem",
                          width: "220px",
                        }}
                        className="row"
                      >
                        <div
                          className="col-xs-12 col-lg-12"
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            marginBottom: "5%",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            src={candado}
                            width={"20px"}
                            style={{ margin: "1vh" }}
                            alt="Formulario seguro"
                          />
                          <p style={{ margin: "1vh" }}>
                            <b> Formulario seguro </b>
                          </p>
                        </div>
                      </div> */}

                      <div style={{ marginBottom: "5%" }} />
                      <div className="">
                        <h1
                          className="titlePaymentMethod"
                          style={
                            theme && theme.sectionSubtitle
                              ? theme.sectionSubtitle
                              : null + this.props &&
                                this.props.individualStyle &&
                                this.props.individualStyle.title
                              ? this.props.individualStyle.title
                              : null
                          }
                        >
                          Selecciona tu medio de pago
                        </h1>

                        <div className="container-pay-methods">
                          {this.getPaymentMethods(paymentPlatformParameters)}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div>
                {paymentPendingFlag === true ? (
                  <div className="payment-pending">
                    <div className="payment-title">
                      <h6
                        style={
                          theme && theme.sectionSubtitle
                            ? theme.sectionSubtitle
                            : null + this.props &&
                              this.props.individualStyle &&
                              this.props.individualStyle.title
                            ? this.props.individualStyle.title
                            : null
                        }
                        className="title"
                      >
                        ¡Su transacción está pendiente!
                      </h6>
                      {platformName !== "" ? (
                        <span
                          style={
                            theme && theme.sectionText1
                              ? theme.sectionText1
                              : null + this.props &&
                                this.props.individualStyle &&
                                this.props.individualStyle.text
                              ? this.props.individualStyle.text
                              : null
                          }
                        >
                          Finalice el pago en las notificaciones de la
                          aplicación {platformName}.
                        </span>
                      ) : null}
                    </div>
                  </div>
                ) : null}

                {linkExpired === true ? (
                  <div className="error-container">
                    <span className="error-container-title">
                      El pago no pudo ser realizado
                    </span>
                    <div>
                      <span>El link de pago ha expirado</span>
                    </div>
                  </div>
                ) : null}

                {linkExpired === false && daviplataPaymentPINform === true ? (
                  <div className="payment-pending">
                    <div className="payment-title">
                      <h6
                        style={
                          theme && theme.sectionSubtitle
                            ? theme.sectionSubtitle
                            : null + this.props &&
                              this.props.individualStyle &&
                              this.props.individualStyle.title
                            ? this.props.individualStyle.title
                            : null
                        }
                        className="title"
                      >
                        Se ha enviado un PIN a la cuenta {platformName} N°{" "}
                        {payerDocumentNumber}
                      </h6>
                      {platformName !== "" ? (
                        <span
                          style={
                            theme && theme.sectionText1
                              ? theme.sectionText1
                              : null + this.props &&
                                this.props.individualStyle &&
                                this.props.individualStyle.text
                              ? this.props.individualStyle.text
                              : null
                          }
                        >
                          Por favor revise sus mensajes de texto y a
                          continuación ingrese el código:
                        </span>
                      ) : null}

                      <Box
                        component="form"
                        sx={{
                          "& > :not(style)": { m: 1, width: "25ch" },
                        }}
                        noValidate
                        autoComplete="off"
                      >
                        <TextField
                          id="outlined-basic"
                          label="Ingresar PIN"
                          variant="outlined"
                          onChange={(e) =>
                            this.onChangePINValue(e.target.value)
                          }
                          value={daviplataPin}
                        />
                      </Box>

                      <div className="row button-center marginTop">
                        <Button
                          className="btnPayment"
                          style={{
                            "--backgroundButton": headerHomeStyle,
                            "--borderColorButton": borderColorButton,
                            "--borderColorButtonHover": borderColorButtonHover,
                            "--colorText": colorText,
                          }}
                          onClick={(e) => this.onConfirmPINdaviplata(e)}
                        >
                          {Strings.basicWords.continue}
                        </Button>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            )}

            {openForm ? (
              <DonationForm
                theme={theme}
                lpId={lpId}
                PAYMENT_PLATFORM_ID={this.state.payment.paymentPlatformId}
                paymentValue={payment.value}
                payment={payment}
                setChange={this.changePaymentState}
                errorMsg={this.state.errorMsg}
                modalStyle={style}
                platformName={platformName}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default PaymentMethods;
