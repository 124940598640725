import React, { Component } from "react";
import "../../stylesLP.css";
import { StandardSocialNetworksLayout } from "./StandardSocialNetworksLayout.jsx";

class SocialNetworksLayoutIndex extends Component {
  renderGalleryComponent() {
    let renderedComponent = [];
    switch (this.props.layout) {
      case 0:
        renderedComponent.push(
          <StandardSocialNetworksLayout
            key={Math.random() * 1000}
            theme={this.props.theme}
            SocialNetworksTitle={this.props.SocialNetworksTitle}
            SocialNetworksFacebookUrl={this.props.SocialNetworksFacebookUrl}
            SocialNetworksInstagramPost={this.props.SocialNetworksInstagramPost}
            SocialNetworksAurapp={this.props.SocialNetworksAurapp}
            SocialNetworksTwitterUsername={
              this.props.SocialNetworksTwitterUsername
            }
            urlCut={this.props.urlCut}
            renderPostComponents={this.props.renderPostComponents}
          />
        );
        break;
    }
    return renderedComponent;
  }

  render() {
    return <div>{this.renderGalleryComponent()}</div>;
  }
}
export default SocialNetworksLayoutIndex;
