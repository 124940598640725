import React, { Component } from "react";
import { Redirect } from "react-router-dom";

import AboutSection from "./about/aboutIndex";
import EventsSection from "./events/eventsIndex";
import NewsSection from "./news/newsIndex";
import TeamSection from "./team/teamIndex";
import CoursesSection from "./courses/coursesIndex";
import GallerySection from "./gallery/galleryIndex";
import ContactSection from "./contact/contactSection";
import DonationsSection from "./donations/donationsIndex.jsx";
import SocialNetworksSection from "./socialNetworks/socialNetworksIndex";
import LinktreeSection from "./linktree/linkTreeIndex.jsx";
import StreamingSection from "./streaming/streamingsIndex.jsx";

import "./stylesLP.css";
var subpageTitle = null;

class Subpage extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  renderSubpageSections = () => {
    let lpSubpagesInfo = this.props.subpagesInfo;
    //Find selected lpsubpage
    let subpageInfo = lpSubpagesInfo.find(
      (s) =>decodeURIComponent(s.name.trim()) === decodeURIComponent(this.props.subpage.trim()) ); //.trim() para eliminar espacios al inicio y al final
    let subpageSectionsInfo = subpageInfo ? subpageInfo.sections : null;

    let subpageSections = [];
    function compare(a, b) {
      if (a.positionsInSubpage > b.positionsInSubpage) {
        return 1;
      }
      if (b.positionsInSubpage > a.positionsInSubpage) {
        return -1;
      }
      return 0;
    }

    subpageSectionsInfo.sort(compare)

    if(subpageSectionsInfo){
      subpageTitle = decodeURIComponent(subpageInfo.name.trim());

      for (var i = 0; i < subpageSectionsInfo.length; i++) {
        switch (subpageSectionsInfo[i].name) {
          case "ABOUT":
            subpageSections.push(
              <AboutSection
                key={i}
                lpId={this.props.lpId}
                lpSectionId={subpageSectionsInfo[i].id}
                theme={this.props.theme ? this.props.theme : null}
                isSubpageFlag={true}
              />
            );
            break;
          case "EVENTS":
            subpageSections.push(
              <EventsSection
                key={i}
                lpId={this.props.lpId}
                lpSectionId={subpageSectionsInfo[i].id}
                theme={this.props.theme ? this.props.theme : null}
                isSubpageFlag={true}
              />
            );
            break;
          case "NEWS":
            subpageSections.push(
              <NewsSection
                key={i}
                lpId={this.props.lpId}
                lpSectionId={subpageSectionsInfo[i].id}
                theme={this.props.theme ? this.props.theme : null}
                isSubpageFlag={true}
              />
            );
            break;
          case "TEAM":
            subpageSections.push(
              <TeamSection
                key={i}
                lpId={this.props.lpId}
                lpSectionId={subpageSectionsInfo[i].id}
                theme={this.props.theme ? this.props.theme : null}
                isSubpageFlag={true}
              />
            );
            break;
          case "COURSES":
            subpageSections.push(
              <CoursesSection
                key={i}
                lpId={this.props.lpId}
                lpSectionId={subpageSectionsInfo[i].id}
                theme={this.props.theme ? this.props.theme : null}
                isSubpageFlag={true}
              />
            );
            break;
          case "GALLERY":
            subpageSections.push(
              <GallerySection
                key={i}
                lpId={this.props.lpId}
                lpSectionId={subpageSectionsInfo[i].id}
                theme={this.props.theme ? this.props.theme : null}
                isSubpageFlag={true}
              />
            );
            break;
          case "LINKTREE":
            subpageSections.push(
              <LinktreeSection
                key={i}
                lpId={this.props.lpId}
                lpSectionId={subpageSectionsInfo[i].id}
                theme={this.props.theme ? this.props.theme : null}
                isSubpageFlag={true}
              />
            );
            break;
          case "SOCIALNETWORKS":
            subpageSections.push(
              <SocialNetworksSection
                key={i}
                lpId={this.props.lpId}
                lpSectionId={subpageSectionsInfo[i].id}
                theme={this.props.theme ? this.props.theme : null}
                isSubpageFlag={true}
              />
            );
            break;
          case "STREAMING":
            subpageSections.push(
              <StreamingSection
                key={i}
                lpId={this.props.lpId}
                lpSectionId={subpageSectionsInfo[i].id}
                theme={this.props.theme ? this.props.theme : null}
                isSubpageFlag={true}
              />
            );
            break;
          case "CONTACT":
            subpageSections.push(
              <ContactSection
                key={i}
                lpId={this.props.lpId}
                lpSectionId={subpageSectionsInfo[i].id}
                theme={this.props.theme ? this.props.theme : null}
                isSubpageFlag={true}
              />
            );
            break;
          case "DONATIONS":
            subpageSections.push(
              <DonationsSection
                key={i}
                lpId={this.props.lpId}
                lpSectionId={subpageSectionsInfo[i].id}
                theme={this.props.theme ? this.props.theme : null}
                isSubpageFlag={true}
              />
            );
        }
      }
    }

    if (subpageSections.length > 0 || subpageSectionsInfo ) {
      return subpageSections;
    } else {
      return <Redirect to="/" />;
    }
  };

  render() {
    const { theme, headerLayout } = this.props;

    return (
      <div>
        {/* <div className="containerAux">
          <div className="row">
            <div
              className="subpage-title col-xs-12 col-md-12 spaceLayout1 wow fadeIn"
              data-wow-duration="1s"
            >
              <h1
                style={theme && theme.sectionTitle ? theme.sectionTitle : null}
              >
                {subpageTitle}
              </h1>
            </div>
          </div>
        </div> */}

        <div> 
          <div style={{ marginTop: '8vh', marginBottom: '8vh' }}>{this.renderSubpageSections()}</div>
        </div>
      </div>
    );
  }
}
export default Subpage;
