import React, { Component } from "react";
import "../../stylesLP.css";
import { StandarLinktreeLayout } from "./StandarLinktreeLayout.jsx";
import { LinearButtonsLinktreeLayout } from "./LinearButtonsLinktreeLayout.jsx";

class LinktreeLayoutIndex extends Component {
  renderLinktreeComponent() {
    let renderedComponent = [];
    switch (this.props.layout) {
      case 0:
        renderedComponent.push(
          <StandarLinktreeLayout
            // key={this.props.key}
            theme={this.props.theme}
            containerTitle={this.props.containerTitle}
            backImg={this.props.backImg}
            backColor={this.props.backColor}
            linkTreeNewTabFlag={this.props.linkTreeNewTabFlag}
            buttons={this.props.buttons}
            buttonsColor={this.props.buttonsColor}
            layout={this.props.layout}
          />
        );
        break;
        case 1:
          renderedComponent.push(
            <LinearButtonsLinktreeLayout
              // key={this.props.key}
              theme={this.props.theme}
              containerTitle={this.props.containerTitle}
              backImg={this.props.backImg}
              backColor={this.props.backColor}
              linkTreeNewTabFlag={this.props.linkTreeNewTabFlag}
              buttons={this.props.buttons}
              buttonsColor={this.props.buttonsColor}
              layout={this.props.layout}
            />
          );
          break;
    }
    return renderedComponent;
  }

  render() {
    return <div>{this.renderLinktreeComponent()}</div>;
  }
}
export default LinktreeLayoutIndex;
