import React, { Component } from "react";
import { Box, CircularProgress, Typography } from "@material-ui/core";
import { Pagination } from "@mui/material";
import moment from "moment";
import {
  getDonationSectionByActiveState,
  getFilePath,
} from "../../../actions/index";
import LayoutIndex from "../genericLayout/newsEventsCourses/layoutStyles/LayoutIndex.jsx";
import { backendDateFormat } from "../../../reactUtils/systemVariables/generalVariables";

import "./index.css";

class DonationsSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: moment(),
      endDate: moment().add(2, "months"),
      pageIdx: 1,
      pageSize: 4,
      totalElements: 0,
      events: [],
      eventsToShow: [],
    };
  }

  componentDidMount() {
    this.getDonationsInformation();
  }

  async getDonationsInformation() {
    let response = await getDonationSectionByActiveState(
      this.props.lpId,
      this.props.lpSectionId,
      1
    );
    let sectionInfo = response.data.data;

    let donationContainerComponent = sectionInfo.components.find(
      (comp) => comp.name == "DonationsContainer"
    );
    let donationsId = sectionInfo.id;
    let donationsContainerTitle = donationContainerComponent.nodes.find(
      (node) => node.nodeType == "DonationsContainerTitle"
    ).value;
    let donations = sectionInfo.components.filter(
      (comp) => comp.name == "DonationComponent"
    );
    donations = donations.sort(this.compareDonationDates);
    let totalElements = donations.length;

    this.setChildComponentsInfoPaged(
      donations,
      this.state.pageIdx,
      this.state.pageSize
    );
    let layout = sectionInfo.lpSectionLayoutStyleId;
    if (layout === 12) {
      //standar donations layout
      layout = 0;
    }
    if (layout === 26) {
      //donations image left layout
      layout = 1;
    }
    if (layout === 27) {
      //donations display grid layout
      layout = 2;
    }
    if(process.env.REACT_APP_DEFAULT_LAYOUT_DONATIONS){
      layout = parseInt(process.env.REACT_APP_DEFAULT_LAYOUT_DONATIONS);
    }

    this.setState({
      donationsId,
      donationsContainerTitle,
      donations,
      totalElements,
      layout,
    });
  }

  async setChildComponentsInfoPaged(donations, pageIdx, pageSize) {
    let donationsToShow = [];
    for (
      var i = (pageIdx - 1) * pageSize;
      i < pageIdx * pageSize && i < donations.length;
      i++
    ) {
      if (!donations[i].img) {
        let imgNode = donations[i].nodes.find(
          (node) => node.nodeType === "DonationComponentImage"
        );
        if (imgNode && imgNode.value != null && imgNode.value !== "") {
          //Get image or video
          let multimedia = [];

          if (!imgNode.value.includes("https://")) {
            // checks if it's an uploaded image
            let imgName = imgNode.value
              .replace(".jpeg", "")
              .replace(".jpg", "")
              .replace(".png", "");
            let format = imgNode.value.match(/\.([^.]*)$/)[1];
            let imgS = await getFilePath(imgName, "smallSize", format);
            let imgM = await getFilePath(imgName, "mediumSize", format);
            let imgL = await getFilePath(imgName, "largeSize", format);
            multimedia.push("isUploadedImage")
            multimedia.push(imgS);
            multimedia.push(imgM);
            multimedia.push(imgL);
          } else if (imgNode.value.includes("https://www.youtube.com/watch?")) {
            // checks if it's video or image link
            let idVideo = getVideoId(imgNode.value);
            multimedia.push("isVideo")
            multimedia.push(idVideo.id);
          } else {
            multimedia.push("isLinkedImage")
            multimedia.push(imgNode);
          }
          donations[i].multimedia = multimedia;
        }
      }
      donationsToShow.push(donations[i]);
    }
    this.setState({ donationsToShow });
  }

  onPaginationChange = (pageIdx, pageSize) => {
    this.setState({ pageIdx, pageSize, donationsToShow: [] });
    this.setChildComponentsInfoPaged(this.state.donations, pageIdx, pageSize);
  };

  compareDonationDates(a, b) {
    let aDateStr = a.nodes.find(
      (node) => node.nodeType === "DonationComponentEndDate"
    ).value;
    let bDateStr = b.nodes.find(
      (node) => node.nodeType === "DonationComponentEndDate"
    ).value;
    let aDate = moment(aDateStr, backendDateFormat);
    let bDate = moment(bDateStr, backendDateFormat);
    if (bDate.isAfter(aDate)) {
      return -1;
    }
    if (aDate.isAfter(bDate)) {
      return 1;
    }
    return 0;
  }

  render() {
    const { theme, lpId } = this.props;
    let colorSpin = "#008cba";
    if (theme != null) {
      colorSpin = theme.sectionTitle.color;
    }

    return (
      <div id="features">
        <section id={"donations" + this.state.donationsId}>
          <div className="containerAux">
            <div className="row">
              <div className="col-xs-12 col-md-12">
                <div className="section-header wow fadeIn" data-wow-duration="1s" >
                  <h1 style={ theme && theme.sectionTitle ? theme.sectionTitle : null }>
                    {this.state.donationsContainerTitle}
                  </h1>
                  <span className="section-divider"></span>
                </div>
              </div>
            </div>
            <div className="row">
              {this.state.donationsToShow === 0 ? (
                <Box>
                  <CircularProgress color={colorSpin} />
                  <Typography>{Strings.generalMessages.loading}</Typography>
                </Box>
              ) : (
                <div>
                  {this.state.layout === 3 ? (
                    <LayoutIndex
                      components={this.state.donationsToShow}
                      layout={this.state.layout}
                      theme={theme ? theme : null}
                      containerTitle={this.state.donationsContainerTitle}
                      donations={true}
                      lpId={lpId}

                    />
                  ) : (
                    <div className="col-xs-12 col-md-12">
                      <LayoutIndex
                      components={this.state.donationsToShow}
                      layout={this.state.layout}
                      theme={theme ? theme : null}
                      containerTitle={this.state.donationContainerTitle}
                      donations={true}
                      lpId={lpId}

                    />
                      <br />
                      <div className="row">
                        <div className="col-12 d-flex justify-content-center">
                          {
                            Math.ceil(this.state.totalElements / this.state.pageSize) > 1 ?
                            <Pagination
                              size="small"
                              onChange={(event, value) => {
                                this.onPaginationChange(value, this.state.pageSize)
                              }}
                              count={ Math.ceil(this.state.totalElements / this.state.pageSize) }
                              page={this.state.pageIdx}
                            />
                            : null
                          }
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
            <br />
          </div>
        </section>
      </div>
    );
  }
}

export default DonationsSection;
