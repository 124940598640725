import React from "react";
import "../index.scss";
import imgNull from "../../../../images/noImage.jpg";
import { Tooltip } from "@material-ui/core";

export function StandarGalleryLayoutStyle(props) {
  const { id, note, image, text } = props;

  return (
    <div className="centerImage">
      <div className="boxStandar" >
        <Tooltip title={note + ".\n Autor: " + text} arrow>
          <img src={ image != "https://somosaura-cms.s3.amazonaws.com/" ? props.image : imgNull }key={id} alt="gallery" />
        </Tooltip>
      </div>
    </div>
  );
}
