import React from "react";
import ShowMoreText from "react-show-more-text";
import {
  VerticalAlignTopOutlined,
  StepForwardOutlined,
} from "@ant-design/icons";

export function TeamMoveDiagonallyLayout(props) {
  const { id, theme, name, image, text, position } = props;
  let widthHeigth = "250px";
  return (
    <div className="wow fadeInRight" key={id}>
      <div className="col-xs-12 col-md-4 col-lg-4">
        <div
          className="card3"
          style={{
            "--widthHeigth": widthHeigth,
          }}
        >
          <div className="imgBx3">
            <img src={image} alt="member team" />
          </div>
          <div className="content3">
            <h4
              style={
                theme && theme.sectionSubtitle ? theme.sectionSubtitle : null
              }
            >
              {name}
            </h4>
            <p style={theme && theme.sectionText1 ? theme.sectionText1 : null}>
              <ShowMoreText
                lines={6}
                more={
                  <span id="moretext">
                    <StepForwardOutlined />
                  </span>
                }
                less={
                  <span id="moretext">
                    <VerticalAlignTopOutlined />
                  </span>
                }
                expanded={false}
              >
                <p id="textDescription">{text}</p>
              </ShowMoreText>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
