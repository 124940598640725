import React, { Component, Fragment } from "react";

import { connect } from "react-redux";
import { Route, Switch } from "react-router";
import { get as _get } from "lodash";
import Header from "./header/headerIndex";
import Footer from "./footer/footerIndex";
import MainPage from "./MainPage.jsx";
import Subpage from "./Subpage.jsx";
import ComponentPage from "./ComponentPage.jsx";
import PaymentMethods from "./donations/paymentMethods/PaymentMethods.jsx";
import DonationSucess from "./donations/DonationSucess.jsx";
import DonationCancel from "./donations/DonationCancel";
import GospelPage from "./gospel/GospelPage.jsx";
import ContactSection from "./contact/contactSection";
import { ThemeFunction } from "../../reactUtils/generalUtils/themeFunction.jsx";
import "./stylesLP.css";
import "../../assets/fonts/fonts.scss";
import {
  getLpSectionsOrder,
  getLpIdByUrl,
  getLPStyleAttributesByLpId,
  getLpSectionInformation,
} from "../../actions/index";

let theme = null;

class LandingPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lpId: 1,
      sections: null,
      sectionsInfo: null,
      subpagesInfo: null,
      theme: null,
      isSubpageFlag: false,
    };
  }

  componentDidMount() {
    //Query id by url
    this.getLpIdByUrl();
  }

  async getLpIdByUrl() {
    let url = this.props.lpUrl;
    let lpId = 73; //Default query value for localhost connection
    let isTabella = false; // debe ser false, solo true para dev de features para tabella

    if (!url.includes("localhost")) {
      let response = await getLpIdByUrl(url);
      lpId = _get(response, "data.data.id", response.data.data);
    }
    if (url.includes("tabella")) {
      isTabella = true;
    }

    //Query lp theme by id
    this.getTheme(lpId);

    //Query enabled sections and their order
    this.getSectionsOrder(lpId);

    this.setState({ lpId, isTabella });
  }

  async infoHeader(lpId, headerId) {
    let response = await getLpSectionInformation(lpId, headerId, "HEADER");
    let sectionInfo = response.data.data;
    let layout = sectionInfo.lpSectionLayoutStyleId;
    if (layout == 16) {
      // Header logo layout
      layout = 1;
    } else if (layout === 80) {
      layout = 2;
    } else {
      layout = 0;
    }
    this.setState({ headerLayout: layout });
  }

  async getTheme(lpId) {
    let response = await getLPStyleAttributesByLpId(lpId);

    if (response) {
      if (
        String(response).includes("Error:") ||
        response.data.data.length == 0
      ) {
        this.setState({ theme: null });
      } else {
        this.setState({ theme: ThemeFunction(response.data.data) });
      }
    }
  }

  async getSectionsOrder(lpId) {
    let response = await getLpSectionsOrder(lpId);
    let sectionsInfo = response.data.data;

    let sections = [];
    let subpagesWithSections = [];
    let subpagesToShow = [];

    let subpages = sectionsInfo.lpSubpages;
    let groupSubpages = sectionsInfo.lpSubpageGroups;
    groupSubpages.forEach((group, index) => {
      {
        //Find subpage into group subpages
        var sectionsGroupSubpages = [];
        subpages.forEach((subpage) => {
          if (
            subpage.subpageGroupId != null &&
            subpage.subpageGroupId === group.id &&
            subpage.isVisible === 1
          ) {
            subpage.positionInHeader = null;
            sectionsGroupSubpages[sectionsGroupSubpages.length] = subpage;
            groupSubpages[index].sections = sectionsGroupSubpages;
          }
        });
      }
    });

    subpages.forEach((subpage) => {
      //If subpage is not into group subpage
      subpagesToShow.push(subpage);
      if (subpage.isEnabled === 1) {
        subpagesWithSections.push(subpage);
        let subpageExists = [];
        subpageExists = subpagesWithSections.find(
          (item) => item.id === subpage.id
        );
        if (!subpage.sections) {
          subpage.sections = [];
        }
        if (subpageExists && subpageExists.length === 0) {
          subpagesWithSections.push(subpage);
        }
      }
    });

    sectionsInfo.lpsections.forEach((section, indexSection) => {
      //Find sections in mainpage
      if (
        section.mustBeSubpage != 1 &&
        section.subpages.length === 0 &&
        section.positionInMainPage != null
      ) {
        sections.push(section);
      } else {
        if (section.subpages.length > 0) {
          //   //If have a subpage asociated
          subpagesWithSections.forEach((subpage, index) => {
            section.subpages.forEach((idAsociatedSection, index2) => {
              let sectionSubpages = [];
              if (subpage.id === idAsociatedSection) {
                sectionSubpages.push(section);
              }
              if (sectionSubpages.length > 0) {
                if (section.positionsInSubpage === null) {
                } else {
                  section.positionsInSubpage =
                    section.positionsInSubpage[index2];
                }
                subpagesWithSections[index].sections[
                  subpagesWithSections[index].sections.length
                ] = section;
              }
            });
          });
        }
      }
    });

    subpagesWithSections.forEach((subpage) => {
      if (subpage.sections.length > 0 && subpage.isVisible == 1) {
        subpagesToShow.push(subpage);
      }
    });

    var positionSections = sections.map(
      (element) => element.positionInMainPage
    );
    const lastSectionPosition = Math.max.apply(null, positionSections);
    this.setState({ posFooter: lastSectionPosition });
    sections = sections.sort(compare);
    let ref = null;
    let indexRef = [];
    sections.forEach((element, index) => {
      sections[index].positionInMainPage = index;
      sections[index].ref = index;
      ref = index;
      indexRef.push(ref);
    });

    function compare(a, b) {
      if (a.positionInMainPage === null) {
        a.positionInMainPage = lastSectionPosition + 2;
      }
      if (a.name === "FOOTER") {
        a.positionInMainPage = lastSectionPosition + 2;
      }
      if (b.name === "FOOTER") {
        b.positionInMainPage = lastSectionPosition + 2;
      }
      if (a.name === "CONTACT") {
        a.positionInMainPage = lastSectionPosition + 1;
      }
      if (b.name === "CONTACT") {
        b.positionInMainPage = lastSectionPosition + 1;
      }
      if (a.positionInMainPage > b.positionInMainPage) {
        return 1;
      }
      if (b.positionInMainPage > a.positionInMainPage) {
        return -1;
      }
      return 0;
    }

    function compareSubpageAndGroupSubpage(a, b) {
      if (a.positionInHeader > b.positionInHeader) {
        return 1;
      }
      if (b.positionInHeader > a.positionInHeader) {
        return -1;
      }
      return 0;
    }

    function compareSubapageInGroupSubpage(a, b) {
      if (a.positionInSubpageGroup > b.positionInSubpageGroup) {
        return 1;
      }
      if (b.positionInSubpageGroup > a.positionInSubpageGroup) {
        return -1;
      }
      return 0;
    }

    function compareSectionsInSubpage(a, b) {
      if (a.positionsInSubpage > b.positionsInSubpage) {
        return 1;
      }
      if (b.positionsInSubpage > a.positionsInSubpage) {
        return -1;
      }
      return 0;
    }

    ref = ref + 1;
    subpagesToShow = subpagesToShow.sort(compareSubpageAndGroupSubpage);
    subpagesToShow.forEach((element, index) => {
      if (element.isVisible == 1 && element.sections.length > 0) {
        //add subpages enable
        if (!subpagesToShow[index].sections) {
          subpagesToShow[index].sections = [];
        }
        subpagesToShow[index].sections.forEach((element, auxRef) => {
          subpagesToShow[index].sections[auxRef].ref = ref + auxRef;
          indexRef.push(subpagesToShow[index].sections[auxRef].ref);
        });
        subpagesToShow[index].style = "subpage";
        if (subpagesToShow[index].sections) {
          subpagesToShow[index].sections = subpagesToShow[index].sections.sort(
            compareSectionsInSubpage
          );
        } else {
          groupSubpages[index].sections = [];
        }
        ref = ref + 1;
      }
    });
    ref = Math.max(...indexRef) + 1;
    groupSubpages = groupSubpages.sort(compareSubpageAndGroupSubpage);
    groupSubpages.forEach((element, index) => {
      //add group subpages enable
      if (
        element.isEnabled === 1 &&
        element.sections &&
        element.sections.length > 0
      ) {
        //Validate if at least one subpage has sections
        element.sections.forEach((subpage) => {
          if (subpage.sections.length > 0) {
            groupSubpages[index].style = "groupSubpage";
            if (groupSubpages[index].sections) {
              groupSubpages[index].sections = groupSubpages[
                index
              ].sections.sort(compareSubapageInGroupSubpage);
            } else {
              groupSubpages[index].sections = [];
            }
            groupSubpages[index].sections.forEach((element, auxRef) => {
              groupSubpages[index].sections[auxRef].ref = ref + auxRef;
              indexRef.push(groupSubpages[index].sections[auxRef].ref);
            });
          }
        });
      }
    });

    //Set header, contact and footer ids
    let header = sections.find((s) => s.name === "HEADER");
    let headerId = header && header.id ? header.id : null;
    let contact = sections.find((s) => s.name === "CONTACT");
    let contactId = contact && contact.id ? contact.id : null;
    let footer = sections.find((s) => s.name === "FOOTER");
    let footerId = footer && footer.id ? footer.id : null;
    this.infoHeader(lpId, headerId);
    this.setState({
      sectionsInfo,
      subpagesInfo: subpagesToShow,
      groupSubpagesInfo: groupSubpages,
      headerId,
      contactId,
      footerId,
    });
  }

  renderSubpage = (routerProps) => {
    const {
      lpId,
      sectionsInfo,
      theme,
      isSubpageFlag,
      subpagesInfo,
      headerLayout,
    } = this.state;
    let subpage = window.location.href
      .substring(window.location.origin.length + 1, window.location.href.length)
      .replace("community/", ""); // to get only subpage name

    if (isSubpageFlag == false) {
      this.setState({ isSubpageFlag: true });
    }
    if (subpage == "Evangelio" || subpage == "evangelio") {
      return <GospelPage lpId={lpId} theme={theme} />;
    }

    if (subpage && subpage !== "Evangelio" && subpage !== "evangelio") {
      return (
        <Subpage
          lpId={lpId}
          sectionsInfo={sectionsInfo}
          subpagesInfo={subpagesInfo}
          subpage={subpage}
          theme={theme}
          key={lpId}
          headerLayout={headerLayout}
        />
      );
    }
  };

  renderComponentPage = (routerProps) => {
    const { lpId, theme, isSubpageFlag } = this.state;

    if (isSubpageFlag == false) {
      this.setState({ isSubpageFlag: true });
    }

    return (
      <ComponentPage
        lpId={lpId}
        componentId={parseInt(routerProps.match.params.id)}
        theme={theme}
      />
    );
  };

  renderPaymentPlatform = (routerProps) => {
    const { lpId, isSubpageFlag, theme, headerId } = this.state;

    if (isSubpageFlag == false) {
      this.setState({ isSubpageFlag: true });
    }
    return (
      <PaymentMethods
        headerId={headerId}
        lpId={lpId}
        componentId={parseInt(routerProps.match.params.id)}
        theme={theme}
      />
    );
  };

  renderPaymentSuccess = () => {
    const { isSubpageFlag, theme } = this.state;

    if (isSubpageFlag == false) {
      this.setState({ isSubpageFlag: true });
    }

    return <DonationSucess theme={theme} />;
  };

  renderPaymentCancel = () => {
    const { isSubpageFlag, theme } = this.state;

    if (isSubpageFlag == false) {
      this.setState({ isSubpageFlag: true });
    }

    return <DonationCancel theme={theme} />;
  };

  renderGospelPage = () => {
    const { lpId, theme, isSubpageFlag } = this.state;

    if (isSubpageFlag == false) {
      this.setState({ isSubpageFlag: true });
    }

    return <GospelPage lpId={lpId} theme={theme} />;
  };

  getContentHeight = () => {
    let headerHeight = 200;
    let footerHeight = 106;
    return window.innerHeight - footerHeight;
  };

  render() {
    const {
      lpId,
      sectionsInfo,
      theme,
      isSubpageFlag,
      subpagesInfo,
      groupSubpagesInfo,
      headerId,
      contactId,
      footerId,
      isTabella,
    } = this.state;
    return (
      <div className="LandingPage" key={lpId}>
        {sectionsInfo ? (
          <Fragment>
            <Header
              lpId={lpId}
              key={lpId}
              sectionsInfo={sectionsInfo}
              subpagesInfo={subpagesInfo}
              groupSubpagesInfo={groupSubpagesInfo}
              theme={theme ? theme : null}
              isSubpageFlag={isSubpageFlag}
              lpSectionId={headerId}
              isTabella={isTabella}
            />
            <div id="content">
              {/* <div id="content"> */}

              {/* //quitar esto y solucionar vista de componetne individual */}
              <Switch>
                <Route
                  exact
                  path="/"
                  render={(props) => (
                    <MainPage
                      {...props}
                      lpId={lpId}
                      sectionsInfo={sectionsInfo}
                      theme={theme}
                    />
                  )}
                />
                <Route
                  exact
                  path="/:subpage"
                  render={this.renderSubpage}
                  headerId={headerId}
                />
                <Route
                  exact
                  path="/component/:id"
                  render={this.renderComponentPage}
                />
                <Route
                  exact
                  path="/payment/:id"
                  render={this.renderPaymentPlatform}
                />
                <Route
                  exact
                  path="/payments/success"
                  render={this.renderPaymentSuccess}
                />
                <Route
                  exact
                  path="/payments/cancel"
                  render={this.renderPaymentCancel}
                />
                {/* Tabella only */}
                <Route
                  exact
                  path="/community/:subpage"
                  render={this.renderSubpage}
                  headerId={headerId}
                />
                <Route
                  exact
                  path="/community/:subpage/payment/:id"
                  render={this.renderPaymentPlatform}
                  headerId={headerId}
                />
              </Switch>
            </div>
            {isTabella == false ? (
              <div style={{background: "aquamarine",  marginTop: "auto"}}
              
    
          >
                <ContactSection
                  lpId={lpId}
                  theme={theme ? theme : null}
                  lpSectionId={contactId}
                />
                <Footer
                  lpId={lpId}
                  theme={theme ? theme : null}
                  lpSectionId={footerId}
                />
              </div>
            ) : null}
          </Fragment>
        ) : (
          ""
        )}
      </div>
    );
  }
}

//export default LandingPage;

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps, {})(LandingPage);
