import axios from "axios";
import { host, port, route, s3Bucket } from '../reactUtils/systemVariables/lpserverInformation';

export async function getFilePath(key, fileSize, format) {
   try {
      let response = "https://somosaura-cms-resized.s3.amazonaws.com/" + key + "_" + fileSize + "." + format
      return response;
   } catch (error) {
      return error;
   }
}