import React from "react";
import { Link } from "react-router-dom";
import imageNull from "../../../../../images/noImage.jpg";
var dateStartTime = "MMMM DD, YYYY";
import moment from "moment";

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

import "./index.scss";

export function Layout5(props) {
  const id = props.id;
  const theme = props.theme;

  let multimedia = null;
  let idVideo = null;
  if (props.multimedia && props.multimedia[0] == "isUploadedImage") {
    multimedia = props.multimedia[3]; //large Size
    if(window.innerWidth < 900) { // mobile 
      multimedia = props.multimedia[2]; //medium Size
    }
  } else if (props.multimedia && props.multimedia[0] == "isVideo") {
    idVideo = props.multimedia[1];
  } else if (props.multimedia && props.multimedia[0] == "isLinkedImage") {
    multimedia = props.multimedia[1].value;
  }

  var event = {};
  if (props.events) {
    event = {
      title: props.title,
      description: props.text,
      startTime: moment(props.date).format(dateStartTime),
      endTime: moment(props.date).add(1, "hours").format(dateStartTime),
      location: props.place,
    };
  }
  let display = "flex";
  if (props.individualStyle) {
    display = "block";
  }

  let buttonLinkPath = "/component/"
  if(props.donations){
    buttonLinkPath = "/payment/"
  }

  return (
    <div className="cardList" >
      <Card
        hoverable
        style={{
          borderStyle: "outset",
          width: "100%",
          height: "100%",
          whiteSpace: "break-spaces",
          textAlign: "center",
          minHeight: '530px'
        }}
      >
        <Link to={{ pathname: buttonLinkPath + id }}>
          <CardMedia
            className="imgNews5"
            image={ multimedia === null ? imageNull : multimedia }
            title="image"
          />
        </Link>
        <CardContent>
          <div>
            <Link to={{ pathname: buttonLinkPath + id }}>
              <h3
                className={"titleLayout5"}
                style={
                  theme && theme.sectionSubtitle
                    ? theme.sectionSubtitle
                    : null + props.individualStyle &&
                      props.individualStyle.title
                    ? props.individualStyle.title
                    : null
                }
              >
                {props.title}
              </h3>
            </Link>
            <br />
            <p
              style={
                theme && theme.sectionText1
                  ? theme.sectionText1
                  : null + props.individualStyle && props.individualStyle.text
                  ? props.individualStyle.text
                  : null
              }
              className="descriptionLayout5"
            >
              {props.text}
            </p>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
