import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { get as _get } from "lodash"
// analitic
import analitic from "./../../reactUtils/helpers/analitics"
// imput
import LandingPage from "./LandingPage";

// request
import { getLpIdByUrl } from "../../actions/index";

const container = (props) => {
  const location = useLocation();

  useEffect(() => {
    const load = async () => {
      if (!window.location.href.includes("localhost")) {
        
        let result = await getLpIdByUrl(props.lpUrl)
        let tagcode = _get(result, 'data.data.analyticsId', null)
        if(tagcode) {
          analitic.initialize(tagcode)
        }
      }
    }
    load()
  }, []);

  useEffect(() => {
    analitic.setPage(window.location.pathname)
  }, [ location ]);

  return (
    <LandingPage {...props} />
  )
}

export default container;