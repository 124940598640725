import React, { Component } from "react";
import { get as _get } from "lodash";
import { CheckCircleFilled, HomeOutlined } from "@ant-design/icons";

// Styles
import "./paymentMethods/payments.css";
import "./index.css";
import Button from "@mui/material/Button";

//Localized
import Strings from "../../../reactUtils/systemVariables/languageLocalization/LocalizedStrings.jsx";

class DonationSucess extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    const { lpId, theme } = this.props;
    let headerHomeStyle = theme ? theme.headerBackground.fixed : null;
    let borderColorButton = theme ? theme.sectionTitle.color : null;
    let borderColorButtonHover = theme ? theme.sectionSubtitle.color : null;
    let fontFamily = theme ? theme.headerTitles.fontFamily : null;
    let colorText = "white";

    this.setState({
      lpId,
      headerHomeStyle,
      borderColorButton,
      borderColorButtonHover,
      fontFamily,
      colorText,
    });
  }

  printInfo = () => {
    window.print();
  };

  goToHome = () => {
    window.location.replace(window.location.origin);
  };

  render() {
    const theme = this.props.theme;
    // gross - fee = neto que se recibe
    // let fee_amount = ""; // monto de paypal
    // let gross_amount = ""; // total monto
    // let send_account_name = ""; // quien envia
    // let received_account_name = ""; //quien recibe
    // let currency = "";
    // let finalPaymentValue = "";
    // let create_time = "";
    // let update_time = "";
    // let search = window.location.search;
    // let params = new URLSearchParams(search);
    // for (const [key, value] of params) {
    //   if (key == "fee_amount") fee_amount = value;
    //   if (key == "gross_amount") gross_amount = value;
    //   if (key == "send_account_name") send_account_name = value;
    //   if (key == "received_account_name") received_account_name = value;
    //   if (key == "currency") currency = value;
    //   if (key == "create_time") create_time = value;
    // }
    // finalPaymentValue = gross_amount - fee_amount;
    // send_account_name = send_account_name.replace("_", " ");
    // received_account_name = received_account_name.replace("_", " ");

    return (
      <div className="containerPaymentsSuccess">
        <div id="features" className="content">
          <div className="marginTop center">
            <div className="payment-success">
              <div className="payment-title">
                <CheckCircleFilled
                  style={{ fontSize: 50, color: "green", margin: "10px" }}
                />
                <h6
                  style={
                    theme && theme.sectionSubtitle
                      ? theme.sectionSubtitle
                      : null + this.props &&
                        this.props.individualStyle &&
                        this.props.individualStyle.title
                      ? this.props.individualStyle.title
                      : null
                  }
                  className="titleDonationSuccess"
                >
                  ¡Transacción exitosa!
                </h6>
              </div>
              <div className="form-container">
                <div className="row button-center marginTop zIndexOne">
                  <Button
                    className="btnPayment"
                    style={{
                      "--backgroundButton": this.state.headerHomeStyle,
                      "--borderColorButton": this.state.borderColorButton,
                      "--borderColorButtonHover":
                        this.state.borderColorButtonHover,
                      "--colorText": this.state.colorText,
                    }}
                    onClick={() => this.goToHome()}
                  >
                    {Strings.sections.home} <HomeOutlined color="white" />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DonationSucess;
