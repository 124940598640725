import React, { Component } from "react";
import "../../stylesLP.css";
import { StandarGalleryLayoutStyle } from "./StandarGalleryLayoutStyle.jsx";
import { GalleryImageMoveLayout } from "./GalleryImageMoveLayout.jsx";
import { GalleryCarouselLayout } from "./GalleryCarouselLayout.jsx";
import { GalleryBlackWhiteLayout } from "./GalleryBlackWhiteLayout.jsx";
import { GalleryOpenImageLayout } from "./GalleryOpenImageLayout.jsx";

class GalleryLayoutIndex extends Component {
  renderGalleryComponent() {
    let renderedComponent = [];
    switch (this.props.layout) {
      case 0:
        renderedComponent.push(
          <StandarGalleryLayoutStyle
            key={this.props.id}
            id={this.props.id}
            note={this.props.note}
            date={this.props.date}
            text={this.props.text}
            image={this.props.image}
            theme={this.props.theme}
          />
        );
        break;
      case 1:
        renderedComponent.push(
          <GalleryImageMoveLayout
            key={this.props.id}
            id={this.props.id}
            note={this.props.note}
            date={this.props.date}
            text={this.props.text}
            image={this.props.image}
            theme={this.props.theme}
          />
        );
        break;
      case 2:
        renderedComponent.push(
          <GalleryBlackWhiteLayout
            key={this.props.id}
            id={this.props.id}
            note={this.props.note}
            date={this.props.date}
            text={this.props.text}
            image={this.props.image}
            theme={this.props.theme}
          />
        );
        break;
      case 3:
        renderedComponent.push(
          <GalleryCarouselLayout
            key={this.props.id}
            id={this.props.id}
            note={this.props.note}
            date={this.props.date}
            text={this.props.text}
            image={this.props.image}
            theme={this.props.theme}
            photos={this.props.photos}
          />
        );
        break;
      case 4:
        renderedComponent.push(
          <GalleryOpenImageLayout
            key={this.props.id}
            id={this.props.id}
            note={this.props.note}
            date={this.props.date}
            text={this.props.text}
            image={this.props.image}
            theme={this.props.theme}
          />
        );
        break;
    }
    return renderedComponent;
  }

  render() {
    return (
      <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
        {this.renderGalleryComponent()}
      </div>
    );
  }
}
export default GalleryLayoutIndex;
