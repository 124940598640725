import React from "react";
import ShowMoreText from "react-show-more-text";
import {
  VerticalAlignTopOutlined,
  StepForwardOutlined,
} from "@ant-design/icons";

export function StandarTeamLayout(props) {
  const { id, theme, name, image, text, position, layout } = props;
  let classname1 = "boxTeam";
  let classname2 = "";
  if (layout) {
    classname2 = "rounded-circle";
  }
  let widthHeigth = "250px";
  return (
    <div className="wow fadeInRight" key={id}>
      <div className="centerImage">
        <div className={classname1} style={{"--widthHeigth": widthHeigth }}>
          <img style={theme && theme.imagesShape ? theme.imagesShape : null} src={image} className={classname2}  alt="member team"/>
        </div>
      </div>
      <div className="col-xs-12 col-lg-12" id="memberName">
        <h4 style={theme && theme.sectionSubtitle ? theme.sectionSubtitle : null} > {name} </h4>
        <span style={theme && theme.sectionText1 ? theme.sectionText1 : null} className="description" > {position} </span>
      </div>
      {text ? (
        <div className="col-xs-12 col-lg-12">
          <p style={theme && theme.sectionText1 ? theme.sectionText1 : null}>
            <p id="textDescription" style={{'--limitLine': 4}}>{text}</p>
          </p>
        </div>
      ) : (
        null
      )}
    </div>
  );
}
