import React, { Component } from "react";
import { Pagination } from "@mui/material";
import moment from "moment";
import { LoadingOutlined } from "@ant-design/icons";
import getVideoId from "get-video-id";

import {
  getSectionInformationBetweenDates,
  getFilePath,
} from "../../../actions/index";

import { backendDateFormat } from "../../../reactUtils/systemVariables/generalVariables";
import LayoutIndex from "../genericLayout/newsEventsCourses/layoutStyles/LayoutIndex.jsx";
import Strings from "../../../reactUtils/systemVariables/languageLocalization/LocalizedStrings.jsx";

class CoursesSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: moment(),
      endDate: moment().add(2, "months"),
      pageIdx: 1,
      pageSize: 4,
      totalElements: 0,
      courses: [],
      coursesToShow: [],
    };
  }

  componentDidMount() {
    this.getCoursesInformation();
  }

  async getCoursesInformation() {
    let response = await getSectionInformationBetweenDates(
      this.props.lpId,
      this.props.lpSectionId,
      "COURSES",
      this.state.startDate.format(),
      this.state.endDate.format()
    );
    let sectionInfo = response.data.data;
    let courseContainerComponent = sectionInfo.components.find(
      (comp) => comp.name === "CoursesContainerComponent"
    );
    let coursesId = sectionInfo.id;
    let coursesContainerTitle = courseContainerComponent.nodes.find(
      (node) => node.nodeType === "CourseContainerTitle"
    ).value;
    let courses = sectionInfo.components.filter(
      (comp) => comp.name === "CoursesComponent"
    );
    courses = courses.sort(this.compareCourseDates);
    let totalElements = courses.length;
    this.setChildComponentsInfoPaged(
      courses,
      this.state.pageIdx,
      this.state.pageSize
    );
    let layout = sectionInfo.lpSectionLayoutStyleId;
    if (layout === 10) {
      //standar courses layout
      layout = 0;
    }
    if (layout === 22) {
      //courses image left layout
      layout = 1;
    }
    if (layout === 23) {
      //courses display grid layout
      layout = 2;
    }
    if (layout === 24) {
      //courses cards layout
      layout = 3;
    }
    if (layout === 25) {
      //courses carousel layout
      layout = 4;
    }
    if (layout === 86) {
      //News carousel layout
      layout = 6;
    }
    if(process.env.REACT_APP_DEFAULT_LAYOUT_COURSES){
      layout = parseInt(process.env.REACT_APP_DEFAULT_LAYOUT_COURSES);
    }
    let background = "";
    if (this.props.background === true) {
      background = "#ffff";
    }
    if (this.props.background === false) {
      background = "#eff5f5";
    }
    this.setState({
      coursesId,
      coursesContainerTitle,
      courses,
      totalElements,
      layout,
      background,
    });
  }

  async setChildComponentsInfoPaged(courses, pageIdx, pageSize) {
    let coursesToShow = [];
    for (
      var i = (pageIdx - 1) * pageSize;
      i < pageIdx * pageSize && i < courses.length;
      i++
    ) {
      if (!courses[i].img) {
        let imgNode = courses[i].nodes.find(
          (node) => node.nodeType === "CourseImage"
        );
        if (imgNode && imgNode.value != null && imgNode.value !== "") {
          //Get image or video
          let multimedia = [];

          if (!imgNode.value.includes("https://")) {
            // checks if it's an uploaded image
            let imgName = imgNode.value
              .replace(".jpeg", "")
              .replace(".jpg", "")
              .replace(".png", "");
            let format = imgNode.value.match(/\.([^.]*)$/)[1];
            let imgS = await getFilePath(imgName, "smallSize", format);
            let imgM = await getFilePath(imgName, "mediumSize", format);
            let imgL = await getFilePath(imgName, "largeSize", format);
            multimedia.push("isUploadedImage")
            multimedia.push(imgS);
            multimedia.push(imgM);
            multimedia.push(imgL);
          } else if (imgNode.value.includes("https://www.youtube.com/watch?")) {
            // checks if it's video or image link
            let idVideo = getVideoId(imgNode.value);
            multimedia.push("isVideo")
            multimedia.push(idVideo.id);
          } else {
            multimedia.push("isLinkedImage")
            multimedia.push(imgNode);
          }
          courses[i].multimedia = multimedia;
        }
      }
      coursesToShow.push(courses[i]);
    }
    this.setState({ coursesToShow });
  }

  onPaginationChange = (pageIdx, pageSize) => {
    this.setState({ pageIdx, pageSize, coursesToShow: [] });
    this.setChildComponentsInfoPaged(this.state.courses, pageIdx, pageSize);
  };

  compareCourseDates(a, b) {
    let aDateStr = a.nodes.find((node) => node.nodeType === "CourseDate").value;
    let bDateStr = b.nodes.find((node) => node.nodeType === "CourseDate").value;
    let aDate = moment(aDateStr, backendDateFormat);
    let bDate = moment(bDateStr, backendDateFormat);
    if (bDate.isAfter(aDate)) {
      return -1;
    }
    if (aDate.isAfter(bDate)) {
      return 1;
    }
    return 0;
  }

  render() {
    const { theme, lpId } = this.props;
    const { background, layout } = this.state;
    const antIcon = <LoadingOutlined style={{ fontSize: 150 }} spin />;
    let colorSpin = "#008cba";
    if (theme != null) {
      colorSpin = theme.sectionTitle.color;
    }
    return (
      <div>
        <section
          id={"courses" + this.state.coursesId}
          // style={{ background: background }}
        >
          <div className="containerAux">
            <div className="row">
              <div className="col-xs-12 col-md-12">
                <div
                  className="section-header wow fadeIn"
                  data-wow-duration="1s"
                >
                  <h1 style={ theme && theme.sectionTitle ? theme.sectionTitle : null  }> {this.state.courseContainerTitle} </h1>
                  <span className="section-divider"></span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-md-12">
                <div>
                  {this.state.coursesToShow.length === 0 ? (
                    <p
                      style={
                        theme && theme.sectionDescription
                          ? theme.sectionDescription
                          : null
                      }
                      className="description"
                    >
                      "Actualmente no se tienen cursos programados."
                    </p>
                  ) : (
                    <LayoutIndex
                      components={this.state.coursesToShow}
                      layout={layout}
                      theme={theme ? theme : null}
                      containerTitle={this.state.coursesContainerTitle}
                      courses={true}
                      lpId={lpId}
                    />
                  )}
                </div>
                <br />
                <div className="row">
                  <div className="col-xs-12 col-md-12 d-flex justify-content-center" >
                    {
                      Math.ceil(this.state.totalElements / this.state.pageSize) > 1 ?
                      <Pagination
                        size="small"
                        onChange={(event, value) => {
                          this.onPaginationChange(value, this.state.pageSize)
                        }}
                        count={ Math.ceil(this.state.totalElements / this.state.pageSize) }
                        page={this.state.pageIdx}
                      />
                      : null
                    }
                  </div>
                </div>
              </div>
            </div>
            <br />
          </div>
        </section>
      </div>
    );
  }
}
export default CoursesSection;
