import React from "react";
import ButtonsContainer from "../../general/ButtonsContainer.jsx";

export function LinearButtonsLinktreeLayout(props) {
  let {
    theme,
    containerTitle,
    backImg,
    backColor,
    buttons,
    buttonsColor,
    linkTreeNewTabFlag,
    layout,
  } = props;
  let flagNewTab = null;
  if (linkTreeNewTabFlag == 1) {
    flagNewTab = "_blank";
  }
  if (linkTreeNewTabFlag == 0) {
    flagNewTab = "";
  }
  var backImage = "";
  if (backImg.image != "https://somosaura-cms.s3.amazonaws.com/") {
    backImage = `linear-gradient(45deg, ${backColor}, ${backColor}), url(${backImg.image})`;
  } else {
    backImage = `linear-gradient(45deg, ${backColor}, ${backColor})`;
  }
  // buttons = [buttons[0]]
  return (
    <div className="backgroundImage" style={{ "--backImage": backImage }}>
      <div className="row">
        <div className="col-12">
          <div className="wow fadeIn" data-wow-duration="1s">
            <h1 style={theme && theme.sectionTitle ? theme.sectionTitle : null}>
              {" "}
              {containerTitle}{" "}
            </h1>
            <span className="section-divider"></span>
          </div>
        </div>
      </div>
      <div className="centerItem">
        <div className="col-xs-12 col-md-12" style={{ minHeight: "250px" }}>
          {backImg.image != "https://somosaura-cms.s3.amazonaws.com/" ? (
            <div className="containerAux">
              <div className="centrado">
                <ButtonsContainer
                  target={flagNewTab}
                  buttons={buttons}
                  isLinkTree={true}
                  buttonsColor={buttonsColor}
                  layoutLinktree={layout}
                  showBorder={true}
                  theme={theme}
                />
              </div>
              {/* <img className="centrado2" src={backImg.image} /> */}
            </div>
          ) : (
            <div className="containerAux">
              <div className="centrado">
                <ButtonsContainer
                  target={flagNewTab}
                  buttons={buttons}
                  isLinkTree={true}
                  buttonsColor={buttonsColor}
                  layoutLinktree={layout}
                  showBorder={true}
                  theme={theme}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
