import React, { Component } from "react";
import "../../stylesLP.css";
import { StandarHeaderLayoutStyle } from "./StandarHeaderLayoutStyle.jsx";
import { HeaderLogoLayout } from "./HeaderLogoLayout.jsx";
import { HeaderLogoAndTextLayout } from "./HeaderLogoAndTextLayout.jsx";
import { HeaderLogoRightLayout } from "./HeaderLogoRightLayout.jsx";

class HeaderLayoutIndex extends Component {
  renderHeaderComponent() {
    let renderedComponent = [];
    switch (this.props.layout) {
      case 0:
        renderedComponent.push(
          <StandarHeaderLayoutStyle
            theme={this.props.theme}
            headerTitle={this.props.headerTitle}
            headerFontSize={this.props.headerFontSize}
            key={this.props.id}
            headerComponentInfo={this.props.headerComponentInfo}
            layoutId = {this.props.layout}
          />
        );
        break;

      case 1:
        renderedComponent.push(
          <HeaderLogoLayout
            theme={this.props.theme}
            headerFontSize={this.props.headerFontSize}
            icon={this.props.icon}
            key={this.props.id}
            headerComponentInfo={this.props.headerComponentInfo}
            layoutId = {this.props.layout}
          />
        );
        break;

      case 2:
        renderedComponent.push(
          <HeaderLogoRightLayout
            theme={this.props.theme}
            headerFontSize={this.props.headerFontSize}
            icon={this.props.icon}
            key={this.props.id}
            headerComponentInfo={this.props.headerComponentInfo}
            layoutId = {this.props.layout}
            buttonHeader={this.props.buttonHeader}
          />
        );
        break;

        case 3:
          renderedComponent.push(
            <HeaderLogoAndTextLayout
              theme={this.props.theme}
              headerTitle={this.props.headerTitle}
              headerFontSize={this.props.headerFontSize}
              icon={this.props.icon}
              key={this.props.id}
              headerComponentInfo={this.props.headerComponentInfo}
              layoutId = {this.props.layout}
            />
          );
          break;
    }
    return renderedComponent;
  }

  render() {
    return <div>{this.renderHeaderComponent()}</div>;
  }
}
export default HeaderLayoutIndex;
