import React from "react";
import { FacebookEmbed, EmbedToggler, TwitterEmbed, InstagramEmbed  } from 'react-social-media-embed';
import AurappOutlined from "../../../../icons/logo_aura_black.png";
import AurappLogo from "../../../../icons/logo_aura_color.png";
import { Scrollbars } from "react-custom-scrollbars";
import Analitics from "./../../../../reactUtils/helpers/analitics"
import ".././index.css";
import Iframe from "react-iframe";

export function StandardSocialNetworksLayout(props) {
  var {
    urlCut,
    SocialNetworksTitle,
    SocialNetworksFacebookUrl,
    SocialNetworksInstagramPost,
    SocialNetworksTwitterUsername,
    SocialNetworksAurapp,
    theme,
  } = props;

  if (SocialNetworksInstagramPost === null) {
    SocialNetworksInstagramPost = "";
  }

  if (SocialNetworksFacebookUrl === null) {
    SocialNetworksFacebookUrl = "";
  }
  if (SocialNetworksTwitterUsername === null) {
    SocialNetworksTwitterUsername = "";
  }

  let count = 3;
  if (SocialNetworksTwitterUsername === "") {
    count = count + 1;
  }
  if (SocialNetworksInstagramPost === "") {
    count = count + 1;
  }
  if (SocialNetworksFacebookUrl === "") {
    count = count + 1;
  }
  if (SocialNetworksAurapp === "") {
    count = count + 1;
  }
  if (count == 6) {
    count = 12;
  }
  if (count == 5) {
    count = 6;
  }

  const sendAnalitic = (action, label, value) => {
    Analitics.setEvent({
      category: "social-network",
      action: action,
      label: label,
      value: value,
      nonInteraction: true, // optional, true/false
    })
  }

  const getLinkIntagram = (link) => {
    let _link = link
    let rexT = /embed/
    if(!_link.endsWith("/")) {
      _link += "/"
    }
    if(!rexT.test(_link)) {
      _link += "embed/"
    }
    return _link
  }

  return (
    <div>
      <div className="wow fadeIn" data-wow-duration="1s">
        <h1 style={theme && theme.sectionTitle ? theme.sectionTitle : null} className="section-title" >
          {SocialNetworksTitle}
        </h1>
        <span className="section-divider"></span>
      </div>
      <div className="centerItem">
        <div className="row" style={{"align-items": "flex-start"}}>
          {SocialNetworksFacebookUrl != "" ? (
            <div className={`col-xs-12 col-md-6 col-lg-${count} wow fadeInRight containerSocialNetwork`}>
              {/* <div className="text-center box_socialNetworks">
                <a onClick={() => sendAnalitic("facebook", "click in facebook", 1)} href={SocialNetworksFacebookUrl}>
                  <FacebookOutlined /> Facebook Post
                </a>
              </div> */}
              {SocialNetworksFacebookUrl ? (
                // <FacebookEmbed url={SocialNetworksFacebookUrl} width={325} />
                  <FacebookEmbed
                    url={SocialNetworksFacebookUrl}
                    width={325}
                  />
              ) : null}
            </div>
          ) : null}

          {SocialNetworksTwitterUsername != "" ? (
            <div className={`col-xs-12 col-md-6 col-lg-${count} wow fadeInRight containerSocialNetwork`}>
              {/* <div className="text-center box_socialNetworks">
                <a onClick={() => sendAnalitic("twitter", "click in twitter", 1)} href={SocialNetworksTwitterUsername}>
                  <TwitterOutlined /> Tweet
                </a>
              </div> */}
              {SocialNetworksTwitterUsername ? (
                <TwitterEmbed url={SocialNetworksTwitterUsername} width={325}/>
              ) : null}
            </div>
          ) : null}

          {SocialNetworksInstagramPost != "" ? (
            <div className={`col-xs-12 col-md-6 col-lg-${count} wow fadeInRight containerSocialNetwork`}>
              {/* <div className="text-center box_socialNetworks">
                <a onClick={() => sendAnalitic("instagram", "click in instagram", 1)} href={SocialNetworksInstagramPost.replace("embed", "")}>
                  <InstagramOutlined /> Instagram Post
                </a>
              </div> */}
              {SocialNetworksInstagramPost ? 
                // <InstagramEmbed 
                // // url={SocialNetworksInstagramPost} 
                // url="https://www.instagram.com/p/CUbHfhpswxt/"
                // width="100%" 
                // /> 
                <Iframe
                  width="100%"
                  height="500"
                  url={getLinkIntagram(SocialNetworksInstagramPost)}
                  style="border:none;overflow:hidden"
                  scrolling="no"
                  frameBorder="0"
                  allowfullscreen="true"
                  allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                />
               : null}
            </div>
          ) : null}

          {SocialNetworksAurapp ? (
            <div className={`col-xs-12 col-md-6 col-lg-${count} wow fadeInRight`}>
              {/* <div className="text-center box_socialNetworks">
                <img id="users" src={AurappOutlined} alt="users" />
                AurApp
              </div> */}
              {SocialNetworksAurapp ? (
                <div className={"border col-12"}>
                  {/* <div className="header_post">
                    <img id="user" src={AurappLogo} alt="user" /> Post de Aurapp
                  </div> */}
                  <Scrollbars style={{ width: "100%", height: 455 }}>
                    {props.renderPostComponents(SocialNetworksAurapp)}
                  </Scrollbars>
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
      </div>
    </div >
  );
}
