import React, { Component } from "react";
import { Box, CircularProgress, Typography } from "@material-ui/core";
import { LoadingOutlined } from "@ant-design/icons";
import StreamingLayoutIndex from "./layoutStyles/StreamingLayoutIndex";

//Queries
import {
  getScheduleEventInfoByLpId,
  getLpSectionInformation,
} from "../../../actions/index";

//Localized
import Strings from "../../../reactUtils/systemVariables/languageLocalization/LocalizedStrings.jsx";

import "./index.css";

class StreamingSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lpId: this.props.lpId,
      lpSectionId: this.props.lpSectionId,
      streamings: [],
      streamingsToShow: [],
      isLoading: false,
    };
  }

  componentDidMount() {
    const { lpId, lpSectionId } = this.props;
    this.getStreamingsInformation();
    this.getAboutUsInformation(lpId, lpSectionId);
    this.setState({
      loadingInfo: true,
    });
  }

  async getAboutUsInformation(lpId, lpSectionId) {
    const response = await getLpSectionInformation(
      lpId,
      lpSectionId,
      "STREAMING"
    );
    if (String(response).includes("Error:")) {
      Modal.error({
        title: Strings.modal.internalServerError,
        content: Strings.modal.internalServerErrorContactSupport,
      });
    } else {
      // Get all the information about the section components
      let sectionInfo = response.data.data;
      let streamingContainerComponent = sectionInfo.components.find(
        (comp) => comp.name === "StreamingContainerComponent"
      );
      let sectionStreamingId = sectionInfo.id;
      let sectionStreamingTitle = streamingContainerComponent.nodes.find(
        (node) => node.nodeType == "StreamingContainerTitle"
      ).value;
      sectionStreamingTitle = sectionStreamingTitle
        ? sectionStreamingTitle
        : "";
      let sectionStreamingPage = streamingContainerComponent.nodes.find(
        (node) => node.nodeType == "StreamingContainerUrlPage"
      ).value;
      sectionStreamingPage = sectionStreamingPage ? sectionStreamingPage : "";
      let background = "";
      // let backgroundImage = "";
      if (this.props.background === true) {
        background = "#eff5f5";
        // backgroundImage =
        //   ' url("https://jointabella.com/wp-content/uploads/2020/06/forchurches-section2.svg")';
      }
      if (this.props.background === false) {
        background = "#ffff";
        // backgroundImage =
        //   ' url("https://jointabella.com/wp-content/uploads/2020/10/BG-Section2-1.svg")';
      }
      let layout = sectionInfo.lpSectionLayoutStyleId;
      if (layout === 15) {
        //Standar layout streamings
        layout = 0;
      }
      this.setState({
        sectionStreamingId,
        sectionStreamingTitle,
        sectionStreamingPage,
        layout,
        background,
        // backgroundImage,
      });
    }
  }

  async getStreamingsInformation() {
    let response = await getScheduleEventInfoByLpId(this.props.lpSectionId);
    let streamings = response.data.data;
    this.setState({
      streamings,
      isLoading: false,
    });
  }


  render() {
    const antIcon = <LoadingOutlined style={{ fontSize: 150 }} spin />;
    const { theme } = this.props;
    const {
      background,
      // backgroundImage
    } = this.state;
    let colorSpin = "#008cba";
    if (theme != null) {
      colorSpin = theme.sectionTitle.color;
    }

    return (
      <div id="features" >
      {/* <div id="features" style={{ background: background }}> */}
        <section id={"streaming" + this.state.sectionStreamingId}>
          <div className="row">
            <div className="col-xs-12 col-xl-12">
              <div className="wow fadeInLeft" data-wow-duration="1s">
                <h1 style={  theme && theme.sectionTitle ? theme.sectionTitle : null  } className="section-title"  >
                  {this.state.sectionStreamingTitle}
                </h1>
                <span className="section-divider"></span>
              </div>
            </div>
          </div>
          <div className="text-center">
            {this.state.streamings.length === 0 ? (
              <Box>
                <CircularProgress color={colorSpin} />
                <Typography>{Strings.generalMessages.loading}</Typography>
              </Box>
            ) : (
              // this.renderStreamingsComponents(this.state.streamings)
              <StreamingLayoutIndex
                streamings={this.state.streamings}
                sectionStreamingPage={this.state.sectionStreamingPage}
                layout={this.state.layout}
                isSubpageFlag={this.props.isSubpageFlag}
                sectionStreamingId={this.state.sectionStreamingId}
                id={this.state.sectionStreamingId}
                key={this.state.sectionStreamingId}
                theme={theme}
              />
            )}
          </div>
        </section>
      </div>
    );
  }
}
export default StreamingSection;
