import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { getGospelInfo } from "../../../actions/index";
import moment from "moment";
import _ from "lodash";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "./GospelPage.css";

var subpageTitle = null;

const dateOnlyFormat = "YYYY-MM-DD";

class GospelPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: moment().format(dateOnlyFormat),
      gospelInfo: [],
      readings: [],
    };
  }

  componentDidMount() {
    this.getGospelInfoByDate(this.state.date);
  }

  async getGospelInfoByDate(date) {
    let readings = [];
    let gospelInfo = [];

    let response = await getGospelInfo(date);

    if (response) {
      readings =
        response.data.data.readings.filter((reading) => reading.type == "reading")
      ;

      gospelInfo = {
        liturgicTitle: response.data.data.liturgic_title,
        date_displayed: response.data.data.date_displayed,

        reading1: {
          title: readings[0] ? readings[0].title : "",
          reference: readings[0] ? readings[0].reference_displayed : "",
          text: readings[0] ? this.deleteString(readings[0].text) : "",
        },

        psalm: {
          title: response.data.data.readings.find(
            (reading) => reading.type == "psalm"
          ).title,
          reference: response.data.data.readings.find(
            (reading) => reading.type == "psalm"
          ).reference_displayed,
          text: this.deleteString(
            response.data.data.readings.find(
              (reading) => reading.type == "psalm"
            ).text
          ),
          chorus: response.data.data.readings.find(
            (reading) => reading.type == "psalm"
          ).chorus,
        },

        reading2: {
          title: readings[1] ? readings[1].title : "",
          reference: readings[1] ? readings[1].reference_displayed : "",
          text: readings[1] ? this.deleteString(readings[1].text) : "",
        },

        gospel: {
          title: response.data.data.readings.find(
            (reading) => reading.type == "gospel"
          ).title,
          reference: response.data.data.readings.find(
            (reading) => reading.type == "gospel"
          ).reference_displayed,
          text: this.deleteString(
            response.data.data.readings.find(
              (reading) => reading.type == "gospel"
            ).text
          ),
        },
      };
    }

    this.setState({ gospelInfo });
  }

  changeDate(e) {
    var { date } = this.state;
    date = moment(e.$d).format(dateOnlyFormat);
    this.getGospelInfoByDate(date);
    this.setState({ date });
  }

  deleteString(str) {
    let startChar = "[";
    let endChar = "]";

    // Find the index of the first occurrence of the start character
    let startIndex = str.indexOf(startChar);

    while (startIndex !== -1) {
      // Find the index of the end character
      let endIndex = str.indexOf(endChar, startIndex + 1);

      if (endIndex !== -1) {
        // Remove the characters between the start and end characters
        str = str.slice(0, startIndex) + str.slice(endIndex + 1);
      } else {
        // If the end character is not found, break out of the loop
        break;
      }

      // Find the next occurrence of the start character
      startIndex = str.indexOf(startChar, startIndex + 1);
    }
    str = str.replaceAll("[", "").replaceAll("]", "");

    return str;
  }

  render() {
    const { theme, headerLayout } = this.props;
    const { redirectHome, gospelInfo, date } = this.state;

    if (redirectHome == true) {
      // Redirects to home if component doesnt exist
      return <Redirect to="/" />;
    }

    return (
      <div>
        <div className="containerAux">
          <div className="containerReadings">
            <div className="col-xs-12 col-md-6 subpage-title wow fadeIn">
              <h1
                style={theme && theme.sectionTitle ? theme.sectionTitle : null}
              >
                <span className="GospelReading-title">
                  {gospelInfo.liturgicTitle}
                </span>
              </h1>
            </div>

            <div className="col-xs-12 col-md-4">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    label={
                      gospelInfo.date_displayed
                        ? gospelInfo.date_displayed
                        : "Fecha"
                    }
                    onChange={(e) => {
                      this.changeDate(e);
                    }}
                    desktopModeMediaQuery="@media (pointer: fine)"
                  />
                </DemoContainer>
              </LocalizationProvider>
            </div>
          </div>

          <div className="containerReadings">
            <div className="col-xs-12 col-md-12">
              <h2
                style={
                  theme && theme.sectionText1
                    ? theme.sectionText1
                    : null + theme && theme.sectionText2
                    ? theme.sectionText2
                    : null
                }
              >
                <span className="GospelReading-title">
                  {gospelInfo.reading1 ? gospelInfo.reading1.title : ""}
                </span>
                <span className="GospelReading-reference">
                    {gospelInfo.reading1 ? gospelInfo.reading1.reference : ""}
                  </span>
              </h2>
            </div>

            <div className="col-xs-12 col-md-12">
              <p
                style={
                  theme && theme.sectionText1
                    ? theme.sectionText1
                    : null + theme && theme.sectionText2
                    ? theme.sectionText2
                    : null
                }
                className="reading-body"
              >
                {gospelInfo.reading1 ? gospelInfo.reading1.text : ""}
              </p>
            </div>
          </div>

          <div className="containerReadings">
            <div className="col-xs-12 col-md-12">
              <h2
                style={
                  theme && theme.sectionText1
                    ? theme.sectionText1
                    : null + theme && theme.sectionText2
                    ? theme.sectionText2
                    : null
                }
              >
                <span className="GospelReading-title">
                  {gospelInfo.psalm ? gospelInfo.psalm.title : ""}{" "}
                </span>
                <span className="GospelReading-reference">
                    {gospelInfo.psalm ? gospelInfo.psalm.reference : ""}
                  </span>
              </h2>
            </div>

            <div className="col-xs-12 col-md-12">
            <p
                style={
                  theme && theme.sectionText1
                    ? theme.sectionText1
                    : null + theme && theme.sectionText2
                    ? theme.sectionText2
                    : null
                }
                className="reading-body"
              >
                  <span className="GospelReading-chorus">
                    {gospelInfo.psalm ? gospelInfo.psalm.chorus : ""}
                </span>
                </p>

            </div>

            <div className="col-xs-12 col-md-12">
              <p
                style={
                  theme && theme.sectionText1
                    ? theme.sectionText1
                    : null + theme && theme.sectionText2
                    ? theme.sectionText2
                    : null
                }
                className="reading-body"
              >
                <span>{gospelInfo.psalm ? gospelInfo.psalm.text : ""}</span>
              </p>
            </div>
          </div>

          {gospelInfo.reading2 && gospelInfo.reading2.title ? (
            <div className="containerReadings">
              <div className="col-xs-12 col-md-12">
                <h2
                  style={
                    theme && theme.sectionText1
                      ? theme.sectionText1
                      : null + theme && theme.sectionText2
                      ? theme.sectionText2
                      : null
                  }
                >
                  <span className="GospelReading-title">
                    {gospelInfo.reading2 ? gospelInfo.reading2.title : ""}
                  </span>
                  <span className="GospelReading-reference">
                      {gospelInfo.reading2 ? gospelInfo.reading2.reference : ""}
                    </span>
                </h2>
              </div>

              <div className="col-xs-12 col-md-12">
                <p
                  style={
                    theme && theme.sectionText1
                      ? theme.sectionText1
                      : null + theme && theme.sectionText2
                      ? theme.sectionText2
                      : null
                  }
                  className="reading-body"
                >
                  {gospelInfo.reading2 ? gospelInfo.reading2.text : ""}
                </p>
              </div>
            </div>
          ) : null}

          <div className="containerReadings">
            <div className="col-xs-12 col-md-12">
              <h2
                style={
                  theme && theme.sectionText1
                    ? theme.sectionText1
                    : null + theme && theme.sectionText2
                    ? theme.sectionText2
                    : null
                }
              >
                <span className="GospelReading-title">
                  {gospelInfo.gospel ? gospelInfo.gospel.title : ""}{" "}
                </span>
                <span className="GospelReading-reference">
                    {gospelInfo.gospel ? gospelInfo.gospel.reference : ""}
                  </span>
              </h2>
            </div>

            <div className="col-xs-12 col-md-12">
              <p
                style={
                  theme && theme.sectionText1
                    ? theme.sectionText1
                    : null + theme && theme.sectionText2
                    ? theme.sectionText2
                    : null
                }
                className="reading-body"
              >
                <span>{gospelInfo.gospel ? gospelInfo.gospel.text : ""}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default GospelPage;
