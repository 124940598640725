import React from "react";
import "../index.scss";
import { Tooltip } from "@material-ui/core";

export function GalleryBlackWhiteLayout(props) {
  const { id, note, text } = props;
  return (
    <div
      className="boxLayout2" >
      <Tooltip title={note + ".\n Autor: " + text} arrow>
        <img
          src={props.image}
          key={id}
          alt="gallery"
        />
      </Tooltip>
    </div>
  );

}
