import React from "react";
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import parse from "html-react-parser";
import imageNull from "../../../../../images/noImage.jpg";
import "./index.scss";
import { Link } from "react-router-dom";
import ButtonsContainer from "../../../general/ButtonsContainer.jsx";
import moment from "moment";
import { chunk as _chunk } from "lodash";
import { Card, CardContent, useMediaQuery } from "@mui/material";
import Carousel from "react-material-ui-carousel";

var dateStartTime = "MMMM DD, YYYY";


export function CarouselLayout(props) {
  let {theme, components, lpId, news, events, courses, donations} = props;
  components = components ? components : [];
  // components = components.concat(components);
  let section = "";
  if (news) {
    section = "News";
  }
  if (events) {
    section = "Event";
  }
  if (courses) {
    section = "Course";
  }
  if (donations) {
    section = "DonationComponent";
  }

  const renderSlides = () => {
    let dividers = 1;
    let screen = window.screen
    if(screen.width >= 400) {
      dividers = 1
    }
    if(screen.width >= 800) {
      dividers = 2
    }
    if(screen.width >= 1200) {
      dividers = 3
    }
    if(screen.width >= 1600) {
      dividers = 4
    }
    return (
      _chunk(components, dividers).map(container => {

        return (
          <div style={{ display: "flex", justifyContent: "space-around", paddingLeft: "50px", paddingRight: "50px" }} >
            {
  
              container.map((element) => {
        
                let buttons = element.buttons;
                let id = element.id;
                let title = element.nodes.find(
                  (node) => node.nodeType == section + "Title"
                );
                title = title ? title.value : "";
                let text = element.nodes.find(
                  (node) => node.nodeType == section + "Text"
                );
                text = text ? parse(text.value) : "";
                let date = element.nodes.find(
                  (node) => node.nodeType == section + "Date"
                );
                date = date ? date.value : "";
                let image  =imageNull;
                let multimedia = element.multimedia;
                if(multimedia && multimedia[1]){
                  image = multimedia[1].value ? multimedia[1].value : multimedia[1]
                }
                if(multimedia && multimedia[3]){
                  image = multimedia[3].value ? multimedia[3].value : multimedia[3];
                }
                var event = {};
                if (events) {
                  event = {
                    title: title,
                    description: text,
                    startTime: moment(date).format(dateStartTime),
                    endTime: moment(date).add(1, "hours").format(dateStartTime),
                  };
                }
                let buttonLinkPath = "/component/"
                if(donations){
                  buttonLinkPath = "/payment/"
                }
        
                return (
                  <Card variant="outlined" style={{ minHeight: "600px", // minWidth: "25vw",
                   width: "25rem", margin: "2rem", borderRadius: 8 }}>
                    <CardContent>
                      <Link to={{ pathname: buttonLinkPath + id }}>
                        <div className="nameAndImageCardNews" >
                          <p style={theme && theme.sectionSubtitle ? theme.sectionSubtitle : null}>{title} </p>
                        </div>
                        <div className="nameAndImageCardNews" >
                          <img src={image} alt="image team" />
                        </div>
                        <div className="cardNewsContent" style={{ "--colorSubtitle": theme && theme.sectionSubtitle ? theme.sectionSubtitle.color : null}}>
                          <p style={theme && theme.sectionText1 ? theme.sectionText1 : null}>{text}</p>
                        </div>
                      </Link>
                        {/* buttons */}
                        {buttons ? 
                        <div className="row">
                          <div className="col-xs-12 col-md-12 text-center">
                            {props.events ? (
                              <ButtonsContainer
                                buttons={buttons}
                                event={event}
                                theme={theme}
                                internalLpSectionComponentId={id}
                                readMore={true}
                                typeLink={false}
                                componentId={id}
                                style='containerLinearButtons'
                                showBorder={true}
                                />
                            ) : (
                              <div>
                                <ButtonsContainer
                                  buttons={buttons}
                                  theme={theme}
                                  internalLpSectionComponentId={id}
                                  readMore={true}
                                  typeLink={false}
                                  componentId={id}
                                  style='containerLinearButtons'
                                  showBorder={true}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                        : null }
                    </CardContent>
                  </Card>
                )
        
        
              })
            }
          </div>
        )
      })
    )
  }

  let titleZero;
  let dateZero;
  let textZero;
  let imageZero = imageNull;
  let buttons = [];
  let id;
  let event = {};
  
  if(components && components.length <= 1){
    titleZero = components[0].nodes.find(
      (node) => node.nodeType === section + "Title"
    );
    titleZero = titleZero ? titleZero.value : "";
    textZero = components[0].nodes.find(
      (node) => node.nodeType === section + "Text"
    );
    textZero = textZero ? parse(textZero.value) : "";
    dateZero = components[0].nodes.find(
      (node) => node.nodeType == section + "Date"
    );
    dateZero = dateZero ? dateZero.value : "";
    let multimedia = components[0].multimedia;
    if(multimedia && multimedia[1]){
      imageZero = multimedia[1].value ? multimedia[1].value : multimedia[1]
    }
    if(multimedia && multimedia[3]){
      imageZero = multimedia[3].value ? multimedia[3].value : multimedia[3];
    }
    buttons = components[0].buttons;
    id = components[0].id;
    if (events) {
      event = {
        title: titleZero,
        description: textZero,
        startTime: moment(dateZero).format(dateStartTime),
        endTime: moment(dateZero).add(1, "hours").format(dateStartTime),
      };
    }
  }

  let buttonLinkPath = "/component/"
  if(donations){
    buttonLinkPath = "/payment/"
  }

  const responsive = {
    660: { items: 1 },
    900: { items: 2 },
    1024: { items: 3 },
  };

//   const settingsCarrousel = {
//     autoPlay: true,
//     animation: "slide",
//     indicators: true,
//     duration: 600,
//     navButtonsAlwaysVisible: false,
//     navButtonsAlwaysInvisible: false,
//     cycleNavigation: true,
//     fullHeightHover: true,
//     swipe: true,
// }
  
  return (
    <div className="wow fadeInRight">
      {
        components && components.length > 1 ?
          <Carousel
            autoPlay={false}
            indicators={true}
            swipe={true}
            fullHeightHover={true}
            cycleNavigation={true}
            navButtonsAlwaysVisible={true}
          >
            {renderSlides()}
          </Carousel>
        :
        <div className="centerImage">
          <div className="cardLayoutNews">
            <Link to={{ pathname: buttonLinkPath + id }}>
              <div className="nameAndImageCardNews" >
                <p style={theme && theme.sectionSubtitle ? theme.sectionSubtitle : null}>{titleZero} </p>
              </div>
              <div className="nameAndImageCardNews" >
                <img src={imageZero} alt="image team" />
              </div>
              <div className="cardNewsContent" style={{ "--colorSubtitle": theme && theme.sectionSubtitle ? theme.sectionSubtitle.color : null}}>
                <p style={theme && theme.sectionText1 ? theme.sectionText1 : null}>{textZero}</p>
              </div>
            </Link>

              {/* buttons */}
              <div className="row">
                <div className="col-xs-12 col-md-12 text-center">
                  {props.events ? (
                    <ButtonsContainer
                      buttons={buttons}
                      event={event}
                      theme={theme}
                      internalLpSectionComponentId={id}
                      readMore={true}
                      typeLink={false}
                      componentId={id}
                      style='containerLinearButtons'
                      showBorder={true}
                    />
                  ) : (
                    <div>
                      <ButtonsContainer
                        buttons={buttons}
                        theme={theme}
                        internalLpSectionComponentId={id}
                        readMore={true}
                        typeLink={false}
                        componentId={id}
                        style='containerLinearButtons'
                        showBorder={true}
                      />
                    </div>
                  )}
                </div>
              </div>
          </div>
        </div>
      }
    </div>
  );
}
