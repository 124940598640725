import React from "react";
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

export function TeamLayout7(props) {
  const { theme, members } = props;
  const renderSlides = () =>
    members.map(element => {
      let name = element.nodes.find((node) => node.nodeType === "TeamMemberName");
      name = name ? name.value : "";
      let text = element.nodes.find((node) => node.nodeType === "TeamMemberText");
      text = text ? text.value : "";
      let image = element.imgL;
      return (
        <div className="cardTeamL7">
          <img src={image} alt="image team" />
          <p style={theme && theme.sectionSubtitle ? theme.sectionSubtitle : null}>{name} </p>
        </div>
      )
  });

    let nameZero;
    let textZero;
    let imageZero;
    if(members.length <= 1){
      nameZero = members[0].nodes.find(  (node) => node.nodeType === "TeamMemberName" );
      nameZero = nameZero ? nameZero.value : "";
      textZero = members[0].nodes.find((node) => node.nodeType === "TeamMemberText");
      textZero = textZero ? textZero.value : "";
      imageZero = members[0].imgL;
    }

    const responsive = {
      660: { items: 1 },
      900: { items: 2 },
      1024: { items: 3 },
    };
    

  return (
    <div className="wow fadeInRight">
      {
        members.length > 1 ?
        // <Slider
        //   dots={true}
        //   slidesToShow={2}
        //   slidesToScroll={1}
        //   // autoplay={true}
        //   autoplaySpeed={3000}
        // >
        //   {renderSlides()}
        // </Slider>
        <AliceCarousel 
          responsive={responsive}
          mouseTracking
          animationType="fadeout" 
          animationDuration={1000}
          disableButtonsControls
          infinite 
          items={renderSlides()} 
        />
        :
        <div className="centerImage">
          <div className="cardTeam">
            <div className="nameAndImageCard" >
              <p style={theme && theme.sectionSubtitle ? theme.sectionSubtitle : null}>{nameZero} </p>
              <img src={imageZero} alt="image team" />
            </div>
          </div>
        </div>
      }
    </div>
  );
}
