import React, { Component } from "react";
import "../../../stylesLP.css";
import { StandarLayout } from "./StandarLayout.jsx";
import { IndividualLayout } from "./IndividualLayout.jsx";
import { ImageLeftTextRigthLayout } from "./ImageLeftTextRigthLayout.jsx";
import { GridLayout } from "./GridLayout.jsx";
import { CardLayout } from "./CardLayout.jsx";
import { CarouselLayout } from "./CarouselLayout.jsx";
import { Layout5 } from "./Layout5.jsx";
import { Layout6 } from "./Layout6.jsx";
import { Layout7 } from "./Layout7.jsx";
import parse from "html-react-parser";
import moment from "moment";

class LayoutIndex extends Component {
  renderLayoutIndex = (components) => {
    const { theme } = this.props;
    let renderComponent = [];
    if (this.props.individualStyle) {
      switch (this.props.layout) {
        case -1:
          renderComponent.push(
            <IndividualLayout
              lpId={this.props.lpId}
              title={this.props.title}
              text={this.props.text}
              date={this.props.date ? moment(this.props.date).subtract(5, "hours")  : ""}
              endDate={this.props.endDate ? moment(this.props.endDate).subtract(5, "hours")  : ""}
              fixedValue={this.props.fixedValue}
              author={this.props.author}
              place={this.props.place}
              responsible={this.props.responsible}
              image={this.props.image}
              multimedia={this.props.multimedia}
              id={this.props.id}
              theme={theme ? theme : null}
              buttons={this.props.buttons}
              // key={this.props.id}
              individualStyle={
                this.props.individualStyle ? this.props.individualStyle : null
              }
              containerTitle={this.props.containerTitle}
            />
          );
          break;
      }
    } else {
      if (components) {
        let section = "";
        if (this.props.news) {
          section = "News";
        }
        if (this.props.events) {
          section = "Event";
        }
        if (this.props.courses) {
          section = "Course";
        }
        if (this.props.donations) {
          section = "DonationComponent";
        }
        components.forEach((element) => {
          let title = element.nodes.find(
            (node) => node.nodeType == section + "Title"
          );
          title = title ? title.value : "";
          let text = element.nodes.find(
            (node) => node.nodeType == section + "Text"
          );
          text = text ? text.value : "";
          let endDate = element.nodes.find(
            (node) => node.nodeType == "DonationComponentEndDate"
          );
          endDate = endDate ? endDate.value : "";
          let fixedValue = element.nodes.find(
            (node) => node.nodeType == "DonationComponentFixedValue"
          );
          fixedValue = fixedValue ? fixedValue.value : "";
          let date = element.nodes.find(
            (node) => node.nodeType == section + "Date"
          );
          date = date ? moment(date.value).subtract(5, "hours")  : "";
          date = date.toLocaleString();
          let author = element.nodes.find(
            (node) => node.nodeType == section + "Author"
          );
          author = author ? author.value : "";
          let place = element.nodes.find(
            (node) => node.nodeType == section + "Place"
          );
          place = place ? place.value : "";
          let responsible = element.nodes.find(
            (node) => node.nodeType == section + "Responsible"
          );
          responsible = responsible ? responsible.value : "";
          let multimedia = element.multimedia
          let imageM = element.imgM ? element.imgM : null;
          switch (this.props.layout) {
            case 0:
              renderComponent.push(
                <StandarLayout
                  title={title}
                  text={parse(text)}
                  date={date}
                  endDate={endDate}
                  fixedValue={fixedValue}
                  author={author}
                  multimedia={multimedia}
                  place={place}
                  responsible={responsible}
                  id={element.id}
                  theme={theme ? theme : null}
                  buttons={element.buttons}
                  individualStyle={
                    this.props.individualStyle
                      ? this.props.individualStyle
                      : null
                  }
                  events={this.props.events ? true : null}
                  news={this.props.news ? true : null}
                  courses={this.props.courses ? true : null}
                  donations={this.props.donations ? true : null}
                  containerTitle={this.props.containerTitle}
                  lpId={this.props.lpId}
                />
              );
              break;

            case 1:
              renderComponent.push(
                <ImageLeftTextRigthLayout
                  title={title}
                  text={parse(text)}
                  date={date}
                  author={author}
                  multimedia={multimedia}
                  place={place}
                  endDate={endDate}
                  fixedValue={fixedValue}
                  responsible={responsible}
                  id={element.id}
                  key={element.id}
                  theme={theme ? theme : null}
                  buttons={element.buttons}
                  individualStyle={
                    this.props.individualStyle
                      ? this.props.individualStyle
                      : null
                  }
                  events={this.props.events ? true : null}
                  news={this.props.news ? true : null}
                  courses={this.props.courses ? true : null}
                  donations={this.props.donations ? true : null}
                  containerTitle={this.props.containerTitle}
                  lpId={this.props.lpId}
                />
              );
              break;

            case 2:
              renderComponent.push(
                <GridLayout
                  title={title}
                  text={parse(text)}
                  date={date}
                  author={author}
                  multimedia={multimedia}
                  place={place}
                  endDate={endDate}
                  fixedValue={fixedValue}
                  responsible={responsible}
                  id={element.id}
                  // key={element.id}
                  theme={theme ? theme : null}
                  buttons={element.buttons}
                  individualStyle={
                    this.props.individualStyle
                      ? this.props.individualStyle
                      : null
                  }
                  events={this.props.events ? true : null}
                  news={this.props.news ? true : null}
                  courses={this.props.courses ? true : null}
                  donations={this.props.donations ? true : null}
                  containerTitle={this.props.containerTitle}
                  lpId={this.props.lpId}
                  components={components}
                />
              );
              break;
            case 3:
              renderComponent.push(
                <CardLayout
                  title={title}
                  text={parse(text)}
                  date={date}
                  author={author}
                  multimedia={multimedia}
                  endDate={endDate}
                  fixedValue={fixedValue}
                  place={place}
                  responsible={responsible}
                  id={element.id}
                  // key={element.id}
                  theme={theme ? theme : null}
                  buttons={element.buttons}
                  individualStyle={
                    this.props.individualStyle
                      ? this.props.individualStyle
                      : null
                  }
                  events={this.props.events ? true : null}
                  news={this.props.news ? true : null}
                  courses={this.props.courses ? true : null}
                  donations={this.props.donations ? true : null}
                  containerTitle={this.props.containerTitle}
                  lpId={this.props.lpId}
                />
              );
              break;
            case 4:
              renderComponent.push(
                <CarouselLayout
                  title={title}
                  text={parse(text)}
                  date={date}
                  endDate={endDate}
                  fixedValue={fixedValue}
                  author={author}
                  multimedia={multimedia}
                  place={place}
                  responsible={responsible}
                  id={element.id}
                  // key={element.id}
                  theme={theme ? theme : null}
                  buttons={element.buttons}
                  individualStyle={
                    this.props.individualStyle
                      ? this.props.individualStyle
                      : null
                  }
                  events={this.props.events ? true : null}
                  news={this.props.news ? true : null}
                  courses={this.props.courses ? true : null}
                  donations={this.props.donations ? true : null}
                  containerTitle={this.props.containerTitle}
                  lpId={this.props.lpId}
                />
              );
              break;
            case 5:
              renderComponent.push(
                <Layout5
                  title={title}
                  text={parse(text)}
                  date={date}
                  endDate={endDate}
                  fixedValue={fixedValue}
                  author={author}
                  multimedia={multimedia}
                  place={place}
                  responsible={responsible}
                  id={element.id}
                  // key={element.id}
                  theme={theme ? theme : null}
                  buttons={element.buttons}
                  individualStyle={
                    this.props.individualStyle
                      ? this.props.individualStyle
                      : null
                  }
                  events={this.props.events ? true : null}
                  news={this.props.news ? true : null}
                  courses={this.props.courses ? true : null}
                  donations={this.props.donations ? true : null}
                  containerTitle={this.props.containerTitle}
                  lpId={this.props.lpId}
                />
              );
              break;
              case 6:
                renderComponent.push(
                  <Layout6
                    title={title}
                    text={parse(text)}
                    date={date}
                    endDate={endDate}
                    fixedValue={fixedValue}
                    author={author}
                    multimedia={multimedia}
                    place={place}
                    responsible={responsible}
                    id={element.id}
                    // key={element.id}
                    theme={theme ? theme : null}
                    buttons={element.buttons}
                    individualStyle={
                      this.props.individualStyle
                        ? this.props.individualStyle
                        : null
                    }
                    events={this.props.events ? true : null}
                    news={this.props.news ? true : null}
                    courses={this.props.courses ? true : null}
                    donations={this.props.donations ? true : null}
                    containerTitle={this.props.containerTitle}
                    lpId={this.props.lpId}
                  />
                );
                break;
              case 7:
                renderComponent.push(
                  <Layout7
                    title={title}
                    text={parse(text)}
                    date={date}
                    endDate={endDate}
                    fixedValue={fixedValue}
                    author={author}
                    multimedia={multimedia}
                    place={place}
                    responsible={responsible}
                    id={element.id}
                    // key={element.id}
                    theme={theme ? theme : null}
                    buttons={element.buttons}
                    individualStyle={
                      this.props.individualStyle
                        ? this.props.individualStyle
                        : null
                    }
                    events={this.props.events ? true : null}
                    news={this.props.news ? true : null}
                    courses={this.props.courses ? true : null}
                    donations={this.props.donations ? true : null}
                    containerTitle={this.props.containerTitle}
                    lpId={this.props.lpId}
                  />
                );
                break;
          }
        });
      }
    }
    return renderComponent;
  };

  render() {
    const theme = this.props.theme;
    const { layout } = this.props;
    let borderColorButton = "#008cba";
    let borderColorButtonHover = "#008cba";
    if (theme != null) {
      borderColorButton = theme.sectionTitle.color;
      borderColorButtonHover = theme.sectionSubtitle.color;
    }

    return (
      <>
        {this.props.layout === 4 ? (
          <div className="wow fadeInRight">
            <CarouselLayout
              theme={theme ? theme : null}
              events={this.props.events ? true : null}
              news={this.props.news ? true : null}
              courses={this.props.courses ? true : null}
              donations={this.props.donations ? true : null}
              containerTitle={this.props.containerTitle}
              lpId={this.props.lpId}
              components={this.props.components}
            />
          </div>
        ) : null}
        {this.props.layout === 3 ? (
          <div className="row wow fadeInRight generalCenterContainerLayout">
            {this.renderLayoutIndex(this.props.components)}
          </div>
        ) : null}
        {layout == 6 ? (
          <div className="row wow fadeInRight generalCenterContainerLayout" >
              {this.renderLayoutIndex(this.props.components)}
          </div>
        ) : null}
        {layout != 3 && layout != 4 && layout != 6 ? (
          <div className="row wow fadeInRight generalContainerLayout" >
              {this.renderLayoutIndex(this.props.components)}
          </div>
        ) : null}
      </>
    );
  }
}
export default LayoutIndex;
