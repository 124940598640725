import React from "react";

export function HeaderLogoAndTextLayout(props) {
  let marginTop = "4px";
  let marginBottom = "4px";
  const check = () => {
    return window.location.host == "parroquiasantaelenasv.com"
  }
  const changeImage = (imageUrl) => {
    if(window.location.host == "parroquiasantaelenasv.com") {
      return imageUrl.replace("small", "large")
    }
    if(window.location.host == "pandevidacer.org" || window.location.host == "www.pandevidacer.org") {
      return imageUrl.replace("small", "large")
    }
    return imageUrl;
  }
  return (
    <div id="logoandtext" style={check() ? { height: "100%", display: "flex" } : {}}>
        <img style={{ width: check() ? "200px" : "auto", maxHeight: check() ? "" : "9vh", marginRight: "5vw"}} src={changeImage(props.icon)} />

      {
        props.headerTitle ? 
        <h1
          style={
            props.theme && props.theme.headerName ? props.theme.headerName : null
          }
        >
          
          <p
            // href="/#home"
            style={{
              fontSize: "2rem",
              color: "white",
            }}
          >
            {props.headerTitle}
          </p>
        </h1>
        : null
      }

    </div>
  );
}
