import React, { Component } from "react";
import "../../../index.css";
import { getLpSectionInformation, getFilePath } from "../../../actions/index";
import LinktreeLayoutIndex from "./layoutStyles/LinktreeLayoutIndex.jsx";
class LinktreeSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      containerTitle: null,
      backImg: {
        id: null,
        s3path: null,
        imageUrl: null,
        image: null,
        loading: false,
      },
      backColor: null,
      buttonsColor: null,
      linksShown: null,
      buttons: [],
    };
  }

  componentDidMount() {
    this.getLinkTreeInformation();
  }

  // Queries
  async getLinkTreeInformation() {
    const response = await getLpSectionInformation(
      this.props.lpId,
      this.props.lpSectionId,
      "LINKTREE"
    );

    if (String(response).includes("Error:")) {
      Modal.error({
        title: Strings.modal.internalServerError,
        content: Strings.modal.internalServerErrorContactSupport,
      });
    } else {
      const { backImg } = { ...this.state };

      const sectionInfo = response.data.data;
      const containerComponent = sectionInfo.components.find(
        (comp) => comp.name === "LinkTreeContainer"
      );
      const containerTitle = containerComponent.nodes.find(
        (node) => node.nodeType === "LinkTreeContainerTitle"
      );
      const backColor = containerComponent.nodes.find(
        (node) => node.nodeType === "LinkTreeContainerBackgroundColor"
      );
      const buttonsColor = containerComponent.nodes.find(
        (node) => node.nodeType === "LinkTreeButtonsColor"
      );
      const linksShown = containerComponent.nodes.find(
        (node) => node.nodeType === "LinkTreeContainerMaxButtonsShown"
      ).value;
      const linkTreeNewTabFlag = containerComponent.nodes.find(
        (node) => node.nodeType === "LinkTreeNewTabFlag"
      ).value;
      // const linkTreeNewTabFlag = 0;
      const pinnedButtons = sectionInfo.components.find(
        (comp) => comp.name === "LinkTreePinnedComponent"
      ).buttons
        ? sectionInfo.components.find(
            (comp) => comp.name === "LinkTreePinnedComponent"
          ).buttons
        : [];
      const tempButtons = sectionInfo.components.find(
        (comp) => comp.name === "LinkTreeTempComponent"
      ).buttons
        ? sectionInfo.components.find(
            (comp) => comp.name === "LinkTreeTempComponent"
          ).buttons
        : [];
      backImg.s3path = containerComponent.nodes.find(
        (node) => node.nodeType === "LinkTreeContainerBackgroundImage"
      )
        ? containerComponent.nodes.find(
            (node) => node.nodeType === "LinkTreeContainerBackgroundImage"
          ).value
        : null;
      backImg.image = null;

      // Get images
      if (backImg.s3path !== "" && backImg.s3path !== null) {
        let imgName = backImg.s3path
          .replace(".jpeg", "")
          .replace(".jpg", "")
          .replace(".png", "");
        let format = backImg.s3path.match(/\.([^.]*)$/)[1];

        let sizeImage = "largeSize" // large Size
        if(window.innerWidth < 900) { // mobile 
          sizeImage = "mediumSize"; //medium Size
        }
        let imgResponse = await getFilePath(imgName, sizeImage, format);
        backImg.image = imgResponse;
      }

      if (backColor.value !== "transparent") {
        backColor.value = backColor.value.replaceAll("'", "");
        backColor.value = this.hexToRgbA(backColor.value);
      }

      if (buttonsColor.value !== "transparent") {
        buttonsColor.value = buttonsColor.value.replaceAll("'", "");
        buttonsColor.value = this.hexToRgbA(buttonsColor.value);
      }
      let layout = sectionInfo.lpSectionLayoutStyleId;
      if (layout === 13) {
        //one column layout linktree
        layout = 0;
      }
      if (layout === 81) {
        //two column layout linktree
        layout = 1;
      }
      if(process.env.REACT_APP_DEFAULT_LAYOUT_LINKTREE){
        layout = parseInt(process.env.REACT_APP_DEFAULT_LAYOUT_LINKTREE);
      }
      // layout = 1;
      this.setState({
        containerTitle: containerTitle.value,
        backImg,
        backColor: backColor.value,
        buttonsColor: buttonsColor.value,
        linksShown: parseInt(linksShown, 10),
        linkTreeNewTabFlag: linkTreeNewTabFlag,
        buttons: pinnedButtons.concat(tempButtons).slice(0, linksShown),
        loadingSection: false,
        loadingInfo: false,
        layout,
      });
    }
  }

  hexToRgbA(hex) {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split("");
      if (c.length == 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = "0x" + c.join("");
      return (
        "rgb(" + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",")
      );
    }
    throw new Error("Bad Hex");
  }

  renderComponentLinktree() {
    let renderedComponent = [];
    const { theme } = this.props;
    const {
      containerTitle,
      backImg,
      backColor,
      buttons,
      buttonsColor,
      linkTreeNewTabFlag,
    } = this.state;
    renderedComponent.push(
      <LinktreeLayoutIndex
        // key={this.props.lpSectionId}
        key={Math.random() * 1000}
        theme={theme}
        containerTitle={containerTitle}
        backImg={backImg}
        backColor={backColor}
        buttons={buttons}
        buttonsColor={buttonsColor}
        linkTreeNewTabFlag={linkTreeNewTabFlag}
        layout={this.state.layout}
      />
    );
    return renderedComponent;
  }

  render() {
    return (
      <div id="features" style={{marginTop: "20vh"}}>
        <div>{this.renderComponentLinktree()}</div>
      </div>
    );
  }
}
export default LinktreeSection;
