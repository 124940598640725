import React from "react";
import "../index.scss";
import { Box } from "@mui/material"
import Carousel from "react-material-ui-carousel";
import imageNull from "../../../../images/noImage.jpg";
import "../../team/index.css"
export function GalleryCarouselLayout(props) {
  const { theme, photos } = props;
  const renderSlides = () =>
    photos.map(element => {
      let image = element.imgL ? element.imgL : imageNull ;
      if(window.innerWidth < 900) { // mobile 
        image = element.imgM ? element.imgM : image;
      }
      return (
        <Box sx={{ display: "flex", justifyContent: "center" }}>          
          <img src={image} alt="image gallery" className="imageGallery" style={{justifyContent: 'flex-start'}}/>
        </Box>
      )
    });

  return (
    <div className="wow fadeInRight" style={{justifyContent: 'center'}}>
      {
        photos && photos.length > 2 ?
        <Carousel
          autoPlay={true}
          indicators={true}
          swipe={true}
          fullHeightHover={true}
          cycleNavigation={true}
        >
          {renderSlides()}
        </Carousel>
        :
        <div className="centerImage">
          <img src={photos && photos[0].imgL ? photos[0].imgL : imageNull} 
          alt="image gallery" className="imageGalleryOnlyOne" />
        </div>
      }
      
    </div>
  );
}
