import React, { Component } from "react";
import "../../stylesLP.css";
import { StandarTeamLayout } from "./StandarTeamLayout.jsx";
import { CircularImageTeamLayout } from "./CircularImageTeamLayout.jsx";
import { TeamMoveDownLayout } from "./TeamMoveDownLayout.jsx";
import { TeamMoveUpLayout } from "./TeamMoveUpLayout.jsx";
import { TeamMoveDiagonallyLayout } from "./TeamMoveDiagonallyLayout.jsx";
import { TeamTurnCardLayout } from "./TeamTurnCardLayout.jsx";
import { TeamLayout5 } from "./TeamLayout5.jsx";

class TeamLayoutIndex extends Component {
  renderTeamComponent() {
    let renderedComponent = [];
    switch (this.props.layout) {
      case 0:
        renderedComponent.push(
          <StandarTeamLayout
            id={this.props.id}
            key={this.props.id}
            name={this.props.name}
            position={this.props.position}
            text={this.props.text}
            image={this.props.image}
            theme={this.props.theme}
            // layout={1}
          />
        );
        break;
      case 1:
        renderedComponent.push(
          <TeamMoveDownLayout
            id={this.props.id}
            key={this.props.id}
            name={this.props.name}
            position={this.props.position}
            text={this.props.text}
            image={this.props.image}
            theme={this.props.theme}
          />
        );
        break;
      case 2:
        renderedComponent.push(
          <TeamMoveUpLayout
            id={this.props.id}
            key={this.props.id}
            name={this.props.name}
            position={this.props.position}
            text={this.props.text}
            image={this.props.image}
            theme={this.props.theme}
          />
        );
        break;
      case 3:
        renderedComponent.push(
          <TeamMoveDiagonallyLayout
            id={this.props.id}
            key={this.props.id}
            name={this.props.name}
            position={this.props.position}
            text={this.props.text}
            image={this.props.image}
            theme={this.props.theme}
          />
        );
        break;
      case 4:
        renderedComponent.push(
          <TeamTurnCardLayout
            id={this.props.id}
            key={this.props.id}
            name={this.props.name}
            position={this.props.position}
            text={this.props.text}
            image={this.props.image}
            theme={this.props.theme}
          />
        );
        break;
      case 5:
        renderedComponent.push(
          <TeamLayout5
            id={this.props.id}
            key={this.props.id}
            name={this.props.name}
            position={this.props.position}
            text={this.props.text}
            image={this.props.image}
            theme={this.props.theme}
          />
        );
        break;
      case 7:
        renderedComponent.push(
          <CircularImageTeamLayout
            id={this.props.id}
            key={this.props.id}
            name={this.props.name}
            position={this.props.position}
            text={this.props.text}
            image={this.props.image}
            theme={this.props.theme}
            // layout={1}
          />
        );
        break;
    }
    return renderedComponent;
  }

  render() {

    return (
      <div className={`col-xs-12 col-sm-6 col-md-4 col-lg-3 containerTeam`} >
        {this.renderTeamComponent()}
      </div>
    );
  }
}
export default TeamLayoutIndex;
