import LocalizedStrings from "react-localization";

let strings = new LocalizedStrings({
  es: {
    basicWords: {
      accept: "Aceptar",
      actionNotAllowed: "Acción no permitida",
      add: "Agregar",
      addBackground: "Agregar fondo",
      addGroup: "Añadir grupo",
      addGroupSubpageOrSubpage: "Agregar grupo de subpáginas o subpágina",
      address: "Dirección",
      addNewSubpageOrSubpagegroup:
        "Si deseas agregar una nueva subpágina o grupo de subpágina, debes  deshabilitar alguna(o) que ya esté creado",
      addSection: "Agregar nueva sección",
      addSubpage: "Agregar nueva subpágina",
      all: "Todo",
      approved: "Aprobado",
      back: "Atrás",
      backgroundImage: "Imagen de fondo",
      buttonColor: "Color de botones",
      cancel: "Cancelar",
      cancelText: "No",
      cantDeleteSections:
        "Las secciones encabezado, inicio, contáctanos o píe de pagina, no se pueden eliminar de la página principal",
      cantMoreSections: "No puedes tener más de diez secciones.",
      cantMoveSectionsOfMainpage:
        "Las secciones encabezado, inicio, contáctanos o píe de pagina, deben permanecer en la página principal",
      cardAddAlwaysAtEnd: "La tarjeta de agregar siempre debe estar al final",
      city: "Ciudad",
      colorBackground: "Color de fondo",
      close: "Cerrar",
      closeDate: "Fecha de cierre",
      component: "Componente",
      confirmationDate: "Fecha de confirmación",
      continue: "Continuar",
      country: "País",
      date: "Fecha",
      dateEvent: "Fecha del evento",
      deleteSubpageOrGroupsubpage: "Suprimir subagina o grupo de subpágina",
      deletePageSection: "Suprimir la sección de la página",
      deleteRegister: "¿Está seguro de que desea borrar este registro?",
      deleteSelectedRegisters:
        "¿Está seguro de que desea borrar los registros seleccionados?",
      deleteAllRegisters:
        "¿Está seguro de que desea borrar todos los registros?",
      deleteSection: "¿Está seguro de que desea borrar la sección?",
      description: "Descripción",
      donate: "Donar",
      edit: "Editar",
      editImage: "Editar imagen",
      editEvents: "Editar eventos",
      editGroup: "Editar grupo",
      editPhoto: "Editar foto",
      editSection: "Editar la sección",
      endTime: "Tiempo final",
      enterAmount: "Valor",
      enterName: "Introduzca el nombre",
      enterPos: "Introduzca la posición",
      enterStreamingPage: "Introduzca página de los en vivo",
      enterText: "Introduzca el texto",
      enterTitle: "Introduzca el título",
      enterLogo: "Añade el logo",
      erase: "Borrar",
      error: "Error",
      expired: "Expirado",
      goTo: "Llévame",
      goToMyWebPage: "Llévame a mi página",
      GoToWithoutSave: "Estas seguro que deseas salir sin guardar los cambios",
      example: "Ejemplo",
      generalInformation: "Información general",
      groupList: "Lista de grupos",
      groupSubpages: "Grupo de subpágina",
      id: "Cédula",
      image: "Imagen",
      information: "Información",
      link: "Link",
      list: "Listo",
      listCourses: "Lista de cursos",
      listEvents: "Lista de eventos",
      listNews: "Lista de noticias",
      listStreamings: "Lista de en vivo",
      load: "Cargar",
      loading: "Cargando",
      lptypeId: "Tipo de landing page",
      mainpage: "Página principal",
      mainpageAtTheTop:
        "La página principal debe estar siempre en la parte superior y la tarjeta adicional en la parte inferior.",
      moremMovements:
        "Para realizar más movimientos, debe guardar y actualizar la página",
      movementNotAllowedMainpage:
        "La página principal debe estar siempre en la primera posición",
      movementNotAllowedAdd:
        "La tarjeta de agregar debe estar siempre en la última posición",
      name: "Nombre",
      nameSubpageOrGroupSubpage:
        "La subpágina o grupo de subpágina debe tener un nombre. El nombre no puede estar vacío",
      no: "No",
      note: "Nota",
      notice: "Aviso",
      numberUsers: "Número de usuarios",
      okText: "Sí",
      organizationType: "Tipo de organización",
      payments: "Pagos",
      paymentPlatforms: "Plataformas de pago",
      pending: "Pendiente",
      phoneContact: "Teléfono de contacto",
      place: "Lugar",
      position: "Cargo",
      rangeDate: "Rango de fechas",
      rangeDateEvents: "Rango de fechas de los eventos",
      readMore: "Leer más",
      recurrence: "Recurrencia",
      region: "Región",
      rejected: "Rechazado",
      responsible: "Responsable",
      role: "Rol",
      save: "Guardar",
      section: "Sección",
      sectionsCanOnlyInMainPageOrSubpage:
        "Las secciones solo pueden estar en la pagina principal o en subpáginas",
      sectionMustBeInSubpage:
        "Las sección que deseas mover debe estar en una subpágina para poder ser visualizada",
      sectionsStructureMainPage:
        "Las secciones encabezado, inicio, contáctanos y píe de página deben permanecer al inicio y final de la página principal para conservar su estructura",
      select: "Seleccione",
      selectBackgroundImage: "Selecciona una imagen de fondo",
      selectColorBackground: "Selecciona un color de fondo",
      selectorTheme: "Selector de temas",
      selectorLayout: "Selector de estilo",
      showLess: "Ver menos",
      showMore: "Ver más",
      startDate: "Fecha de inicio",
      startTime: "Hora de inicio",
      status: "Estado",
      streamingPage: "Página de streaming",
      subpage: "Subpágina",
      subpagesCanOnlyInGroupSubpages:
        "Las subpáginas solo pueden estar en grupos de subpáginas",
      telephone: "Teléfono",
      text: "Texto",
      theme: "Tema",
      thumbnail: "Thumbnail",
      title: "Título",
      typeofGroup: "Tipo de grupo",
      typeUser: "Tipo de usuario",
      unlimited: "Ilimitada",
      value: "Valor",
      visibleSubpage:
        "Habilitar o deshabilitar que se visualice la subpágina en la página web",
      webAddress: "Dirección web",
    },
    buttons: {
      addButton: "Agregar botón",
      buttons: "Botones",
      buttonsTooltip:
        "Agrega botones para vincular otros componentes (Formularios, Donaciones, Noticias, Eventos, etc) o enlaces externos",
      editButton: "Editar botón",
      externalLink: "Link externo",
      externalLinkTooltip:
        "Vincula este botón a un link de un sitio web externo",
      moreNews: "Mostrar más noticias",
      nameTooltip: "Texto visible en el botón",
      noAssignedButtons: "No hay botones asignados",
      ownComponent: "Componente propio",
      ownComponentTooltip:
        "Vincula este botón a otro componente propio de tu sitio como una noticia, evento, formulario o una donación",
    },
    calendarComponents: {
      addCalendar: "Agregar al calendario",
      error: "Error",
      errorEvent: "Este es un evento de error",
      errorEvent1: "Este es el evento de error 1",
      errorEvent2: "Este es el evento de error 2",
      errorEvent3: "Este es el evento de error 3",
      errorEvent4: "Este es el evento de error 4",
      success: "Éxito",
      usualEvent: "Este es un evento habitual",
      veryLongUsualEvent: "Este es un evento usual muy largo",
      warning: "Alerta",
      warningEvent: "Este es un evento de advertencia",
    },
    donations: {
      addDonation: "Añadir donación",
      addDonationText:
        "¡Crea donaciones que apoyen el crecimiento de tu comunidad!",
      donationsList: "Lista de donaciones",
      donationType: "Buscar por tipo de donación",
      paymentPlatform: "Plataforma de pago",
      tax: "Impuesto",
      typeTooltip:
        "Filtra las donaciones dependiendo de su estado de actividad. Ilimitadas: Sin fecha de cierre, Cerradas: Fecha de cierre ya pasó, Abiertas: Ilimitadas y fecha de cierre no ha pasado, Todas: Abiertas y cerradas",
      types: {
        all: "Todas",
        open: "Abiertas",
        closed: "Cerradas",
        unlimited: "Ilimitadas",
      },
      voluntary: "Abierto",
    },
    footer: {
      rigthsReserved: "Todos los derechos reservados",
      developedBy: "desarrollado por",
    },
    generalMessages: {
      addCourse: "Añadir curso",
      addCourseText: "¡Crea increíbles cursos para formar a tu comunidad!",
      addSection: "Añadir sección",
      addSectionPosibilities: "Secciones que puedes añadir",
      addImage: "Añadir imagen",
      addImages: "Añadir imágenes",
      addVideo: "Añadir video",
      addMember: "Añadir miembro del equipo",
      addMemberText:
        "¡Agrega a las personas increíbles que conforman tu equipo!",
      addPhotoGallery: "Añadir foto a galería",
      addPhotoGalleryText:
        "¡Una imagen vale más que mil palabras! Agrega las mejores fotos para compartir con tu comunidad.",
      assignedUsers: "Usuarios asignados",
      changingPositionHelper: "Cambiar la posición de la sección en la página",
      checkURLError: "Por favor comprueba la URL.",
      contrary: "De lo contrario, ",
      credentialsError: "Por favor verifique las credenciales",
      downsection: "Bajar la sección en la página",
      editMember: "Editar miembro",
      editEvent: "Editar evento",
      editNews: "Editar noticia",
      errorBackend: "Error: No es posible conectar con el backend",
      errorChangePassword:
        "Ocurrió un error al cambiar la contraseña. Por favor intente de nuevo",
      errorConect: "Error de conexión",
      followUs: "Síguenos",
      helpLocalizedPage: "Posición en la que se ubica la sección en la página",
      hereClick: "Haz clic aquí",
      imageGallery: "Imagen de nuestra galería",
      loading: "Cargando...",
      localizedPage: "Posición en la página",
      numberNews: "Noticias para mostrar",
      pageNotFound: "Error: Página no encontrada",
      passwordNoMatch:
        "Las nuevas contraseñas no coinciden. Por favor verifique",
      preview: "Vista previa",
      redirectionPage: "para ser redirigido a la página de inicio.",
      sucessChangePassword: "Éxito al cambiar la contraseña",
      test: "Prueba",
      treeSections: "Árbol de secciones",
      unauthorized: "Error: no autorizado",
      upsection: "Subir la sección en la página",
      url: "URL",
    },
    generalUser: {
      addEvent: "Añadir evento",
      addNews: "Añadir noticia",
      addNewsText: "¡Crea increíbles noticias para compartir con tu comunidad!",
      addEvent: "Añadir evento",
      addEventText: "¡Crea increíbles eventos para compartir con tu comunidad!",
      addRoleAndPrivileges: "Añadir rol y asignar privilegios",
      addStreaming: "Añadir en vivo",
      addUser: "Añadir usuario",
      apostolicGroupBelong: "Grupo apostólico al que pertenece",
      auraApp: "AurApp",
      assigningRoleToUsers: "Asignación de roles a usuarios",
      availablePrivileges: "Privilegios disponibles",
      availableUsers: "Usuarios disponibles",
      changePassword: "Cambiar contraseña",
      currentPassword: " Contraseña actual",
      dateOfBirth: "Fecha de nacimiento",
      documentIdNumber: "Documento de identidad",
      editRoleAndPrivileges: "Editar rol y asignar privilegios",
      editUser: "Editar usuario",
      editNews: "Editar noticia",
      editStreaming: "Editar en vivo",
      generalParametres: "Parámetros generales",
      groups: "Grupos",
      identityCardNumber: "Número de documento",
      inputPassword: "Ingrese la contraseña",
      lastName1: "Primer apellido",
      lastName2: "Segundo apellido",
      logOut: "Cerrar sesión",
      myaccount: "Mi cuenta",
      myGroupSubpage: "Mi grupo de subpáginas",
      myPage: "Mi página",
      mySubpage: "Mi subpágina",
      myUsers: "Usuarios",
      newPassword: "Nueva contraseña",
      pages: "Páginas",
      pagesList: "Lista de páginas",
      personalInformation: "Datos personales",
      repeatNewPassword: "Repetir contraseña nueva",
      roles: "Roles",
      rolesList: "Lista de Roles",
      rolesPrivileges: "Roles y Privilegios",
      contentMyPage: "Contenido de mi página",
      contentMyPageTooltip:
        "Aquí podrás configurar el contenido de tu página web, como la página principal, subpáginas y grupos de subpáginas. El orden del encabezado tendrá la siguiente estructura: Primero, se visualizan las secciones que se encuentran en la página principal. Después, las subpáginas y grupos de subpáginas conservarán el orden que tu configures en esta página.",
      styleConfig: "Configuración de estilo",
      unassignedUsers: "Usuarios sin asignar",
      User: "Usuario",
      userEmail: "Correo electrónico",
      userListbyrole: "Lista de usuarios por rol",
      username: "Nombre de usuario",
      users: "Mis usuarios",
      usersList: "Lista de usuarios",
      tooltipMainpage:
        "Se refiere a las secciones que serán visualizadas en la página principal de tu página web. Estas secciones aparecen al inicio del encabezado según el orden que elijas.",
      tooltipSubpage:
        "En una subpágina podrás incluir las secciones que desees de manera independiente de la página principal. En el encabezado se visualizará el nombre de la subpágina, sin las secciones.",
      tooltipGroupsubpage:
        "En un grupo de subpáginas podrás agrupar subpáginas de tal manera que en el encabezado se visualiza un menú (nombre del grupo de subpáginas) que permite desplegar un submenú con las subpáginas asociadas a éste.",
    },
    layout: {
      coursesTitleCardsLayoutDescription:
        "Visualización en tarjetas. Sólo se observa el título y la descripción.",
      coursesTitleCardsLayoutTitle: "Estilo tarjetas",
      coursesCarouselLayoutDescription:
        "Carrusel de cursos. Se desplazan horizontalmente",
      coursesCarouselLayoutTitle: "Estilo carrusel",
      coursesDisplayGridLayoutDescription: "Visualización tipo cuadrícula",
      coursesDisplayGridLayoutTitle: "Estilo cuadrícula",
      coursesImageLeftLayoutDescription:
        "Imagen a la izquierda. Texto a la derecha. Botones en la parte inferior",
      coursesImageLeftLayoutTitle: "Estilo imagen a la izquierda",
      donationsDisplayGridLayoutDescription: "Visualización tipo cuadrícula",
      donationsDisplayGridLayoutTitle: "Estilo cuadrícula",
      donationsImageLeftLayoutDescription:
        "Imagen a la izquierda. Texto a la derecha. Botones en la parte inferior",
      donationsImageLeftLayoutTitle: "Estilo imagen a la izquierda",
      eventsCardsLayoutDescription:
        "Visualización en tarjetas. Sólo se observa el título y la descripción",
      eventsCardsLayoutTitle: "Estilo tarjetas",
      eventsCarouselLayoutDescription:
        "Carrusel de eventos. Se desplazan horizontalmente",
      eventsCarouselLayoutTitle: "Estilo carrusel",
      eventsImageLeftLayoutDescription:
        "Imagen a la izquierda. Texto a la derecha. Botones en la parte inferior",
      eventsImageLeftLayoutTitle: "Estilo imagen a la izquierda",
      eventsImageRigthLayoutDescription:
        "Imagen a la derecha. Texto a la izquierda. Botones en la parte inferior",
      eventsImageRigthLayoutTitle: "Estilo imagen a la derecha",
      galleryBlackAndWhiteDescription:
        "Imágenes a blanco y negro. Cuando pasa el puntero sobre ellas, toman color",
      galleryBlackAndWhiteTitle: "Estilo imágenes blanco y negro",
      galleryMoveImageDescription:
        "Las imágenes se mueven cuando se pasa el puntero sobre ellas",
      galleryMoveImageTitle: "Estilo imágenes con movimiento",
      headerLogoDescription: "Logo en vez del nombre",
      headerLogoTitle: "Estilo logo",
      homeOneImageLayoutDescription: "Una imagen en vez de tres",
      homeOneImageLayoutTitle: "Estilo una imagen",
      homeSlideImagesLayoutDescription:
        "Las imágenes se van desplazando una a una automáticamente",
      homeSlideImagesLayoutTitle:
        "Estilo imágenes desplazándose horizontalmente",
      homeVideoLayoutDescription: "Video en el fondo de la sección inicio",
      homeVideoLayoutTitle: "Estilo vídeo",
      newsCardsLayoutDescription:
        "Visualización en tarjetas. Sólo se observa el título y la descripción.",
      newsCardsLayoutTitle: "Estilo tarjetas",
      newsCarouselLayoutDescription:
        "Carrusel de noticias. Se desplazan horizontalmente",
      newsCarouselLayoutTitle: "Estilo carrusel",
      newsDisplayGridLayoutDescription: "Visualización tipo cuadrícula",
      newsDisplayGridLayoutTitle: "Estilo cuadrícula",
      newsImageLeftLayoutDescription:
        "Imagen a la izquierda. Texto a la derecha. Botones en la parte inferior",
      newsImageLeftLayoutTitle: "Estilo imagen a la izquierda",
      standarAboutUsLayoutDescription: "Vista estándar",
      standarAboutUsLayoutTitle: "Estilo estándar de nosotros",
      standarCoursesLayoutDescription: "Vista estándar",
      standarCoursesLayoutTitle: "Estilo estándar",
      standarDonationsLayoutDescription: "Vista estándar",
      standarDonationsLayoutTitle: "Estilo estándar",
      standarEventsLayoutDescription: "Vista estándar",
      standarEventsLayoutTitle: "Estilo estándar",
      standarGalleryLayoutDescription: "Vista estándar",
      standarGalleryLayoutTitle: "Estilo estándar",
      standarHeaderLayoutDescription: "Vista estándar",
      standarHeaderLayoutTitle: "Estilo estándar",
      standarHomeLayoutDescription: "Vista estándar",
      standarHomeLayoutTitle: "Estilo estándar",
      standarLinktreeLayoutDescription: "Vista estándar",
      standarLinktreeLayoutTitle: "Estilo estándar de enlaces de interés",
      standarNewsLayoutDescription: "Vista estándar",
      standarNewsLayoutTitle: "Estilo estándar",
      standarSocialNetworksLayoutDescription: "",
      standarSocialNetworksLayoutTitle: "Estilo estándar de redes sociales",
      standarStreamingsLayoutDescription: "Vista estándar",
      standarStreamingsLayoutTitle: "Estilo estándar",
      standarTeamLayoutDescription: "Vista estándar",
      standarTeamLayoutTitle: "Estilo estándar",
      teamLayoutDiagonallyDescription:
        "Desplazamiento diagonal cuando el puntero pasa sobre la imagen",
      teamLayoutDiagonallyTitle: "Estilo movimiento diagonal",
      teamFlipCardLayoutDescription:
        "Giro de la tarjeta cuando el puntero pasa sobre la imagen",
      teamFlipCardLayoutTitle: "Estilo giro de tarjeta",
      teamLayoutScrollDownDescription:
        "Desplazamiento hacia abajo cuando el puntero pasa sobre la imagen",
      teamLayoutScrollDownTitle: "Estilo movimiento hacia abajo",
      teamLayoutScrollUpDescription:
        "Desplazamiento hacia arriba cuando el puntero pasa sobre la imagen",
      teamLayoutScrollUpTitle: "Estilo movimiento hacia arriba",
    },
    linktree: {
      linksShown: "Enlaces a mostrar",
      openInNewTab: " Abrir enlaces en nueva pestaña",
    },
    login: {
      enterUserAndPressOk: "Ingrese su usuario y presione Aceptar",
      enterValidPassword: "Por favor ingrese una contraseña válida",
      enterValidUser: "Por favor ingrese un usuario válido",
      exit: "Salir",
      failedProcess:
        "Proceso fallido. No se pudo enviar el correo para reestablecer la contraseña",
      forgotMyPassword: "Olvidé mi contraseña",
      invalidPasswordOrUser:
        "Usuario o contraseña no validos / Si el error persiste contacte con el Administrador",
      linkSentToResetPassword:
        "Se enviará un link al correo electrónico registrado para reestablecer su contraseña",
      logIn: "Ingresar",
      password: "Contraseña",
      recoveryPassword: "Recuperación de contraseña",
      successfulProcess:
        "Proceso exitoso. Hemos enviado un correo con un link para reestablecer la contraseña",
    },
    modal: {
      assignmentSuccessful: "Asignación exitosa",
      contactSuccessfullyEdited: "El contacto fue editado con éxito",
      componentUnselected: "No se ha seleccionado el componente",
      courseSuccessfullyCreated: "El curso fue creado con éxito",
      disablingSuccessful: "Deshabilitación exitosa",
      disassignedSuccessfully: "Desasignación exitosa",
      editionSuccess: "Edición exitosa",
      errorAuthentication: "Error de autenticación",
      errorAuthenticationToLogin:
        "Se presentó un error en la autenticación, será redireccionado al inicio",
      eventSuccessfullyDisabled: "El evento fue deshabilitado exitosamente",
      eventSuccessfullyEdited: "El evento fue editado con éxito",
      eventSuccessfullyCreated: "El evento fue creado con éxito",
      fieldEmpty: "Uno de los campos se encuentra vacío",
      donationSuccesfullEdited: "La donación fue editada con éxito",
      donationSuccesfullCreated: "La donación fue creada con éxito",
      donationSuccesfullDisabled: "La donación fue deshabilitada con éxito",
      groupSuccesfullEdited: "El grupo fue editado con éxito",
      groupSuccesfullCreated: "El grupo fue creado con éxito",
      groupSuccesfullDisabled: "El grupo fue deshabilitado con éxito",
      headerSuccessfullyEdited: "El encabezado fue editado con éxito",
      footerSuccessfullyEdited: "El pie de página fue editado con éxito",
      homeSuccessfullyEdited: "El inicio fue editado con éxito",
      internalServerError: "Error interno del servidor",
      internalServerErrorContactSupport:
        "Por favor pruebe más tarde o contacte a soporte técnico",
      linkButtonEmpty: "El lnik del botón no puede estar vacío",
      memberSuccessfullyAdded: "El integrante fue añadido con éxito",
      memberSuccessfullyDisabled:
        "El integrante fue deshabilitado exitosamente",
      memberSuccessfullyEdited: "El integrante fue editado con éxito",
      modifiedSuccesfully: "Modificación exitosa",
      nameButtonEmpty: "El nombre del botón no puede estar vacío",
      newsSuccessfullyCreated: "La noticia fue creada con éxito",
      newsSuccessfullyDisabled: " La noticia fue deshabilitada exitosamente",
      newsSuccessfullyUpdate: "La noticia fue actualizada con éxito ",
      noOrganizationSelected: "No se seleccionó ninguna organización",
      noSectionSelected: "No se seleccionó ninguna sección",
      notSelectRol: "No ha seleccionado al menos un rol",
      notValidEmail: "El correo electrónico no es válido",
      notValidID: "El documento de identidad no es válido",
      notValidLastname: "El primer apellido no es válido",
      notValidName: "El nombre no es válido",
      notValidphone: "El teléfono no es válido",
      notValidUsername: "El nombre de usuario no es válido",
      organizationNotSelected: " Organización no seleccionada",
      organizationSuccesfullCreated: "La organización fue creada con éxito",
      organizationSuccesfullDisabled:
        "La organización fue deshabilitada exitosamente",
      organizationSuccesfullEdited: "La organización fue editada con éxito",
      photoSuccessfullyAdded: "La foto fue añadida con éxito",
      photoSuccessfullyDisabled: " La foto fue deshabilitada exitosamente",
      photoSuccessfullyEdited: "La foto fue editada con éxito",
      positionSectionSuccessfullyModified:
        "La posición fue modificada exitosamente",
      privilegeSuccessfullyDisengaged:
        "El privilegio fue desasignado exitosamente",
      roleSuccesfullyAdded: "El rol fue añadido con éxito",
      roleSuccesfullyDisabled: "El rol fue deshabilitado exitosamente",
      roleSuccesfullyEdited: "El rol fue editado con éxito",
      sectionDisabledSuccessful: "La sección fue deshabilitada exitosamente",
      sectionUnselected: "Sección no seleccionada",
      successfulCreation: "Creación exitosa",
      successfulDisability: "Deshabilitación exitosa",
      successfulEdition: "Edición exitosa",
      updateSuccessful: "Actualización exitosa",
      userAlreadyCrated: "El usuario ya se encuentra creado",
      userDisassignedSuccessfully: "El usuario fue desasignado exitosamente",
      usersSuccesfullyAssigned: "Los usuarios fueron asignados con éxito",
      userSuccesfullyCreated: "El usuario fue creado con éxito",
      userSuccesfullyDisabled: "El usuario fue deshabilitado exitosamente",
      userSuccesfullyEdited: "El usuario fue editado con éxito",
      userSuccesRemovedRole: "El usuario fue desasignado exitosamente del rol",
      userUpdatedSuccessfully: "Usuario actualizado con éxito",
      visibilitySubpage:
        "La visibilidad de la subpágina fue modificada exitosamente",
      wrongFile: "Campo incorrecto",
    },
    organization: {
      addOrganization: "Añadir organización",
      editOrganization: "Editar organización",
      myOrganization: "Mi organización",
      organization: "Organización",
      organizationList: "Lista de organizaciones",
      organizations: "Organizaciones",
    },
    sections: {
      aboutUs: "Nosotros",
      author: "Autor",
      contactUs: "Contáctanos",
      courses: "Cursos",
      donations: "Donaciones",
      events: "Eventos",
      footer: "Pie de página",
      forms: "Formularios",
      gallery: "Galería",
      header: "Encabezado",
      home: "Inicio",
      infoPage: "Información página",
      linkFacebook:
        "Agrega el link de la página de Facebook. Por ejemplo: https://www.facebook.com/SOMOS-AURA-114538460262706",
      linkInstagram: "Introduzca el link de Instagram",
      linkPostInstagram:
        "Agrega el link del post de instagram. Por ejemplo: https://www.instagram.com/p/CHVtSlcDM3A/",
      linkTree: "Enlaces de interés",
      linkTwitter: "Introduzca el link de Twitter",
      linkAurapp: "Introduzca el link de Aurapp",
      mision: "Misión",
      news: "Noticias",
      sections: "Secciones",
      socialnetworks: "Redes sociales",
      streamings: "En vivo",
      publicationDate: "Fecha de publicación",
      previsualization: "Previsualización",
      team: "Equipo",
      UsernameTwitter: "Agrega tu nombre de usuario. Por ejemplo: auraSomos",
      vision: "Visión",
    },
    scheduleEventType: {
      mass: "Misa",
      rosary: "Rosario",
      chapletOfTheDivineMercy: "Coronilla de la Divina Misercordia",
      conference: "Conferencia",
      integration: "Integración",
      retreat: "Retiro",
      other: "Otro",
    },
    streaming: {
      after: "Después de",
      allDays: "Todos los días",
      allMonths: "Todos los meses",
      monToFri: "De lunes a viernes",
      annually: "Anualmente",
      april: "abril",
      august: "agosto",
      custom: "Personalizado",
      dateInit: "Fecha de inicio",
      day: "día",
      days: "días",
      descriptionAlertEditModal1:
        "Las modificaciones no aplican para los eventos que ya fueron creados. Los cambios se verán reflejados en los eventos que se generarán automáticamente a partir del",
      descriptionAlertEditModal2:
        "Si quieres cambiar la información de algún evento ya creado, da clic aquí.",
      descriptionAlertEditModal3:
        "Si quieres editar el título, descripción, link o fecha del evento, da clic aquí.",
      descriptionAlertEditModal4:
        "Para editar los botones y el tipo de evento, puedes hacerlo a continuación.",
      december: "dicembre",
      end: "Termina",
      endDate: "Fecha de finalización",
      eventDate: "Fecha del evento",
      eventGeneratedPerMonth: "Los eventos se generan automáticamente cada mes",
      every: "Cada",
      everyWeek: "Cada semana",
      eventOcurrency: "Repetir cada",
      eventWillBeRepeat: "El evento se repetirá",
      february: "febrero",
      fifth: "el quinto",
      finishEvent: "Finalización del evento",
      first: "el primer",
      firstEventDate: "Fecha del primer evento",
      fourth: "el cuarto",
      frecuencyValue: "La cantidad de repeticiones",
      friday: "Viernes",
      fromAux: "del",
      from: "Desde",
      hour: "hora",
      hours: "horas",
      initDate: "Fecha de inicio",
      january: "enero",
      july: "julio",
      june: "junio",
      never: "Nunca",
      futureStreamings: "Próximos en vivo",
      noRepeat: "No se repite",
      november: "noviembre",
      march: "marzo",
      mass: "Misa",
      may: "mayo",
      minute: "minuto",
      minutes: "minutos",
      modalErrorBody: "Revisa los siguientes campos",
      modalErrorStartDate:
        "La fecha de inicio del evento no puede ser igual o después de la finalización",
      modalErrorDescription:
        "La cantidad de caracteres de la descripción no puede ser mayor o igual a 250",
      modalErrorTitle:
        "Algunos campos no se encuentran diligenciados correctamente",
      monday: "Lunes",
      month: "mes",
      months: "meses",
      pastStreamings: "En vivo pasados",
      repeatBy: "Repetir cada",
      repeated: "Se repite",
      repetitionMonth: "Repeticiones al mes",
      rosary: "Rosario",
      october: "octubre",
      on: "de",
      on: "de",
      saturday: "Sábado",
      second: "el segundo",
      selectOcurrence: "Repetir cada",
      september: "septiembre",
      sunday: "Domingo",
      the: "el",
      theDay: "el día",
      theDays: "Los días",
      third: "el tercer",
      thursday: "Jueves",
      timeFinal: "Hora de finalización",
      timeInit: "Hora de inicio",
      timesOcurred: "veces ocurrido el evento",
      to: "hasta",
      tooltipAddButton: "Agrega un nuevo en vivo",
      tooltipEventDate: "Fecha de inicio y finalización del evento",
      tooltipMonths:
        "De acuerdo a los días seleccionados, cada mes se repetirá el evento",
      tooltipOcurrence:
        "Los eventos se generarán automáticamente con la frecuencia elegida",
      tooltipTypeEvent: "El tipo del o los eventos que vas a crear",
      tooltipSaveButton: "Guardar los en vivos modificados",
      tooltipYears: "Anualmente se repetirá el evento programado",
      tuesday: "Martes",
      typeEvent: "Tipo de evento",
      urlMustBeChannel: "La url debe ser un canal de youtube",
      wednesday: "Miércoles",
      weHaveEventLive: "¡Tenemos un evento en vivo!",
      weHaveEventLives: "¡Tenemos eventos en vivo!",
      week: "semana",
      weeks: "semanas",
      weekDays: "Los días de la semana",
      year: "año",
      years: "años",
    },
    subpages: {
      associateToExistingSubpage: "Asociar a subpágina existente",
      associatedSubpages: "Subpáginas asociadas",
      createSubpage: "Crear subpágina",
      editSubpagesAssociation: "Editar asociación a subpáginas",
      noSubpagesAssociated: "No hay subpáginas asociadas",
      sectionMustHaveSubpage:
        "Esta sección debe estar asociada a una subpágina para ser visible",
      subpageName: "Nombre subpágina",
      subpage: "Subpágina",
      subpagesEditionSuccess: "Edición de asociación de subpáginas exitosa",
      subpagesTooltip:
        "Indica la asociación de la sección a una subpágina. Si hay una subpágina asociada la sección será mostrada en el sitio web en una página aparte de la página principal",
    },
  },
  en: {
    basicWords: {
      accept: "Accept",
      actionNotAllowed: "Action not allowed",
      add: "Add",
      addBackground: "Add background",
      addGroup: "Add group",
      addGroupSubpageOrSubpage: "Add subpage group or subpage",
      address: "Address",
      addNewSubpageOrSubpagegroup:
        "If you want to add a new subpage or subpage group, you must disable any subpage(s) already created",
      addSection: "Add new section",
      addSubpage: "Add new subpage",
      all: "All",
      approved: "Approved",
      back: "Back",
      backgroundImage: "Background image",
      buttonColor: "Button color",
      cancel: "Cancel",
      cancelText: "No",
      cantDeleteSections:
        "Header, home, contact us and footer sections cannot be removed from main page",
      cantMoreSections: "There can't be more than ten sections",
      cantMoveSectionsOfMainpage:
        "Header, home, contact us and footer sections should remain on the main page",
      cardAddAlwaysAtEnd: "The add card should always be at the end",
      city: "City",
      colorBackground: "Background color",
      close: "Close",
      closeDate: "Closing date",
      component: "Component",
      confirmationDate: "Confirmation date",
      continue: "Continue",
      donate: "Donate",
      country: "Country",
      date: "Date",
      dateEvent: "Event date",
      deleteSubpageOrGroupsubpage: "Delete subage or subpage group",
      deletePageSection: "Delete page section",
      deleteRegister: "Are you sure you want to delete this record?",
      deleteSelectedRegisters:
        "Are you sure you want to delete selected records?",
      deleteAllRegisters: "Are you sure you want to delete all records?",
      deleteSection: "Are you sure you want to delete this section?",
      description: "Description",
      edit: "Edit",
      editImage: "Edit image",
      editEvents: "Edit events",
      editGroup: "Edit group",
      editPhoto: "Edit photo",
      editSection: "Edit section",
      endTime: "End time",
      enterAmount: "Amount",
      enterName: "Enter name",
      enterPos: "Enter position",
      enterStreamingPage: "Enter streaming page",
      enterText: "Enter text",
      enterTitle: "Enter title",
      enterLogo: "Enter logo",
      erase: "Erase",
      error: "Error",
      example: "Example",
      expired: "Expired",
      goTo: "Take me",
      goToMyWebPage: "Take me to my website",
      GoToWithoutSave: "Are you sure you want to exit without saving changes",
      generalInformation: "General information",
      groupList: "Group list",
      groupSubpages: "Subpage group",
      id: "ID",
      image: "Image",
      information: "Information",
      link: "Link",
      list: "List",
      listCourses: "Courses list",
      listEvents: "Events list",
      listNews: "News list",
      listStreamings: "Streamings list",
      load: "Load",
      loading: "Loading",
      lptypeId: "Landing page type",
      mainpage: "Main page",
      mainpageAtTheTop:
        "Main page should always be at the top and the add card at the bottom",
      moremMovements: "To do more changes, you must save and refresh page",
      movementNotAllowedMainpage:
        "Main page should always be at the first position",
      movementNotAllowedAdd: "The add card must always be at the last position",
      name: "Name",
      nameSubpageOrGroupSubpage:
        "The subpage or subpage group name cannot be empty.",
      no: "No",
      note: "Note",
      notice: "Notice",
      numberUsers: "Number of users",
      okText: "Yes",
      organizationType: "Organization type",
      payments: "Payments",
      paymentPlatforms: "Payment platforms",
      pending: "Pending",
      phoneContact: "Contact phone number",
      place: "Place",
      position: "Position",
      rangeDate: "Date Range",
      rangeDateEvents: "Event date range",
      readMore: "Read more",
      recurrence: "Recurrence",
      region: "Region",
      rejected: "Rejected",
      responsible: "Responsible",
      role: "Role",
      save: "Save",
      section: "Section",
      sectionsCanOnlyInMainPageOrSubpage:
        "Sections can only be on main page or subpages",
      sectionMustBeInSubpage:
        "The section you wish to move must be on a subpage in order to be displayed.",
      sectionsStructureMainPage:
        "Header, home, contact us and footer sections should remain at the top and bottom of the main page to preserve its structure.",
      select: "Select",
      selectBackgroundImage: "Choose a background image",
      selectColorBackground: "Choose a background color",
      selectorTheme: "Theme selector",
      selectorLayout: "Layout selector",
      showLess: "Show less",
      showMore: "Show more",
      startTime: "Start Time",
      startDate: "Start date",
      status: "Status",
      streamingPage: "Streaming page",
      subpage: "Subpage",
      subpagesCanOnlyInGroupSubpages: "Subpages can only be in groups",
      telephone: "Telephone",
      text: "Text",
      theme: "Theme",
      thumbnail: "Thumbnail",
      title: "Title",
      typeofGroup: "Group type",
      typeUser: "Type of user",
      unlimited: "Unlimited",
      value: "Value",
      visibleSubpage: "Shows or hides subpage on website",
      webAddress: "Web address",
    },
    buttons: {
      addButton: "Add button",
      buttons: "Buttons",
      buttonsTooltip:
        "Add buttons to link other components (Forms, Donations, News, Events, etc) or external links",
      editButton: "Edit button",
      externalLink: "External link",
      externalLinkTooltip:
        "Link this button to an external link of another site",
      moreNews: "Get more news",
      nameTooltip: "Text visible on the button",
      noAssignedButtons: "No assigned buttons",
      ownComponent: "Own component",
      ownComponentTooltip:
        "Link this button to another component of the site like an event, news, donation or course",
    },
    calendarComponents: {
      addCalendar: "Add to calendar",
      error: "Error",
      errorEvent: "This is error event",
      errorEvent1: "This is error event 1",
      errorEvent2: "This is error event 2",
      errorEvent3: "This is error event 3",
      errorEvent4: "This is error event 4",
      success: "Success",
      usualEvent: "This is an usual event",
      veryLongUsualEvent: "This is a very long usual event",
      warning: "Warning",
      warningEvent: "This is a warning event",
    },
    donations: {
      addDonation: "Add donation",
      addDonationText: "Add donations to support your community!",
      donationsList: "Donations list",
      donationType: "Search by donation type",
      paymentPlatform: "Payment platform",
      tax: "Tax",
      typeTooltip:
        "Filter donations depending on their acitve state. Unlimited: No closing date. Closed: Closing date already over. Open: Unlimited and closing date still valid. All: Closed and open",
      types: {
        all: "All",
        open: "Open",
        closed: "Close",
        unlimited: "Unlimited",
      },
      voluntary: "Open",
    },
    footer: {
      rigthsReserved: "All Rights Reserved",
      developedBy: "developed by",
    },
    generalMessages: {
      addCourse: "Add course",
      addCourseText: "Add amazing courses for your community!",
      addSection: "Add section",
      addSectionPosibilities: "Sections you can add",
      addImage: "Add image",
      addImages: "Add images",
      addVideo: "Add video",
      addMember: "Add team member",
      addMemberText: "Add the amazing people that are part of your team!",
      addPhotoGallery: "Add photo",
      addPhotoGalleryText:
        "A picture is worth a thousand words! Add the best photos to share with your community.",
      assignedUsers: "Users assgigned",
      changingPositionHelper:
        "Changing the position of the section on the page",
      checkURLError: "Please check the URL.",
      credentialsError: "Please check the Credentials",
      contrary: "On the contrary, ",
      downsection: "Download section on page",
      editMember: "Edit member",
      editEvent: "Edit event",
      editNews: "Edit news",
      errorBackend: "Error: Unable to connect to backend",
      errorChangePassword:
        "An error occurred when changing the password. Please try again",
      errorConect: "Connection error",
      followUs: "Follow us",
      helpLocalizedPage: "Position of the section on the page",
      hereClick: "Click here",
      imageGallery: "Image our gallery",
      loading: "Loading...",
      localizedPage: "Position on page",
      numberNews: "News to show",
      unauthorized: "Error: Unauthorized",
      pageNotFound: "Error: Page not found",
      passwordNoMatch: "The new passwords do not match. Please check",
      preview: "Preview",
      redirectionPage: "to be redirected to the home page.",
      sucessChangePassword: "Success in changing the password",
      test: "Test",
      treeSections: "Section tree",
      unauthorized: "Error: unauthorized",
      upsection: "Upload section on page",
      url: "URL",
    },
    generalUser: {
      addEvent: "Add event",
      addNews: "Add news",
      addNewsText: "Add great news to share with your community!",
      addEvent: "Add event",
      addEventText: "Add great events to share with your community!",
      addRoleAndPrivileges: "Add roles and assigning privileges",
      addStreaming: "Add streaming",
      addUser: "Add user",
      apostolicGroupBelong: "Apostolic group you belong to",
      auraApp: "AurApp",
      assignedPrivileges: "Assigned privileges",
      assigningRoleToUsers: "Assign roles to users",
      availablePrivileges: "Available privileges",
      availableUsers: "Users available",
      changePassword: "Cambio de clave",
      currentPassword: " Contraseña actual",
      dateOfBirth: "Date of birth",
      documentIdNumber: "Document ID number",
      editRoleAndPrivileges: "Edit roles and assigning privileges",
      editUser: "Edit user",
      editNews: "Edit news",
      editStreaming: "Edit streaming",
      generalParametres: "General parameters",
      groups: "Groups",
      identityCardNumber: "Document number",
      inputPassword: "Input password",
      lastName1: "First last name",
      lastName2: "Second last name",
      logOut: "Logout",
      myaccount: "My account",
      myGroupSubpage: "My group of subpages",
      myPage: "My page",
      mySubpage: "My subpage",
      myUsers: "Users",
      newPassword: "New password",
      pages: "Pages",
      pagesList: "Pages list",
      personalInformation: "Personal information",
      repeatNewPassword: "Repeat new password",
      roles: "Roles",
      rolesList: "List of roles",
      rolesPrivileges: "Roles and Privileges",
      contentMyPage: "Website content",
      contentMyPageTooltip:
        "Here you can configure your website content such as the homepage, subpages and subpage groups. The order of the header will have the following structure: First, the sections that are on the main page are displayed on the home page are displayed first. Then, the subpages and subpage groups will keep the order you set up on this page order you set up on this page.",
      styleConfig: "Style settings",
      unassignedUsers: "Unassigned users",
      User: "User",
      userEmail: "email",
      userListbyrole: "User list by role",
      username: "User name",
      users: "My users",
      usersList: "User list",
      tooltipMainpage:
        "Refers to the sections that will be displayed on home page. These sections appear at the top of the header in the order you choose.",
      tooltipSubpage:
        "In a subpage you can include any sections you want. The header will display the name of the subpage, without the sections.",
      tooltipGroupsubpage:
        "In a subpage group you can group subpages in such a way that in the header a menu (subpage group name) is displayed. This menu allows you to display a submenu with the subpages associated to it.",
      layout: {
        coursesTitleCardsLayoutDescription:
          "Display type card. Only title and text",
        coursesTitleCardsLayoutTitle: "Card courses layout",
        coursesCarouselLayoutDescription:
          "Courses carrousel. It moves horizontally",
        coursesCarouselLayoutTitle: "Courses carousel layout",
        coursesDisplayGridLayoutDescription: "Display type grid",
        coursesDisplayGridLayoutTitle: "Courses display grid",
        coursesImageLeftLayoutDescription:
          "Image at the left. Text at the right. Buttons at the bottom.",
        coursesImageLeftLayoutTitle: "Courses image left layout",
        donationsDisplayGridLayoutDescription: "Display type grid",
        donationsDisplayGridLayoutTitle: "Donations display grid",
        donationsImageLeftLayoutDescription:
          "Image at the left. Text at the right. Buttons at the bottom.",
        donationsImageLeftLayoutTitle: "Donations image left layout",
        eventsCardsLayoutDescription: "Display type card. Only title and text",
        eventsCardsLayoutTitle: "Events cards layout",
        eventsCarouselLayoutDescription:
          "Events carrousel. It moves horizontally",
        eventsCarouselLayoutTitle: "Events carousel layout",
        eventsImageLeftLayoutDescription:
          "Image at the left. Text at the right. Buttons at the bottom",
        eventsImageLeftLayoutTitle: "Events image left layout",
        eventsImageRigthLayoutDescription:
          "Image at the right. Text at the left. Buttons at the bottom",
        eventsImageRigthLayoutTitle: "Events image right layout",
        galleryBlackAndWhiteDescription:
          "Black and white images. When the mouse hovers over the image, it turns to color.",
        galleryBlackAndWhiteTitle: "Gallery black and white image",
        galleryMoveImageDescription: "Image moves when mouse hovers",
        galleryMoveImageTitle: "Gallery move image layout",
        headerLogoDescription: "Logo instead of name view",
        headerLogoTitle: "Header logo layout",
        homeOneImageLayoutDescription: "One image at background",
        homeOneImageLayoutTitle: "Home one image layout ",
        homeSlideImagesLayoutDescription: "Slide images at background",
        homeSlideImagesLayoutTitle: "Home slide images layout",
        homeVideoLayoutDescription: "Video background at home section",
        homeVideoLayoutTitle: "Home video layout",
        newsCardsLayoutDescription: "Display type card. Only title and text",
        newsCardsLayoutTitle: "News cards layout",
        newsCarouselLayoutDescription: "News carrousel. It moves horizontally",
        newsCarouselLayoutTitle: "News carousel layout",
        newsDisplayGridLayoutDescription: "Display type grid",
        newsDisplayGridLayoutTitle: "News display grid",
        newsImageLeftLayoutDescription:
          "Image at the left. Text at the right. Buttons at the bottom",
        newsImageLeftLayoutTitle: "News image left layout",
        standarAboutUsLayoutDescription: "Standard view",
        standarAboutUsLayoutTitle: "Standard about us layout",
        standarCoursesLayoutDescription: "Standard view",
        standarCoursesLayoutTitle: "Standard courses layout",
        standarDonationsLayoutDescription: "Standard view",
        standarDonationsLayoutTitle: "Standard donations layout",
        standarEventsLayoutDescription: "Standard view",
        standarEventsLayoutTitle: "Standard events layout",
        standarGalleryLayoutDescription: "Standard view",
        standarGalleryLayoutTitle: "Standard gallery layout",
        standarHeaderLayoutDescription: "Standard view",
        standarHeaderLayoutTitle: "Standard header layout",
        standarHomeLayoutDescription: "Standard view",
        standarHomeLayoutTitle: "Standard home layout",
        standarLinktreeLayoutDescription: "Standard view",
        standarLinktreeLayoutTitle: "Standard linktree layout",
        standarNewsLayoutDescription: "Standard view",
        standarNewsLayoutTitle: "Standard news layout",
        standarSocialNetworksLayoutDescription: "Standard view",
        standarSocialNetworksLayoutTitle: "Standard social networks layout",
        standarStreamingsLayoutDescription: "Standard view",
        standarStreamingsLayoutTitle: "Standard streamings layout",
        standarTeamLayoutDescription: "Standard view",
        standarTeamLayoutTitle: "Standard team layout",
        teamLayoutDiagonallyDescription:
          "Scrolling text diagonally text when mouse hover",
        teamLayoutDiagonallyTitle: "Team layout diagonally layout",
        teamFlipCardLayoutDescription: "Flip card when mouse hover",
        teamFlipCardLayoutTitle: "Team flip card layout",
        teamLayoutScrollDownDescription:
          "Scrolling text down text when mouse hover",
        teamLayoutScrollDownTitle: "Team layout scroll down",
        teamLayoutScrollUpDescription:
          "Scrolling text up text when mouse hover",
        teamLayoutScrollUpTitle: "Team layout scroll up",
      },
      linktree: {
        linksShown: "Links shown",
        openInNewTab: " Open links in new tab",
      },
      login: {
        enterUserAndPressOk: "Enter your username and press OK",
        enterValidPassword: "Please enter a valid password",
        enterValidUser: "Please enter a valid username",
        exit: "Exit",
        failedProcess: "Process failed. Unable to send mail to reset password",
        forgotMyPassword: "Forgot my password",
        invalidPasswordOrUser:
          "Invalid username or password / If the error persists contact the administrator",
        linkSentToResetPassword:
          "A link will be sent to the registered email to reset your password",
        logIn: "Login",
        password: "Password",
        recoveryPassword: "Password recovery",
        successfulProcess:
          "Successful process. We have sent an email with a link to reset the password",
      },
    },
    modal: {
      assignmentSuccessful: "Successful assignment",
      contactSuccessfullyEdited: "Contact was successfully edited",
      componentUnselected: "A component has not been selected",
      courseSuccessfullyCreated: "Course was successfully created",
      disablingSuccessful: "Successful disabling",
      disassignedSuccessfully: "Successfully Disassigned",
      editionSuccess: "Successful edition",
      errorAuthentication: "Authentication error",
      errorAuthenticationToLogin:
        "An error occurred in the authentication, you will be redirected to the login",
      eventSuccessfullyDisabled: "Event was successfully disabled",
      eventSuccessfullyEdited: "Event was successfully edited",
      eventSuccessfullyUpdate: "Event successfully updated",
      eventSuccessfullyCreated: "Events was successfully created",
      fieldEmpty: "One field is empty",
      donationSuccesfullEdited: "Donation was successfully edited",
      donationSuccesfullCreated: "Donation was successfully created",
      donationSuccesfullDisabled: "Donation was successfully disabled",
      groupSuccesfullEdited: "Group was successfully edited",
      groupSuccesfullCreated: "Group was successfully created",
      groupSuccesfullDisabled: "Group was successfully disabled",
      headerSuccessfullyEdited: "Header was successfully edited",
      footerSuccessfullyEdited: "Footer was successfully edited",
      homeSuccessfullyEdited: "Home was successfully edited",
      internalServerError: "Internal server error",
      internalServerErrorContactSupport:
        "Internal server error, please try again later or contact technical support",
      linkButtonEmpty: "Button lnik cannot be empty",
      memberSuccessfullyAdded: "Member was successfully added",
      memberSuccessfullyDisabled: "Member was successfully disabled",
      memberSuccessfullyEdited: "Member was successfully edited",
      modifiedSuccesfully: "Successfully modified",
      nameButtonEmpty: "Button name cannot be empty",
      newsSuccessfullyCreated: "News was successfully created",
      newsSuccessfullyDisabled: "News was successfully disabled",
      newsSuccessfullyUpdate: "News was successfully updated",
      noOrganizationSelected: "No organization was selected",
      noSectionSelected: "No section was selected",
      notSelectRol: "You have not selected at least one role",
      notValidEmail: "Email is not valid",
      notValidID: "Identity card is not valid",
      notValidLastname: "The first surname is not valid",
      notValidName: "Name is not valid",
      notValidphone: "Phone is not valid",
      notValidUsername: "Username is not valid",
      organizationNotSelected: "Organization not selected",
      organizationSuccesfullCreated: "Organization was successfully created",
      organizationSuccesfullDisabled: "Organization was successfully disabled",
      organizationSuccesfullEdited: "Organization was successfully edited",
      photoSuccessfullyAdded: "Photo was added successfully",
      photoSuccessfullyDisabled: "Photo was successfully disabled",
      photoSuccessfullyEdited: "Photo was successfully edited",
      positionSectionSuccessfullyModified:
        "The position was successfully modified",
      privilegeSuccessfullyDisengaged: "Privilege was successfully disengaged",
      roleSuccesfullyAdded: "Role was successfully added",
      roleSuccesfullyDisabled: "Role was successfully disabled",
      roleSuccesfullyEdited: "Role was successfully edited",
      sectionDisabledSuccessful: "Section was successfully disabled",
      sectionUnselected: "Unselected section",
      successfulCreation: "Successful creation",
      successfulDisability: "Successful disabling",
      successfulEdition: "Successful edition",
      updateSuccessful: "Successful update",
      userAlreadyCrated: "User is already created",
      userDisassignedSuccessfully: "User was successfully disengaged",
      usersSuccesfullyAssigned: "Users were successfully assigned",
      userSuccesfullyCreated: "User was successfully created",
      userSuccesfullyDisabled: "User was successfully disabled ",
      userSuccesfullyEdited: "User was successfully edited",
      userSuccesRemovedRole: "User was successfully removed from the role",
      userUpdatedSuccessfully: "User successfully updated",
      visibilitySubpage:
        "The visibility of the subpage was successfully modified",
      wrongFile: "Wrong field",
    },
    organization: {
      addOrganization: "Add organization",
      editOrganization: "Edit organization",
      myOrganization: "My organization",
      organization: "Organization",
      organizationList: "Organization list",
      organizations: "Organizations",
    },
    sections: {
      aboutUs: "About us",
      author: "Author",
      contactUs: "Contact us",
      courses: "Courses",
      donations: "Donations",
      events: "Events",
      footer: "Footer",
      forms: "Forms",
      gallery: "Gallery",
      header: "Header",
      home: "Home",
      infoPage: "Page information",
      linkFacebook:
        "Add the link to the Facebook page. For example: https://www.facebook.com/SOMOS-AURA-114538460262706",
      linkInstagram: "Enter the Instagram link",
      linkPostInstagram:
        "Add the link to the instagram post. For example: https://www.instagram.com/p/CHVtSlcDM3A/",
      linkTree: "Linktree",
      linkTwitter: "Enter the Twitter link",
      linkAurapp: "Enter the Aurapp link",
      mision: "Mission",
      news: "News",
      sections: "Sections",
      socialnetworks: "Social networks",
      streamings: "Streaming",
      publicationDate: "Publication date",
      previsualization: "Previsualization",
      team: "Team",
      UsernameTwitter: "Add your username. For example: auraSomos",
      vision: "Vision",
    },
    scheduleEventType: {
      mass: "Mass",
      rosary: "Rosary",
      chapletOfTheDivineMercy: "Chaplet of the Divine Mercy",
      conference: "Conference",
      integration: "Integration",
      retreat: "Retreat",
      other: "Other",
    },
    streaming: {
      after: "After",
      allDays: "Everyday",
      allMonths: "All months",
      monToFri: "Monday to friday",
      annually: "Annually",
      april: "April",
      august: "August",
      custom: "Custom",
      descriptionAlertEditModal1:
        "Modifications do not apply to events that have already been created. The changes will be reflected in the events that will be automatically generated from the",
      descriptionAlertEditModal2:
        "If you want to change the information of an event already created, click here.",
      descriptionAlertEditModal3:
        "If you want to edit the title, description, link or date of the event, click here. To edit the buttons and the type of event, you can do it below.",
      descriptionAlertEditModal4:
        "To edit the buttons and the type of event, you can do it below.",
      december: "December",
      dateInit: "Start date",
      day: "day",
      days: "days",
      end: "End",
      endDate: "End date",
      eventDate: "Event date",
      eventGeneratedPerMonth: "Events are generated automatically each month",
      every: "Every",
      everyWeek: "Every week",
      eventOcurrency: "Event ocurrency",
      eventWillBeRepeat: "Event will be repeated",
      february: "february",
      fifth: "fifth",
      finishEvent: "End of the event",
      first: "first",
      firstEventDate: "Date of first event",
      fourth: "fourth",
      frecuencyValue: "Number of repetitions",
      friday: "Friday",
      fromAux: "from",
      from: "from",
      hour: "hour",
      hours: "hours",
      initDate: "Start date",
      january: "January",
      july: "July",
      june: "June",
      never: "Never",
      futureStreamings: "Next streamings",
      noRepeat: "No repeat",
      november: "November",
      march: "March",
      mass: "Mass",
      may: "May",
      minute: "minute",
      minutes: "minutes",
      modalErrorBody: "Check the following fields",
      modalErrorStartDate:
        "The start date of the event cannot be after or equal the end of the event",
      modalErrorDescription:
        "The number of characters in the description cannot be greater than or equal to 250",
      modalErrorTitle: "Some fields are not filled in correctly",
      monday: "Monday",
      month: "month",
      months: "months",
      pastStreamings: "Past streaming",
      repeatBy: "Repeat by",
      repeated: "Repeated",
      repetitionMonth: "Repetitions per month",
      rosary: "Rosary",
      october: "october",
      on: "on",
      saturday: "Saturday",
      second: "second",
      selectOcurrence: "Select recurrence",
      september: "september",
      sunday: "Sunday",
      the: "the",
      theDay: "the day",
      theDays: "Days",
      third: "third",
      thursday: "Thursday",
      timeFinal: "End time",
      timeInit: "Start time",
      timesOcurred: "times the event occurred",
      to: "to",
      tooltipAddButton: "Add new streaming",
      tooltipEventDate: "Start and end date of event",
      tooltipMonths:
        "According to the selected days, the event will be repeated every month.",
      tooltipOcurrence:
        "Events will be generated automatically with the selected frequency",
      tooltipTypeEvent: "The type of event(s) you are going to create",
      tooltipSaveButton: "Saving modified streamingsa",
      tooltipYears: "The programmed event will be repeated annually",
      tuesday: "Tuesday",
      typeEvent: "Event type",
      urlMustBeChannel: "Url must be a youtube channel",
      wednesday: "Wednesday",
      weHaveEventLive: "We have a live streaming event!",
      weHaveEventLives: "We have live streaming events!",
      weeks: "weeks",
      week: "week",
      weekDays: "Days of the week",
      year: "year",
      years: "years",
    },
    subpages: {
      associateToExistingSubpage: "Associate to existing subpage",
      associatedSubpages: "Associated subpages",
      createSubpage: "Create subpage",
      editSubpagesAssociation: "Edit subpages association",
      noSubpagesAssociated: "There are no associated subpages",
      sectionMustHaveSubpage:
        "This section must have a subpage associated to be visible",
      subpageName: "Subpage name",
      subpage: "Subpage",
      subpagesEditionSuccess:
        "Edition of subpages association succesfully done",
      subpagesTooltip:
        "Refers to the association of the section to a subpage. If there is a subpage associated, the section is shown in the website as a page apart of the main page",
    },
  },
  it: {
    basicWords: {
      accept: "Accetta",
      actionNotAllowed: "Azione non consentita",
      add: "Aggiungi",
      addBackground: "Aggiungi sfondo",
      addGroup: "Aggiungi gruppo",
      addGroupSubpageOrSubpage:
        "Aggiungere un gruppo di sottopagine o una sottopagina",
      address: "Indirizzo",
      addNewSubpageOrSubpagegroup:
        "Se vuoi aggiungere una nuova sottopagina o gruppo di sottopagine, devi disabilitare qualsiasi sottopagina già creata",
      addSection: "Aggiungere una nuova sezione",
      addSubpage: "Aggiungere una nuova sottopagina",
      back: "Torna a",
      backgroundImage: "Immagine di sfondo",
      buttonColor: "Colore del pulsante",
      cancel: "Annulla",
      cancelText: "No",
      cantDeleteSections:
        "Le sezioni header, home, contact us o footer non possono essere rimosse dalla pagina principale.",
      cantMoreSections: "Non può avere più di dieci sezioni",
      cantMoveSectionsOfMainpage:
        "Le sezioni header, home, contact us o footer dovrebbero rimanere nella pagina principale",
      cardAddAlwaysAtEnd:
        "La scheda aggiuntiva dovrebbe essere sempre alla fine",
      city: "Città",
      colorBackground: "Colore di sfondo",
      close: "Chiudere",
      closeDate: "Data di chiusura",
      component: "Componente",
      country: "Paese",
      date: "Data",
      dateEvent: "Data dell'evento",
      deleteSubpageOrGroupsubpage:
        "Elimina la sottopagina o il gruppo di sottopagine",
      deletePageSection: "Cancellare la sezione della pagina",
      deleteRegister: "Sei sicuro di voler cancellare questo record?",
      deleteSection: "Sei sicuro di voler cancellare la sezione?",
      description: "Descrizione",
      edit: "Modifica",
      editImage: "Modifica image",
      editEvents: "Modifica eventi",
      editGroup: "Modifica gruppo",
      editPhoto: "Modifica foto",
      editSection: "Modifica sezione",
      endTime: "Tempo di fine",
      enterName: "Inserire il nome",
      enterPos: "Inserire la posizione",
      enterStreamingPage: "Insire streaming page",
      enterText: "Inserire il testo",
      enterTitle: "Inserire il titolo",
      erase: "Cancellare",
      error: "Errore",
      example: "Esempio",
      goTo: "Portami a",
      goToMyWebPage: "Portami la mia pagina web",
      GoToWithoutSave: "Sei sicuro di voler uscire senza salvare le modifiche",
      groupList: "Elenco dei gruppi",
      groupSubpages: "Grupo de subpágina",
      id: "Certificato",
      image: "Immagine",
      information: "Informazioni",
      link: "Link",
      list: "controllare",
      listCourses: "Elenco dei corsi",
      listEvents: "Elenco degli eventi",
      listNews: "Elenco delle notizie",
      listStreamings: "Elenco delle streaming",
      load: "Scaricare",
      lptypeId: "Tipo di pagina di destinazione",
      mainpage: "Pagina principale",
      mainpageAtTheTop:
        "La pagina principale dovrebbe essere sempre in alto e la scheda aggiuntiva in basso.",
      moremMovements:
        "Per fare più movimenti, è necessario salvare e aggiornare la pagina",
      movementNotAllowedMainpage:
        "The main page should always be in the first position",
      movementNotAllowedAdd:
        "La carta aggiuntiva deve essere sempre nell'ultima posizione",
      name: "Nome",
      nameSubpageOrGroupSubpage:
        "La sottopagina o il gruppo di sottopagine deve avere un nome. Il nome non può essere vuoto",
      no: "No",
      note: "Nota",
      notice: "Avisso",
      numberUsers: "Numero utente",
      okText: "Sì",
      organizationType: "Tipo di organizzazione",
      phoneContact: "Numero di telefono di contatto",
      place: "Posto",
      position: "Posizione",
      rangeDate: "Intervallo di date",
      rangeDateEvents: "Gamma di date dell'evento",
      readMore: "Leggi di più",
      recurrence: "Ricorrenza",
      region: "Regione",
      responsible: "Responsabile",
      role: "Ruolo",
      save: "Salva",
      section: "Sezione",
      sectionsCanOnlyInMainPageOrSubpage:
        "Le sezioni possono essere solo nella pagina principale o nelle sottopagine",
      sectionMustBeInSubpage:
        "La sezione che vuoi spostare deve trovarsi in una sottopagina per essere visualizzata",
      sectionsStructureMainPage:
        "Le sezioni header, home, contact us e footer dovrebbero rimanere in cima e in fondo alla pagina principale per preservarne la struttura",
      select: "Selezionare",
      selectBackgroundImage: "Selezionare un'immagine di sfondo",
      selectColorBackground: "Selezionare un colore di sfondo",
      selectorTheme: "Selettore di argomenti",
      showLess: "Vedi di meno",
      showMore: "Vedi di più",
      startTime: "Ora di inizio",
      streamingPage: "Pagina di streaming",
      subpage: "Subpágina",
      subpagesCanOnlyInGroupSubpages:
        "Le sottopagine possono essere solo in gruppi di sottopagine",
      telephone: "Telefono",
      text: "Testo",
      theme: "Argomento",
      thumbnail: "Thumbnail",
      title: "Titolo",
      typeofGroup: "Tipo di gruppo",
      typeUser: "Tipo di utente",
      visibleSubpage:
        "Abilita o disabilita la sottopagina da visualizzare nella pagina web",
      webAddress: "Indirizzo web",
    },
    buttons: {
      addButton: "Aggiungi pulsante",
      buttons: "Pulsante",
      buttonsTooltip:
        "Aggiungere pulsanti per collegare altri componenti (moduli, donazioni, notizie, eventi, ecc.) o collegamenti esterni",
      editButton: "Tasto Modifica",
      externalLink: "Collegamento esterno",
      externalLinkTooltip:
        "Collegate questo pulsante a un link su un sito web esterno",
      moreNews: "Ricevi altre notizie",
      nameTooltip: "Testo visibile sul",
      noAssignedButtons: "Nessun pulsante assegnato",
      ownComponent: "Componente proprio",
      ownComponentTooltip:
        "Collega questo pulsante ad un altro componente del tuo sito come una notizia, un evento, un modulo o una donazione",
    },
    calendarComponents: {
      addCalendar: "Aggiungi al calendario",
      error: "Errore ",
      errorEvent: "Questo è un evento di errore",
      errorEvent1: "Questo è l'evento di errore 1",
      errorEvent2: "Questo è l'evento di errore 2",
      errorEvent3: "Questo è l'evento di errore 3",
      errorEvent4: "Questo è l'evento di errore 4",
      success: "Successo",
      usualEvent: "Questo è un evento regolare",
      veryLongUsualEvent: "Questo è un evento lungo come al solito",
      warning: "Allarme",
      warningEvent: "Questo è un evento di avvertimento",
    },
    footer: {
      rigthsReserved: "Tutti i diritti riservati",
      developedBy: "sviluppato da",
    },
    generalMessages: {
      addCourse: "Aggiungi corso",
      addSection: "Aggiungi sezione",
      addSectionPosibilities: "Sezioni che puoi aggiungere",
      addImages: "Aggiungi immagini",
      assignedUsers: "Utenti assegnati",
      changingPositionHelper:
        "Modifica della posizione della sezione sulla pagina",
      checkURLError: "Si prega di controllare l'URL",
      contrary: "Altrimenti, ",
      credentialsError: "Si prega di controllare le credenziali",
      downsection: "Scarica la sezione della pagina",
      editMember: "Modifica membro",
      editNews: "Modifica notizie",
      errorBackend: "Errore: Impossibile connettersi al backend",
      errorChangePassword:
        "Si è verificato un errore quando la password è stata modificata. Si prega di riprovare",
      errorConect: "Errore di collegamento",
      followUs: "Follow us",
      helpLocalizedPage: "Posizione della sezione sulla pagina",
      hereClick: "Clicca qui",
      imageGallery: "Immagine la nostra galleria",
      loading: "Caricamento...",
      localizedPage: "Posizione sulla pagina",
      numberNews: "Numero di notizie",
      pageNotFound: "Errore: Pagina non trovata",
      passwordNoMatch:
        "Le nuove password non corrispondono. Si prega di controllare",
      preview: "Anteprima",
      redirectionPage: "per essere reindirizzati alla home page.",
      sucessChangePassword: "Successo nel cambiare la password",
      test: "Prova",
      treeSections: "Sezione Albero",
      unauthorized: "Errore: non autorizzato",
      upsection: "Carica la sezione nella pagina",
      url: "URL",
    },
    generalUser: {
      addEvent: "Aggiungi evento",
      addNews: "Aggiungi notizie",
      addRoleAndPrivileges: "Aggiunta di un ruolo e assegnazione di privilegi",
      addStreaming: "Aggiungere lo streaming",
      addUser: "Aggiungi utente",
      apostolicGroupBelong: "Gruppo apostolico al quale appartiene",
      auraApp: "AurApp",
      assigningRoleToUsers: "Assegnazione dei ruoli agli utenti",
      availablePrivileges: "Privilegi disponibili",
      availableUsers: "Utenti disponibili",
      changePassword: "Cambiare la password",
      currentPassword: "Password attuale",
      dateOfBirth: "Data di nascita",
      documentIdNumber: "Carta d'identità",
      editRoleAndPrivileges: "Modificare il ruolo e assegnare i privilegi",
      editUser: "Modifica utente",
      editNews: "Modifica notizie",
      editStreaming: "Modifica streaming",
      generalParametres: "Parametri generali",
      groups: "Gruppi",
      identityCardNumber: "Numero del documento",
      inputPassword: "Inserisci la password",
      lastName1: "Primo cognome",
      lastName2: "Secondo cognome",
      logOut: "Chiudere la sessione",
      myaccount: "Il mio conto",
      myGroupSubpage: "Il mio gruppo di sottopagine",
      myPage: "La mia pagina",
      mySubpage: "La mia sottopagina",
      myUsers: "Utenti",
      newPassword: "Nuova password",
      pages: "Pagine",
      pagesList: "Elenco delle pagine",
      personalInformation: "Informazioni personali",
      repeatNewPassword: "Ripetere la nuova password",
      roles: "Ruoli",
      rolesList: "Elenco dei ruoli",
      rolesPrivileges: "Ruoli e privilegi",
      contentMyPage: "Contenuto della mia pagina",
      contentMyPageTooltip:
        "Qui puoi configurare il contenuto del tuo sito web, come la homepage, le sottopagine e i gruppi di sottopagine. L'ordine dell'intestazione ha la seguente struttura: in primo luogo, vengono visualizzate le sezioni che si trovano nella homepage sulla homepage vengono visualizzati per primi. Dopo di che, le sottopagine e i gruppi di sottopagine manterranno l'ordine che hai impostato in questa pagina ordine che hai impostato in questa pagina.",
      styleConfig: "Impostazioni di stile",
      unassignedUsers: "Utenti non assegnati",
      User: "Utente",
      userEmail: "Indirizzo e-mail",
      userListbyrole: "Elenco utenti per ruolo",
      username: "Nome utente",
      users: "I miei utenti",
      usersList: "Elenco utenti",
      tooltipMainpage:
        "Si riferisce alle sezioni che saranno visualizzate sulla home page del tuo sito web. Queste sezioni appaiono in cima all'intestazione nell'ordine che hai scelto.",
      tooltipSubpage:
        "In una sottopagina puoi includere le sezioni che vuoi indipendentemente dalla pagina principale. L'intestazione mostrerà il nome della sottopagina, senza le sezioni.",
      tooltipGroupsubpage:
        "In un gruppo di sottopagine puoi raggruppare le sottopagine in modo tale che nell'intestazione si veda un menu (nome del gruppo di sottopagine) che permette di visualizzare un sottomenu con le sottopagine ad esso associate. ad esso.",
    },
    layout: {
      coursesTitleCardsLayoutDescription:
        "heda del tipo di visualizzazione. Solo titolo e testo",
      coursesTitleCardsLayoutTitle: "Layout delle carte degly corsi",
      coursesCarouselLayoutDescription:
        "Carosello di corsi. Si muove orizzontalmente",
      coursesCarouselLayoutTitle: "Corsi a carosello",
      coursesDisplayGridLayoutDescription: "Visualizzazione tipo griglia",
      coursesDisplayGridLayoutTitle: "Griglia di visualizzazione dei corsi",
      coursesImageLeftLayoutDescription:
        "Immagine a sinistra. Testo a destra. Pulsanti in basso",
      coursesImageLeftLayoutTitle: "Corsi immagine a sinistra",
      donationsDisplayGridLayoutDescription: "Tipo di visualizzazione griglia",
      donationsDisplayGridLayoutTitle:
        "Griglia di visualizzazione delle donazioni",
      donationsImageLeftLayoutDescription:
        "Immagine a sinistra. Testo a destra. Pulsanti in basso",
      donationsImageLeftLayoutTitle: "Layout immagine donazioni a sinistra",
      eventsCardsLayoutDescription:
        "heda del tipo di visualizzazione. Solo titolo e testo",
      eventsCardsLayoutTitle: "Layout delle carte degly eventi",
      eventsCarouselLayoutDescription:
        "Carosello di eventi. Si muove orizzontalmente",
      eventsCarouselLayoutTitle: "Layout carosello eventi",
      eventsImageLeftLayoutDescription:
        "Immagine a sinistra. Testo a destra. Pulsanti in basso",
      eventsImageLeftLayoutTitle: "Eventi immagine a sinistra",
      eventsImageRigthLayoutDescription:
        "Immagine a destra. Testo a sinistra. Pulsanti in basso",
      eventsImageRigthLayoutTitle: "Layout immagine eventi a destra",
      galleryBlackAndWhiteDescription:
        "Immagine in bianco e nero. Quando il mouse passa sopra l'immagine, questa sarà a colori",
      galleryBlackAndWhiteTitle: "Galleria immagine IN bianco e nero",
      galleryMoveImageDescription: "Sposta l'immagine al passaggio del mouse",
      galleryMoveImageTitle: "Galleria sposta Layout immagine",
      headerLogoDescription: "Logo al posto della visualizzazione del nome",
      headerLogoTitle: "Layout logo intestazione",
      homeOneImageLayoutDescription: "Un'immagine sullo sfondo",
      homeOneImageLayoutTitle: "Home un layout di immagine ",
      homeSlideImagesLayoutDescription: "Far scorrere le immagini sullo sfondo",
      homeSlideImagesLayoutTitle: "Layout immagini slide home",
      homeVideoLayoutDescription: "Fondo video nella sezione home",
      homeVideoLayoutTitle: "Layout video a casa",
      newsCardsLayoutDescription:
        "Scheda del tipo di visualizzazione. Solo titolo e testo",
      newsCardsLayoutTitle: "Layout delle schede delle notizie",
      newsCarouselLayoutDescription:
        "Carosello di notizie. Si muove orizzontalmente",
      newsCarouselLayoutTitle: "Layout del carosello di notizie",
      newsDisplayGridLayoutDescription: "Visualizzazione tipo griglia",
      newsDisplayGridLayoutTitle: "Griglia di visualizzazione dei notizie",
      newsImageLeftLayoutDescription:
        "Immagine a sinistra. Testo a destra. Pulsanti in basso",
      newsImageLeftLayoutTitle: "Layout dell'immagine delle notizie a sinistra",
      standarAboutUsLayoutDescription: "Vista standard",
      standarAboutUsLayoutTitle: "Layout standard su di noi",
      standarCoursesLayoutDescription: "Vista standard",
      standarCoursesLayoutTitle: "Layout standard dei corsi",
      standarDonationsLayoutDescription: "Vista standard",
      standarDonationsLayoutTitle: "Layout standard delle donazioni",
      standarEventsLayoutDescription: "Vista standard",
      standarEventsLayoutTitle: "Layout standard degli eventi",
      standarGalleryLayoutDescription: "Vista standard",
      standarGalleryLayoutTitle: "Layout standard della Galleria",
      standarHeaderLayoutDescription: "Vista standard",
      standarHeaderLayoutTitle: "Layout standard dell'intestazione",
      standarHomeLayoutDescription: "Vista standard",
      standarHomeLayoutTitle: "Layout standard della casa ",
      standarLinktreeLayoutDescription: "Vista standard",
      standarLinktreeLayoutTitle: "Layout standard dell'albero dei link",
      standarNewsLayoutDescription: "Vista standard",
      standarNewsLayoutTitle: "Layout standard delle notizie",
      standarSocialNetworksLayoutDescription: "Vista standard",
      standarSocialNetworksLayoutTitle: "Layout standard delle reti sociali",
      standarStreamingsLayoutDescription: "Vista standard",
      standarStreamingsLayoutTitle: "Layout standard dei flussi",
      standarTeamLayoutDescription: "Vista standard",
      standarTeamLayoutTitle: "Layout standard della squadra",
      teamLayoutDiagonallyDescription:
        "Scorrimento del testo in diagonale quando il mouse passa sopra",
      teamLayoutDiagonallyTitle: "Layout di squadra in diagonale",
      teamFlipCardLayoutDescription:
        "capovolgere la carta quando il mouse passa sopra",
      teamFlipCardLayoutTitle: "team flip card layout",
      teamLayoutScrollDownDescription:
        "Scorrimento del testo verso il basso testo quando il mouse passa",
      teamLayoutScrollDownTitle: "Layout della squadra scorrere verso il basso",
      teamLayoutScrollUpDescription:
        "Scorrimento del testo verso l'alto testo quando il mouse passa sopra",
      teamLayoutScrollUpTitle: "Layout della squadra scorrere verso l'alto",
    },
    linktree: {
      linksShown: "Collegamenti mostrati",
    },

    login: {
      enterUserAndPressOk: "Inserite il vostro utente e premete il tasto OK",
      enterValidPassword: "Inserire una password valida",
      enterValidUser: "Inserire un nome utente valido",
      exit: "Uscita",
      failedProcess:
        "Processo fallito. Impossibile inviare posta per reimpostare la password",
      forgotMyPassword: "Ho dimenticato la mia password",
      invalidPasswordOrUser:
        "Nome utente o password non validi / Se l'errore persiste contattare l'Amministratore",
      linkSentToResetPassword:
        "Un link sarà inviato all'email registrata per reimpostare la password",
      logIn: "Entra in",
      password: "Password",
      recoveryPassword: "Recupero password",
      successfulProcess:
        "Un processo di successo. Abbiamo inviato un'e-mail con un link per reimpostare la password",
    },
    modal: {
      assignmentSuccessful: "Assegnazione di successo",
      contactSuccessfullyEdited: "Il contatto è stato modificato con successo",
      componentUnselected: "Una sezione non è stata selezionata",
      courseSuccessfullyCreated: "Il corso è stato creato con successo",
      disablingSuccessful: "Disabilitazione di successo",
      disassignedSuccessfully: "Disassegnati con successo",
      editionSuccess: "Edizione di successo",
      errorAuthentication: "Errore di autenticazione",
      errorAuthenticationToLogin:
        "Si è verificato un errore nell'autenticazione, si verrà reindirizzati all'avvio",
      eventSuccessfullyDisabled: "L'evento è stato disabilitato con successo",
      eventSuccessfullyEdited: "L'evento è stato curato con successo",
      eventSuccessfullyUpdate: "L'evento è stato aggiornato con successo",
      eventSuccessfullyCreated: "L'evento è stata creata con successo",
      fieldEmpty: "Uno dei campi è vuoto",
      donationSuccesfullEdited: "La donazione è stata editata con successo",
      donationSuccesfullCreated: "La donazione è stata creata con successo",
      donationSuccesfullDisabled:
        "La donazione è stata disattivata con successo",
      groupSuccesfullEdited: "Il gruppo è stato editato con successo",
      groupSuccesfullCreated: "Il gruppo è stato creato con successo",
      groupSuccesfullDisabled: "Il gruppo è stato disattivato con successo",
      headerSuccessfullyEdited: "Il titolo è stato modificato con successo",
      footerSuccessfullyEdited:
        "Il piè di pagina è stato modificato con successo",
      homeSuccessfullyEdited: "L'inizio è stato modificato con successo",
      internalServerError: "Errore interno del server",
      internalServerErrorContactSupport:
        "Errore interno del server, si prega di riprovare più tardi o di contattare il supporto tecnico",
      linkButtonEmpty: "Il link del pulsante non può essere vuoto",
      memberSuccessfullyAdded: "Il membro è stato aggiunto con successo",
      memberSuccessfullyDisabled: "Il membro è stato disattivato con successo",
      memberSuccessfullyEdited: "Il membro è stato redatto con successo",
      modifiedSuccesfully: "Modifiche di successo",
      nameButtonEmpty: "Il nome del pulsante non può essere vuoto",
      newsSuccessfullyCreated: "La notizia è stata creata con successo",
      newsSuccessfullyDisabled: "La notizia è stata disattivata con successo",
      newsSuccessfullyUpdate: "La notizia è stata aggiornata con successo",
      noOrganizationSelected: "Nessuna organizzazione è stata selezionata",
      noSectionSelected: "Non è stata selezionata nessuna sezione",
      notSelectRol: "Non hai selezionato almeno un ruolo",
      notValidEmail: "L'e-mail non è valida",
      notValidID: "La carta d'identità non è valida",
      notValidLastname: "Il primo cognome non è valido",
      notValidName: "Il nome non è valido",
      notValidphone: "Il telefono non è valido",
      notValidUsername: "Il nome utente non è valido",
      organizationNotSelected: "Organizzazione non selezionata",
      organizationSuccesfullCreated:
        "L'organizzazione è stata creata con successo",
      organizationSuccesfullDisabled:
        "L'organizzazione è stata disattivata con successo",
      organizationSuccesfullEdited:
        "L'organizzazione è stata curata con successo",
      photoSuccessfullyAdded: "La foto è stata aggiunta con successo",
      photoSuccessfullyDisabled: "La foto è stata disattivata con successo",
      photoSuccessfullyEdited: "La foto è stata modificata con successo",
      positionSectionSuccessfullyModified:
        "La posizione è stata modificata con successo",
      privilegeSuccessfullyDisengaged:
        "Il privilegio è stato disimpegnato con successo",
      roleSuccesfullyAdded: "Il ruolo è stato aggiunto con successo",
      roleSuccesfullyDisabled: "Il ruolo è stato disabilitato con successo",
      roleSuccesfullyEdited: "Il ruolo è stato curato con successo ",
      sectionDisabledSuccessful: " La sezione è stata disattivata con successo",
      sectionUnselected: "Sezione non selezionata",
      successfulCreation: "Creazione di successo",
      successfulDisability: "Disabilitazione di successo",
      successfulEdition: "Edizione di successo",
      updateSuccessful: "Aggiornamento riuscito",
      userAlreadyCrated: "L'utente è già creato",
      userDisassignedSuccessfully: "L'utente è stato disinserito con successo",
      usersSuccesfullyAssigned: "Gli utenti sono stati assegnati con successo",
      userSuccesfullyCreated: "L'utente è stato creato con successo",
      userSuccesfullyDisabled: "L'utente è stato disabilitato con successo",
      userSuccesfullyEdited: "L'utente è stato modificato con successo",
      userSuccesRemovedRole: "L'utente è stato disinserito con successo dal",
      userUpdatedSuccessfully: "Utente aggiornato con successo",
      visibilitySubpage:
        "La visibilità della sottopagina è stata modificata con successo",
      wrongFile: "Campo sbagliato",
    },
    organization: {
      addOrganization: "Aggiungi organizzazione",
      editOrganization: "Modifica organizzazione",
      myOrganization: "La mia organizzazione",
      organization: "Organizzazione",
      organizationList: "Elenco delle organizzazioni",
      organizations: "Organizzazioni",
    },
    sections: {
      aboutUs: "Chi siamo",
      author: "Autore",
      contactUs: "Contattateci",
      courses: "Corsi",
      donations: "Donazioni",
      events: " Eventi",
      footer: "Piè di pagina",
      forms: "Moduli",
      gallery: "Galleria",
      header: "Intestazione",
      home: "Home",
      infoPage: "Pagina informativa",
      linkFacebook:
        "Aggiungi il link alla pagina Facebook. Per esempio: https://www.facebook.com/SOMOS-AURA-114538460262706",
      linkInstagram: "Inserire il link di Instagram",
      linkTree: "Link interessanti",
      linkPostInstagram:
        "Aggiungi il link al post di instagram. Per esempio: https://www.instagram.com/p/CHVtSlcDM3A/",
      linkTwitter: "Inserisci il link di Twitter",
      linkAurapp: "Inserisci il link di Aurapp",
      mision: "Missione",
      news: "Notizie",
      sections: "Sections",
      socialnetworks: "Reti sociali",
      streamings: "Streaming",
      publicationDate: "Data di pubblicazione",
      previsualization: "Previsualizzazione",
      team: "Squadra",
      UsernameTwitter: "Aggiungi il tuo nome utente. Per esempio: auraSomos",
      vision: "Visione",
    },
    streaming: {
      after: "Dopo",
      allDays: "Tutti i giorno",
      allMonths: "Tutti i mesis",
      monToFri: "Da lunedì a venerdì",
      annually: "Annualmente",
      april: "aprile",
      august: "agosto",
      custom: "Personalizzato",
      dateInit: "Data di inizio",
      day: "giorni",
      days: "giorni",
      descriptionAlertEditModal1:
        "Le modifiche non si applicano agli eventi che sono già stati creati. Le modifiche si rifletteranno negli eventi che saranno generati automaticamente dal",
      descriptionAlertEditModal2:
        "Se vuoi cambiare le informazioni di un evento già creato, clicca qui.",
      descriptionAlertEditModal3:
        "Se vuoi modificare il titolo, la descrizione, il link o la data dell'evento, clicca qui. Per modificare i pulsanti e il tipo di evento, potete farlo qui sotto.",
      descriptionAlertEditModal4:
        "Per modificare i pulsanti e il tipo di evento, puoi farlo qui sotto.",
      december: "dicembre",
      end: "Termina",
      eventDate: "Data dell'evento",
      eventGeneratedPerMonth:
        "Gli eventi sono generati automaticamente ogni mese",
      endDate: "Data di fine",
      every: "Ogni",
      everyWeek: "Ogni settimana",
      eventOcurrency: "Ripetere ogni",
      eventWillBeRepeat: "L'evento sarà ripetuto",
      february: "febbraio",
      fifth: "il quinto",
      finishEvent: "Fine dell'evento",
      first: "il primo",
      firstEventDate: "Data del primo evento",
      fourth: "il quarto",
      frecuencyValue: "Il numero di ripetizioni",
      friday: "Venerdì",
      fromAux: "dal",
      from: "Dal",
      hour: "tempo",
      hours: "ore",
      initDate: "Data di inizio",
      january: "gennaio",
      july: "july",
      june: "luglio",
      never: "Mai",
      futureStreamings: "Prossimi streaming",
      march: "marzo",
      mass: "Massa",
      noRepeat: "Non viene ripetuto",
      november: "novembre",
      may: "maggio",
      minute: "minuto",
      minutes: "minuti",
      modalErrorBody: "Controllare i seguenti campi",
      modalErrorStartDate:
        "La data di inizio dell'evento non può essere successiva alla fine dell'evento",
      modalErrorDescription:
        "Il numero di caratteri nella descrizione non può essere maggiore o uguale a 250",
      modalErrorTitle: "Alcuni campi non sono compilati correttamente",
      monday: "Lunedì",
      month: "mese",
      months: "mesi",
      pastStreamings: "Streaming passati",
      repeatBy: "Ripeti per",
      repeated: "Si ripete",
      repetitionMonth: "Ripetizioni per mese",
      rosary: "Rosario",
      october: "ottobre",
      on: "",
      saturday: "Sabato",
      second: "il secondo",
      selectOcurrence: "Ripeti ogni",
      september: "settembre",
      sunday: "Domenica",
      the: "el",
      theDay: "il giorni",
      theDays: "I giorni",
      third: "il terzo",
      thursday: "Giovedì",
      timeFinal: "Ora di inizio",
      timeInit: "Tra di inizio",
      timesOcurred: "volte l'evento si è verificato",
      to: "al",
      tooltipAddButton: "Aggiungi un nuovo streaming",
      tooltipEventDate: "Data di inizio e fine dell'evento",
      tooltipMonths:
        "A seconda dei giorni selezionati, l'evento sarà ripetuto ogni mese",
      tooltipOcurrence:
        "Gli eventi saranno generati automaticamente con la frequenza scelta",
      tooltipTypeEvent: "Il tipo di evento(i) che stai per creare",
      tooltipSaveButton: "Salvare i flussi",
      tooltipYears: "L'evento programmato sarà ripetuto ogni anno",
      tuesday: "Martedì",
      typeEvent: "Tipo di evento",
      urlMustBeChannel: "L'url deve essere un canale youtube",
      wednesday: "Mercoledì",
      weHaveEventLive: "Abbiamo un evento in diretta streaming!",
      weHaveEventLives: "Abbiamo eventi in diretta streaming!",
      weeks: "settimane",
      week: "settimane",
      weekDays: "Giorni della settimana",
      year: "anno",
      years: "anni",
    },
    subpages: {
      associateToExistingSubpage: "Collegamento alla sottopagina esistente",
      createSubpage: "Crea sottopagina",
      editSubpagesAssociation: "Modifica associazione a sottopagine",
      noSubpagesAssociated: "Nessuna sottopagina associata",
      sectionMustHaveSubpage:
        "Questa sezione deve essere associata ad una sottopagina per essere visibile",
      subpageName: "Nome della sottopagina",
      subpage: "Sottopagina",
      subpagesEditionSuccess:
        "Edizione di successo della sotto-pagina dell'associazione",
      subpagesTooltip:
        "Indica l'associazione della sezione ad una sottopagina. Se c'è una sotto-pagina associata, la sezione sarà visualizzata sul sito web in una pagina separata dalla home page",
      associatedSubpages: "Sottopagine associate",
    },
  },
});
export default strings;
